<ion-modal [isOpen]="isOpen" [initialBreakpoint]="1" [breakpoints]="[0, 0.8, 1]" (didDismiss)="didDismiss()" mode="ios" [ngStyle]="{ '--max-height': modalMaxHeight }">
	<ng-template>
		<ion-header class="ion-no-border">
			<ng-container [ngSwitch]="getMode()">
				<!--DEFAULT HEADER-->
				<ion-row *ngSwitchCase="'default'">
					<ion-toolbar class="ion-text-end white-background no-padding" style="margin-top: 1.5rem">
						<sav-button
							style="box-sizing: border-box"
							color="button-ghost-blue"
							size="small"
							(click)="triggerSaveTextualNote()"
							[class]="editingMode ? '' : 'initially-transparent-not-clickable'"
							id="buttonSaveNote"
							aria-label="buttonSaveNote">
							<span [innerHTML]="'noteTaking.textualNote.save' | translate"></span>
						</sav-button>
					</ion-toolbar>
				</ion-row>
				<!--NOTEHISTORY HEADER-->
				<ion-row *ngSwitchCase="'noteHistory'">
					<ion-toolbar class="white-background no-padding">
						<ion-col>
							<div style="margin: 1.6rem 1.6rem 0">
								<ion-row ion-no-padding>
									<ion-col size="8" style="align-items: center; display: flex" class="ion-no-padding">
										<span class="sav-headline-2">{{ noteObject.activity_title || 'Daily Challenge' }}</span>
									</ion-col>
									<ion-col
										[class.initially-transparent-not-clickable]="!editingMode"
										(click)="triggerSaveHistoricNote()"
										size="4"
										style="align-items: center; display: flex; flex-direction: row-reverse"
										class="ion-text-end ion-no-padding">
										<span class="sav-label-3" style="color: #049bda" [innerHTML]="'noteTaking.textualNote.save' | translate" color="button-ghost-blue"></span>
									</ion-col>
								</ion-row>

								<ion-row style="margin-top: 1.3rem">
									<span class="sav-label-5" [innerHTML]="'profile.profilePage.selectedWeek' | translate: { week: noteObject.week }"></span> &nbsp; &nbsp;
									<span class="sav-label-5 transparent-60-grey-text"> {{ getWeekDayLabel(noteObject.date) | translate }}.{{ noteObject.date | date: 'dd' }}</span>
								</ion-row>
								<ng-container [ngSwitch]="noteObject.type">
									<ion-row>
										<ion-col style="align-items: center; display: flex" class="ion-no-padding">
											<sav-chip *ngSwitchCase="NoteActivityType.exercise" color="chip-light" size="tiny" type="rounded">
												<span [innerHTML]="'journey.notesScreen.exercise' | translate"></span>
											</sav-chip>
											<sav-chip *ngSwitchCase="NoteActivityType.daily_challenge" color="chip-yellow" size="tiny" type="rounded">
												<span [innerHTML]="'journey.notesScreen.dailyChallenge' | translate"></span>
											</sav-chip>
										</ion-col>
										<ion-col style="justify-content: flex-end; display: flex" class="ion-no-padding">
											<ion-button mode="ios" color="white" size="small" class="modal-redo-button" (click)="triggerRedoForActivity(noteObject)">
												<ng-container [ngSwitch]="noteObject.type">
													<div *ngSwitchCase="NoteActivityType.exercise" class="sav-label-5 black-text" [innerHTML]="'journey.notesScreen.redoExercise' | translate"></div>
													<div
														*ngSwitchCase="NoteActivityType.daily_challenge"
														class="sav-label-5 black-text"
														[innerHTML]="'journey.notesScreen.redoDailyChallenge' | translate"></div>
												</ng-container>
											</ion-button>
										</ion-col>
									</ion-row>
								</ng-container>
							</div>
						</ion-col>
					</ion-toolbar>
				</ion-row>
				<!--FEEDBACK HEADER-->
				<ion-row *ngSwitchCase="'settings'" style="margin: 1.6rem">
					<ion-toolbar class="ion-text-start white-background no-padding" style="margin-top: 1.5rem">
						<span class="sav-headline-1" [innerHTML]="'settings.feedback.feedbackTitle' | translate"></span>
					</ion-toolbar>
				</ion-row>
			</ng-container>
		</ion-header>
		<ion-content>
			<div [class]="!editingMode && loaded ? 'outline-text overflow' : 'overflow'">
				<ion-grid class="no-grid-padding" style="margin: 0 1.6rem">
					<!-- MAIN CONTENT -->
					<ion-row class="ion-align-items-center savvi-parent-align-center">
						<ion-col class="savvi-center-col">
							<ion-grid class="savvi-card-grid no-grid-padding">
								<ion-skeleton-text *ngIf="!loaded" [animated]="true" style="width: 100%; height: 40rem; border-radius: 1.2rem"></ion-skeleton-text>

								<ng-container *ngIf="editingMode; then editing; else viewing"></ng-container>
								<ng-template #editing>
									<ion-row style="margin-bottom: 5rem; margin-top: 0.2rem">
										<ion-col *ngIf="loaded" style="max-height: 28rem; min-height: 15rem" id="quillEditor" aria-label="quillEditor">
											<ng-container [ngSwitch]="getMode()">
												<!--DEFAULT EDITOR-->
												<quill-editor
													*ngSwitchCase="'default'"
													[placeholder]="getPlaceholderTextTextualNote()"
													class="editor"
													[styles]="{ 'font-size': '1.6rem', 'line-height': '2.4rem', 'font-family': 'Poppins' }"
													[(ngModel)]="textualNote.text">
												</quill-editor>
												<!--END DEFAULT EDITOR-->
												<!--NOTEHISTORY EDITOR-->
												<quill-editor
													*ngSwitchCase="'noteHistory'"
													[placeholder]="getPlaceholderTextTextualNote()"
													class="editor"
													[styles]="{ 'font-size': '1.6rem', 'line-height': '2.4rem', 'font-family': 'Poppins' }"
													[(ngModel)]="noteObject.text"></quill-editor>
												<!--END NOTEHISTORY EDITOR-->
												<!--FEEDBACK EDITOR-->
												<quill-editor
													*ngSwitchCase="'settings'"
													[modules]="feedbackEditor"
													[format]="'text'"
													[placeholder]="getPlaceholderTextFeedback()"
													class="editor"
													[styles]="{ 'font-size': '1.6rem', 'line-height': '2.4rem', 'font-family': 'Poppins' }"
													[(ngModel)]="feedbackString"
													(ngModelChange)="isFeedbackDisabled()">
												</quill-editor>
												<!--END FEEDBACK EDITOR-->
											</ng-container>
										</ion-col>
									</ion-row>
								</ng-template>

								<ng-template #viewing>
									<ion-row>
										<ion-col *ngIf="loaded">
											<ng-container [ngSwitch]="getMode()">
												<!--DEFAULT EDITOR-->
												<div
													*ngSwitchCase="'default'"
													class="sav-paragraph-text-2 hyphenate"
													style="min-height: 10rem"
													[(innerHTML)]="textualNote.text"
													(click)="editTextualNote()"></div>
												<!--NOTEHISTORY EDITOR-->
												<div
													*ngSwitchCase="'noteHistory'"
													class="sav-paragraph-text-2 hyphenate"
													style="min-height: 15rem"
													[(innerHTML)]="noteObject.text"
													(click)="editTextualNote()"></div>
												<!--FEEDBACK EDITOR-->
												<div *ngSwitchCase="'settings'" class="sav-paragraph-text-2 hyphenate" style="min-height: 15rem" (click)="editTextualNote()"></div>
											</ng-container>
										</ion-col>
									</ion-row>
								</ng-template>
							</ion-grid>
						</ion-col>
					</ion-row>
					<!-- MAIN CONTENT END -->
				</ion-grid>
			</div>
		</ion-content>

		<!-- FOOTER -->
		<ng-container [ngSwitch]="getMode()">
			<!--DEFAULT FOOTER-->
			<ion-footer *ngSwitchCase="'default'" class="white-background ion-no-border ion-padding">
				<ion-toolbar *ngIf="!editingMode" class="white-background ion-no-padding">
					<sav-button size="medium" expand="block" (click)="editTextualNote()">
						<span [innerHTML]="'noteTaking.textualNote.edit' | translate"></span>
					</sav-button>
				</ion-toolbar>
			</ion-footer>
			<!--FEEDBACK FOOTER-->
			<ion-footer *ngSwitchCase="'settings'" class="white-background ion-no-border ion-padding">
				<ion-toolbar class="white-background ion-no-padding">
					<sav-button size="medium" expand="block" (click)="triggerSendFeedback()" [disabled]="feedbackInsufficient ? true : null" id="buttonEditNote" aria-label="buttonEditNote">
						<span [innerHTML]="'settings.feedback.sendFeedback' | translate"></span>
					</sav-button>
				</ion-toolbar>
			</ion-footer>
			<!--NOTE HISTORY FOOTER-->
			<ion-footer *ngSwitchCase="'noteHistory'" class="white-background ion-no-border ion-padding">
				<!--Potentially : OR for NOTE HISTORY / DEFAULT -->
				<ion-toolbar *ngIf="!editingMode" class="white-background ion-no-padding">
					<sav-button size="medium" expand="block" (click)="editTextualNote()">
						<span [innerHTML]="'noteTaking.textualNote.edit' | translate"></span>
					</sav-button>
				</ion-toolbar>
			</ion-footer>
		</ng-container>
		<!-- FOOTER END-->
	</ng-template>
</ion-modal>
