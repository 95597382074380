<ion-app>
	<div *ngIf="showStreakCounterAnimation">
		<ion-backdrop visible="true" class="streak-count-animation-backdrop"></ion-backdrop>
		<ion-grid class="no-grid-padding grid-position" style="padding-top: 8rem">
			<ion-row class="savvi-charakter-Animation">
				<ion-col size="3" class="ion-text-center full-height">
					<div id="animationStreakCounter" aria-label="animationStreakCounter">
						<ng-lottie [options]="sidePeekAnimationOptions" (animationCreated)="animationCreated($event)"></ng-lottie>
					</div>
				</ion-col>
				<ion-col size="9" class="full-height" style="margin-top: 10rem">
					<div id="speechBubble" aria-label="speechBubble" class="small-hero-speech-bubble initially-transparent speech-bubble sb-left-middle hyphenate ion-margin">
						<span class="sav-paragraph-text-1" [innerHTML]="bubbleText"></span>
					</div>
				</ion-col>
			</ion-row>
			<ion-row id="btnRow" class="initially-transparent-not-clickable ion-justify-content-spacebetween ion-padding full-width" style="padding-top: 3rem">
				<ion-col>
					<sav-button color="button-ghost" size="medium" expand="block" (click)="replayAnimation()" id="buttonReplayAnimation" aria-label="buttonReplayAnimation">
						<span style="color: white" [innerHTML]="'streakCounter.tutorialRepeatButtonText' | translate"></span
					></sav-button>
				</ion-col>
				<ion-col>
					<sav-button
						size="medium"
						expand="block"
						(click)="closeStreakCounterTutorialAnimation()"
						id="buttonCloseStreakCounterTutorialAnimation"
						aria-label="buttonCloseStreakCounterTutorialAnimation">
						<span [innerHTML]="'continue' | translate"></span>
					</sav-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</div>

	<ion-router-outlet [swipeGesture]="false"></ion-router-outlet>
	<app-textual-note-modal [isOpen]="showNoteModal" [didDismiss]="onDismissNoteModal" [textualNote]="textualNote" [noteObject]="noteObject"></app-textual-note-modal>
</ion-app>
