// Generated by https://quicktype.io

export interface FeedbackProfile {
	none_visited_feedback: boolean;
	feedback: TextFeedbacks;
	skill_stats: SkillStatsOverview;
	textbox_feedbacks: {
		structure: {
			all_textbox_feedbacks: TextBoxFeedback[];
			non_visited_structure: NonVisitedStructureSkills[];
		};
	};
}

export interface NonVisitedStructureSkills {
	skill_id: number;
	skill_name: string;
	behaviors: {
		behavior_id: number;
		behavior_name: string;
	}[];
}

export interface TextBoxFeedback {
	skill_id: number;
	skill_name: string;
	behaviors: {
		behavior_id: number;
		behavior_name: string;
		according_feedback: {
			[GivenFrom.Manager]: TextFeedbackQuestion[];
			[GivenFrom.Subordinate]: TextFeedbackQuestion[];
			[GivenFrom.Colleague]: TextFeedbackQuestion[];
		};
	}[];
}

export interface TextFeedbackQuestion {
	feedback_question: {
		id: number;
		is_inverse: boolean;
		behavior: number;
		microtraining: number;
		text: string;
		feedback_textbox_template: string;
	};
	textbox_input: string;
	visited: boolean;
	created_at: string;
	id: number;
}

export interface TextFeedbacks {
	colleague: TextFeedback[];
	manager: TextFeedback[];
	subordinate: TextFeedback[];
	total: TextFeedback[];
}

export interface TextFeedback {
	created_at: string;
	textbox_input: string;
	given_from: GivenFrom;
	visited: boolean;
}

export enum GivenFrom {
	Own = 'own',
	Total = 'total',
	Manager = 'manager',
	Colleague = 'colleague',
	Subordinate = 'subordinate'
}

export interface SkillStatsOverview {
	colleague: SkillStats[];
	manager: SkillStats[];
	subordinate: SkillStats[];
	own: SkillStats[];
	total: SkillStats[];
}

export interface SkillStats {
	skill_stat: SkillStat;
	behavior_stats: BehaviorStat[];
}

export type BehaviorStat = {
	behavior: FeedbackBehavior;
	score: number;
	given_from?: GivenFrom;
	user?: number;
	deviation_to_user?: number;
	package?: number;
	is_blindspot?: boolean;
};

export interface SkillStat {
	skill: FeedbackSkill;
	score: number;
	given_from?: GivenFrom;
	user?: number;
	is_blindspot?: boolean;
	deviation_to_user?: number;
	package?: number;
}

interface FeedbackSkill {
	name: string;
	id: number;

	description: string;
}

export type FeedbackBehavior = {
	id: number;
	name: string;
	description: string;
};

export type BehaviorFeedbackPageProps = {
	name: string;
	id: number;
	feedbacks: {
		[GivenFrom.Colleague]: TextFeedbackQuestion[];
		[GivenFrom.Manager]: TextFeedbackQuestion[];
		[GivenFrom.Subordinate]: TextFeedbackQuestion[];
		[GivenFrom.Total]: TextFeedbackQuestion[];
	};
};
