import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class FeedbackVotingService {
	private classification: string;
	private keyword: string;
	private feedback_statement: string = '';

	constructor(private _apiService: ApiService) {}

	setFeedbackValues(classification: string, keyword: string, feedback_statement: string) {
		this.classification = classification;
		this.keyword = keyword;
		this.feedback_statement = feedback_statement;
	}

	sendFeedbackOnMicrotraining(package_id_context: number, microtraining_id_context: number) {
		const postData = {
			microtraining_id: microtraining_id_context,
			package_id: package_id_context,
			classification: this.classification,
			keyword: this.keyword,
			feedback_statement: this.feedback_statement
		};
		this._apiService.post('/feedbackVoting/vote', postData).toPromise();

		this.resetFeedbackVotingService();
	}

	resetFeedbackVotingService() {
		this.classification = null;
		this.keyword = null;
		this.feedback_statement = '';
	}
}
