import { StepResourceType } from './step-resource-type';

export type ProgressionStateTraining = {
	state: ExerciseProgressionStateName;
	order_number: number;
	previous_steps_info: PreviousStepInfo[];
};

export type ProgressionStateDailyChallenge = {
	order_number?: number;
	previous_steps_info: PreviousStepInfo[];
};

export type PreviousStepInfo =
	| ({ order_number: number } & (
			| PreviousStepInfoTextual
			| PreviousStepInfoInfographic
			| PreviousStepInfoQuiz
			| PreviousStepInfoMatchingPair
			| PreviousStepInfoVideo
			| PreviousStepInfoAudio
			| PreviousStepInfoGapText
	  ))
	| {};

export type PreviousStepInfoTextual = {
	step_type: StepResourceType.StepTextual;
	result: [];
};

export type PreviousStepInfoInfographic = {
	step_type: StepResourceType.StepInfographic;
	result: [];
};

export type PreviousStepInfoQuiz = {
	step_type: StepResourceType.StepQuiz;
	result: [
		{
			step_quiz: number;
			answer_choice: number;
			correct_choice: boolean;
		}
	];
};

export type PreviousStepInfoMatchingPair = {
	step_type: StepResourceType.StepMatchingPairs;
	result: {
		pair_id: number;
		tries_till_right_answer: number;
	}[];
};

export type PreviousStepInfoVideo = {
	step_type: StepResourceType.StepVideo;
	result: [];
};

export type PreviousStepInfoAudio = {
	step_type: StepResourceType.StepAudio;
	result: [];
};

export type PreviousStepInfoGapText = {
	step_type: StepResourceType.StepGapText;
	result: { gap_text: { gap_id: number; tries_till_right_answer: number }[] };
};

export enum ExerciseProgressionStateName {
	VideoDone = 'video_done',
	ExerciseStepDone = 'exercise_step_done',
	ExerciseCompletlyDone = 'exercise_completly_done',
	GoalSelectionDone = 'goal_selection_done'
}
