// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import Config from '../config.json';
import { OktaProvider, ProviderOptions, iOSOptions, WebOptions } from '@ionic-enterprise/auth';

export const environment = {
	production: false,
	isNative: false,
	apiUrl: 'https://api.dev.savvi.hr/api/v2.10.0',
	OTCObjectStorage: 'https://savvi.obs.eu-de.otc.t-systems.com/',

	// Assets
	i18nUrl: 'https://dev-savvi.obs.eu-de.otc.t-systems.com/i18n/',
	OTCAssetsUrl: 'https://dev-savvi.obs.eu-de.otc.t-systems.com/',
	useLocalConfigFile: false,
	maximumAssetVersion: 1,

	//oneSignalIds:
	//DEV: bfa5c1aa-4efc-4a95-92c7-c8b607a44cdc
	//STAG: 8d8eba7d-8817-47dc-add3-d233135ce9df
	//PROD: b362a91d-76d8-4701-8d90-4c69e6fdf0a0
	oneSignalId: 'bfa5c1aa-4efc-4a95-92c7-c8b607a44cdc'
	// apiUrl: 'http://localhost:8000/api'
};

export const authProvider = new OktaProvider();

export const nativeZitadelAuthOptions: ProviderOptions = {
	// client or application id for provider
	clientId: '200541598696669441@savvi',
	// the discovery url for the provider
	// OpenID configuration
	discoveryUrl: 'https://savvi-dev-p72yue.zitadel.cloud/.well-known/openid-configuration',
	// the URI to redirect to after log in
	redirectUri: 'hr.savvi.training://',
	// requested scopes from provider
	scope: 'openid offline_access email profile',
	// the audience, if applicable
	//audience: 'FILL_IN',
	// the URL to redirect to after log out
	logoutUrl: 'hr.savvi.training://',
	audience: ''
};

export const additionalIOSAuthOptions: iOSOptions = {
	// The type of iOS webview to use. 'shared' will use a webview that can
	// share session/cookies on iOS to provide SSO across multiple apps but
	// will cause a prompt for the user which asks them to confirm they want
	// to share site data with the app. 'private' uses a webview which will
	// not prompt the user but will not be able to share session/cookie data
	// either for true SSO across multiple apps.
	webView: 'private'
};

export const webZitadelAuthOptions: ProviderOptions = {
	// client or application id for provider
	clientId: '200248586968039681@savvi',
	// the discovery url for the provider
	// OpenID configuration
	discoveryUrl: 'https://savvi-dev-p72yue.zitadel.cloud/.well-known/openid-configuration',
	// the URI to redirect to after log in
	redirectUri: 'https://app.dev.savvi.hr',
	// requested scopes from provider
	scope: 'openid offline_access email profile',
	// the audience, if applicable
	//audience: 'FILL_IN',
	// the URL to redirect to after log out
	logoutUrl: 'https://app.dev.savvi.hr',
	audience: ''
};

export const additionalWebAuthOptions: WebOptions = {
	uiMode: 'popup',
	authFlow: 'PKCE'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/error';  // Included with Angular CLI.
