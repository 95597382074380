import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@environments/environment';
import { PackageService } from './package.service';
import { ApiService } from './api.service';
import { SavviTranslateHttpLoader } from '@app/_helpers/savvi-translate-http-loader';
import { Locale } from 'date-fns';
import { de, enUS, fr } from 'date-fns/locale';

export function HttpLoaderFactory(apiService: ApiService) {
	return new SavviTranslateHttpLoader(apiService, environment.i18nUrl, '.json');
}

@Injectable({
	providedIn: 'root'
})
export class TranslationService {
	private _language: string;
	private _locale: Locale;

	constructor(
		private packageService: PackageService,
		public translate: TranslateService,
		public apiService: ApiService
	) {
		this._initTranslate();
	}

	private _initTranslate(language?: string) {
		// Set the default language for translation strings, and the current language.
		this.translate.setDefaultLang('de');
		if (language) {
			this._language = language;
			this.setLocale(language);
		} else {
			// Set your language here
			this._language = 'de';
			this.setLocale('de');
		}
		this.translate.use(this._language);
		this.apiService.language = this._language;
	}

	public set language(language: string) {
		this._initTranslate(language);
	}

	public get locale(): Locale {
		return this._locale;
	}

	private setLocale(language: string) {
		switch (language) {
			case 'de':
				this._locale = de;
				break;
			case 'en':
				this._locale = enUS;
				break;
			case 'fr':
				this._locale = fr;
				break;
			default:
				this._locale = de;
				break;
		}
	}
}
