import { ElementRef, Injectable } from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';

type ElementIdentifier = string | ElementRef | { el: HTMLElement };
type ElementIdentifiers = ElementIdentifier | ElementIdentifier[];

@Injectable({
	providedIn: 'root'
})
export class AnimationFactoryService {
	constructor(private animationCtrl: AnimationController) {}

	/**
	 * Creates a fade-in animation for the specified element(s).
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeIn(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = true) {
		let elements: HTMLElement[];

		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 0
			})
			.duration(duration)
			.fromTo('opacity', '0', '1')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});
		return animation;
	}

	/**
	 * Creates an animation that fades out the specified elements.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeOut(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = false) {
		let elements: HTMLElement[];

		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 1
			})
			.duration(duration)
			.fromTo('opacity', '1', '0')
			.easing('ease-out')
			.afterStyles({
				opacity: 0,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});
		return animation;
	}

	/**
	 * Creates a fade-in animation with a slide effect.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeInWithSlide(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = true) {
		console.log('identifiers', identifiers);
		let elements: HTMLElement[];

		console.log('isArray', Array.isArray(identifiers));
		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 0
			})
			.duration(duration)
			.fromTo('opacity', '0', '1')
			.fromTo('transform', 'translateY(1rem)', 'translateY(0rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});

		return animation;
	}

	/**
	 * Creates an animation that fades out and slides down the specified elements.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeOutWithSlide(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = false) {
		let elements: HTMLElement[];

		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 1
			})
			.duration(duration)
			.fromTo('opacity', '1', '0')
			.fromTo('transform', 'translateY(0rem)', 'translateY(1rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 0,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});
		return animation;
	}

	/**
	 * Creates a fade-in animation with a slide effect from left to right.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeInWithSlideLeftToRight(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = true) {
		console.log('identifiers', identifiers);
		let elements: HTMLElement[];

		console.log('isArray', Array.isArray(identifiers));
		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 0
			})
			.duration(duration)
			.fromTo('opacity', '0', '1')
			.fromTo('transform', 'translateX(-1rem)', 'translateX(0rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});

		return animation;
	}

	/**
	 * Creates a fade-out animation with a slide effect from left to right.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeOutWithSlideLeftToRight(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = false) {
		console.log('identifiers', identifiers);
		let elements: HTMLElement[];

		console.log('isArray', Array.isArray(identifiers));
		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 1
			})
			.duration(duration)
			.fromTo('opacity', '1', '0')
			.fromTo('transform', 'translateX(0rem)', 'translateX(-1rem)')
			.easing('ease-in')
			.afterStyles({
				opacity: 0,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});

		return animation;
	}

	/**
	 * Creates a fade-in animation with a slide effect from right to left.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeInWithSlideRightToLeft(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = true) {
		console.log('identifiers', identifiers);
		let elements: HTMLElement[];

		console.log('isArray', Array.isArray(identifiers));
		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 0
			})
			.duration(duration)
			.fromTo('opacity', '0', '1')
			.fromTo('transform', 'translateX(1rem)', 'translateX(0rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});

		return animation;
	}

	/**
	 * Creates a fade-out animation with a slide effect from right to left.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	fadeOutWithSlideRightToLeft(identifiers: ElementIdentifiers, duration: number = 500, pointerEventsAfterStyles: boolean = false) {
		console.log('identifiers', identifiers);
		let elements: HTMLElement[];

		console.log('isArray', Array.isArray(identifiers));
		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.beforeStyles({
				opacity: 1
			})
			.duration(duration)
			.fromTo('opacity', '1', '0')
			.fromTo('transform', 'translateX(0rem)', 'translateX(1rem)')
			.easing('ease-in')
			.afterStyles({
				opacity: 0,
				'pointer-events': pointerEventsAfterStyles ? 'auto' : 'none'
			});

		return animation;
	}

	/**
	 * Creates a wiggle animation.
	 *
	 * @param identifiers - The identifiers of the elements to animate. This can be either a single identifier or an array of identifiers.
	 *                     An identifier can be a string representing the element's ID, or an HTMLElement object.
	 * @param duration - The duration of the animation in milliseconds. Default is 500ms.
	 * @returns The created animation object.
	 */
	wiggle(identifiers: ElementIdentifiers, duration: number = 500) {
		let elements: HTMLElement[];

		if (Array.isArray(identifiers)) {
			elements = identifiers.map((identifier) => this.getElement(identifier));
		} else {
			elements = [this.getElement(identifiers)];
		}

		const animation = this.animationCtrl
			.create()
			.addElement(elements)
			.duration(duration)
			.keyframes([
				{ offset: 0, transform: 'rotate(0deg)' },
				{ offset: 0.1, transform: 'rotate(60deg)' },
				{ offset: 0.2, transform: 'rotate(-53deg)' },
				{ offset: 0.3, transform: 'rotate(45deg)' },
				{ offset: 0.4, transform: 'rotate(-40deg)' },
				{ offset: 0.5, transform: 'rotate(32deg)' },
				{ offset: 0.6, transform: 'rotate(-24deg)' },
				{ offset: 0.7, transform: 'rotate(18deg)' },
				{ offset: 0.8, transform: 'rotate(-10deg)' },
				{ offset: 0.9, transform: 'rotate(4deg)' },
				{ offset: 1, transform: 'rotate(0deg)' }
			])
			.easing('ease-in-out');

		return animation;
	}

	/**
	 * Retrieves the HTML element based on the provided identifier. This function can be used when implementing custom animations and still getting the ElementRef through the angular decorator @ViewChild.
	 *
	 * @param identifier - The identifier of the element. It can be a string representing the element's ID,
	 * an ElementRef object, or an Ionic component.
	 * @returns The HTML element corresponding to the identifier, or undefined if the element is not found.
	 */
	public getElement(identifier: ElementIdentifier): HTMLElement {
		if (typeof identifier === 'string') {
			// Query for the element by ID
			return document.getElementById(identifier);
		} else if (identifier instanceof ElementRef) {
			// The identifier is an ElementRef
			return identifier.nativeElement;
		} else if ('el' in identifier) {
			// The identifier is an Ionic component
			return identifier.el;
		}

		console.error('Could not find element with identifier', identifier);
		return undefined;
	}
}
