import { Injectable } from '@angular/core';
import { HistoryStartupLocation } from '@app/_models/history-startup-location';
import { WeekHistoryItem } from '@app/_models/week-history-item';
import { NoteHistoryItem } from '@app/_models/note-history-item';
import { ApiService } from './api.service';
import { map } from 'rxjs';
import { MicrotrainingsSelection } from '@app/_models/microtrainings-selection';
import { HttpParams } from '@angular/common/http';
import { LibraryStartupLocation } from '@app/library-start-up-location';
import { AchievementStartupLocation } from '@app/achievement-startup-location';

@Injectable({
	providedIn: 'root'
})
export class JourneyService {
	private historyStartupLocation: HistoryStartupLocation = HistoryStartupLocation.home;
	private libraryStartupLocation: LibraryStartupLocation = LibraryStartupLocation.home;
	private achievementStartupLocation: AchievementStartupLocation = AchievementStartupLocation.home;
	private completeWeekHistory: WeekHistoryItem[];
	private completeNoteHistory: NoteHistoryItem[];
	private valuesForHistoryAccordionGroup: string[] = ['0'];
	private valuesForNoteHistoryAccordionGroup: string[] = ['0'];
	private microtrainingHistory: MicrotrainingsSelection[];
	constructor(private apiService: ApiService) {}

	getCompleteWeekHistory(): WeekHistoryItem[] {
		return this.completeWeekHistory;
	}

	getCompleteNoteHistory(): NoteHistoryItem[] {
		return this.completeNoteHistory;
	}

	setHistoryStartupLocationHome(): void {
		console.log('History Startup location now set to Home');
		this.historyStartupLocation = HistoryStartupLocation.home;
	}

	setHistoryStartupLocationJourney(): void {
		this.historyStartupLocation = HistoryStartupLocation.journey;
	}

	getHistoryStartupLocation(): HistoryStartupLocation {
		console.log('History Startup location is: ' + HistoryStartupLocation[this.historyStartupLocation]);
		return this.historyStartupLocation;
	}

	setLibraryStartupLocationJourney(): void {
		console.log('Library Startup location now set to Journey');
		this.libraryStartupLocation = LibraryStartupLocation.journey;
	}

	setLibraryStartupLocationHome(): void {
		console.log('Library Startup location now set to Home');
		this.libraryStartupLocation = LibraryStartupLocation.home;
	}

	setLibraryStartupLocationEndOfJourneyCongratulations(): void {
		console.log('Library Startup location now set to EndOfJourneyCongratulations');
		this.libraryStartupLocation = LibraryStartupLocation.endOfJourneyCongratulations;
	}

	getLibraryStartupLocation(): LibraryStartupLocation {
		console.log('Library Startup location is: ' + LibraryStartupLocation[this.libraryStartupLocation]);
		return this.libraryStartupLocation;
	}

	setAchievementStartupLocationJourney(): void {
		console.log('Achievement Startup location now set to Journey');
		this.achievementStartupLocation = AchievementStartupLocation.journey;
	}

	setAchievementStartupLocationHome(): void {
		console.log('Achievement Startup location now set to Home');
		this.achievementStartupLocation = AchievementStartupLocation.home;
	}

	setAchievementStartupLocationMultiPackageHub(): void {
		console.log('Achievement Startup location now set to MultiPackageHub');
		this.achievementStartupLocation = AchievementStartupLocation.multiPackageHub;
	}

	getAchievementStartupLocation(): AchievementStartupLocation {
		console.log('Achievement Startup location is: ' + AchievementStartupLocation[this.achievementStartupLocation]);
		return this.achievementStartupLocation;
	}

	loadMicrotrainingHistory(packageId: number): Promise<MicrotrainingsSelection[]> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		params = params.set('finished_group_training_required', 'false');
		return this.apiService
			.get<MicrotrainingsSelection[]>('/microtrainings/history', params)
			.pipe(map((microtrainingHistory) => (this.microtrainingHistory = microtrainingHistory)))
			.toPromise();
	}

	loadHistory(): Promise<WeekHistoryItem[]> {
		console.log('LOAD HISTORY CALLED FROM MACHINE SERVICE');
		return this.apiService
			.get<WeekHistoryItem[]>('/history')
			.pipe(map((completeWeekHistory) => (this.completeWeekHistory = completeWeekHistory.sort((a, b) => b.week_count - a.week_count))))
			.toPromise();
	}

	loadNoteHistory(): Promise<NoteHistoryItem[]> {
		return this.apiService
			.get<NoteHistoryItem[]>('/history/notes')
			.pipe(map((completeNoteHistory) => (this.completeNoteHistory = completeNoteHistory.sort((a, b) => b.week_count - a.week_count))))
			.toPromise();
	}

	getValuesForHistoryAccordionGroup(): string[] {
		return this.valuesForHistoryAccordionGroup;
	}

	historyWeekAccordionWasClicked(weekIndex: string[]) {
		this.valuesForHistoryAccordionGroup = weekIndex;
	}

	getValuesForNoteHistoryAccordionGroup(): string[] {
		return this.valuesForNoteHistoryAccordionGroup;
	}

	noteHistoryWeekAccordionWasClicked(weekIndex: string[]) {
		this.valuesForNoteHistoryAccordionGroup = weekIndex;
	}

	getCurrentMicrotrainingHistory(): MicrotrainingsSelection[] {
		/*this.microtrainingHistory=[
      {
        "package": 1,
        "week": 1,
        "microtraining": {
            "pk": 9,
            "name": "Anspruch immer perfekt zu sein",
            "description": "Dein größter Kritiker"
        },
        "single_training_completed": true,
        "group_training_completed": false,
        "updated_at": "2021-01-22T17:17:48.455101Z"
      },
      {
        "package": 1,
        "week": 2,
        "microtraining": {
          "pk": 11,
          "name": "Die perfekte Welt",
          "description": "Die perfekte Welt"
        },
        "single_training_completed": true,
        "group_training_completed": false,
        "updated_at": "2021-02-02T13:21:41.418850Z"
      },
      {
        "package": 1,
        "week": 3,
        "microtraining": {
            "pk": 8,
            "name": "Der perfekte Teufelskreis",
            "description": "Der perfekte Teufelskreis"
        },
        "single_training_completed": true,
        "group_training_completed": false,
        "updated_at": "2021-02-03T11:56:02.474206Z"
      }
    ]*/
		return this.microtrainingHistory;
	}

	hasMicrotrainingBeenDone(microtraining_id: number) {
		if (this.getCurrentMicrotrainingHistory()) {
			if (this.getCurrentMicrotrainingHistory().find((skill) => skill.microtraining.pk == microtraining_id)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	getTrainingPicture(trainingName: string): string {
		var i: number;
		var codePointsSum: number;
		var codePointAverage: number;

		codePointsSum = 0;
		for (i = trainingName.length - 1; i >= 0; i--) {
			codePointsSum += trainingName.codePointAt(i);
		}

		codePointAverage = Math.round(codePointsSum / trainingName.length);

		var trainingPictureNumber: number;
		trainingPictureNumber = codePointAverage % 18;

		return 'assets/trainings/training' + trainingPictureNumber + '.svg';
	}

	getTrainingDateForMicrotraining(microtraining_id: number): string {
		if (this.getCurrentMicrotrainingHistory()) {
			return this.getCurrentMicrotrainingHistory().find((skill) => skill.microtraining.pk == microtraining_id).updated_at;
		}
		return null;
	}
}
