import { Location } from '@angular/common';
import { Compiler, Injector, Type } from '@angular/core';
import { ChildrenOutletContexts, NavigationExtras, Router, Routes, UrlSerializer, UrlTree } from '@angular/router';

export class GlobalRouterDecorator extends Router {
	private static _historyEnabled = true;
	public static enableHistory(enabled: boolean = true) {
		GlobalRouterDecorator._historyEnabled = !!enabled;
	}

	constructor() {
		super();
	}

	navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		return super.navigate(commands, this.enhanceExtras(extras));
	}

	navigateByUrl(url: string | UrlTree, extras?: NavigationExtras) {
		return super.navigateByUrl(url, this.enhanceExtras(extras));
	}

	private enhanceExtras(extras: NavigationExtras) {
		return !GlobalRouterDecorator._historyEnabled
			? {
					...extras,
					replaceUrl: true
			  }
			: extras;
	}
}
