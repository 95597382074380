import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import { ThemingService } from './theming.service';

@Injectable({
	providedIn: 'root'
})
export class LoaderService {
	private loadingRequestsStream$: Subject<boolean>;
	private loaderElement: HTMLIonLoadingElement;
	private lazyDismissTimer;

	private loaderCompletedAlertSubject: BehaviorSubject<boolean>;
	public loaderCompletedAlert: Observable<boolean>;
	private _isLoaded: boolean = false;

	private initalAppDataIsLoadingSubject: BehaviorSubject<boolean>;
	public initalAppDataIsLoadingItem: Observable<boolean>;

	public subLoaded: boolean = true;

	private loaderProgressAlertSubject: BehaviorSubject<boolean>;
	public loaderProgressAlert: Observable<boolean>;
	public loaderProgress = 0;

	constructor(
		private loadingCtrl: LoadingController,
		private translationService: TranslationService,
		private themingService: ThemingService
	) {
		this.initValues();

		this.loaderProgressAlertSubject = new BehaviorSubject<boolean>(null);
		this.loaderProgressAlert = this.loaderProgressAlertSubject.asObservable();

		this.loaderCompletedAlertSubject = new BehaviorSubject<boolean>(null);
		this.loaderCompletedAlert = this.loaderCompletedAlertSubject.asObservable();

		this.initalAppDataIsLoadingSubject = new BehaviorSubject<boolean>(null);
		this.initalAppDataIsLoadingItem = this.initalAppDataIsLoadingSubject.asObservable();
	}

	private initValues() {
		this.loaderElement = null;
		this.lazyDismissTimer = null;
		this.loadingRequestsStream$ = new Subject();
		this.loadingRequestsStream$
			.pipe(
				distinctUntilChanged(),
				concatMap((loader) => {
					if (loader) {
						return this.createLoader();
					} else {
						return this.dismissLoader();
					}
				})
			)
			.subscribe(); // we do not worry of unsubscribing here since this service will always be used across the app
	}

	public updateLoadingProgress(amountToAdd: number) {
		this.loaderProgress += amountToAdd;
		console.log('Progess Value = ' + this.loaderProgress);
	}

	public resetLoadingProgress() {
		this.loaderProgress = 0;
	}

	updateLoaderProgressAlertSubject() {
		this.loaderProgressAlertSubject.next(true);
	}

	public isLoaded(): boolean {
		return this._isLoaded && !this.themingService.isLoading;
	}

	public isLoadedWithoutAssets(): boolean {
		return this._isLoaded;
	}

	public isSubLoaded(): boolean {
		return this.subLoaded;
	}

	public setFullScreenLoadingSpinnerActive() {
		this.initalAppDataIsLoadingSubject.next(true);
	}

	public resetFullScreenLoadingSpinnerActive() {
		this.initalAppDataIsLoadingSubject.next(false);
	}

	public resetLoading() {
		this._isLoaded = false;
		this.loaderCompletedAlertSubject.next(false);
		console.log('Loader has been reset. _isLoaded = ' + this._isLoaded);
	}

	public loadingComplete() {
		this.loaderCompletedAlertSubject.next(true);
		this._isLoaded = true;
		console.log('Loader has completed loading. _isLoaded = ' + this._isLoaded);
	}

	public resetSubLoading() {
		this.subLoaded = false;
	}

	public subLoadingComplete() {
		this.subLoaded = true;
		console.log('SubLoader has completed loading. subLoaded = ' + this.subLoaded);
	}

	public showLoader() {
		this.loadingRequestsStream$.next(true);
	}

	public hideLoader() {
		this.loadingRequestsStream$.next(false);
	}

	private async createLoader(): Promise<void> {
		// we check if there is a loader already instantiated:
		if (!this.loaderElement) {
			// if not we create new loader and limit its max duration to 2000ms to prevent blocking loader to hangout indefinitely
			this.loaderElement = await this.loadingCtrl.create({
				message: '',
				spinner: 'crescent',
				duration: 5000
			});
			// its essential we return a Promise here as this is what concatMap will leverage for serialization
			return this.loaderElement.present();
		} else {
			// if loader element exists already we just return resolved promise:
			return Promise.resolve();
		}
	}

	private async dismissLoader(): Promise<void> {
		// here we check if loader element exists and that there is no timer running already
		if (this.loaderElement && !this.lazyDismissTimer) {
			// we set the timer
			this.lazyDismissTimer = setTimeout(async () => {
				// after 700ms we dismiss our loader element:
				await this.loaderElement.dismiss();
				// nullify our properties right after dismiss promise fulfilled itself:
				this.loaderElement = null;
				clearTimeout(this.lazyDismissTimer);
				this.lazyDismissTimer = null;
				// still remember to return a promise to let concatMap know it can proceed
				return Promise.resolve();
			}, 500);
		} else {
			// if loader element does not exist or if there is already a timer running - there is nothing to dismiss, we just return empty promise
			return Promise.resolve();
		}
	}
}
