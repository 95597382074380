import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { TranslationService } from './translation.service';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {
	private errorID: string;
	private errorCode: string;
	private isKnownError: boolean = false;
	private knownErrorList: string[] = [];
	private errorListNoRetry: string[] = ['400', '401', '460', '461'];

	private internetProblemModalShownSubject: BehaviorSubject<boolean>;
	internetProblemModalShown: Observable<boolean>;

	private requestCompletedSuccessfullySubscription: Subscription;

	private isAppCurrentylRetryingRequests: boolean = false;

	constructor(
		private apiService: ApiService,
		private authenticationService: AuthenticationService,
		private translationService: TranslationService
	) {
		this.internetProblemModalShownSubject = new BehaviorSubject<boolean>(null);
		this.internetProblemModalShown = this.internetProblemModalShownSubject.asObservable();

		this.requestCompletedSuccessfullySubscription = this.apiService.requestCompletedSuccessfully.subscribe((requestStatusPositiv) =>
			requestStatusPositiv && this.getIsAppCurrentlyRetryingRequests() ? this.resetInternetProblemModal() : null
		);

		this.apiService.setErrorListNoRetry(this.errorListNoRetry);
	}

	isErrorAKnownError(error): boolean {
		if (this.isHttpError(error)) {
			if (this.knownErrorList.length == 0) {
				let errorCodeList: string = this.getTranslatedValue('requestError.knownErrorList');
				console.log('Here is the error code list extracted from the language.json: ' + errorCodeList);
				if (errorCodeList) {
					this.knownErrorList = errorCodeList.split(',');
				}
			}

			console.log(
				'isErrorAKnownError is called with known error list: ' +
					JSON.stringify(this.knownErrorList) +
					' and the error code to be tested is: ' +
					error['status'].toString() +
					'. The function returns: ' +
					this.knownErrorList.includes(error['status'].toString())
			);

			return this.knownErrorList.includes(error['status'].toString());
		}
		// if the language file is available, we can read out the known errors
	}

	isHttpError(error): boolean {
		if (error['status'] != undefined) {
			return true;
		} else {
			return false;
		}
	}

	displayInternetProblemModal() {
		this.internetProblemModalShownSubject.next(true);
	}

	resetInternetProblemModal() {
		this.internetProblemModalShownSubject.next(false);
		this.isAppCurrentylRetryingRequests = false;
	}

	setIsAppCurrentylRetryingRequests(value: boolean) {
		this.isAppCurrentylRetryingRequests = value;
	}

	getIsAppCurrentlyRetryingRequests(): boolean {
		return this.isAppCurrentylRetryingRequests;
	}

	async saveError(data: string): Promise<string> {
		let errorPath = '/log/error';

		const postData = {
			data: data
		};

		const isLoggedIn = await this.authenticationService.isUserAuthenticatedNoRefreshAttempt();

		if (!isLoggedIn) {
			errorPath = '/log/error/non-auth';
		}

		return this.apiService
			.post<string>(errorPath, postData)
			.pipe(map((errorID) => (this.errorID = errorID)))
			.toPromise();
	}

	setErrorCode(code: string) {
		this.errorCode = code;
		this.isKnownError = true;
	}

	getErrorCode(): string {
		return this.errorCode;
	}

	getErrorID(): string {
		return this.errorID;
	}

	isErrorKnownError(): boolean {
		return this.isKnownError;
	}

	resetErrorService() {
		this.isKnownError = false;
		this.errorCode = null;
		this.errorID = null;
	}

	getTranslatedValue(input: string): string {
		let value: string;
		this.translationService.translate.get(input).subscribe((res: string) => {
			value = res;
		});

		return value;
	}
}
