import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class HomeService {
	private tilesExpanded: boolean = true;
	private isHomeInitialized: boolean = false;

	constructor() {}

	getIsHomeInitialized(): boolean {
		return this.isHomeInitialized;
	}

	setIsHomeInitialized(isHomeInitialized: boolean) {
		this.isHomeInitialized = isHomeInitialized;
	}

	areTilesExpanded(): boolean {
		return this.tilesExpanded;
	}

	expandingTilesClicked() {
		this.tilesExpanded = !this.tilesExpanded;
	}

	setTilesExpanded(isExpanded: boolean) {
		this.tilesExpanded = isExpanded;
	}
}
