import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ThemingService } from '@app/_services/theming.service';
@Pipe({
	name: 'resolveAnimationSrc'
})
@Injectable({
	providedIn: 'root'
})
export class ResolveAnimationSrcPipe implements PipeTransform {
	constructor(private themingService: ThemingService) {}

	async transform(value: string, ...args): Promise<{ path: string } | { animationData: string }> {
		if (value) {
			return await this.themingService.loadAnimation(value, ...args);
		}
	}
}
