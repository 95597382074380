<div class="modal-container">
	<ion-card class="modal-card savvi-card no-padding">
		<ion-card-content class="no-padding">
			<ion-grid class="no-grid-padding ion-padding">
				<ion-row class="ion-justify-content-center" style="margin-bottom: 2.5rem">
					<ion-col class="ion-text-center">
						<img class="internet-problem-img" src="../../assets/error/internet_problem.svg" />
					</ion-col>
				</ion-row>

				<ion-row style="margin-bottom: 2rem">
					<ion-col>
						<div class="sav-title-2 ion-text-center" [innerHTML]="'requestError.noInternetTitle' | translate"></div>
					</ion-col>
				</ion-row>

				<ion-row style="margin-bottom: 6.5rem">
					<ion-col>
						<div *ngIf="!isAppCurrentlyRetryingRequests" class="sav-paragraph-text-2 grey-text ion-text-center" [innerHTML]="'requestError.noInternetMessage' | translate"></div>
						<div *ngIf="isAppCurrentlyRetryingRequests" class="sav-paragraph-text-2 grey-text ion-text-center" [innerHTML]="'requestError.retryMessage' | translate"></div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col>
						<sav-button [disabled]="isAppCurrentlyRetryingRequests" (click)="triggerRetry()" expand="block">
							<span *ngIf="!isAppCurrentlyRetryingRequests" [innerHTML]="'requestError.retry' | translate"></span>
							<ion-spinner *ngIf="isAppCurrentlyRetryingRequests" class="white-spinner" name="crescent"></ion-spinner>
						</sav-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card-content>
	</ion-card>
</div>
