import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '@app/_services/api.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class SuccessfulResponseInterceptor implements HttpInterceptor {
	constructor(private apiService: ApiService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap((event) => {
				if (event.type === HttpEventType.Response) {
					if (event.status > 199 && event.status < 300) {
						this.apiService.triggerRequestCompletedSuccessfullyEvent();
					}
				}
			})
		);
	}
}
