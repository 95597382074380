export enum PlayerPointType {
	microtraining,
	daily_challenge,
	quiz,
	totw,
	rmi,
	rmi_achievement,
	streak,
	duel
}
