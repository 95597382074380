import { EventEmitter, Injectable } from '@angular/core';
import { InstallPromptComponent } from '@app/app-welcome-screen/install-prompt/install-prompt.component';
import { ModalController, Platform } from '@ionic/angular';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

export class PromptConfig {
	constructor(
		public mobileType: 'ios' | 'android',
		public promptEvent: any
	) {}
}

@Injectable({
	providedIn: 'root'
})
export class PWAService {
	private promptEvent: any;
	public openPrompt: EventEmitter<PromptConfig> = new EventEmitter<PromptConfig>();

	constructor(
		private platform: Platform //private modalController : ModalController
	) {}

	public initPwaPrompt() {
		if (this.platform.is('android')) {
			window.addEventListener('beforeinstallprompt', (event: any) => {
				event.preventDefault();
				this.promptEvent = event;
				this.openPromptComponent('android');
			});
		} else if (this.platform.is('ios')) {
			const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone'];
			if (!isInStandaloneMode) {
				this.openPromptComponent('ios');
			}
		}
	}

	private openPromptComponent(mobileType: 'ios' | 'android') {
		timer(3000)
			.pipe(take(1))
			.subscribe(() => this.openPrompt.emit(new PromptConfig(mobileType, this.promptEvent))); /*this.openInstallPrompt(mobileType)*/
	}

	/*async openInstallPrompt(mobileType: 'ios' | 'android') {
    const modal = await this.modalController.create({
      component: InstallPromptComponent,
      componentProps: {
        'mobileType': mobileType,
        'promptEvent': this.promptEvent
      },
      cssClass: 'description-modal'
    });

    return await modal.present();
  }*/
}
