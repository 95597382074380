import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { GamificationService } from './gamification.service';
import { AnimationController, Animation } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class TourGuideService {
	private showTourGuideSpaceMapPart2ItemSubject: BehaviorSubject<boolean>;
	public showTourGuideSpaceMapPart2Item: Observable<boolean>;

	private showJourneyTabTooltipItemSubject: BehaviorSubject<boolean>;
	public showJourneyTabTooltipItem: Observable<boolean>;

	private showPlanet2TooltipItemSubject: BehaviorSubject<boolean>;
	public showPlanet2TooltipItem: Observable<boolean>;

	private showSpaceMapExitTooltipItemSubject: BehaviorSubject<boolean>;
	public showSpaceMapExitTooltipItem: Observable<boolean>;

	private showAchievementsAnimationItemSubject: BehaviorSubject<boolean>;
	public showAchievementsAnimationItem: Observable<boolean>;

	private showJourneyAchievementsTooltipItemSubject: BehaviorSubject<boolean>;
	public showJourneyAchievementsTooltipItem: Observable<boolean>;

	private showAchievementsTooltipItemSubject: BehaviorSubject<boolean>;
	public showAchievementsTooltipItem: Observable<boolean>;

	private showAchievementsBackTooltipItemSubject: BehaviorSubject<boolean>;
	public showAchievementsBackTooltipItem: Observable<boolean>;

	private showJourneySpacemapTooltipItemSubject: BehaviorSubject<boolean>;
	public showJourneySpacemapTooltipItem: Observable<boolean>;

	private showHomeTabTooltipItemSubject: BehaviorSubject<boolean>;
	public showHomeTabTooltipItem: Observable<boolean>;

	private showPreStartHomeTabTooltipItemSubject: BehaviorSubject<boolean>;
	public showPreStartHomeTabTooltipItem: Observable<boolean>;

	private showTabBarSubject: BehaviorSubject<boolean>;
	public showTabBarItem: Observable<boolean>;

	// tour guide data
	private hasTourGuideNotBeenViewed: boolean = false;
	private showTourGuide: boolean = false;
	private isSecondTourGuideStep: boolean = false;
	private isThirdTourGuideStep: boolean = false;

	//Animations
	private popoverEntryAnimation: Animation;

	constructor(private animationCtrl: AnimationController) {
		this.showTourGuideSpaceMapPart2ItemSubject = new BehaviorSubject<boolean>(null);
		this.showTourGuideSpaceMapPart2Item = this.showTourGuideSpaceMapPart2ItemSubject.asObservable();

		this.showPlanet2TooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showPlanet2TooltipItem = this.showPlanet2TooltipItemSubject.asObservable();

		this.showSpaceMapExitTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showSpaceMapExitTooltipItem = this.showSpaceMapExitTooltipItemSubject.asObservable();

		this.showJourneyTabTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showJourneyTabTooltipItem = this.showJourneyTabTooltipItemSubject.asObservable();

		this.showAchievementsAnimationItemSubject = new BehaviorSubject<boolean>(null);
		this.showAchievementsAnimationItem = this.showAchievementsAnimationItemSubject.asObservable();

		this.showJourneyAchievementsTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showJourneyAchievementsTooltipItem = this.showJourneyAchievementsTooltipItemSubject.asObservable();

		this.showAchievementsTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showAchievementsTooltipItem = this.showAchievementsTooltipItemSubject.asObservable();

		this.showAchievementsBackTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showAchievementsBackTooltipItem = this.showAchievementsBackTooltipItemSubject.asObservable();

		this.showJourneySpacemapTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showJourneySpacemapTooltipItem = this.showJourneySpacemapTooltipItemSubject.asObservable();

		this.showHomeTabTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showHomeTabTooltipItem = this.showHomeTabTooltipItemSubject.asObservable();

		this.showPreStartHomeTabTooltipItemSubject = new BehaviorSubject<boolean>(false);
		this.showPreStartHomeTabTooltipItem = this.showPreStartHomeTabTooltipItemSubject.asObservable();

		this.showTabBarSubject = new BehaviorSubject<boolean>(null);
		this.showTabBarItem = this.showTabBarSubject.asObservable();
	}
	// Animate

	defineEntryAnimation(myElementRef: any) {
		const popoverEntryAnimation: Animation = this.animationCtrl.create().addElement(myElementRef).duration(1000).fromTo('opacity', '1', '0.5');
		return popoverEntryAnimation;
	}

	defineCloseAnimation(myElementRef: any) {
		const popoverCloseAnimation: Animation = this.animationCtrl.create().addElement(myElementRef).duration(1000).fromTo('opacity', '0.5', '1');
		return popoverCloseAnimation;
	}

	// TOUR GUIDE

	getShowTourGuide(): boolean {
		return this.showTourGuide;
	}

	setShowTourGuide() {
		this.showTourGuide = true;
	}

	resetShowTourGuide() {
		this.showTourGuide = false;
	}

	getIsSecondTourGuideStep() {
		return this.isSecondTourGuideStep;
	}

	setIsSecondTourGuideStep() {
		this.isSecondTourGuideStep = true;
	}

	resetIsSecondTourGuideStep() {
		this.isSecondTourGuideStep = false;
	}

	setIsThirdTourGuideStep() {
		this.isThirdTourGuideStep = true;
	}

	resetIsThirdTourGuideStep() {
		this.isThirdTourGuideStep = false;
	}

	getIsThirdTourGuideStep() {
		return this.isThirdTourGuideStep;
	}

	triggerShowTourGuideSpaceMapPart2ItemSubject() {
		this.showTourGuideSpaceMapPart2ItemSubject.next(true);
		console.log('The modal is closed and the next part of the animation should run');
	}

	openJourneyTabTooltip() {
		this.showJourneyTabTooltipItemSubject.next(true);
		console.log('Tab Journey Tooltip is shown');
	}

	closeJourneyTabTooltip() {
		this.showJourneyTabTooltipItemSubject.next(false);
		console.log('Tab Journey Tooltip is closed');
	}

	openShowPlanet2Tooltip() {
		this.showPlanet2TooltipItemSubject.next(true);
		console.log('Planet 2 Tooltip is shown');
	}

	closeShowPlanet2Tooltip() {
		this.showPlanet2TooltipItemSubject.next(false);
		console.log('Planet 2 Tooltip is closed');
		this.setIsThirdTourGuideStep();
	}

	getShowPlanet2Tooltip(): boolean {
		return this.showPlanet2TooltipItemSubject.value;
	}

	openSpaceMapExitTooltip() {
		this.showSpaceMapExitTooltipItemSubject.next(true);
		console.log('Space Map Exit Tooltip is shown');
	}

	closeSpaceMapExitTooltip() {
		this.showSpaceMapExitTooltipItemSubject.next(false);
		console.log('Space Map Exit Tooltip is closed');
	}

	startAchievementsAnimation() {
		this.showAchievementsAnimationItemSubject.next(true);
		console.log('Achievements Tour Guide Animation is shown');
	}

	stopAchievementsAnimation() {
		this.showAchievementsAnimationItemSubject.next(false);
		console.log('Achievements Tour Guide Animation is closed');
	}

	openJourneyAchievementsTooltip() {
		this.showJourneyAchievementsTooltipItemSubject.next(true);
		console.log('Journey Achievement Tooltip is shown');
	}

	closeJourneyAchievementsTooltip() {
		this.showJourneyAchievementsTooltipItemSubject.next(false);
		console.log('Journey Achievement Tooltip is closed');
	}

	startAchievementsTooltip() {
		this.showAchievementsTooltipItemSubject.next(true);
		console.log('Achievements Tooltip is shown');
	}

	stopAchievementsTooltip() {
		this.showAchievementsTooltipItemSubject.next(false);
		console.log('Achievements Tooltip is closed');
	}

	startAchievementsBackTooltip() {
		this.showAchievementsBackTooltipItemSubject.next(true);
		console.log('Achievements Back Tooltip is shown');
	}

	stopAchievementsBackTooltip() {
		this.showAchievementsBackTooltipItemSubject.next(false);
		console.log('Achievements Back Tooltip is closed');
	}

	openJourneySpacemapTooltip() {
		this.showJourneySpacemapTooltipItemSubject.next(true);
		console.log('Journey Spacemap Tooltip is shown');
	}

	getShowSpaceMapTooltip(): boolean {
		return this.showJourneySpacemapTooltipItemSubject.value;
	}
	closeJourneySpacemapTooltip() {
		this.showJourneySpacemapTooltipItemSubject.next(false);
		console.log('Journey Spacemap Tooltip is closed');
	}

	openHomeTabTooltip() {
		this.showHomeTabTooltipItemSubject.next(true);
		console.log('Home Tab Tooltip is shown');
	}

	closeHomeTabTooltip() {
		this.showHomeTabTooltipItemSubject.next(false);
		console.log('Home Tab Tooltip is closed');
	}

	openPreStartHomeTabTooltip() {
		this.showPreStartHomeTabTooltipItemSubject.next(true);
		console.log('Pre Start Home Tab Tooltip is shown');
	}

	closePreStartHomeTabTooltip() {
		this.showPreStartHomeTabTooltipItemSubject.next(false);

		console.log('Pre Start Home Tab Tooltip is closed');
	}

	openTabBar() {
		this.showTabBarSubject.next(true);
		console.log('Tab Bar is shown');
	}
	closeTabBar() {
		this.showTabBarSubject.next(false);
		console.log('Tab Bar is closed');
	}
}
