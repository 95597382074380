import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RewardedMicroInteractionComponent } from './rewarded-micro-interaction.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@app/_services/translation.service';
import { ApiService } from '@app/_services/api.service';

@NgModule({
	imports: [
		CommonModule, 
		FormsModule, 
		IonicModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ApiService]
			}
		})],
	declarations: [RewardedMicroInteractionComponent],
	exports: [RewardedMicroInteractionComponent]
})
export class RewardedMicroInteractionModule {}
