import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PackageService } from './package.service';
import { TranslationService } from './translation.service';
import { GamificationService } from './gamification.service';
import { Directory, Filesystem, GetUriResult } from '@capacitor/filesystem';
import { User } from '@app/_models/user';
import { HttpParams } from '@angular/common/http';
import { DataStorageService } from './data-storage.service';

@Injectable({
	providedIn: 'root'
})
export class PdfService {
	private isProfileGenerated: boolean = false;
	private hasLoadError: boolean = false;
	private pdfBlob: Blob;
	private fileUri: GetUriResult;
	private isCertificateGenerated: boolean = false;
	private pdfType: PdfType;
	private fileName: string = '';
	private pdfGenerationStatus: boolean;

	constructor(
		private apiService: ApiService,
		private packageService: PackageService,
		private translationService: TranslationService,
		private gamificationService: GamificationService,
		private dataStorageService: DataStorageService
	) {}

	async loadFeedbackProfilePDF(user: User) {
		this.pdfType = PdfType.profile;
		try {
			this.isProfileGenerated = false;
			this.hasLoadError = false;

			const url = this.packageService.getCurrentPackage().threesixty_degree_feedback_enabled ? '/profile/export' : '/profile/export_without_feedback';

			const response: Blob = (await this.apiService.downloadPDFFormat(url).toPromise()) as Blob;
			this.pdfBlob = response; // Save the PDF blob to the private variable
			console.log('loadFeedbackProfilePDF', response);
			this.isProfileGenerated = true;

			const base64Data = await this.blobToBase64(this.pdfBlob);

			// First, write the PDF to the local file system
			const fileName = this.getPDFName(user);
			this.fileName = fileName;
			const fileWrite = await Filesystem.writeFile({
				path: fileName,
				data: base64Data,
				directory: Directory.Documents,
				recursive: true
			});
			// Get the URI of the saved file
			this.fileUri = await Filesystem.getUri({
				directory: Directory.Documents,
				path: fileName
			});
			const pdfGenerationDate = new Date();
			this.dataStorageService.writeValue(this.dataStorageService.getPdfKey(), fileName);
			this.dataStorageService.writeValue(this.dataStorageService.getPdfDateKey(), new Date().toISOString());
		} catch (error) {
			console.error('Error downloading the PDF', error);
			this.isProfileGenerated = false;
			this.hasLoadError = true;
		}
		console.log('isProfileGenerated', this.isProfileGenerated);
	}

	async loadEndOfJourneyCertificatePDF(user: User) {
		this.pdfType = PdfType.certificate;
		try {
			this.isCertificateGenerated = false;

			const url = '/certificate/export?receive_as=download';

			const response: Blob = (await this.apiService.downloadPDFFormat(url).toPromise()) as Blob;
			this.pdfBlob = response; // Save the PDF blob to the private variable
			console.log('loadEndOfJourneyCertificatePDF', response);

			const base64Data = await this.blobToBase64(this.pdfBlob);
			// writing pdf to local file stystem
			const fileName = this.getPDFName(user);
			try {
				const fileWrite = await Filesystem.writeFile({
					path: fileName,
					data: base64Data,
					directory: Directory.Documents,
					recursive: true
				});
				// Get the URI of the saved file unsure if this is needed as sharing through email works differently
				this.fileUri = await Filesystem.getUri({
					directory: Directory.Documents,
					path: fileName
				});
				this.isCertificateGenerated = true;
			} catch (writeError) {
				console.error('Error writing file:', writeError);
			}
		} catch (error) {
			console.error('Error downloading the PDF', error);
			this.isCertificateGenerated = false;
			//this.hasLoadError = true;
		}
		console.log('isCertificateGenerated', this.isCertificateGenerated);
	}
	async loadPdfFromFileSystem(): Promise<void> {
		try {
			// Retrieve the filename from DataStorageService
			const fileName = await this.dataStorageService.readValue(this.dataStorageService.getPdfKey());
			if (!fileName) {
				throw new Error('No filename found in DataStorageService');
			}

			if (typeof fileName === 'string') {
				// Read the file from the filesystem
				this.fileUri = await Filesystem.getUri({
					directory: Directory.Documents,
					path: fileName
				});

				// Read the file from the filesystem
				const fileRead = await Filesystem.readFile({
					directory: Directory.Documents,
					path: fileName
				});

				// Convert the base64 data back to a Blob
				const base64Data = fileRead.data;
				this.pdfBlob = this.base64ToBlob(base64Data.toString(), 'application/pdf');

				console.log('PDF loaded and converted to Blob', this.pdfBlob);
			}
		} catch (error) {
			console.error('Error loading PDF from filesystem', error);
		}
	}

	getPDFName(user: User): string {
		let nameAndTime: string = user.first_name + '_' + user.last_name + '_' + this.getFormattedDate();
		if (this.pdfType === PdfType.profile) {
			return this.translationService.translate.instant('feedbackProfile.feedbackSharing.fileName', { userTime: nameAndTime });
		} else if (this.pdfType === PdfType.certificate) {
			return this.translationService.translate.instant('endOfJourney.certificate.fileName', { userTime: nameAndTime });
		}
	}

	getFileName(): string {
		return this.fileName;
	}

	getFormattedDate() {
		const date = new Date(); // Creates a new date object with the current date and time
		const year = date.getFullYear(); // Gets the full year (e.g., 2024)
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Gets the month, adds 1 because getMonth() returns 0-11
		const day = date.getDate().toString().padStart(2, '0'); // Gets the day of the month and ensures it is two digits

		return `${year}_${month}_${day}`; // Formats the date
	}

	getIsProfileGenerated(): boolean {
		return this.isProfileGenerated;
	}

	getIsCertificateGenerated(): boolean {
		return this.isCertificateGenerated;
	}

	getHasLoadError(): boolean {
		return this.hasLoadError;
	}

	getPDFBlob(): Blob {
		return this.pdfBlob;
	}

	getFeedbackProfileFileUri(): GetUriResult {
		return this.fileUri;
	}

	async blobToBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result as string;
				resolve(base64data.split(',')[1]); // Removes the 'data:application/pdf;base64,' part
			};
			reader.onerror = (error) => {
				reject(error);
			};
		});
	}

	base64ToBlob(base64: string, contentType: string): Blob {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return new Blob([byteArray], { type: contentType });
	}

	async shouldPdfBeGenerated(): Promise<boolean> {
		let pdfPath = await this.dataStorageService.readValue(this.dataStorageService.getPdfKey());
		if (!pdfPath) {
			this.isProfileGenerated = false;
		} else {
			this.isProfileGenerated = true;
		}

		if (!this.isProfileGenerated) {
			console.log('New PDF will be generated because the profile has not been generated yet');
			this.setPdfGenerationStatus(true);
			console.log("is Profile Not Generated or too old evaluated to: ", this.getPdfGenerationStatus());
			return true;
		}

		const pdfTooOld = await this.isPdfTooOld();

		if (pdfTooOld) {
			console.log('New PDF will be generated because the existing PDF is too old');
			this.setPdfGenerationStatus(true);
			console.log('is Profile Not Generated or too old evaluated to: ', this.getPdfGenerationStatus());
			return true;
		} else {
			console.log('No new PDF will be generated, existing PDF is still valid');
			console.log('Loading PDF from filesystem');
			await this.loadPdfFromFileSystem();
			console.log(`PDF loaded from filesystem with fileUri: ${this.fileUri}, pdfBlob:`, this.pdfBlob);
			this.setPdfGenerationStatus(false);
			console.log('is Profile Not Generated or too old evaluated to: ', this.getPdfGenerationStatus());

			return false;
		}
	}

	private async isPdfTooOld(): Promise<boolean> {
		const pdfDate = await this.dataStorageService.readValue(this.dataStorageService.getPdfDateKey());

		if (!pdfDate) {
			// If no data or timestamp is found, assume the PDF is too old and needs regeneration
			console.log('No valid timestamp found, generating new PDF');
			return true;
		}

		if (typeof pdfDate === 'string') {
			const savedDate = new Date(pdfDate.toString());
			const currentDate = new Date();

			// Calculate the difference
			const timeDifference = currentDate.getTime() - savedDate.getTime();
			const differenceInMinutes = timeDifference / (1000 * 60);

			const thresholdInMinutes = 10;

			// Check if the PDF is older than the threshold
			if (differenceInMinutes > thresholdInMinutes) {
				console.log(`PDF is too old (older than ${thresholdInMinutes} minutes), new PDF will be generated`);
				return true;
			}
		}

		console.log('No new PDF generated, existing PDF is still valid');
		return false;
	}

	setPdfGenerationStatus(status: boolean) {
		this.pdfGenerationStatus = status;
	}

	getPdfGenerationStatus(): boolean {
		return this.pdfGenerationStatus;
	}
}

export enum PdfType {
	profile = 'profile',
	certificate = 'certificate'
}
