import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { defineCustomElements as savviDefineCustomElements } from '@savvi-learning/savvi-components/loader';

defineCustomElements(window);
savviDefineCustomElements(
	window /*, {
  exclude: ['ion-tabs', 'ion-tab']
}*/
);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));
