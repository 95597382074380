import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternetProblemModalComponent } from './internet-problem-modal.component';
import { ApiService } from '@app/_services/api.service';
import { HttpLoaderFactory } from '@app/_services/translation.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ComponentLibraryModule as SavviComponentsAngularModule } from '@savvi-learning/savvi-components-angular';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		SavviComponentsAngularModule,
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ApiService]
			}
		})
	],
	declarations: [InternetProblemModalComponent],
	exports: [InternetProblemModalComponent]
})
export class InternetProblemModalModule {}
