import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { ApiService } from './api.service';
import { UserSettings } from '@app/user-settings';
import { addHours, addMinutes, format, formatISO, getUnixTime, parseISO, startOfWeek } from 'date-fns';
import { PackageService } from './package.service';
import { TranslationService } from './translation.service';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {
	private calenderBlockerModalSubject: BehaviorSubject<boolean>;
	public calenderBlockerModalItem: Observable<boolean>;
	private userSettings: UserSettings;
	public _feedbackString: string;
	public shouldAppFeedbackBeSaved: boolean = false;
	constructor(
		private apiService: ApiService,
		private translationService: TranslationService
	) {
		this.calenderBlockerModalSubject = new BehaviorSubject<boolean>(false);
		this.calenderBlockerModalItem = this.calenderBlockerModalSubject.asObservable();
		//Default Timestamp to Monday 8:15 Should be overwritten by API
	}

	/**
	 * This method calculates the startDate for the calendar blocker. It takes the minDateISO and adds the blocker time to it.
	 * @returns
	 */
	calculateCalendarDates(): { minDate: Date; maxDate: Date } {
		let minDate;
		minDate = startOfWeek(new Date(), {
			locale: this.translationService.locale,
			weekStartsOn: 1
		});
		const maxDate = addMinutes(addHours(minDate, 23), 59);

		return { minDate, maxDate };
	}

	/**
	 * This method calculates the startDate for the calendar blocker. It takes the minDateISO and adds the blocker time to it.
	 * @returns
	 */
	calculateStartDate(calendarBlockerDatetime: string | null = null, minDate: Date): { chosenDateISO: string; currentlySelectedDayForCalendarBlocker: number } {
		if (calendarBlockerDatetime == null) {
		}
		const calendarBlocker = parseISO(calendarBlockerDatetime);
		const startDate = addMinutes(addHours(minDate, calendarBlocker.getHours()), calendarBlocker.getMinutes());
		format(startDate, 'yyyy-MM-ddThh:mm:ss.sssz', { locale: this.translationService.locale });
		const chosenDateISO = formatISO(startDate);
		const currentlySelectedDayForCalendarBlocker = calendarBlocker.getDay() - 1;
		return { chosenDateISO, currentlySelectedDayForCalendarBlocker };
	}

	loadUserSettings(): Promise<UserSettings> {
		return this.apiService
			.get<UserSettings>('/settings')
			.pipe(
				map((userSettings) => {
					console.log('UserSettings: ' + JSON.stringify(userSettings));
					this.userSettings = userSettings;
					return userSettings;
				})
			)
			.toPromise();
	}

	saveUserNotificationSettings(offset: number, pushEnabled: boolean): Promise<number> {
		const postData = {
			offset_to_weekly_time_reference: offset,
			blocker_notifications_enabled: pushEnabled
		};
		console.log('saveUserNotificationSettings was called with postData: ' + JSON.stringify(postData));
		return this.apiService.post<number>('/settings/calendar_appointment', postData).toPromise();
	}

	saveUserHapticsSettings(isHapticsEnabled: boolean): Promise<number> {
		const postData = {
			haptics_enabled: isHapticsEnabled
		};
		console.log('saveUserHapticsSettings was called with postData: ' + JSON.stringify(postData));
		return this.apiService.post<number>('/settings/haptics', postData).toPromise();
	}

	setUserSettings(userSettings: any) {
		this.userSettings = { ...this.userSettings, ...userSettings };
	}

	getUserSettings(): UserSettings {
		return this.userSettings;
	}

	isHapticsEnabled(): boolean {
		return this.userSettings.haptics_enabled;
	}

	setHapticEnabled(isHapticsEnabled: boolean) {
		this.userSettings.haptics_enabled = isHapticsEnabled;
	}

	public openChangeScheduleModal() {
		this.calenderBlockerModalSubject.next(true);
	}

	public closeChangeScheduleModal() {
		this.calenderBlockerModalSubject.next(false);
	}

	sendFeedback(): Promise<any> {
		console.log('Feedback details', this._feedbackString);
		const postData = {
			feedback: this._feedbackString
		};
		return this.apiService.post('/settings/feedback', postData).toPromise();
	}

	get feedbackString(): string {
		return this._feedbackString;
	}

	set feedbackString(textualNote: string) {
		this._feedbackString = textualNote;
	}
}
