import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from '@environments/environment';
import { GetResult, Preferences } from '@capacitor/preferences';
import { GamificationEnvironment } from '@app/_models/multi-package';
import { PackageService } from './package.service';
import { Package } from '@app/_models/package';
import { LicenseSelfService } from './license-self-service.service';

type ThemeConfig = {
	themes: { [key: string]: Theme };
	gamificationEnvironments: { [key: string]: Theme };
	version: string;
};

type Theme = {
	cssVars: { [key: string]: string };
	variables: { [key: string]: string };
	assets: {
		[key: string]: {
			src: string;
			location: 'build' | 'otc' | 'inactive';
			local_path: string;
			file_extension: string;
		};
	};
};

@Injectable({
	providedIn: 'root'
})
export class ThemingService {
	private config: ThemeConfig;
	private gamificationEnvironmentName: GamificationEnvironment;
	private themeName: string;
	private loadingItems: Map<unknown, unknown> = new Map();
	private useLocal = environment.useLocalConfigFile || false;
	private noPackage: Package;
	constructor(
		private apiService: ApiService,
		private http: HttpClient,
		private storageService: StorageService,
		private packageService: PackageService,
		private licenseSelfService: LicenseSelfService
	) {}

	setConfig(config: ThemeConfig) {
		this.config = config;
	}

	get isLoading(): boolean {
		return this.loadingItems.size > 0;
	}
	getLoadingItems() {
		return this.loadingItems;
	}

	compareVersions(v1: string | null, v2: string | null): boolean {
		if (!v1 || !v2) return false;
		const v1parts = v1.split('.');
		const v2parts = v2.split('.');
		const k = Math.min(v1parts.length, v2parts.length);
		for (let i = 0; i < k; ++i) {
			if (parseInt(v1parts[i]) > parseInt(v2parts[i])) {
				return false;
			} else if (parseInt(v1parts[i]) < parseInt(v2parts[i])) {
				return true;
			}
		}
		return false;
	}

	isConfigToHigh(versionCode: string | null, maximumVersion: number): boolean {
		if (!versionCode) return false;
		const versionParts = versionCode.split('.');
		const majorVersion = parseInt(versionParts[0]);
		if (majorVersion > maximumVersion) {
			return true;
		}
		return false;
	}

	applyTheme(config: { [key: string]: Theme }, theme: string) {
		if (!config) {
			console.log('No config');
			return;
		}

		Object.keys(config[theme].cssVars).forEach((k) => {
			document.body.style.setProperty(k, config[theme].cssVars[k]);
		});
	}

	async loadConfig() {
		console.log('loading config');

		let gamificationEnvironment: GamificationEnvironment;
		let themeName: string;

		//boolean checking for isSelfService which also needs to be reset in case of package change etc
		if (!!this.licenseSelfService.getSubscriptionStatus() && !this.licenseSelfService.userHasPackage()) {
			gamificationEnvironment = GamificationEnvironment.Space;
			themeName = 'light';
		} else {
			const currentPackage = this.packageService.getCurrentPackage();
			gamificationEnvironment = currentPackage.gamification_environment || GamificationEnvironment.Space;
			themeName = currentPackage.theme || 'light';
			console.log('gamificationEnvironment', gamificationEnvironment);
			console.log('themeName', themeName);
			console.log('currentPackage', currentPackage);
		}

		if (!this.gamificationEnvironmentName) this.gamificationEnvironmentName = gamificationEnvironment;
		if (!this.themeName) this.themeName = themeName;

		let config: ThemeConfig | null = null;

		try {
			if (this.useLocal) {
				console.log('loading config from local');
				config = await lastValueFrom(this.http.get<ThemeConfig>('../../assets/theme_config.json').pipe(map((res) => res)));
			} else {
				console.log('loading config from OTC');
				config = await lastValueFrom(this.apiService.getS3<any>(`${environment.OTCAssetsUrl}assets/theme_config.json`));
			}
		} catch (error) {
			console.error('Error loading config:', error);
		}

		if (!config) {
			console.error('Config not found, loading default config');
			config = await lastValueFrom(this.http.get<ThemeConfig>('../../assets/theme_config.json').pipe(map((res) => res)));
		}

		this.setConfig(config);
		console.log(config, 'loggedConfig');
		this.applyTheme(config.themes, themeName);
		this.applyGamificationEnvironment(config.gamificationEnvironments, gamificationEnvironment);
		console.log('loading config complete', config);

		return config;
	}

	shouldLoadAssets(currentVersion, isAllFilesExist, currentGamificationEnvironment, currentTheme): boolean {
		return (
			!currentVersion.value ||
			!isAllFilesExist ||
			this.isConfigToHigh(currentVersion.value, environment.maximumAssetVersion) ||
			this.compareVersions(currentVersion.value, this.config.version) ||
			!currentGamificationEnvironment ||
			currentGamificationEnvironment.value !== (this.gamificationEnvironmentName || GamificationEnvironment.Space) ||
			!currentTheme ||
			currentTheme.value !== (this.themeName || 'light')
		);
	}

	async loadAssets() {
		const currentVersion = await Preferences.get({ key: 'theme_config_version' });

		const currentGamificationEnvironment = await Preferences.get({ key: 'current_gamification_environment' });
		const currentTheme = await Preferences.get({ key: 'current_theme' });

		console.log(this.config, '<-- config', this.gamificationEnvironmentName, '<-- Gamification Environment');
		console.log('currentVersion before Check Assets', currentVersion);

		const isAllFilesExist = await this.checkIfAssetsExist();
		console.log(' currentVersion isAllFilesExist', isAllFilesExist);
		console.log('should load assets', this.shouldLoadAssets(currentVersion, isAllFilesExist, currentGamificationEnvironment, currentTheme));

		if (this.shouldLoadAssets(currentVersion, isAllFilesExist, currentGamificationEnvironment, currentTheme)) {
			console.log('load Assets set Preferences', currentVersion);

			await Preferences.set({ key: 'theme_config_version', value: this.config.version });
			await Preferences.set({ key: 'current_gamification_environment', value: this.gamificationEnvironmentName });
			await Preferences.set({ key: 'current_theme', value: this.themeName });

			console.log('reload assets');

			const finalAssets = Promise.all([...(await this.downloadThemeAssets(this.themeName)), ...(await this.downloadGamificationEnvironmentAssets(this.gamificationEnvironmentName))])
				.then((results) => {
					return results.length;
				})
				.catch((error) => console.log('Promise All resolve: ', error));

			console.log('finalAssets', finalAssets);
			return finalAssets;
		} else {
			console.log('All files exist');
			return Promise.resolve(1);
		}
	}

	async checkIfAssetsExist(config: ThemeConfig = this.config, gamificationEnvironment: GamificationEnvironment = this.gamificationEnvironmentName, theme: string = this.themeName) {
		const assets = { ...config.themes[theme].assets, ...config.gamificationEnvironments[gamificationEnvironment].assets };
		const assetsList = Object.keys(assets).map((key) => {
			return assets[key];
		});
		const allFiles: any = await Promise.all(
			assetsList.map(async (elm) => {
				console.debug('checkIfAssetsExist', elm);
				if (elm.location === 'otc') {
					return await this.storageService.checkIfFileExists({ ...elm, path: elm.src });
				}
			})
		).catch((error) => {
			console.log('Promise All resolve: CheckIfExist', error);
			return [{ status: 'error', message: error }];
		});
		return !allFiles.filter((elm) => {
			return elm == false;
		}).length;
	}

	async downloadThemeAssets(theme: string) {
		const assets = this.config.themes[theme].assets;
		const assetsList = Object.keys(assets).map((key) => {
			return assets[key];
		});
		return Promise.all(
			assetsList.map((elm) => {
				console.debug('downloadThemeAssets', elm);
				if (elm.location === 'otc') {
					return this.storageService.storeFile({ ...elm, path: elm.src });
				}
			})
		).catch((error) => {
			return [{ status: 'error', message: error }];
		});
	}

	async downloadGamificationEnvironmentAssets(gamificationEnvironment: string) {
		const assets = this.config.gamificationEnvironments[gamificationEnvironment].assets;

		const assetsList = Object.keys(assets).map((key) => {
			return assets[key];
		});
		return Promise.all(
			assetsList.map((elm) => {
				console.debug('downloadGamificationEnvironmentAssets', elm);
				if (elm.location === 'otc') {
					return this.storageService.storeFile({ ...elm, path: elm.src });
				}
			})
		).catch((error) => {
			return [{ status: 'error', message: error }];
		});
	}

	applyGamificationEnvironment(config?: { [key: string]: Theme }, gamificationEnvironment?: string) {
		if (!config) {
			return;
		}

		Object.keys(config[gamificationEnvironment].cssVars).forEach((k) => {
			document.body.style.setProperty(k, config[gamificationEnvironment].cssVars[k]);
		});
	}

	async loadImg(key: string, gamificationEnvironment: GamificationEnvironment = this.gamificationEnvironmentName, theme: string = this.themeName): Promise<string> {
		let output = null;
		if (this.gamificationEnvironmentName === undefined) {
			this.gamificationEnvironmentName = GamificationEnvironment.Space;
		}
		try {
			const obj = this.getConfigObject(key, gamificationEnvironment, theme);
			this.loadingItems.set(key, obj);
			if (obj.location === 'otc') {
				output = (await this.storageService.loadFile({ ...obj, path: obj.src })) as string;
			} else if (obj.location === 'build') {
				output = obj.local_path;
			} else if (obj.location === 'inactive') {
				output = null;
			} else {
				throw new Error('Invalid location');
			}
		} catch (error) {
			console.error(error);
			output = 'Error';
		} finally {
			this.loadingItems.delete(key);
			return output;
		}
	}

	async loadAnimation(key: string, gamificationEnvironment: GamificationEnvironment = this.gamificationEnvironmentName, theme: string = this.themeName) {
		let output = null;
		try {
			const obj = this.getConfigObject(key, gamificationEnvironment, theme);
			this.loadingItems.set(key, obj);
			console.debug('loadAnimation from Pipe: ', key, obj);
			if (obj.location === 'otc') {
				output = {
					animationData: await this.storageService.loadFile({ ...obj, path: obj.src }).then((data) => {
						return data;
					})
				};
			} else if (obj.location === 'build') {
				output = {
					path: obj.local_path
				};
			} else if (obj.location === 'inactive') {
				output = null;
			} else {
				output = {
					path: ''
				};
				throw new Error('Invalid location');
			}
		} catch (error) {
			console.error(error);
		} finally {
			this.loadingItems.delete(key);
			return output;
		}
	}

	getConfigObject(key: string, gamificationEnvironment: GamificationEnvironment = this.gamificationEnvironmentName, theme: string = this.themeName) {
		console.debug('getConfigObject', key, gamificationEnvironment, theme);
		let obj = this.config.gamificationEnvironments[gamificationEnvironment].assets[key];
		if (!obj) {
			if (this.config.themes[theme].assets[key]) {
				obj = this.config.themes[theme].assets[key];
			}
		}
		if (!obj) {
			throw new Error('Object not found in config file key: ' + key + '    gamificationEnvironment: ' + gamificationEnvironment + '   theme: ' + theme);
		}
		return obj;
	}

	getConfigVariable(key: string, gamificationEnvironment: GamificationEnvironment = this.gamificationEnvironmentName, theme: string = this.themeName) {
		console.debug('getConfigVariable', key, gamificationEnvironment, theme);
		let variable = this.config.gamificationEnvironments[gamificationEnvironment].variables[key];
		if (!variable && this.config.themes[theme].variables[key]) {
			variable = this.config.themes[theme].variables[key];
		}

		if (!variable) {
			throw new Error('Variable not found in config file key: ' + key + '    gamificationEnvironment: ' + gamificationEnvironment + '   theme: ' + theme);
		}

		return variable;
	}
}
