import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MultiPackage, GamificationEnvironment, SwiperSegment, PackageHistoryStartupLocation } from '@app/_models/multi-package';
import { map, Observable, BehaviorSubject } from 'rxjs';
import { PackageHistory } from '@app/_models/package-history';
import { Certificate } from '@app/_models/certificate';
import { FeedbackProfile, GivenFrom } from '@app/_models/feedback-profile';
import { HttpParams } from '@angular/common/http';
import { ProfileInsights } from '@app/_models/profile-insights';
import { ProfileStats } from '@app/_models/profile-stats';
import { AchievementProgress } from '@app/_models/achievement-progress';
import { ConquestAchievements, ConquestSelectedMicrotraining, Conquests } from '@app/_models/conquests';
import { EndOfJourneyService } from './end-of-journey.service';
import { SkillType } from '@app/_models/skill-type';
import { StartupLocationMicrotrainingHistory } from '@app/_models/startup-location-microtraining-history';

@Injectable({
	providedIn: 'root'
})
export class MultiPackageHubService {
	private packageHistoryRedrawingItemSubject: BehaviorSubject<boolean>;
	public packageHistoryRedrawingItem: Observable<boolean>;

	private startupLocationMicrotrainingHistory: StartupLocationMicrotrainingHistory;
	private multiPackageHub: MultiPackage[];
	private selectedPackage: MultiPackage;
	private packageHistory:
		| PackageHistory
		| {
				insights: null;
				stats: null;
				conquests: null;
				feedbackProfile: null;
				package: null;
				achievements: null;
				skilltype: null;
		  };
	private certificate: Certificate;
	private PackageHistoryStartupLocation: PackageHistoryStartupLocation = PackageHistoryStartupLocation.Profile;
	public package_history_swiper_index: SwiperSegment;

	private microtrainingHistoryContent: {
		microtrainings: ConquestSelectedMicrotraining[];
		gamificationEnvironment: GamificationEnvironment;
		highestLevel: number;
	};

	constructor(
		private apiService: ApiService,
		private endOfJourneyService: EndOfJourneyService
	) {
		this.packageHistory = {
			insights: null,
			stats: null,
			conquests: null,
			package: null,
			feedbackProfile: null,
			achievements: null,
			skilltype: null
		};
		this.packageHistoryRedrawingItemSubject = new BehaviorSubject<boolean>(null);
		this.packageHistoryRedrawingItem = this.packageHistoryRedrawingItemSubject.asObservable();
	}

	loadMultiPackageHub(): Promise<MultiPackage[]> {
		console.log('loadMultiPackageHub');
		return this.apiService
			.get<MultiPackage[]>('/packages/stats')
			.pipe(map((response) => (this.multiPackageHub = response))) // remove first element (current package)
			.toPromise();
	}

	loadPackageInsights(package_id: number = 0): Promise<ProfileInsights> {
		console.log('loadPackageInsights');
		return this.apiService
			.get<ProfileInsights>('/profile/insights', null, [{ key: 'package_id', value: package_id.toString() }])
			.pipe(map((profileInsights) => (this.packageHistory.insights = profileInsights)))
			.toPromise();
	}

	loadPackageStats(package_id: number = 0): Promise<ProfileStats> {
		console.log('loadPackageStats');
		let params = new HttpParams();
		params = params.set('package_id', package_id.toString());
		return this.apiService
			.get<any>(`/profile/stats`, params)
			.pipe(map((response) => (this.packageHistory.stats = response)))
			.toPromise();
	}

	loadPackageConquests(package_id: number = 0): Promise<Conquests> {
		console.log('loadPackageConquests');
		return this.apiService
			.get<Conquests>(`/profile/conquests`, null, [{ key: 'package_id', value: package_id.toString() }])
			.pipe(map((response) => (this.packageHistory.conquests = response)))
			.toPromise();
	}

	loadPackageFeedbackProfile(package_id: number = 0): Promise<any> {
		console.log('loadPackageFeedbackProfile');
		let params = new HttpParams();
		params = params.set('package_id', package_id.toString());
		return this.apiService
			.get<FeedbackProfile>(`/threesixty_degree_feedback/stats`, null, [{ key: 'package_id', value: package_id.toString() }])
			.pipe(
				map((response) => {
					let isEmpty = true;
					[GivenFrom.Colleague, GivenFrom.Manager, GivenFrom.Subordinate].forEach((givenFrom) => {
						if (response.skill_stats[givenFrom].length > 0) {
							isEmpty = false;
						}
					});
					this.packageHistory.feedbackProfile = isEmpty ? null : response;
				})
			)
			.toPromise();
	}

	loadPackageAchievements(package_id: number = 0): Promise<AchievementProgress[]> {
		console.log('loadPackageAchievements');
		return this.apiService
			.get<AchievementProgress[]>(`/achievements/overview`, null, [{ key: 'package_id', value: package_id.toString() }])
			.pipe(map((response) => (this.packageHistory.achievements = response)))
			.toPromise();
	}

	//Certificate is already loaded in the packageHistory, so we just need to return it
	loadCertificate(package_id: number): Promise<Certificate> {
		this.certificate = this.multiPackageHub.find((packageItem) => packageItem.pk === package_id).certificate;
		console.log('loadCertificate', this.certificate);
		this.certificate.package_name = this.multiPackageHub.find((packageItem) => packageItem.pk === package_id).external_name;
		this.endOfJourneyService.certificate = this.certificate;
		return Promise.resolve(this.certificate);
	}

	loadSkillType(packageId: number): Promise<SkillType> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		console.log('loadSkillType', params);
		return this.apiService
			.get<SkillType>('/me/skill_type_reveal', params)
			.pipe(map((skillType) => (this.packageHistory.skilltype = skillType)))
			.toPromise();
	}

	isMultiPackageHubAvailable(): boolean {
		return this.multiPackageHub.length > 0;
	}

	isStartupLocationMicrotrainingHistory(location: StartupLocationMicrotrainingHistory) {
		console.log('isStartupLocationMicrotrainingHistory', this.startupLocationMicrotrainingHistory, location);
		return this.startupLocationMicrotrainingHistory == location;
	}

	setStartupLocationMicrotrainingHistory(location: StartupLocationMicrotrainingHistory) {
		console.log('setStartupLocationMicrotrainingHistory', location);
		this.startupLocationMicrotrainingHistory = location;
	}

	setMicrotrainingHistoryContent(microtrainingHistoryContent: { microtrainings: ConquestSelectedMicrotraining[]; gamificationEnvironment: GamificationEnvironment; highestLevel: number }) {
		this.microtrainingHistoryContent = microtrainingHistoryContent;
	}

	getMicrotrainingHistoryContent() {
		return this.microtrainingHistoryContent;
	}

	setSelectedPackage(selectedPackage: MultiPackage): void {
		this.selectedPackage = selectedPackage;
		this.setMicrotrainingHistoryContent({
			microtrainings: this.selectedPackage.conquests.selected_microtrainings,
			gamificationEnvironment: this.selectedPackage.gamification_environment,
			highestLevel: this.selectedPackage.level
		});
	}

	getSelectedPackage(): MultiPackage {
		return this.selectedPackage;
	}
	getSkillType(): SkillType {
		return this.packageHistory.skilltype;
	}

	getBestAchievements(): ConquestAchievements[] {
		return this.packageHistory.conquests.achievements;
	}

	getAllAchievementsData(): AchievementProgress[] {
		return this.packageHistory.achievements;
	}

	getFeedbackProfileData(): FeedbackProfile {
		return this.packageHistory.feedbackProfile;
	}

	getMultiPackages(): MultiPackage[] | boolean {
		if (this.multiPackageHub.length === 0) {
			return false;
		}
		return this.multiPackageHub;
	}

	getMultiPackagesList(): MultiPackage[] {
		return this.multiPackageHub;
	}

	getMultiPackagesWithoutActive(): MultiPackage[] | boolean {
		if (this.multiPackageHub.length === 0) {
			return false;
		}
		console.log('MULTIPACKAGE: ', this.multiPackageHub.slice(1));
		return this.multiPackageHub.slice(1);
	}

	getPackageHistory(): PackageHistory {
		return this.packageHistory;
	}

	getCertificate(): Certificate {
		return this.certificate;
	}

	getConquests(): Conquests {
		return this.packageHistory.conquests;
	}

	getConquestMicrotrainings(): ConquestSelectedMicrotraining[] {
		return this.packageHistory.conquests.selected_microtrainings;
	}

	getGamificationEnvironment(): GamificationEnvironment {
		return this.selectedPackage.gamification_environment;
	}

	getHighestLevelReached(): number {
		return this.selectedPackage.level;
	}

	resetMultiPackageHub(): void {
		this.multiPackageHub = [];
	}

	resetPackageHistory(): void {
		this.packageHistory = null;
	}

	resetCertificate(): void {
		this.certificate = null;
	}
	getPackageHistoryStartupLocation(): PackageHistoryStartupLocation {
		return this.PackageHistoryStartupLocation;
	}

	setPackageHistoryStartupLocation(locationEnum: PackageHistoryStartupLocation): void {
		this.PackageHistoryStartupLocation = locationEnum;
	}

	resetPackageHistoryRedrawingItemSubject(value: boolean) {
		this.packageHistoryRedrawingItemSubject.next(value);
	}
}
