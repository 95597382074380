import { Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { SettingsService } from './settings.service';
import { ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class HapticService {
	constructor(
		private settingsService: SettingsService,
		private toastController: ToastController
	) {}

	//Primitive haptic functions

	get isHapticsEnabled(): boolean {
		return this.settingsService.getUserSettings().haptics_enabled;
	}

	hapticsImpactHeavy = async () => {
		await Haptics.impact({ style: ImpactStyle.Heavy });
	};

	hapticsImpactMedium = async () => {
		await Haptics.impact({ style: ImpactStyle.Medium });
	};

	hapticsImpactLight = async () => {
		await Haptics.impact({ style: ImpactStyle.Light });
	};

	hapticsSelectionStart = async () => {
		await Haptics.selectionStart();
	};

	hapticsSelectionChanged = async () => {
		await Haptics.selectionChanged();
	};

	hapticsSelectionEnd = async () => {
		await Haptics.selectionEnd();
	};

	vibrate = async (duration: number = 100) => {
		await Haptics.vibrate({ duration: duration });
	};

	//Event haptic functions

	rmiRecievedHaptic = async (isHapticEnabled: boolean = this.isHapticsEnabled) => {
		if (!isHapticEnabled) return;
		setTimeout(async () => {
			await this.hapticsImpactHeavy();
		}, 100);
	};

	levelUpHaptic = async (isHapticEnabled: boolean = this.isHapticsEnabled) => {
		if (!isHapticEnabled) return;
		setTimeout(async () => {
			await this.hapticsImpactHeavy();
		}, 100);
	};

	skillTypeRevealHaptic = async (isHapticEnabled: boolean = this.isHapticsEnabled) => {
		if (!isHapticEnabled) return;
		await Haptics.vibrate({ duration: 200 });
		setTimeout(async () => {
			await Haptics.vibrate({ duration: 200 });
		}, 300);
		setTimeout(async () => {
			await Haptics.vibrate({ duration: 200 });
		}, 600);
		setTimeout(async () => {
			await Haptics.vibrate({ duration: 600 });
		}, 900);
	};

	//Custom haptic functions

	slotMachineHaptic = async (isHapticEnabled: boolean = this.isHapticsEnabled) => {
		if (!isHapticEnabled) return;
		this.generateHapticFeedback();
	};

	generateHapticFeedback = async (animationDuration: number = 6000) => {
		// Calculate the number of vibrations and the delay between them
		const vibrationDuration = 150; // Duration of each vibration in milliseconds
		const numberOfVibrations = animationDuration / vibrationDuration;
		const delayBetweenVibrations = animationDuration / numberOfVibrations;

		// Start the vibration pattern
		Haptics.selectionStart();

		for (let i = 0; i < numberOfVibrations; i++) {
			setTimeout(
				() => {
					// Haptics.vibrate();
					// this.hapticsImpactMedium();
					this.hapticsSelectionChanged();
				},
				i * delayBetweenVibrations + 1000
			);
		}
		setTimeout(() => {
			Haptics.vibrate({ duration: 500 });
		}, animationDuration + 1000);
	};

	intToImpactStyle = (int: number): ImpactStyle => {
		switch (int) {
			case 0:
				return ImpactStyle.Heavy;
			case 1:
				return ImpactStyle.Light;
			case 2:
				return ImpactStyle.Medium;
			default:
				return ImpactStyle.Heavy;
		}
	};

	async presentToast(message: string = 'Hello World!', duration: number = 2000, position: 'top' | 'middle' | 'bottom' = 'bottom') {
		const toast = await this.toastController.create({
			message: message,
			duration: duration,
			position: position
		});

		await toast.present();
	}

	vibrationDebugSequence = async () => {
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 1/8, Heavy Impact');
			await this.hapticsImpactHeavy();
		}, 100);
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 2/8, Medium Impact');
			await this.hapticsImpactMedium();
		}, 3100);
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 3/8, Light Impact');
			await this.hapticsImpactLight();
		}, 5200);
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 4/8, Selection Start');
			await this.hapticsSelectionStart();
		}, 7300);
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 5/8, 10x Selection Changed');
			for (let i = 0; i < 10; i++) {
				setTimeout(
					async () => {
						await this.hapticsSelectionChanged();
					},
					i * 500 + 1
				);
			}
		}, 8300);
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 6/8, Selection End', 2000);
			await this.hapticsSelectionEnd();
		}, 14000);
		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 7/8, Vibration for 1 Second', 2000);
			await Haptics.vibrate({ duration: 1000 });
		}, 16000);

		setTimeout(async () => {
			this.presentToast('Haptic Debug Sequence: 8/8, Vibration for 500ms', 2000);
			await Haptics.vibrate({ duration: 500 });
		}, 18000);
	};

	breakAnimationHaptic = async (offset: number = 0) => {
		setTimeout(async () => {
			await this.hapticsImpactHeavy();
		}, 1400 + offset);
	};

	profileSetupIncomingAnimationHaptics = async () => {
		setTimeout(async () => {
			await this.vibrate(2500);
		}, 500);
	};

	perTrainingIntroLeaveAnmationHaptic = async () => {
		setTimeout(async () => {
			await this.vibrate(2500);
		}, 500);
	};
}
