import { UserConfig } from './user-config';
import { Avatar } from './avatar';
import { UserPreferences } from './user-preferences';

export class User {
	//IF YOU ADD ATTRIBUTES TO THIS CLASS, GO TO savvi-machine.service.ts
	// AND IN THE METHOD updateUserDetails ENSURE TO ASSIGN THE NEW VALUES TO THE CONTEXT.
	id: number;
	email: string;
	password: string;
	first_name: string;
	last_name: string;
	user_config: UserConfig;
	user_avatar: Avatar;
	user_preferences: UserPreferences;
	access?: string;
	refresh?: string;
}
