import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Back } from '@app/_xstate/savvi-machine.events';
import { SavviMachineService } from '@app/_xstate/savvi-machine.service';
import { IonicModule } from '@ionic/angular';

@Component({
	selector: 'savvi-default-header',
	templateUrl: './savvi-default-header.component.html',
	styleUrls: ['./savvi-default-header.component.scss'],
	standalone: true,
	imports: [CommonModule, FormsModule, IonicModule]
})
export class SavviDefaultHeaderComponent implements OnInit {
	@Input() backButtonCallback?: () => void;
	@Input() backButtonIcon?: 'backarrow' | 'close' = 'backarrow';
	@Input() backButtonColor?: 'white' | 'black' = 'black';
	@Input() title?: string;
	@Input() background?: 'white' | 'transparent' = 'white';
	constructor(private savviMachineService: SavviMachineService) {}

	public headerBackgroundClass: string;
	public backButtonIconClass: string;
	ngOnInit() {
		if (!this.backButtonCallback) {
			this.backButtonCallback = () => {
				this.savviMachineService.send(new Back());
			};
		}
		switch (this.backButtonColor) {
			case 'white':
				this.backButtonIconClass = 'icon-white';
				break;
			case 'black':
				this.backButtonIconClass = 'icon-black';
				break;
		}
		switch (this.background) {
			case 'white':
				this.headerBackgroundClass = 'ion-no-border white-background';
				break;
			case 'transparent':
				this.headerBackgroundClass = 'ion-no-border transparent-background';
				break;
		}
	}
}
