import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AchievementProgress } from '@app/_models/achievement-progress';
import { AchievementRecommendation } from '@app/_models/achievement-recommendation';
import { HttpParams } from '@angular/common/http';
import { PlanetDetails } from '@app/_models/planet-details';
import { PlayerProgressInfo } from '@app/_models/player-progress-info';
import { StreakData } from '@app/_models/streak-data';
import { ProgressDetails } from '@app/_models/progress-details';
import { NextAchievementDetail } from '@app/_models/next-achievement-detail';
import { PlayerPointType } from '@app/_models/player-progression-startup';
import { RewardedMicroInteractionService } from './rewarded-micro-interaction.service';
import { TranslationService } from './translation.service';
import { environment } from '@environments/environment';
import { FeatureUnlockList } from '@app/_models/feature-unlock-list';

@Injectable({
	providedIn: 'root'
})
export class GamificationService {
	private allAchievementsWithProgress: AchievementProgress[];

	private achievementDetailShownSubject: BehaviorSubject<boolean>;
	public achievementDetailShown: Observable<boolean>;

	private showPlayerProgressToastSubject: BehaviorSubject<boolean>;
	public showPlayerProgressToast: Observable<boolean>;

	private showPlayerProgressCompletedItemSubject: BehaviorSubject<boolean>;
	public showPlayerProgressCompletedItem: Observable<boolean>;

	private showPlayerProgressBonusPointsItemSubject: BehaviorSubject<boolean>;
	public showPlayerProgressBonusPointsItem: Observable<boolean>;

	private showPlayerProgressUnlockedAchievementItemSubject: BehaviorSubject<number>;
	public showPlayerProgressUnlockedAchievementItem: Observable<number>;

	private showPlayerProgressReturnButtonSubject: BehaviorSubject<boolean>;
	public showPlayerProgressReturnButtonItem: Observable<boolean>;

	private skipAllAnimationsSubject: BehaviorSubject<boolean>;
	public skipAllAnimationsItem: Observable<boolean>;

	private showPlayerProgressLevelUpSubject: BehaviorSubject<boolean>;
	public showPlayerProgressLevelUpItem: Observable<boolean>;

	private showAllPlayerProgressItemsSubject: BehaviorSubject<boolean>;
	public showAllPlayerProgressItemsItem: Observable<boolean>;

	private showStreakCounterItemSubject: BehaviorSubject<boolean>;
	public showStreakCounterItem: Observable<boolean>;

	private showStreakCounterTooltipItemSubject: BehaviorSubject<boolean>;
	public showStreakCounterTooltipItem: Observable<boolean>;

	private showStreakCounterTutorialAnimationItemSubject: BehaviorSubject<boolean>;
	public showStreakCounterTutorialAnimationItem: Observable<boolean>;

	private showStreakDetailItemSubject: BehaviorSubject<boolean>;
	public showStreakDetailItem: Observable<boolean>;

	private isPlayerInfoLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isPlayerInfoLoadedItem: Observable<boolean> = this.isPlayerInfoLoaded.asObservable();

	private currentlySelectedAchievement: AchievementProgress;

	private featureUnlockList: FeatureUnlockList;

	// space map and player progress data
	private spaceMapDetails: PlanetDetails[];
	private currentlySelectedPlanet: PlanetDetails;
	private playerProgressInfo: PlayerProgressInfo;
	private streakData: StreakData;
	private hasUserSeenStreakCountPointAnimation: boolean = false;
	private cameFromStreakCounter: boolean = false;
	private nextAchievementDetails: NextAchievementDetail[];
	private levelUpHappened: boolean = false;
	private showLevelUpOnSpaceMap: boolean = false;
	private playerProgressionStartup: PlayerPointType;

	progressDetails: ProgressDetails;

	private playerProgressDisplayedPoints: number = 0;
	private pointBaselineForAnimation: number = 0;
	private currentLevelThreshold: number = 0;
	private nextLevelThreshold: number = 10000000;

	private skipAllAnimations: boolean = false;
	public isProgressionComponentCurrentlyVisible: boolean = false;

	constructor(
		private apiService: ApiService,
		private rmiService: RewardedMicroInteractionService,
		private translationService: TranslationService
	) {
		this.showPlayerProgressToastSubject = new BehaviorSubject<boolean>(null);
		this.showPlayerProgressToast = this.showPlayerProgressToastSubject.asObservable();

		this.showPlayerProgressCompletedItemSubject = new BehaviorSubject<boolean>(null);
		this.showPlayerProgressCompletedItem = this.showPlayerProgressCompletedItemSubject.asObservable();

		this.showPlayerProgressBonusPointsItemSubject = new BehaviorSubject<boolean>(null);
		this.showPlayerProgressBonusPointsItem = this.showPlayerProgressBonusPointsItemSubject.asObservable();

		this.showPlayerProgressUnlockedAchievementItemSubject = new BehaviorSubject<number>(null);
		this.showPlayerProgressUnlockedAchievementItem = this.showPlayerProgressUnlockedAchievementItemSubject.asObservable();

		this.showPlayerProgressReturnButtonSubject = new BehaviorSubject<boolean>(null);
		this.showPlayerProgressReturnButtonItem = this.showPlayerProgressReturnButtonSubject.asObservable();

		this.skipAllAnimationsSubject = new BehaviorSubject<boolean>(null);
		this.skipAllAnimationsItem = this.skipAllAnimationsSubject.asObservable();

		this.showPlayerProgressLevelUpSubject = new BehaviorSubject<boolean>(null);
		this.showPlayerProgressLevelUpItem = this.showPlayerProgressLevelUpSubject.asObservable();

		this.showAllPlayerProgressItemsSubject = new BehaviorSubject<boolean>(null);
		this.showAllPlayerProgressItemsItem = this.showAllPlayerProgressItemsSubject.asObservable();

		this.showStreakCounterItemSubject = new BehaviorSubject<boolean>(null);
		this.showStreakCounterItem = this.showStreakCounterItemSubject.asObservable();

		this.showStreakCounterTooltipItemSubject = new BehaviorSubject<boolean>(null);
		this.showStreakCounterTooltipItem = this.showStreakCounterTooltipItemSubject.asObservable();

		this.showStreakCounterTutorialAnimationItemSubject = new BehaviorSubject<boolean>(null);
		this.showStreakCounterTutorialAnimationItem = this.showStreakCounterTutorialAnimationItemSubject.asObservable();

		this.showStreakDetailItemSubject = new BehaviorSubject<boolean>(null);
		this.showStreakDetailItem = this.showStreakDetailItemSubject.asObservable();
	}

	loadAchievementsOverview(): Promise<AchievementProgress[]> {
		return this.apiService
			.get<AchievementProgress[]>('/achievements/overview')
			.pipe(map((allAchievementsList) => (this.allAchievementsWithProgress = allAchievementsList)))
			.toPromise();
	}

	loadAchievementProgressList(): Promise<NextAchievementDetail[]> {
		return this.apiService
			.get<NextAchievementDetail[]>('/achievements/progress_list')
			.pipe(map((nextAchievementDetails) => (this.nextAchievementDetails = nextAchievementDetails)))
			.toPromise();
	}

	loadStreakCounterData(): Promise<StreakData> {
		return this.apiService
			.get<StreakData>('/gamification/streak_counter_status')
			.pipe(map((streakData) => (this.streakData = streakData)))
			.toPromise();
	}

	loadSpaceMapDetails(): Promise<any> {
		return this.apiService
			.get<any>('/gamification/gamification_story_map_details')
			.pipe(map((spaceMapDetails) => (this.spaceMapDetails = spaceMapDetails)))
			.toPromise();
	}

	loadPlayerProgressInfo(): Promise<PlayerProgressInfo> {
		return this.apiService
			.get<PlayerProgressInfo>('/gamification/player_progress')
			.pipe(map((playerProgressInfo) => (this.playerProgressInfo = playerProgressInfo)))
			.pipe(
				map((playerProgressInfo) => {
					this.isPlayerInfoLoaded.next(true);
					return playerProgressInfo;
				})
			)
			.toPromise();
	}

	saveTourGuideViewed(): Promise<any> {
		return this.apiService.post('/gamification/tour_guide_viewed').toPromise();
	}

	saveStreakIncreaseViewed(): Promise<any> {
		return this.apiService
			.post<ProgressDetails>('/gamification/streak_increase_viewed')
			.pipe(map((playerProgress) => (this.progressDetails = playerProgress)))
			.toPromise();
	}

	saveStreakExplainationViewed() {
		this.apiService.post('/gamification/streak_explanation_viewed').toPromise();
	}

	loadFeatureUnlockList(): Promise<FeatureUnlockList> {
		return this.apiService
			.get<FeatureUnlockList>('/gamification/features')
			.pipe(map((featureUnlockList) => (this.featureUnlockList = featureUnlockList)))
			.toPromise();
	}

	sendRewardedMicroInteractionWithLevelUpChance(RMI_name: string): Promise<ProgressDetails> {
		let params = new HttpParams();
		params = params.set('origin_key', RMI_name);
		const postData = {
			origin_key: RMI_name
		};

		return this.apiService
			.post<ProgressDetails>('/gamification/rmi', {}, params)
			.pipe(map((progressDetails) => (this.progressDetails = progressDetails)))
			.toPromise();
	}

	setAllAchievementsWithProgress(allAchievementsWithProgress: AchievementProgress[]) {
		this.allAchievementsWithProgress = allAchievementsWithProgress;
	}

	getAllAchievementsWithProgress(): AchievementProgress[] {
		return this.allAchievementsWithProgress;
	}

	getSpaceMapDetails(): PlanetDetails[] {
		return this.spaceMapDetails;
	}

	getPlayerProgressInfo(): PlayerProgressInfo {
		return this.playerProgressInfo;
	}

	getStreakData(): StreakData {
		return this.streakData;
	}

	setStreakCountDataIncreasedViewed() {
		this.streakData.has_streak_increase_notified = true;
	}

	getNextAchievementDetails(): NextAchievementDetail[] {
		return this.nextAchievementDetails;
	}

	getCurrentlySelectedAchievement(): AchievementProgress {
		return this.currentlySelectedAchievement;
	}

	setCurrentlySelectedAchievement(achievement: AchievementProgress) {
		this.currentlySelectedAchievement = achievement;
	}

	setPlayerProgressDisplayedPoints() {
		console.log('Player Progression Details:', this.progressDetails);
		this.playerProgressDisplayedPoints = this.progressDetails.player_progress_update.old_points;
		this.pointBaselineForAnimation = this.progressDetails.player_progress_update.old_points;

		let oldLevel = this.progressDetails.player_progress_update.old_gamification_checkpoint;

		if (oldLevel < 5) {
			this.currentLevelThreshold = this.getSpaceMapDetails().find((planet) => planet.level == oldLevel).points_required;
			this.nextLevelThreshold = this.getSpaceMapDetails().find((planet) => planet.level == oldLevel + 1).points_required;
		}
	}

	resetPlayerProgressLevelUp() {
		this.levelUpHappened = false;
	}

	setShowLevelUpOnSpaceMap() {
		this.showLevelUpOnSpaceMap = true;
	}

	getShowLevelUpOnSpaceMap(): boolean {
		return this.showLevelUpOnSpaceMap;
	}

	resetShowLevelUpOnSpaceMap() {
		this.showLevelUpOnSpaceMap = false;
	}

	getPlayerProgressDisplayedPoints(): number {
		return this.playerProgressDisplayedPoints;
	}

	getCurrentLevelThreshold(): number {
		return this.currentLevelThreshold;
	}

	getNextLevelThreshold(): number {
		return this.nextLevelThreshold;
	}

	triggerPlayerProgressAnimation(startupLocation: PlayerPointType, showRestOfPlayerProgressionUpdate: boolean) {
		this.playerProgressionStartup = startupLocation;

		switch (startupLocation) {
			case PlayerPointType.microtraining:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow(this.translationService.translate.instant('progressionComponent.trainingCompleted'));
				}
				break;
			case PlayerPointType.quiz:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow(this.translationService.translate.instant('progressionComponent.quizCompleted'));
				}
				break;
			case PlayerPointType.daily_challenge:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow(this.translationService.translate.instant('progressionComponent.dailyChallengeCompleted'));
				}
				break;
			case PlayerPointType.rmi:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow(this.translationService.translate.instant('progressionComponent.bonusPoints'));
				}
				break;
			case PlayerPointType.rmi_achievement:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow(this.translationService.translate.instant('progressionComponent.achievementUnlocked'));
				}
				break;
			case PlayerPointType.streak:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow('');
				}
				break;
			case PlayerPointType.duel:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow('');
				}
				break;
			case PlayerPointType.totw:
				if (showRestOfPlayerProgressionUpdate) {
					this.triggerRestOfPlayerProgressAnimationFlow();
				} else {
					this.triggerPlayerProgressAnimationFlow('');
				}
				break;
		}
	}

	triggerRestOfPlayerProgressAnimationFlow() {
		this.playerProgressDisplayedPoints = this.nextLevelThreshold;

		let currentLevel = this.progressDetails.player_progress_update.current_gamification_checkpoint;

		if (currentLevel < 5) {
			this.currentLevelThreshold = this.getSpaceMapDetails().find((planet) => planet.level == currentLevel).points_required;
			this.nextLevelThreshold = this.getSpaceMapDetails().find((planet) => planet.level == currentLevel + 1).points_required;
		} else {
			this.nextLevelThreshold = 100000000;
		}

		setTimeout(() => {
			this.showAllPlayerProgressItemsSubject.next(true);
		}, 1000);
		setTimeout(() => {
			this.resetShowAllPlayerProgressItemsSubject();
		}, 2000);

		setTimeout(() => {
			this.pointBaselineForAnimation = this.playerProgressDisplayedPoints;
			this.updateProgressAnimation(this.progressDetails.player_progress_update.points - this.getSpaceMapDetails().find((planet) => planet.level == currentLevel).points_required);
		}, 2400);
	}

	triggerPlayerProgressAnimationFlow(completedTitle: string) {
		let progressItemsShown = 0;

		if (this.progressDetails.point_details.base_points > 0) {
			this.rmiService.toastTitle = completedTitle;
			this.rmiService.toastDescription = null;
			this.rmiService.pointsGained = this.progressDetails.point_details.base_points;
			this.rmiService.isToastForAchievement = false;

			setTimeout(() => {
				// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
				if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.showPlayerProgressToastSubject.next(true);
			}, 700);
			setTimeout(() => {
				this.resetShowPlayerProgressToastSubject();
			}, 2000);
			setTimeout(() => {
				// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
				if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.showPlayerProgressCompletedItemSubject.next(true);
			}, 2100);
			setTimeout(() => {
				this.resetShowPlayerProgressCompletedItemSubject();
			}, 2500);
			setTimeout(() => {
				// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
				if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.updateProgressAnimation(this.progressDetails.point_details.base_points);
			}, 2300);

			progressItemsShown += 1;
		}

		if (this.progressDetails.point_details.bonus_points > 0) {
			setTimeout(
				() => {
					if (!this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) {
						if (this.playerProgressionStartup == PlayerPointType.streak || this.playerProgressionStartup == PlayerPointType.duel || this.playerProgressionStartup == PlayerPointType.rmi) {
							this.rmiService.toastTitle = this.translationService.translate.instant('progressionComponent.bonusPoints');
							this.rmiService.toastDescription = this.progressDetails.point_details.bonus_transactions[0].description;
							this.rmiService.pointsGained = this.progressDetails.point_details.bonus_points;
							this.rmiService.isToastForAchievement = false;
						} else {
							this.rmiService.toastTitle = this.translationService.translate.instant('progressionComponent.totalBonusPoints');
							this.rmiService.toastDescription = null;
							this.rmiService.pointsGained = this.progressDetails.point_details.bonus_points;
							this.rmiService.isToastForAchievement = false;
						}
					}
				},
				700 + progressItemsShown * 3500
			);

			setTimeout(
				() => {
					// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
					if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.showPlayerProgressToastSubject.next(true);
				},
				700 + (progressItemsShown * 3500 + 100)
			);
			setTimeout(
				() => {
					this.resetShowPlayerProgressToastSubject();
				},
				700 + (progressItemsShown * 3500 + 400)
			);
			setTimeout(
				() => {
					// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
					if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.showPlayerProgressBonusPointsItemSubject.next(true);
				},
				700 + (progressItemsShown * 3500 + 1500)
			);
			setTimeout(
				() => {
					this.resetShowPlayerProgressBonusPointsItemSubject();
				},
				700 + (progressItemsShown * 3500 + 1900)
			);
			setTimeout(
				() => {
					// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
					if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.updateProgressAnimation(this.progressDetails.point_details.bonus_points);
				},
				700 + (progressItemsShown * 3500 + 1700)
			);

			progressItemsShown += 1;
		}

		this.progressDetails.point_details.achievements.forEach((achievement, unlockedAchievementIndex) => {
			setTimeout(
				() => {
					if (!this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) {
						this.rmiService.toastTitle = this.translationService.translate.instant('progressionComponent.achievementUnlocked');
						this.rmiService.toastDescription = achievement.achievement.name;
						this.rmiService.pointsGained = achievement.points_gained_multiplied;
						this.rmiService.isToastForAchievement = true;
						this.rmiService.achievementIconPath = environment.OTCObjectStorage + achievement.achievement.icon_paths[achievement.unlocked_stage - 1];
					}
				},
				700 + progressItemsShown * 3500
			);

			setTimeout(
				() => {
					// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
					if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.showPlayerProgressToastSubject.next(true);
				},
				700 + (progressItemsShown * 3500 + 100)
			);
			setTimeout(
				() => {
					this.resetShowPlayerProgressToastSubject();
				},
				700 + (progressItemsShown * 3500 + 400)
			);
			setTimeout(
				() => {
					// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
					if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible)
						this.showPlayerProgressUnlockedAchievementItemSubject.next(unlockedAchievementIndex);
				},
				700 + (progressItemsShown * 3500 + 1500)
			);
			setTimeout(
				() => {
					this.resetShowPlayerProgressUnlockedAchievementItemSubject();
				},
				700 + (progressItemsShown * 3500 + 1900)
			);
			setTimeout(
				() => {
					// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
					if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible)
						this.updateProgressAnimation(this.progressDetails.point_details.achievements[unlockedAchievementIndex].points_gained_multiplied);
				},
				700 + (progressItemsShown * 3500 + 1700)
			);

			progressItemsShown += 1;
		});

		setTimeout(
			() => {
				// only trigger if there was no levelup and animations are not skipped and the user is currently on the progression component screen and not already back on home
				if (!this.levelUpHappened && !this.skipAllAnimations && this.isProgressionComponentCurrentlyVisible) this.showPlayerProgressReturnButtonSubject.next(true);
			},
			700 + progressItemsShown * 3500
		);
		setTimeout(
			() => {
				this.resetShowPlayerProgressReturnButtonSubject();
			},
			700 + (progressItemsShown * 3500 + 100)
		);
	}

	openShowStreakCounterUnlockedModal() {
		this.showStreakCounterItemSubject.next(true);
	}
	closeShowStreakCounterUnlockedModal() {
		this.showStreakCounterItemSubject.next(false);
	}
	openShowStreakCounterTooltip() {
		this.showStreakCounterTooltipItemSubject.next(true);
		console.log('Open Tooltip Gamification');
	}

	async closeShowStreakCounterTooltip() {
		this.showStreakCounterTooltipItemSubject.next(false);
		console.log('Close Tooltip Gamification');
		/**
		 * This is to prevent a bug, that prevents the tooltip from being hidden again, because it loses the javascript connection when a backdrop is overlayed
		 * If we wait a small amount of time, the observable has enough time to notify the subscribers and the tooltip is hidden before the Animation Backdrop is overlayed
		 * TODO: Find a better solution
		 */
		await new Promise((r) => setTimeout(r, 200));
	}

	openShowStreakCounterTutorialAnimation() {
		console.log('Open Animation Gamification');
		this.showStreakCounterTutorialAnimationItemSubject.next(true);
	}

	closeShowStreakCounterTutorialAnimation() {
		this.showStreakCounterTutorialAnimationItemSubject.next(false);
	}

	openStreakDetailModal() {
		console.log('Open Animation Gamification');
		this.showStreakDetailItemSubject.next(true);
	}

	closeStreakDetailModal() {
		this.showStreakDetailItemSubject.next(false);
	}

	getStreakCountValue() {
		return this.streakData.streak_count;
	}

	setHasUserSeenStreakCountPointAnimation() {
		console.log('setHasUserSeenStreakCountPointAnimation called. Value before was: ' + this.hasUserSeenStreakCountPointAnimation);
		this.hasUserSeenStreakCountPointAnimation = true;
	}

	resetHasUserSeenStreakCountPointAnimation() {
		console.log('resetHasUserSeenStreakCountPointAnimation called. Value before was: ' + this.hasUserSeenStreakCountPointAnimation);
		this.hasUserSeenStreakCountPointAnimation = false;
	}

	getHasUserSeenStreakCountPointAnimation(): boolean {
		return this.hasUserSeenStreakCountPointAnimation;
	}

	setCameFromStreakCounter() {
		this.cameFromStreakCounter = true;
	}

	resetCameFromStreakCounter() {
		this.cameFromStreakCounter = false;
	}

	getCameFromStreakCounter(): boolean {
		return this.cameFromStreakCounter;
	}

	private interval = null;
	private animationStart = null;
	private animationPointStartValue = 0;

	updateProgressAnimation(limitValue: number) {
		this.animationStart = new Date().getTime();
		this.animationPointStartValue = this.playerProgressDisplayedPoints;
		this.pointBaselineForAnimation = this.pointBaselineForAnimation + limitValue;
		clearInterval(this.interval);
		this.interval = setInterval(() => {
			this.setProgress(limitValue);
		}, 5);
	}

	setProgress(limitValue: number) {
		// check if the user selected the skip button
		if (this.skipAllAnimations) {
			clearInterval(this.interval);

			if (this.progressDetails.player_progress_update.current_gamification_checkpoint < 5 && this.nextLevelThreshold < this.progressDetails.player_progress_update.points) {
				console.log('Player will level up so points are set to nextLevelThreshold which is: ' + this.nextLevelThreshold);

				this.playerProgressDisplayedPoints = this.nextLevelThreshold;
				// user is not going to level up so displayed points are set to final value
			} else {
				console.log('Player will not level up so points are set to player_progress_update.points which is: ' + this.progressDetails.player_progress_update.points);
				this.playerProgressDisplayedPoints = this.progressDetails.player_progress_update.points;
			}

			return;
		}
		this.increase(limitValue);
		// check if level up happened
		if (this.playerProgressDisplayedPoints >= this.nextLevelThreshold) {
			this.playerProgressDisplayedPoints = this.nextLevelThreshold;
			clearInterval(this.interval);
			this.levelUpHappened = true;
			this.showPlayerProgressLevelUpSubject.next(true);
			setTimeout(() => {
				this.showPlayerProgressLevelUpSubject.next(false);
			}, 1000);
		} else {
			// check if animation of points is done
			if (this.playerProgressDisplayedPoints >= this.pointBaselineForAnimation) {
				this.playerProgressDisplayedPoints = this.pointBaselineForAnimation;
				clearInterval(this.interval);
			}
		}
	}

	increase(limitValue: number) {
		let elapsedTime = new Date().getTime() - this.animationStart;
		let percentageTimeSpent = elapsedTime / 1000;
		this.playerProgressDisplayedPoints = Math.round(this.easeOutCubic(percentageTimeSpent) * limitValue) + this.animationPointStartValue;
	}

	easeOutCubic(x: number): number {
		return 1 - Math.pow(1 - x, 3);
	}

	resetShowPlayerProgressToastSubject() {
		this.showPlayerProgressToastSubject.next(false);
	}

	resetShowPlayerProgressCompletedItemSubject() {
		this.showPlayerProgressCompletedItemSubject.next(false);
	}

	resetShowPlayerProgressBonusPointsItemSubject() {
		this.showPlayerProgressBonusPointsItemSubject.next(false);
	}

	resetShowPlayerProgressUnlockedAchievementItemSubject() {
		this.showPlayerProgressUnlockedAchievementItemSubject.next(-1);
	}

	resetShowPlayerProgressReturnButtonSubject() {
		this.showPlayerProgressReturnButtonSubject.next(false);
	}

	resetShowAllPlayerProgressItemsSubject() {
		this.showAllPlayerProgressItemsSubject.next(false);
	}

	setCurrentlySelectedPlanet(planet: PlanetDetails) {
		this.currentlySelectedPlanet = planet;
	}

	getCurrentlySelectedPlanet(): PlanetDetails {
		return this.currentlySelectedPlanet;
	}

	getSkipAllAnimations(): boolean {
		return this.skipAllAnimations;
	}

	skipAllAnimationsTriggered() {
		this.skipAllAnimations = true;

		console.log('skipAllAnimations has been clicked');

		this.setPlayerDisplayerPointsAfterSkipButtonIsClicked();

		this.skipAllAnimationsSubject.next(true);
		setTimeout(() => {
			this.skipAllAnimationsSubject.next(false);
		});
	}

	setPlayerDisplayerPointsAfterSkipButtonIsClicked() {
		// if user still has to level up, set displayed point to next level treshhold and start level up animations
		if (this.progressDetails.player_progress_update.old_gamification_checkpoint < 5 && this.nextLevelThreshold < this.progressDetails.player_progress_update.points) {
			console.log('Player will level up so points are set to nextLevelThreshold which is: ' + this.nextLevelThreshold);

			this.playerProgressDisplayedPoints = this.nextLevelThreshold;

			this.levelUpHappened = true;
			this.showPlayerProgressLevelUpSubject.next(true);
			setTimeout(() => {
				this.showPlayerProgressLevelUpSubject.next(false);
			}, 1000);
			// user is not going to level up so displayed points are set to final value
		} else {
			console.log('Player will not level up so points are set to player_progress_update.points which is: ' + this.progressDetails.player_progress_update.points);

			this.playerProgressDisplayedPoints = this.progressDetails.player_progress_update.points;
		}

		console.log('playerProgressDisplayedPoints: ' + this.playerProgressDisplayedPoints);
	}

	setPlayerProgressInfo(playerProgressInfo: PlayerProgressInfo) {
		this.playerProgressInfo = playerProgressInfo;
	}
	getHasViewedFeedbackExplanation() {
		return this.playerProgressInfo.has_viewed_threesixty_degree_feedback_explanation;
	}

	resetSkipAllAnimationsTrigger() {
		this.skipAllAnimations = false;
	}

	hasLevelUpHappened(): boolean {
		return this.levelUpHappened;
	}

	getFeatureUnlockList(): FeatureUnlockList {
		return this.featureUnlockList;
	}

	// Small points animation

	private animationStartSmallPoints = null;
	private playerSmallPointsDisplayedPoints: number = 0;
	private animationPointStartValueSmallPoints: number = 0;
	private intervalSmallPoints = null;
	private showSmallPointsAnimation: boolean = false;

	setPlayerSmallPointsDisplayedPoints() {
		this.playerSmallPointsDisplayedPoints = this.progressDetails.player_progress_update.old_points;
	}

	getPlayerSmallPointsDisplayedPoints(): number {
		return this.playerSmallPointsDisplayedPoints;
	}

	getPointsGained(): number {
		return this.progressDetails.player_progress_update.points - this.progressDetails.player_progress_update.old_points;
	}

	updateSmallPointsAnimation() {
		let limitValue = this.progressDetails.player_progress_update.points - this.progressDetails.player_progress_update.old_points;
		this.animationStartSmallPoints = new Date().getTime();
		this.animationPointStartValueSmallPoints = this.playerSmallPointsDisplayedPoints;
		clearInterval(this.intervalSmallPoints);
		this.intervalSmallPoints = setInterval(() => {
			this.setProgressSmallPointsAnim(limitValue);
		}, 5);
	}

	private setProgressSmallPointsAnim(limitValue: number) {
		this.increaseSmallPointsAnim(limitValue);
		// check if animation of points is done
		if (this.progressDetails.player_progress_update) {
			if (this.playerSmallPointsDisplayedPoints >= this.progressDetails.player_progress_update.points) {
				this.playerSmallPointsDisplayedPoints = this.progressDetails.player_progress_update.points;
				clearInterval(this.intervalSmallPoints);
			}
		}
	}

	private increaseSmallPointsAnim(limitValue: number) {
		let elapsedTime = new Date().getTime() - this.animationStartSmallPoints;
		let percentageTimeSpent = elapsedTime / 1500;
		this.playerSmallPointsDisplayedPoints = Math.round(this.easeOutCubic(percentageTimeSpent) * limitValue) + this.animationPointStartValueSmallPoints;
	}

	resetShowSmallPointsAnimation() {
		this.showSmallPointsAnimation = false;
	}

	setShowSmallPointsAnimation() {
		this.showSmallPointsAnimation = true;
	}

	isShowSmallPointsAnimation(): boolean {
		return this.showSmallPointsAnimation;
	}

	hasLevelUpHappenedAfterGainingPoints(): boolean {
		if (
			!this.progressDetails ||
			!this.progressDetails.player_progress_update ||
			!this.progressDetails.player_progress_update.current_gamification_checkpoint ||
			!this.progressDetails.player_progress_update.old_gamification_checkpoint
		) {
			return false;
		}

		console.log(
			'hasLevelUpHappenedAfterGainingPoints returned ' +
				(this.progressDetails.player_progress_update.current_gamification_checkpoint > this.progressDetails.player_progress_update.old_gamification_checkpoint)
		);
		return this.progressDetails.player_progress_update.current_gamification_checkpoint > this.progressDetails.player_progress_update.old_gamification_checkpoint;
	}

	hasUserReceivedPoints(): boolean {
		if (this.progressDetails?.player_progress_update?.points) {
			console.log('hasUserReceivedPoints returned ' + true);
			return true;
		} else {
			console.log('hasUserReceivedPoints returned ' + false);
			return false;
		}
	}

	resetProgressDetails() {
		console.log('resetProgressDetails called');
		this.progressDetails = null;
	}
}
