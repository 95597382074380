import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Certificate } from '@app/_models/certificate';
import { PackageStatus } from '@app/_models/package-status';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { PackageService } from './package.service';
import { ProfileService } from './profile.service';
import { SingleTrainingService } from './single-training.service';

@Injectable({
	providedIn: 'root'
})
export class EndOfJourneyService {
	private _packageCompleted: boolean = false;
	private _certificate: Certificate;
	private _packageStatus: PackageStatus;

	private certifcateStartupLocation: 'MultiPackageHub' | 'EndOfJourney' | 'Home' = 'EndOfJourney';

	constructor(
		private apiService: ApiService,
		private profileService: ProfileService,
		private singleTrainingService: SingleTrainingService
	) {}

	loadCertificate(): Promise<Certificate> {
		console.log('LOAD CERTIFICATE GOT CALLED');

		return this.apiService
			.get<Certificate>('/certificate')
			.pipe(map((certificate) => (this._certificate = certificate)))
			.toPromise();
	}

	loadPackageStatus(packageId: number): Promise<PackageStatus> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		return this.apiService
			.get<PackageStatus>('/package/status', params)
			.pipe(map((packageStatus) => (this._packageStatus = packageStatus)))
			.toPromise();
	}

	setCertificateStartupLocationMultiPackageHub() {
		this.certifcateStartupLocation = 'MultiPackageHub';
	}

	setCertificateStartupLocationEndOfJourney() {
		this.certifcateStartupLocation = 'EndOfJourney';
	}

	setCertificateStartupLocationHome() {
		this.certifcateStartupLocation = 'Home';
	}

	getCertificateStartupLocation(): 'MultiPackageHub' | 'EndOfJourney' | 'Home' {
		return this.certifcateStartupLocation;
	}

	get packageCompleted(): boolean {
		/*if(this.profileService.getCurrentUserStats() && this.profileService.getCurrentUserStats()[0] && this.profileService.getCurrentUserStats()[0].week) {
      return this.profileService.getCurrentUserStats()[0].week === this.singleTrainingService.getCurrentWeek();
    } else {
      return this._packageCompleted;
    }*/
		return this._packageStatus.package_completed;
	}

	get certificate(): Certificate {
		return this._certificate;
	}

	set certificate(certificate: Certificate) {
		this._certificate = certificate;
	}
}
