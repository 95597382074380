import { User } from '@app/_models/user'
import { HttpErrorResponse } from '@angular/common/http'
import { StatsPerWeek } from '@app/_models/stats-per-week'
import { SingleTrainingContent } from '@app/_models/singleTrainingContent'
import { GroupTrainingContent } from '@app/_models/group-training-content'
import { Goal } from '@app/_models/goal'
import { Package } from '@app/_models/package'
import { Microtraining } from '@app/_models/microtraining'
import { CompletedAchievementReview } from '@app/_models/completed-achievement-review'
import { QuizQuestionChoice } from '@app/_models/quiz-question-choice'
import { NoteActivityType } from '@app/_models/note-activity-type'
import { DuelDetail } from '@app/_models/duel-detail'
import { StepResult } from '@app/_models/step-result'

//App Startup Events:
export class Init {
    readonly type = 'INIT'
}

export class StartApp {
    readonly type = 'START_APP'
}

//General Navigation Events
export class Continue {
    readonly type = 'CONTINUE'
}

export class Back {
    readonly type = 'BACK'
}

export class Next {
    readonly type = 'NEXT'
}

export class HandleError {
    readonly type = 'HANDLE_ERROR'
    constructor(public error: any) {}
}

//Authentication Events:
export class Login {
    readonly type = 'LOGIN'
    constructor(public email : string, public password : string) {}
}

export class LoginNew {
    readonly type = 'LOGIN_NEW'
}

export class LoginSuccessful {
    readonly type = 'LOGIN_SUCCESSFUL'
    constructor(public user: User) {}
}

export class RefreshLogin {
    readonly type= 'REFRESH_LOGIN'
}

export class RefreshSuccessful {
    readonly type = 'REFRESH_SUCCESSFUL'
    constructor(public user: User) {}
}

export class RefreshFail {
    readonly type= 'REFRESH_FAIL'
    constructor(public httpErrorResponse : HttpErrorResponse, public returnUrl : string) {}
}

export class Logout {
    readonly type= 'LOGOUT'
}

export class ChangeCurrentPassword {
    readonly type='CHANGE_CURRENT_PASSWORD'
    constructor(public oldPassword : string, public newPassword : string) {}
}

export class CurrentPasswordChangedToastPresented {
    readonly type='CURRENT_PASSWORD_CHANGED_TOAST_PRESENTED'
}

export class FinishPasswordChange {
    readonly type='FINISH_PASSWORD_CHANGE'
}

//License Self Service Events:

export class FinishSetupGoToOnboarding {
    readonly type = 'FINISH_SETUP_GO_TO_ONBOARDING'
}

//Pretraining Events:
export class LoadPackages {
    readonly type = 'LOAD_PACKAGES'
}

export class PackagesLoaded {
    readonly type = 'PACKAGES_LOADED';
    constructor(public packages: Package[]) {}
}

// ******************************************


export class ResetPackage{
    readonly type="RESET_PACKAGE"
}

export class SubmitPackageReset{
    readonly type="SUBMIT_PACKAGE_RESET"
}

export class CancelPackageReset{
    readonly type="CANCEL_PACKAGE_RESET"
}

export class IncreasePackage{
    readonly type="INCREASE_PACKAGE"
}

export class GoToPilotIntro{
    readonly type="GO_TO_PILOT_INTRO"
}

export class GoToCongratulationsScreen{
    readonly type="GO_TO_CONGRATULATIONS_SCREEN"
}

export class StartPretraining {
    readonly type = 'START_PRETRAINING';
}

export class ShowPackageGoals {
    readonly type = 'SHOW_PACKAGE_GOALS'
}

export class SaveGenericGoalSelection {
    readonly type = 'SAVE_GENERIC_GOAL_SELECTION';
    constructor(public generic_goal_ids: number[]) {}
}

export class SaveLongTermGoalSelection {
    readonly type = 'SAVE_LONG_TERM_GOAL_SELECTION';
}

export class ShowAvatarSelection {
    readonly type = 'SHOW_AVATAR_SELECTION'
}

export class SaveAvatarSelection {
    readonly type = 'SAVE_AVATAR_SELECTION';
    constructor(public avatar: string) {}
}

export class ShowPretrainingQuestion {
    readonly type = 'SHOW_PRETRAINING_QUESTION'
}

export class ShowNextQuestion {
    readonly type = 'SHOW_NEXT_QUESTION'
}

export class PretrainingQuestionAnswersSaved {
    readonly type = 'PRETRAINING_QUESTION_ANSWERS_SAVED'
}

export class LoadUserStats {
    readonly type = 'LOAD_USER_STATS'
}

export class ResultCalculationFinished {
    readonly type = 'RESULT_CALCULATION_FINISHED';
}

export class ProfileExplanationViewed {
    readonly type = 'PROFILE_EXPLANATION_VIEWED'
}

export class ShowJourneyBehaviorSelection {
    readonly type = 'SHOW_JOURNEY_BEHAVIOR_SELECTION';
}

export class ShowJourneyBehaviorSelectionModification {
    readonly type = 'SHOW_JOURNEY_BEHAVIOR_SELECTION_MODIFICATION';
}

export class SaveJourneyBehaviorSelectionModification {
    readonly type = 'SAVE_JOURNEY_BEHAVIOR_SELECTION_MODIFICATION'
}

export class UpdateBehaviourSelection {
    readonly type = 'UPDATE_BEHAVIOUR_SELECTION'
}

export class CompletePretraining {
    readonly type = 'COMPLETE_PRETRAINING'
}

export class SaveQuestionFeedback {
    readonly type = 'SAVE_QUESTION_FEEDBACK';
    constructor(public feedback: number) {}
}

export class ShowPretrainingCompleted {
    readonly type = 'SHOW_PRETRAINING_COMPLETED';
}

export class StartOnsiteTraining {
    readonly type = 'START_ONSITE_TRAINING'
}

export class FinishOnsiteTraining {
    readonly type = 'FINISH_ONSITE_TRAINING'
}

export class ShowPackageStart {
    readonly type = 'SHOW_PACKAGE_START'
}

export class CompletePackageIntro {
    readonly type = 'COMPLETE_PACKAGE_INTRO'
}


//Tab Navigation Events
export class GoToSingleTrainingTab {
    readonly type = 'GO_TO_SINGLE_TRAINING_TAB'
}

export class GoToGroupTrainingTab {
    readonly type = 'GO_TO_GROUP_TRAINING_TAB'
}

export class GoToHomeTab {
    readonly type = 'GO_TO_HOME_TAB'
}

export class GoToProfileTab {
    readonly type = 'GO_TO_PROFILE_TAB'
}

export class GoToJourneyTab {
    readonly type = 'GO_TO_JOURNEY_TAB'
}

//Home Events
export class GoToHistoryOverview {
    readonly type = 'GO_TO_HISTORY_OVERVIEW'
}

export class GoToHistory {
    readonly type = 'GO_TO_HISTORY'
}


export class GoToLibraryOverview {
    readonly type = 'GO_TO_LIBRARY_OVERVIEW'
}

export class GoToLeaderboard {
    readonly type = 'GO_TO_LEADERBOARD'
}

export class GoToAchievementReview {
    readonly type = 'GO_TO_ACHIEVEMENT_REVIEW'
}

export class GoToTipOfTheWeek {
    readonly type = 'GO_TO_TIP_OF_THE_WEEK'
}

export class CloseTipOfTheWeek {
    readonly type = 'CLOSE_TIP_OF_THE_WEEK'
}

export class StartEndOfJourney {
    readonly type = 'START_END_OF_JOURNEY'
}

export class GoToNewChallenge {
    readonly type = 'GO_TO_NEW_CHALLENGE'
}

export class GoToChallengeCompletion {
    readonly type = 'GO_TO_CHALLENGE_COMPLETION'
}

//Daily Challenge Events
export class StartDailyChallenge {
    readonly type = 'START_DAILY_CHALLENGE'
}

export class RejectDailyChallenge {
    readonly type = 'REJECT_DAILY_CHALLENGE'
}

export class SetChallengeTimeAndPush {
    readonly type = 'SET_CHALLENGE_TIME_AND_PUSH';
    constructor(public challengeTime : string, public enablePush : boolean) {}
}

export class ChallengeCompletedSuccessfully {
    readonly type = 'CHALLENGE_COMPLETED_SUCCESSFULLY'
}

export class DailyChallengeSuccessAnimationFinished {
    readonly type = 'DAILY_CHALLENGE_SUCCESS_ANIMATION_FINISHED'
}

export class ChallengeCompletedFailed {
    readonly type = 'CHALLENGE_COMPLETED_FAILED'
}

//Achievement Review Events
export class SaveAchievementReviewCompleted {
    readonly type = 'SAVE_ACHIEVEMENT_REVIEW_COMPLETED';
}

export class  SendCertificateToEmail{
    readonly type = "SEND_CERTIFICATE_TO_EMAIL";
}

export class  GoToEndOfJourneyCertificate{
    readonly type = "GO_TO_END_OF_JOURNEY_CERTIFICATE";
}

export class DownloadEndOfJourneyCertificate{
    readonly type = "DOWNLOAD_END_OF_JOURNEY_CERTIFICATE"
}

//Gamification Events
export class OpenSpaceMap {
    readonly type = "OPEN_SPACE_MAP"
}

export class CloseSpaceMap {
    readonly type = "CLOSE_SPACE_MAP"
}

export class OpenSpaceMapLevelUp {
    readonly type = "OPEN_SPACE_MAP_LEVEL_UP"
}

export class ReturnFromLevelUp {
    readonly type = "RETURN_FROM_LEVEL_UP"
}

export class RMIRequierementsMet {
    readonly type = 'RMI_REQUIREMENTS_MET';
    constructor(public RMI_name : string) {};
}

export class RMIRequierementsMetFake {
    readonly type = 'RMI_REQUIREMENTS_MET_FAKE';
    constructor(public RMI_name : string) {};
}

export class StartPlayerProgressionAnimation {
    readonly type = 'START_PLAYER_PROGRESS_ANIMATION'
}

export class ShowAchievementDetailModal{
    readonly type = "SHOW_ACHIEVEMENT_DETAIL_MODAL";
}

export class CloseAchievementDetailModal{
    readonly type = "CLOSE_ACHIEVEMENT_DETAIL_MODAL";
}

export class ShowAchievementsInJourney{
    readonly type = "SHOW_ACHIEVEMENTS_IN_JOURNEY";
}

export class ShowTrainingOverviewScreen{
    readonly type = "SHOW_TRAINING_OVERVIEW_SCREEN";
}

export class CloseTrainingOverviewScreen{
    readonly type = "CLOSE_TRAINING_OVERVIEW_SCREEN";
}

export class ShowSkillType{
    readonly type = "SHOW_SKILL_TYPE"
}

export class GoToWeeklyQuiz{
    readonly type = "GO_TO_WEEKLY_QUIZ";
}

export class StartWeeklyQuiz{
    readonly type = "START_WEEKLY_QUIZ";
}

export class QuestionAnswerGiven{
    readonly type = "QUESTION_ANSWER_GIVEN";
    constructor(public choice: QuizQuestionChoice) {}
}

export class ExplanationSelected{
    readonly type = "EXPLANATION_SELECTED";
}

export class ContinueToNextQuestionOrSummary{
    readonly type = "CONTINUE_TO_NEXT_QUESTION_OR_SUMMARY";
}

export class ReturnToPreviousQuestion{
    readonly type = "RETURN_TO_PREVIOUS_QUESTION";
}

export class CloseQuestionExplanation{
    readonly type = "CLOSE_QUESTION_EXPLANATION";
}

export class CloseQuiz{
    readonly type = "CLOSE_QUIZ";
}
export class CloseStreakCounterLevelUp{
    readonly type = "CLOSE_STREAK_COUNTER_LEVEL_UP";
}
export class CloseStreakCounterTooltip{
    readonly type = "CLOSE_STREAK_COUNTER_TOOLTIP";
}
export class OpenStreakCounterDetail{
    readonly type = "OPEN_STREAK_COUNTER_DETAIL"
}

export class CheckForStreakCounterIncrease {
    readonly type = "CHECK_FOR_STREAK_COUNTER_INCREASE";
}
export class ReturnToHome {
    readonly type = "RETURN_TO_HOME"
}

export class StartTourGuide{
    readonly type = "START_TOUR_GUIDE";
}

export class CloseProfileTutorial{
    readonly type = "CLOSE_PROFILE_TUTORIAL";
}

export class RewardObjectLoaded{
    readonly type = "REWARD_OBJECT_LOADED";
}

//Single Training Events
export class LoadTrainingMetadata {
    readonly type = 'LOAD_TRAINING_METADATA'
}

export class TrainingMetadataLoaded {
    readonly type = 'TRAINING_METADATA_LOADED'
}

export class CurrentWeekStatusLoaded {
    readonly type = 'CURRENT_WEEK_STATUS_LOADED'
}

export class StartSingleTraining {
    readonly type = 'START_SINGLE_TRAINING'
}

export class LoadCurrentGoal {
    readonly type = 'LOAD_CURRENT_GOAL'
}

export class CurrentGoalLoaded {
    readonly type = 'CURRENT_GOAL_LOADED'
    constructor(public goal: Goal) {}
}

export class SaveGoalSelection {
    readonly type = 'SAVE_GOAL_SELECTION'
    constructor(public goal_pk: number) {}
}

export class GoalSelectionSaved {
    readonly type= 'GOAL_SELECTION_SAVED'
}

export class MicrotrainingQuestionsLoaded {
    readonly type = 'MICROTRAINING_QUESTIONS_LOADED'
}

export class ShowMicrotrainingQuestion {
    readonly type = 'SHOW_MICROTRAINING_QUESTION'
}

export class ShowMicrotrainingIntro {
    readonly type = 'SHOW_MICROTRAINING_INTRO'
}

export class StartSuggestions {
    readonly type = 'START_SUGGESTIONS'
}

export class ShowSkillChoice {
    readonly type = 'SHOW_SKILL_CHOICE'
}

export class SelectSkill {
    readonly type = 'SELECT_SKILL'
    constructor(public skillPk : number) {}
}

export class SkillSelectionSaved {
    readonly type = 'SKILL_SELECTION_SAVED'
}

export class LoadMicrotrainingSuggestion {
    readonly type = 'LOAD_MICROTRAINING_SUGGESTION'
}

export class MicrotrainingSelectionSaved {
    readonly type = 'MICROTRAINING_SELECTION_SAVED'
}

export class MicrotrainingSuggestionLoaded {
    readonly type = 'MICROTRAINING_SUGGESTION_LOADED'
}

export class ShowMicrotrainingSuggestion {
    readonly type = 'SHOW_MICROTRAINING_SUGGESTION'
}

export class ShowMicrotrainingChoice {
    readonly type = 'SHOW_MICROTRAINING_CHOICE'
}

export class SelectMicrotraining {
    readonly type = 'SELECT_MICROTRAINING'
    constructor(public microtraining : Microtraining) {}
}

export class LoadSingleTrainingContent {
    readonly type = 'LOAD_SINGLE_TRAINING_CONTENT'
}

export class SingleTrainingContentLoaded {
    readonly type = 'SINGLE_TRAINING_CONTENT_LOADED'
    constructor(public singleTrainingContent: SingleTrainingContent) {}
}

export class GoToTrainingSuggestionScreen {
    readonly type = 'GO_TO_TRAINING_SUGGESTION_SCREEN'
}

export class GoToTrainingChoiceScreen {
    readonly type = 'GO_TO_TRAINING_CHOICE_SCREEN'
}

export class ShowExerciseExplanation {
    readonly type = 'SHOW_EXERCISE_EXPLANATION'
}

export class ShowVideoScreen {
    readonly type = 'SHOW_VIDEO_SCREEN'
}

export class ImpulsViewed {
    readonly type = 'IMPULS_VIEWED'
}

export class ShowExerciseStepFromExplanation {
    readonly type = 'SHOW_EXERCISE_STEP_FROM_EXPLANATION';
    constructor(public doNotShowAgain: boolean) {};
}

export class DeleteProgressState{
    readonly type = 'DELETE_PROGRESS_STATE';
}

export class SaveExerciseStepCompleted{
    readonly type = 'SAVE_EXERCISE_STEP_COMPLETED'
    constructor(public exerciseStepOrderNumber: number, public result: StepResult) {}
}

export class SaveDailyChallengeStepCompleted{
    readonly type = 'SAVE_DAILY_CHALLENGE_STEP_COMPLETED'
    constructor(public dailyChallengeStepOrderNumber: number, public result: StepResult) {}
}

export class ShowExerciseStep {
    readonly type = 'SHOW_EXERCISE_STEP'
}

export class ShowNextStep {
    readonly type = 'SHOW_NEXT_STEP'
}

export class ShowPreviousExerciseStep {
    readonly type = 'SHOW_PREVIOUS_EXERCISE_STEP'
}

export class ShowFeedbackVotingScreen {
    readonly type = 'SHOW_FEEDBACK_VOTING_SCREEN'
}

export class SkipFeedbackVoting {
    readonly type = 'SKIP_FEEDBACK_VOTING'
}

export class FeedbackVotingGiven {
    readonly type = 'FEEDBACK_VOTING_GIVEN'
}

export class CompleteFeedbackVoting {
    readonly type = 'COMPLETE_FEEDBACK_VOTING'
}
export class CompleteExercise {
    readonly type = 'COMPLETE_EXERCISE'
}

export class ShowGoalSelection {
    readonly type = 'SHOW_GOAL_SELECTION'
}

export class ShowIndividualGoalScreen {
    readonly type = 'SHOW_INDIVIDUAL_GOAL_SCREEN'
}

export class ShowSingleTrainingCompletedScreen {
    readonly type = 'SHOW_SINGLE_TRAINING_COMPLETED_SCREEN'
}

export class ReinitializeSingleTraining {
    readonly type= 'REINITIALIZE_SINGLE_TRAINING'
}



//Group Training Events:
export class StartGroupTraining {
    readonly type='START_GROUP_TRAINING'
}

export class ShowGroupTrainingCompleted {
    readonly type='SHOW_GROUP_TRAINING_COMPLETED'
}

export class LoadGroupMatching {
    readonly type='LOAD_GROUP_MATCHING'
}

export class GroupMatchingLoaded {
    readonly type='GROUP_MATCHING_LOADED'
}

export class ShowGroupWait {
    readonly type='SHOW_GROUP_WAIT'
}

export class ShowGroupIntro {
    readonly type='SHOW_GROUP_INTRO'
}

export class LoadGroupTrainingContent {
    readonly type='LOAD_GROUP_TRAINING_CONTENT'
}

export class GroupTrainingContentLoaded {
    readonly type ='GROUP_TRAINING_CONTENT_LOADED'
    constructor(public groupTrainingContent: GroupTrainingContent) {}
}

export class ShowGroupExerciseStep {
    readonly type='SHOW_GROUP_EXERCISE_STEP'
}

export class ShowNextGroupExerciseStep {
    readonly type ='SHOW_NEXT_GROUP_EXERCISE_STEP'
}

export class ShowPreviousGroupExerciseStep {
    readonly type ='SHOW_PREVIOUS_GROUP_EXERCISE_STEP'
}

export class LoadFeedbackNotes {
    readonly type='LOAD_FEEDBACK_NOTES'
}

export class FeedbackNotesLoaded {
    readonly type='FEEDBACK_NOTES_LOADED'
}

export class CompleteGroupExercise {
    readonly type='COMPLETE_GROUP_EXERCISE'
}

export class GoToGoalAttainment {
    readonly type = 'GO_TO_GOAL_ATTAINMENT'
}

// ****************************

export class ShowMicrotrainingInfo{
    readonly type= 'SHOW_MICROTRAINING_INFO'
    constructor(public microtraining : Microtraining) {}
}
// ******************************

//Home Events:
export class LoadHomeData {
    readonly type='LOAD_HOME_DATA'
}

export class HomeDataLoaded {
    readonly type='HOME_DATA_LOADED'
}


//Profile Events:
export class LoadProfileData {
    readonly type='LOAD_PROFILE_DATA'
}

export class UserStatsLoaded {
    readonly type='USER_STATS_LOADED'
    constructor(public statsPerWeek : StatsPerWeek) {}
}

export class UserDetailsLoaded {
    readonly type='USER_DETAILS_LOADED'
    constructor(public user : User) {}
}

export class GoToSettings {
    readonly type='GO_TO_SETTINGS'
}

export class GoToFeedbackProfile {
    readonly type='GO_TO_FEEDBACK_PROFILE'
}

export class GoToHistoryTraining {
    readonly type='GO_TO_HISTORY_TRAINING'
    constructor(public microtraining_uuid : string) {}
}

export class GoToQuizHistory {
    readonly type='GO_TO_QUIZ_HISTORY'
    constructor(public quiz_uuid : string) {}
}

export class GoToTOTWHistory {
    readonly type='GO_TO_TOTW_HISTORY'
    constructor(public totw_uuid : string) {}
}

export class GoToDailyChallengeHistory {
    readonly type='GO_TO_DAILY_CHALLENGE_HISTORY'
    constructor(public daily_challenge_uuid : string) {}
}

export class GoToLibraryTraining {
    readonly type='GO_TO_LIBRARY_TRAINING'
    constructor(public microtraining_uuid : string) {}
}

//Note Taking Events:
export class LoadNotes {
    readonly type='LOAD_NOTES'
    constructor(public object_uuid : string, public noteActivityType : NoteActivityType) {}
}

export class ShowTextualNoteModal {
    readonly type='SHOW_TEXTUAL_NOTE_MODAL'
}

export class CloseTextualNoteModal {
    readonly type='CLOSE_TEXTUAL_NOTE_MODAL'
}

export class SaveTextualNote {
    readonly type='SAVE_TEXTUAL_NOTE'
}

export class saveHistoricNote {
    readonly type='SAVE_HISTORIC_NOTE'
}

//settings events

export class SendFeedback {
    readonly type='SEND_FEEDBACK'
}


//Generic Error Events:
export class LoadingFail {
    readonly type= 'LOADING_FAIL'
    constructor(public data: any) {}
}

export class Retry {
    readonly type= 'RETRY'
}

//Journey Events:
export class GoToSpaceMap {
  readonly type = 'GO_TO_SPACE_MAP';
}

export class GoToCompleteHistory {
  readonly type = 'GO_TO_COMPLETE_HISTORY';
}

export class GoToAchievements {
  readonly type = 'GO_TO_ACHIEVEMENTS';
}

export class GoToMicrotrainingHistory {
    readonly type = 'GO_TO_MICROTRAINING_HISTORY';
}

export class GoToNotes {
  readonly type = 'GO_TO_NOTES';
}

export class GoToCompleteLibrary {
  readonly type = 'GO_TO_COMPLETE_LIBRARY';
}

export class BackToJourney {
  readonly type = 'BACK_TO_JOURNEY';
}

export class CloseHistory {
  readonly type = 'CLOSE_HISTORY';
}

export class CloseNotes {
    readonly type = 'CLOSE_NOTES';
  }

export class CloseLibrary {
  readonly type = 'CLOSE_LIBRARY';
}

// Duel

export class GoToDuels {
    readonly type = 'GO_TO_DUELS';
}

export class GoToDuelDetails {
    readonly type = 'GO_TO_DUEL_DETAILS';
    constructor(public duel: DuelDetail) {}
}

export class SendDuelInvitationViewed {
    readonly type = 'SEND_DUEL_INVITATION_VIEWED';
}

export class SendDuelCompletionViewed {
    readonly type = 'SEND_DUEL_COMPLETION_VIEWED';
    constructor(public duel: DuelDetail) {};
}

export class StartSlotMachineAnim {
    readonly type = 'START_SLOT_MACHINE_ANIM';
}

export class SetDuelDetailCalledForNewlyGeneratedDuel {
    readonly type = 'SET_DUEL_DETAIL_CALLED_FOR_NEWLY_GENERATED_DUEL';
}

export class StartGeneratedDuel {
    readonly type = 'START_GENERATED_DUEL';
}

export class GoToPlayerProgression {
    readonly type = "GO_TO_PLAYER_PROGRESSION"
}

//Lottery
export class GoToLotteryUpdate {
    readonly type = "GO_TO_LOTTERY_UPDATE"
}

export class GoToLotteryResult {
    readonly type = "GO_TO_LOTTERY_RESULT"
}

//Multi Package Hub Events

export class GoToMultiPackageHub {
    readonly type = "GO_TO_MULTI_PACKAGE_HUB"
}
export class GoToCertificatePage {
    readonly type = "GO_TO_CERTIFICATE_PAGE"
}

export class GoToPackageHistoryPage{
    readonly type = "GO_TO_PACKAGE_HISTORY_PAGE"
}

export class RequestPackageHistory {
    readonly type = "REQUEST_PACKAGE_HISTORY"
    constructor(public package_id : number) {}
}

export class RequestCertificate{
    readonly type = "REQUEST_CERTIFICATE"
    constructor(public package_id : number) {}
}


//Settings

export class ChangeAvatar {
    readonly type = 'CHANGE_AVATAR';
}

export class ChangeSchedule {
    readonly type = 'CHANGE_SCHEDULE';
}

export class SaveChangeSchedule {
    readonly type = 'SAVE_CHANGE_SCHEDULE';
    constructor(public offset: number, public pushEnable: boolean) {}
}

export class SaveChangeHapticsPreferences {
    readonly type = 'SAVE_CHANGE_HAPTICS_ENABLED';
    constructor(public hapticsEnabled: boolean) {}
}

export class ChangePassword {
    readonly type = 'CHANGE_PASSWORD';
}

export class GoToPolicy {
    readonly type = 'GO_TO_POLICY';
}

export class GoToContact {
    readonly type = 'GO_TO_CONTACT';
}

export class SaveTextFeedbackVisited {
    readonly type = 'SAVE_TEXT_FEEDBACK_VISITED';
    constructor(public feedback_ids : number[]) {}
}

export class GoToBehaviorFeedback {
    readonly type = 'GO_TO_BEHAVIOR_FEEDBACK';
}
export class SaveBehaviorFeedbackVisited {
    readonly type = 'SAVE_BEHAVIOR_FEEDBACK_VISITED';
    constructor(public feedback_ids : number[]) {}
}

export class GoToShareFeedbackProfile {
    readonly type = 'GO_TO_SHARE_FEEDBACK_PROFILE';
}

export class SkipProgressionComponent {
    readonly type = 'SKIP_PROGRESSION_COMPONENT'
}

export class GoToSelfServiceTrainingSetup {
    readonly type = 'GO_TO_SELF_SERVICE_TRAINING_SETUP'
}

export class GoToSelfServiceTeamInvite {
    readonly type = 'GO_TO_SELF_SERVICE_TEAM_INVITE'
}

export class GoToSelfServiceSummary {
    readonly type = 'GO_TO_SELF_SERVICE_SUMMARY'
}

export class GoToSelfServiceTeamOverview {
    readonly type = 'GO_TO_SELF_SERVICE_TEAM_OVERVIEW'
}

export class ShowTeamMemberDetailModal {
    readonly type = 'SHOW_TEAM_MEMBER_DETAIL_MODAL'
}

export class CloseSelfServiceTeamMemberModal {
    readonly type= 'CLOSE_SELF_SERVICE_TEAM_MEMBER_MODAL'
}

export type SavviEvent = Init |
    Continue |
    Next |
    Back |
    HandleError |
    CancelPackageReset |
    CompleteExercise |
    CompleteGroupExercise |
    CompletePackageIntro |
    CompletePretraining |
    SaveGoalSelection |
    CurrentGoalLoaded |
    FeedbackNotesLoaded |
    FinishOnsiteTraining |
    GoToGroupTrainingTab |
    GoToHomeTab |
    GoToProfileTab |
    GoToJourneyTab |
    GoToHistoryOverview |
    GoToLibraryOverview |
    GoToLeaderboard |
    GoToAchievementReview |
    GoToTipOfTheWeek |
    CloseTipOfTheWeek |
    StartEndOfJourney |
    GoToNewChallenge |
    GoToChallengeCompletion |
    StartDailyChallenge |
    RejectDailyChallenge |
    SetChallengeTimeAndPush |
    ChallengeCompletedSuccessfully |
    DailyChallengeSuccessAnimationFinished |
    ChallengeCompletedFailed |
    SaveAchievementReviewCompleted |
    GoToSingleTrainingTab |
    GoToGoalAttainment |
    GoToCongratulationsScreen |
    IncreasePackage |
    LoadCurrentGoal |
    LoadFeedbackNotes |
    LoadGroupTrainingContent |
    LoadGroupMatching |
    LoadingFail |
    Retry |
    LoadMicrotrainingSuggestion |
    ShowMicrotrainingInfo |
    MicrotrainingSelectionSaved |
    LoadPackages |
    LoadProfileData |
    LoadSingleTrainingContent |
    LoadTrainingMetadata |
    Login |
    LoginNew |
    LoginSuccessful |
    Logout |
    MicrotrainingQuestionsLoaded |
    MicrotrainingSuggestionLoaded |
    PackagesLoaded |
    StartPretraining |
    ShowPackageGoals |
    SaveGenericGoalSelection |
    SaveLongTermGoalSelection |
    ShowAvatarSelection |
    SaveAvatarSelection |
    PretrainingQuestionAnswersSaved |
    LoadUserStats |
    ResultCalculationFinished |
    ProfileExplanationViewed |
    ShowJourneyBehaviorSelection |
    ShowJourneyBehaviorSelectionModification |
    SaveJourneyBehaviorSelectionModification |
    UpdateBehaviourSelection |
    SaveQuestionFeedback |
    ShowPretrainingCompleted |
    ShowPackageStart |
    RefreshFail |
    RefreshLogin |
    RefreshSuccessful |
    ChangeCurrentPassword |
    CurrentPasswordChangedToastPresented | 
    FinishPasswordChange |
    ReinitializeSingleTraining |
    ResetPackage |
    GoalSelectionSaved |
    GroupTrainingContentLoaded |
    GroupMatchingLoaded |
    ShowGroupExerciseStep |
    ShowNextGroupExerciseStep |
    ShowPreviousGroupExerciseStep |
    GoToTrainingSuggestionScreen |
    GoToTrainingChoiceScreen |
    SendCertificateToEmail |
    GoToEndOfJourneyCertificate |
    DownloadEndOfJourneyCertificate |
    ShowExerciseExplanation |
    ShowExerciseStepFromExplanation |
    ShowVideoScreen|
    ImpulsViewed |
    ShowExerciseStep |
    ShowGoalSelection |
    ShowGroupTrainingCompleted |
    ShowGroupIntro |
    ShowGroupWait |
    ShowIndividualGoalScreen |
    ShowSingleTrainingCompletedScreen |
    ShowMicrotrainingSuggestion |
    ShowMicrotrainingChoice |
    SelectMicrotraining |
    ShowMicrotrainingIntro |
    ShowMicrotrainingQuestion |
    ShowNextStep |
    ShowNextQuestion |
    ShowPretrainingQuestion |
    ShowPreviousExerciseStep |
    ShowSkillChoice |
    SelectSkill |
    SkillSelectionSaved |
    SingleTrainingContentLoaded |
    StartApp |
    StartGroupTraining |
    StartOnsiteTraining |
    StartSingleTraining |
    StartSuggestions |
    SubmitPackageReset |
    TrainingMetadataLoaded |
    UserDetailsLoaded |
    UserStatsLoaded |
    GoToHistoryTraining |
    GoToQuizHistory |
    GoToTOTWHistory |
    GoToDailyChallengeHistory |
    ShowFeedbackVotingScreen |
    SkipFeedbackVoting |
    FeedbackVotingGiven |
    CompleteFeedbackVoting|
    GoToLibraryTraining |
    OpenSpaceMap |
    CloseSpaceMap |
    OpenSpaceMapLevelUp |
    ReturnFromLevelUp |
    RMIRequierementsMet |
    RMIRequierementsMetFake |
    StartPlayerProgressionAnimation |
    ShowAchievementDetailModal |
    CloseAchievementDetailModal |
    ShowAchievementsInJourney |
    ShowTrainingOverviewScreen |
    CloseTrainingOverviewScreen |
    ShowSkillType |
    GoToWeeklyQuiz |
    StartWeeklyQuiz |
    QuestionAnswerGiven |
    ExplanationSelected |
    ContinueToNextQuestionOrSummary |
    ReturnToPreviousQuestion |
    CloseQuestionExplanation |
    CloseQuiz |
    StartTourGuide | 
    CloseStreakCounterLevelUp |
    CloseStreakCounterTooltip |
    OpenStreakCounterDetail |
    CheckForStreakCounterIncrease |
    ReturnToHome |
    RewardObjectLoaded |
    GoToPilotIntro |
    LoadNotes |
    ShowTextualNoteModal |
    CloseTextualNoteModal |
    CloseTextualNoteModal |
    saveHistoricNote |
   SaveTextualNote |
   saveHistoricNote |
   SendFeedback |
   GoToSpaceMap |
   GoToCompleteHistory |
   GoToAchievements |
   GoToNotes |
   GoToCompleteLibrary |
   CloseHistory |
   CloseLibrary |
   CloseNotes |
   GoToDuels |
   GoToDuelDetails |
   SendDuelInvitationViewed |
   SendDuelCompletionViewed |
   StartSlotMachineAnim |
   SetDuelDetailCalledForNewlyGeneratedDuel |
   StartGeneratedDuel |
   GoToPlayerProgression |
   GoToLotteryUpdate |
   GoToLotteryResult |
   BackToJourney |
   GoToHistory |
    SaveTextualNote|
    GoToSettings |
    ChangeAvatar |
    ChangeSchedule |
    SaveChangeSchedule |
    SaveChangeHapticsPreferences |
    ChangePassword |
    GoToPolicy |
    GoToContact |
    GoToFeedbackProfile |
    SaveTextFeedbackVisited |
    SaveBehaviorFeedbackVisited |
    GoToShareFeedbackProfile |
    GoToBehaviorFeedback |
    SkipProgressionComponent |
    SaveExerciseStepCompleted |
    SaveDailyChallengeStepCompleted |
    DeleteProgressState |
    GoToMultiPackageHub |
    RequestPackageHistory |
    GoToCertificatePage |
    GoToPackageHistoryPage |
    RequestCertificate  |
    GoToMicrotrainingHistory |
    CloseProfileTutorial |
    GoToSelfServiceTrainingSetup|
    GoToSelfServiceTeamInvite |
    GoToSelfServiceSummary |
    FinishSetupGoToOnboarding |
    GoToSelfServiceTeamOverview |
    ShowTeamMemberDetailModal |
    CloseSelfServiceTeamMemberModal ;

export interface Errors {

    error: {
        [key: string]: string;
    }
}
