import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AuthResult } from '@ionic-enterprise/auth';
import { BrowserVault, DeviceSecurityType, IdentityVaultConfig, Vault, VaultType } from '@ionic-enterprise/identity-vault';
import { Platform } from '@ionic/angular';

const config: IdentityVaultConfig = {
	key: 'hr.savvi.training.identityvault',
	type: VaultType.SecureStorage,
	deviceSecurityType: DeviceSecurityType.None,
	lockAfterBackgrounded: 200000,
	shouldClearVaultAfterTooManyFailedAttempts: true,
	customPasscodeInvalidUnlockAttempts: 2,
	unlockVaultOnLoad: true
};

const vaultKey = 'auth-result';

@Injectable({
	providedIn: 'root'
})
export class VaultService {
	private vault: Vault | BrowserVault;

	constructor(private platform: Platform) {
		this.vault = platform.is('hybrid') ? new Vault(config) : new BrowserVault(config);
	}

	isLocked() {
		return this.vault.isLocked();
	}

	getKeys() {
		return this.vault.getKeys();
	}

	public clear(): Promise<void> {
		return this.vault.clear();
	}

	public getSession(): Promise<AuthResult | null> {
		return this.vault.getValue<AuthResult>(vaultKey);
	}

	public setSession(value: AuthResult): Promise<void> {
		return this.vault.setValue(vaultKey, value);
	}
}
