import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AchievementReviewStats } from '@app/_models/achievement-review-stats';
import { CompletedAchievementReview } from '@app/_models/completed-achievement-review';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { SavviContext } from '@app/_xstate/savvi-context';
import { SingleTrainingService } from './single-training.service';
import { GamificationService } from './gamification.service';

@Injectable({
	providedIn: 'root'
})
export class AchievementReviewService {
	private achievementReviewStats: AchievementReviewStats;

	achievementReviewAnimationShown: boolean = false;

	constructor(
		private apiService: ApiService,
		private singleTrainingService: SingleTrainingService,
		private gamificationService: GamificationService
	) {}

	loadAchievementReviewStats(packageId: number): Promise<AchievementReviewStats> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());

		return this.apiService
			.get<AchievementReviewStats>('/achievementsReview/stats', params)
			.pipe(map((achievementReviewStats) => (this.achievementReviewStats = achievementReviewStats)))
			.toPromise();
	}

	saveAchievementReviewCompleted(): Promise<any> {
		return this.apiService.post<any>('/achievementsReview/has_seen_review').toPromise();
	}

	getAchievementReviewStats(): AchievementReviewStats {
		return this.achievementReviewStats;
	}

	showAchievementReviewAnimation() {
		this.achievementReviewAnimationShown = true;
	}

	hideAchievementReviewAnimation() {
		this.achievementReviewAnimationShown = false;
	}

	isAchievementReviewViewedThisWeek(): boolean {
		return this.gamificationService.getPlayerProgressInfo().has_seen_review_this_week;
	}

	isAchievementReviewWeekOrIsLastWeekAndIsTrainingCompleted(): boolean {
		let isAchievementReviewWeek: boolean = false;
		let achievementReviewWeeks = this.gamificationService.getPlayerProgressInfo().achievement_review_weeks;

		if (achievementReviewWeeks) {
			if (achievementReviewWeeks.length > 0) {
				isAchievementReviewWeek = achievementReviewWeeks.includes(this.singleTrainingService.getCurrentWeek());
			}
		}
		let isLastWeekAndIsTrainingCompleted = this.singleTrainingService.isLastWeekAndIsTrainingCompleted();

		return isAchievementReviewWeek || isLastWeekAndIsTrainingCompleted;
	}
}
