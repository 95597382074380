<div class="modal-container">
	<ion-card class="ion-margin savvi-card modal-card">
		<ion-card-content>
			<ion-grid>
				<ion-row>
					<ion-col size="auto">
						<a (click)="close()">
							<ion-icon name="close" size="small" class="close-modal-icon-black"></ion-icon>
						</a>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col class="ion-text-center">
						<ion-card-title translate>welcome.installPrompt.installation</ion-card-title>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col>
						<div class="ion-text-center savvi-paragraph" translate>welcome.installPrompt.recommendation</div>
						<div *ngIf="mobileType === 'ios'" class="ion-text-center savvi-paragraph">
							<span translate>welcome.installPrompt.howTo</span> <br />
							<strong translate>welcome.installPrompt.home</strong>
						</div>
					</ion-col>
				</ion-row>
				<ion-row *ngIf="mobileType === 'ios'">
					<ion-col size="5" class="ion-text-end">
						<ion-icon size="large" name="share-outline"></ion-icon>
					</ion-col>
					<ion-col size="2" class="ion-text-center">
						<ion-icon size="large" name="chevron-forward-outline"></ion-icon>
					</ion-col>
					<ion-col size="5" class="ion-text-start">
						<ion-img src="./assets/ios-add-btn.png"></ion-img>
					</ion-col>
				</ion-row>
				<ion-row *ngIf="mobileType === 'android'">
					<ion-col>
						<ion-button class="savvi-button" expand="block" (click)="installPwa()" translate>welcome.installPrompt.install</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-card-content>
	</ion-card>
</div>
