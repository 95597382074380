import { id } from 'date-fns/locale';
import { actions, createMachine, Machine, MachineConfig } from 'xstate';
import { SavviContext } from './savvi-context';
import { SavviSchema } from './savvi-schema';
import { SavviEvent, LoadingFail, UserStatsLoaded } from './savvi-machine.events';
import { Package } from '@app/_models/package';
import { TrainingMode } from '@app/_models/training-mode';
import { ProfileSection, ProfileSegment } from '@app/_models/profile-section';
import { SkillTypeStartupLocation } from '@app/_models/skill-type-startup-location';

export const context: SavviContext = {
    user: {
        id: -1,
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        user_avatar: null,
        user_config: null,
        user_preferences: null
    },
    week: null,
    packages: [],
    currentPretrainingProgress: {
        currentPretrainingQuestion: 0,
        currentStep: 1,
        totalSteps: 4,
        onsiteTrainingOverAlreadyChecked: false,
        pretrainingStatus: null,
        profileExplanationViewed: false
    },
    skillTypeStartupLocation: SkillTypeStartupLocation.profile,
    quizStartupLocation: null,
    totwStartupLocation: null,
    dailyChallengeStartupLocation: null,
    statsPerWeek: null,
    microtraining: null,
    currentTrainingProgress: {
        // TODO: rename these to be prefixed with "currentlyShown" (instead of "current")
        currentMicrotrainingQuestion: 0,
        currentGroupExerciseStep: 0,
        microtrainingStartedFromFullList: false
    },
    singleTrainingContent: null,
    groupTrainingContent: null,
    profileSection: ProfileSection.relyOnSubstateHistory,
    trainingMode: TrainingMode.normal,
    isDailyChallengeHistory: false,
    lastTrainingWasHistoryOrLibrary: false,
    impulsViewed: false,
    errors: null,
    popupChecks: {
        achievementReviewChecked: false
    },
    microtrainingWasAlreadySet: false,
    toastMessages: {
        showPasswordChangedToast: false
    },
    EOJFlowStarted: false,
    playerProgressionStartup: null,
    showRestOfPlayerProgressionUpdate: false,
    playerProgressionHasStarted: false,
    textualNoteModalShown: false,
    historicNoteModalShown: false,
    goToLibraryAfterLoadingFlag: false,
    subscription: false,
};

export const savviMachineConfig: MachineConfig<SavviContext, SavviSchema, SavviEvent> = {
    id: 'savvi',
    context,
    strict: true,
    initial: 'app_context',
    states: {
        app_context: {
            id: 'app_context',
            initial: 'boot',
            on: {
                HANDLE_ERROR: {
                    target: '#request_error',
                    actions: ['assignFrontendError']
                }
            },
            states: {
                boot: {
                    after: {
                        '500': {
                            target: 'waiting_for_logged_in_decision'
                        }
                    }
                },
                waiting_for_logged_in_decision: {
                    invoke: {
                        id: 'evaluateIsAuthenticated',
                        src: 'evaluateIsAuthenticated',
                        onDone: [
                            {
                                target: 'logged_in_entry',
                                cond: 'isLoggedIn'
                            },
                            {
                                target: 'app_welcome_screen'
                            }
                        ],
                        onError: [
                            {
                                target: '#request_error',
                                actions: ['assignErrors']
                            }
                        ]
                    }
                },
                app_welcome_screen: {
                    id: 'app_welcome_screen',
                    entry: ['hideSplashScreen', 'loadingCompleted', 'displayAppWelcomeScreen'],
                    on: {
                        /*START_APP: {
                            target: 'login_screen',
                        },*/
                        LOGIN_NEW: {
                            target: 'login_loading_new'
                        }
                    }
                },
                login_screen: {
                    entry: ['loadingCompleted', 'displayLoginScreen'],
                    id: 'login_screen',
                    on: {
                        LOGIN_NEW: 'login_loading_new'
                    }
                },
                login_loading_new: {
                    entry: ['setFullScreenLoadingSpinnerActive', 'resetLoadingService'],
                    invoke: {
                        id: 'requestLoginNew',
                        src: 'requestLoginNew',
                        onDone: {
                            target: 'logged_in_entry',
                            actions: ['assignUser', 'removeOldErrors']
                        },
                        onError: [
                            {
                                target: 'login_screen',
                                actions: ['assignErrors'],
                                cond: 'is401Unauthorized'
                            },
                            {
                                target: '#request_error',
                                actions: ['assignErrors']
                            }
                        ]
                    }
                },
        
                logged_in_entry: {
                    entry: ['setFullScreenLoadingSpinnerActive'],
                    id: 'logged_in_entry',
                    type: 'parallel',
                    states: {
                        user_details_loading: {
                            initial: 'loading',
                            states: {
                                loading: {
                                    invoke: {
                                        id: 'requestUserDetails',
                                        src: 'requestUserDetails',
                                        onDone: {
                                            target: 'success',
                                            actions: ['updateUserDetails', 'setOneSignalExternalUserId']
                                        },
                                        onError: {
                                            target: '#request_error',
                                            actions: ['assignErrors']
                                        }
                                    }
                                },
                                success: {
                                    type: 'final'
                                }
                            }
                        },
                        available_avatars_loading: {
                            initial: 'loading',
                            states: {
                                loading: {
                                    invoke: {
                                        id: 'requestAvailableAvatars',
                                        src: 'requestAvailableAvatars',
                                        onDone: {
                                            target: 'success'
                                        },
                                        onError: {
                                            target: '#request_error',
                                            actions: ['assignErrors']
                                        }
                                    }
                                },
                                success: {
                                    type: 'final'
                                }
                            }
                        },
                        packages_and_pretraining_loading: {
                            id: 'packages_and_pretraining_loading',
                            initial: 'subscription_status_loading',
                            states: {
                                subscription_status_loading: {
                                    initial: 'loading',
                                    states: {
                                        loading: {
                                            invoke: {
                                                id: 'requestSelfServiceStatus',
                                                src: 'requestSelfServiceStatus',
                                                onDone: [
                                                    {
                                                        target: '#packages_and_pretraining_loading.success',
                                                        cond: 'isUserSelfService',
                                                        actions: ['setSubscriptionStatus']
                                                    },
                                                    {
                                                        target: '#initialPackageLoading',
                                                    }
                                                    
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        success: {
                                            type: 'final'
                                        }
                                    }
                                },
                                loading_packages: {
                                    id: 'initialPackageLoading',
                                    invoke: {
                                        id: 'requestPackages',
                                        src: 'requestPackages',
                                        onDone: {
                                            target: 'loading_after_package',
                                            actions: ['assignPackages', 'setLanguage']
                                        },
                                        onError: [
                                            {
                                                target: '#request_error',
                                                actions: ['assignErrors']
                                            }
                                        ]
                                    }
                                },
                                loading_after_package: {
                                    id: 'loading_after_package',
                                    type: 'parallel',
                                    onDone: {target: 'success'},
                                    states: {
                                        pretraining_status_loading: {
                                            initial: 'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestPretrainingStatus',
                                                        src: 'requestPretrainingStatus',
                                                        onDone: {
                                                            target: 'success',
                                                            actions: ['assignPretrainingStatus']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        load_config_and_assets: {
                                            initial: 'loading_config',
                                            states: {
                                                loading_config: {
                                                    invoke: {
                                                        id: 'requestConfig',
                                                        src: 'requestConfig',
                                                        onDone: {
                                                            target: 'loading_assets'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                loading_assets: {
                                                    invoke: {
                                                        id: 'loadAssets',
                                                        src: 'loadAssets',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        rewarded_micro_interaction_point_list_loading: {
                                            initial: 'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestRewardedMicroInteractionPointList',
                                                        src: 'requestRewardedMicroInteractionPointList',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                    },
                                },
                                success: {
                                    type: 'final'
                                }
        
                            }
                        },
                    },
                    onDone: [
                        {
                            target: '#logged_in.main_scope.self_service',
                            cond: 'isUserSelfService'
                        },
                        {
                            target: '#logged_in.main_scope.waiting_for_pretraining_completed_decision',
                        }
                    ]
                },
                logged_in: {
                    entry: ['hideSplashScreen'],
                    id: 'logged_in',
                    initial: 'main_scope',
                    on: {
                        /*REFRESH_LOGIN: 'refresh_loading',*/
                        LOGOUT: {
                            target: 'login_screen',
                            actions: ['triggerLogout']
                        }
                    },
                    states: {
                        logged_in_substate_history: {
                            type: 'history',
                            history: 'deep'
                        },
                        main_scope: {
                            id: 'main_scope',
                            initial: 'waiting_for_pretraining_completed_decision',
                            states: {
                                main_scope_substate_history: {
                                    type: 'history',
                                    history: 'deep'
                                },
                                password_change: {
                                    initial: 'password_change_screen',
                                    states: {
                                        password_change_screen: {
                                            entry: ['loadingCompleted', 'displayPasswordChangeScreen'],
                                            on: {
                                                CHANGE_CURRENT_PASSWORD: 'password_change_saving'
                                            }
                                        },
                                        password_change_saving: {
                                            entry: ['resetLoadingService', 'removeOldErrors'],
                                            invoke: {
                                                id: 'savePasswordChange',
                                                src: 'savePasswordChange',
                                                onDone: {
                                                    target: '#main_scope.waiting_for_pretraining_completed_decision',
                                                    actions: ['passwordHasBeenChanged']
                                                },
                                                onError: [
                                                    {
                                                        target: 'password_change_screen',
                                                        actions: ['assignErrors'],
                                                        cond: 'is400OldPasswordWrong'
                                                    },
                                                    {
                                                        target: '#request_error',
                                                        actions: ['assignErrors']
                                                    }
                                                ]
                                            }
                                        }
                                    }
                                },
                                self_service: {
                                    initial: 'self_service_entry',
                                    states: {
                                        self_service_entry: {
                                            initial: 'loading_config',
                                            entry: ['resetFullScreenLoadingSpinnerActive', 'resetLoadingService'],
                                            states: {
                                                loading_config: {
                                                    invoke: {
                                                        id: 'requestConfigForSelfService',
                                                        src: 'requestConfig', 
                                                        onDone: {
                                                            target: 'loading_assets',
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                loading_assets: {
                                                    invoke: {
                                                        id: 'loadAssetsForSelfService',
                                                        src: 'loadAssets', 
                                                        onDone: {
                                                            target: 'self_service_loading',
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                self_service_loading: {
                                                    invoke: {
                                                        id: 'requestSelfServiceData',
                                                        src: 'requestSelfServiceData',
                                                        onDone: [{
                                                            target: '#logged_in.main_scope.self_service.self_service_login_screen',
                                                            cond: 'isSelfServiceStartupLocationLogin'
                                                        },
                                                        {
                                                            target: '#logged_in.main_scope.self_service.self_service_summary_screen',
                                                            cond: 'isSelfServiceStartupLocationSettings'
                                                        }],
                                                        onError: [
                                                            {
                                                                target: '#request_error',
                                                                actions: ['assignErrors' ]
                                                            }
                                                        ]
                                                    }
                                                },
                                            }
                                        },
                                        self_service_login_screen: {
                                            entry: ['loadingCompleted', 'displaySelfServiceLoginScreen'],
                                            id: 'self_service_login_screen',
                                            on: {
                                                GO_TO_SELF_SERVICE_TRAINING_SETUP:
                                                'self_service_training_setup'
                                            }
                                        },
                                        self_service_training_setup: {
                                            entry: ['displaySelfServiceTrainingSetup'],
                                            id: 'self_service_login_screen',
                                            on: {
                                                BACK:[
                                                    {
                                                        target: 'self_service_login_screen',
                                                        cond: 'isSelfServiceStartupLocationLogin'
                                                    },
                                                    { target: 'self_service_summary_screen'}
                                                ],
                                                GO_TO_SELF_SERVICE_TEAM_INVITE:
                                                'self_service_team_invite_screen',
                                                GO_TO_SELF_SERVICE_SUMMARY:
                                                'self_service_summary_screen'
                                            }
                                        },
                                        self_service_team_invite_screen: {
                                            entry: ['displaySelfServiceTeamInvite'],
                                            id: 'self_service_login_screen',
                                            on: {
                                                BACK:[
                                                    {
                                                        target: 'self_service_training_setup',
                                                        cond: 'isSelfServiceStartupLocationLogin'
                                                    },
                                                    { target: 'self_service_team_overview'}
                                                ],
                                                GO_TO_SELF_SERVICE_SUMMARY:
                                                'self_service_summary_screen'
                                            }
                                        },
                                        self_service_summary_screen: {
                                        
                                            entry: ['displaySelfServiceSummary'],
                                            id: 'self_service_summary_screen',
                                            on: {
                                                BACK:[
                                                {
                                                    target: 'self_service_team_invite_screen',
                                                    cond: 'isSelfServiceStartupLocationLogin'
                                                },
                                                { target: '#main_scope.profile.settings'}
                                            ],
                                                FINISH_SETUP_GO_TO_ONBOARDING:
                                                [
                                                    {
                                                        target: 'waiting_for_self_service_package_creation',
                                                        
                                                        cond: 'isSelfServiceStartupLocationLogin'
                                                    },
                                                    { target:  'waiting_for_self_service_package_change',
        
                                                    }],
                                                GO_TO_SELF_SERVICE_TRAINING_SETUP:
                                                'self_service_training_setup',
                                                GO_TO_SELF_SERVICE_TEAM_OVERVIEW:
                                                'self_service_team_overview'
                                            }
                                        },
                                        self_service_team_overview: {
                                            entry: ['loadingCompleted', 'displaySelfServiceTeamOverview'],
                                            id: 'self_service_summary_screen',
                                            on: {
                                                BACK:
                                                    {
                                                        target: 'self_service_summary_screen'
                                                    },
                                                    SHOW_TEAM_MEMBER_DETAIL_MODAL:
                                                    'self_service_team_member_modal',
                                                    GO_TO_SELF_SERVICE_TEAM_INVITE:
                                                    'self_service_team_invite_screen'
                                                }
                                        },
                                        self_service_team_member_modal: {
                                            on: {
                                                CLOSE_SELF_SERVICE_TEAM_MEMBER_MODAL: 'self_service_team_overview',
                                            },
                                        },
                                        waiting_for_self_service_package_change: {
                                            entry: ['resetLoadingService'],
                                            invoke: {
                                                id: 'saveSelfServicePackageChange',
                                                src: 'saveSelfServicePackageChange',
                                                onDone: {
                                                    target: '#logged_in_entry',
                                                    
                                                }, onError:[
                                                    {
                                                        target: '#request_error',
                                                        actions: ['assignErrors' ]
                                                    }
                                                ]
                                            } 
                                        },
                                        waiting_for_self_service_package_creation: {
                                            entry: ['resetLoadingService'],
                                            invoke: {
                                                id: 'createSelfServicePackage',
                                                src: 'createSelfServicePackage',
                                                onDone: {
                                                    target: '#logged_in_entry',
                                                    
                                                }, onError:[
                                                    {
                                                        target: '#request_error',
                                                        actions: ['assignErrors' ]
                                                    }
                                                ]
                                            } 
                                        }
                                    }
                                },
                                waiting_for_pretraining_completed_decision: {
                                    always: [
                                        {
                                            target: 'pretraining_status_reloading',
                                            cond: 'pretrainingCompleted'
                                        },
                                        {
                                            target: 'pretraining',
                                            actions: ['resetFullScreenLoadingSpinnerActive']
                                        }
                                    ]
                                },  
                                pretraining: {
                                    id: 'pretraining',
                                    initial: 'pilot_user_decision',
                                    states: {
                                        pilot_user_decision: {
                                            entry: ['resetBehaviorToGoalSelection', 'resetCurrentPretrainingStep', 'resetProfileExplanationViewed'],
                                            always: [
                                                {
                                                    target: 'pilot_user_introduction_screen',
                                                    cond: 'isPilotUser'
                                                },
                                                {
                                                    target: 'pretraining_intro_screen'
                                                }
                                            ]
                                        },
                                        pilot_user_introduction_screen: {
                                            id: 'pilot_user_introduction_screen',
                                            entry: ['displayPilotUserIntroductionScreen'],
                                            on: {
                                                GO_TO_HOME_TAB: {
                                                    target: '#home.home_substate_history'
                                                },
                                                GO_TO_PILOT_INTRO: {
                                                    target: 'pretraining_intro_screen'
                                                },
                                                CURRENT_PASSWORD_CHANGED_TOAST_PRESENTED: {
                                                    actions: ['toastHasBeenShown']
                                                }
                                            }
                                        },
                                        pretraining_intro_screen: {
                                            entry: ['displayPretrainingIntroScreen'],
                                            on: {
                                                START_PRETRAINING: {
                                                    target: 'goal_behavior_intro_screen'
                                                },
                                                CURRENT_PASSWORD_CHANGED_TOAST_PRESENTED: {
                                                    actions: ['toastHasBeenShown']
                                                }
                                            }
                                        },
                                        goal_behavior_intro_screen: {
                                            entry: ['displayGoalBehaviorIntroScreen'],
                                            on: {
                                                CONTINUE: 'long_term_goal_loading'
                                            }
                                        },
                                        long_term_goal_loading: {
                                            entry: ['resetLoadingService', 'displayLongTermGoalsScreen'],
                                            invoke: {
                                                id: 'requestLongTermGoals',
                                                src: 'requestLongTermGoals',
                                                onDone: {
                                                    target: 'journey_behaviors_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        journey_behaviors_loading: {
                                            invoke: {
                                                id: 'requestSkillsPackage',
                                                src: 'requestSkillsPackage',
                                                onDone: {
                                                    target: 'long_term_goal_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        long_term_goal_screen: {
                                            entry: ['loadingCompleted', 'displayLongTermGoalsScreen'],
                                            on: {
                                                SHOW_JOURNEY_BEHAVIOR_SELECTION: {
                                                    target: 'journey_behaviors_screen',
                                                    actions: ['incrementCurrentPretrainingStep']
                                                },
                                                BACK: {
                                                    target: 'goal_behavior_intro_screen'
                                                }
                                            }
                                        },

                                        journey_behaviors_screen: {
                                            entry: ['displayJourneyBehaviorsScreen'],
                                            on: {
                                                CONTINUE: [
                                                    {
                                                        target: 'behavior_to_long_term_goal_selection_saving',
                                                        actions: ['incrementCurrentPretrainingStep']
                                                    }
                                                ],
                                                BACK: [
                                                    {
                                                        target: 'long_term_goal_screen',
                                                        actions: ['removeAllBehaviorFromSelectedGoal', 'decrementCurrentPretrainingStep']
                                                    }
                                                ]
                                            }
                                        },
                                        behavior_to_long_term_goal_selection_saving: {
                                            entry: ['resetLoadingService', 'displayAvatarSelectionScreen'],
                                            invoke: {
                                                id: 'saveJourneyBehaviorToGoalSelection',
                                                src: 'saveJourneyBehaviorToGoalSelection',
                                                onDone: {
                                                    target: 'avatar_selection_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        avatar_selection_screen: {
                                            entry: ['loadingCompleted', 'displayAvatarSelectionScreen'],
                                            on: {
                                                SAVE_AVATAR_SELECTION: {
                                                    target: 'avatar_selection_saving',
                                                    actions: ['incrementCurrentPretrainingStep']
                                                },
                                                BACK: {
                                                    target: 'journey_behaviors_screen',
                                                    actions: ['decrementCurrentPretrainingStep']
                                                }
                                            }
                                        },
                                        avatar_selection_saving: {
                                            entry: ['assignUserAvatar', 'resetLoadingService', 'displayCalendarBlockerScreen'],
                                            invoke: {
                                                id: 'saveAvatarSelection',
                                                src: 'saveAvatarSelection',
                                                onDone: {
                                                    target: 'calendar_blocker_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        calendar_blocker_screen: {
                                            entry: ['loadingCompleted', 'displayCalendarBlockerScreen'],
                                            on: {
                                                SAVE_CHANGE_SCHEDULE: {
                                                    target: 'calendar_blocker_saving'
                                                },
                                                BACK: {
                                                    target: 'avatar_selection_screen',
                                                    actions: ['decrementCurrentPretrainingStep']
                                                }
                                            }
                                        },
                                        calendar_blocker_saving: {
                                            entry: ['displayProfileSetupScreen', 'resetLoadingService'],
                                            invoke: {
                                                id: 'saveCalendarBlocker',
                                                src: 'saveCalendarBlocker',
                                                onDone: {
                                                    target: 'user_details_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        user_details_loading: {
                                            invoke: {
                                                id: 'requestUserDetails',
                                                src: 'requestUserDetails',
                                                onDone: {
                                                    target: 'pretraining_questions_loading',
                                                    actions: ['updateUserDetails']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        pretraining_questions_loading: {
                                            invoke: {
                                                id: 'requestPretrainingQuestions',
                                                src: 'requestPretrainingQuestions',

                                                onDone: {
                                                    target: 'profile_setup_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        profile_setup_screen: {
                                            entry: ['loadingCompleted', 'displayProfileSetupScreen'],
                                            on: {
                                                CONTINUE: 'pretraining_questions_intro_screen'
                                            }
                                        },
                                        pretraining_questions_intro_screen: {
                                            entry: ['displayPretrainingQuestionsIntroScreen'],
                                            on: {
                                                SHOW_PRETRAINING_QUESTION: {
                                                    target: 'pretraining_question_screen',
                                                    actions: ['resetCurrentPretrainingQuestion']
                                                },
                                                BACK: {
                                                    target: 'profile_setup_screen'
                                                }
                                            }
                                        },
                                        pretraining_question_screen: {
                                            entry: ['displayPretrainingQuestionScreen'],
                                            on: {
                                                SHOW_NEXT_QUESTION: [
                                                    {
                                                        target: 'pretraining_question_screen',
                                                        actions: ['incrementCurrentPretrainingQuestion'],
                                                        cond: 'isNotLastPretrainingQuestion'
                                                    },
                                                    {
                                                        target: 'pretraining_question_answers_saving',
                                                        actions: ['incrementCurrentPretrainingQuestion']
                                                    }
                                                ],
                                                BACK: [
                                                    {
                                                        target: 'pretraining_question_screen',
                                                        actions: ['decrementCurrentPretrainingQuestion'],
                                                        cond: 'isNotFirstPretrainingQuestion'
                                                    },
                                                    {
                                                        target: 'pretraining_questions_intro_screen'
                                                    }
                                                ]
                                            }
                                        },
                                        /* call the displayResultCalculationScreen actions in the enrty of skill_type_loading so that the progressbar of the pretraining questions can reach 100% */
                                        pretraining_question_answers_saving: {
                                            entry: ['resetLoadingService'],
                                            invoke: {
                                                id: 'savePretrainingQuestionAnswers',
                                                src: 'savePretrainingQuestionAnswers'
                                            },
                                            on: {
                                                PRETRAINING_QUESTION_ANSWERS_SAVED: {
                                                    target: 'skill_type_loading'
                                                }
                                            }
                                        },
                                        skill_type_loading: {
                                            entry: [
                                                'displayResultCalculationScreen',
                                                'incrementCurrentPretrainingStep',
                                                'updateLoadingProgressPretrainingResultCalculation'
                                            ],
                                            invoke: {
                                                id: 'requestSkillType',
                                                src: 'requestSkillType',

                                                onDone: {
                                                    target: 'user_stats_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        user_stats_loading: {
                                            entry: ['updateLoadingProgressPretrainingResultCalculation'],
                                            invoke: {
                                                id: 'requestUserStats',
                                                src: 'requestUserStats',

                                                onDone: {
                                                    target: 'current_week_loading',
                                                    actions: []
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },

                                        current_week_loading: {
                                            entry: ['updateLoadingProgressPretrainingResultCalculation'],
                                            invoke: {
                                                id: 'requestCurrentWeek',
                                                src: 'requestCurrentWeek',
                                                onDone: {
                                                    target: 'result_calculation_loading_completed'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        result_calculation_loading_completed: {
                                            entry: ['updateLoadingProgressPretrainingResultCalculation'],
                                            on: {
                                                RESULT_CALCULATION_FINISHED: [
                                                    {
                                                        target: 'skill_type_explanation_screen',
                                                        cond: 'hasSkillType',
                                                        actions: ['setSkillTypeStartupLocationPretraining']
                                                    },
                                                    {
                                                        target: 'profile_introduction_screen'
                                                    }
                                                ]
                                            },
                                            exit: ['resetLoadingService']
                                        },
                                        skill_type_explanation_screen: {
                                            id: 'skill_type_explanation_screen',
                                            entry: ['displaySkillTypeExplanationScreen'],
                                            on: {
                                                CONTINUE: {
                                                    target: 'profile_introduction_screen',
                                                    actions: ['incrementCurrentPretrainingStep']
                                                },
                                                BACK: [
                                                        {
                                                            target: '#multi_package_hub.package_history_screen',
                                                            cond: 'isSkillTypeStartupLocationPackageHistory'
                                                        },
                                                        {
                                                            target: '#profile.profile_substate_history',
                                                            cond: 'isSkillTypeStartupLocationProfile',
                                                        }
                                                ]
                                            }
                                        },
                                        profile_introduction_screen: {
                                            entry: ['displayProfileIntroductionScreen'],
                                            on: {
                                                CONTINUE: {
                                                    target: 'journey_behavior_selection_loading',
                                                    actions: ['incrementCurrentPretrainingStep']
                                                },
                                                BACK: {
                                                    target: 'skill_type_explanation_screen',
                                                    actions: ['decrementCurrentPretrainingStep', 'setSkillTypeStartupLocationReturningDuringOnboarding'],
                                                    cond: 'hasSkillType'
                                                },
                                                PROFILE_EXPLANATION_VIEWED: {
                                                    actions: ['profileExplanationViewed']
                                                }
                                            }
                                        },
                                        journey_behavior_selection_loading: {
                                            entry: ['resetLoadingService', 'displayJourneyBehaviorSelectionScreen'],
                                            invoke: {
                                                id: 'loadTrainingPlan',
                                                src: 'loadTrainingPlan',
                                                onDone: {
                                                    target: 'journey_behavior_selection_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        journey_behavior_selection_screen: {
                                            entry: ['loadingCompleted', 'displayJourneyBehaviorSelectionScreen'],
                                            on: {
                                                CONTINUE: {
                                                    target: 'gamification_touchpoint_screen'
                                                },
                                                SHOW_JOURNEY_BEHAVIOR_SELECTION_MODIFICATION: {
                                                    target: 'journey_behavior_selection_modification_screen'
                                                },
                                                BACK: {
                                                    target: 'profile_introduction_screen',
                                                    actions: ['decrementCurrentPretrainingStep']
                                                }
                                            },
                                            exit: ['resetLoadingService']
                                        },
                                        journey_behavior_selection_modification_screen: {
                                            entry: ['displayJourneyBehaviorSelectionModificationScreen', 'copyOldTrainingPlan'],
                                            on: {
                                                SAVE_JOURNEY_BEHAVIOR_SELECTION_MODIFICATION: {
                                                    target: 'journey_behavior_selection_saving'
                                                },
                                                BACK: {
                                                    target: 'journey_behavior_selection_screen',
                                                    actions: ['restoreOldTrainingPlan']
                                                }
                                            }
                                        },
                                        journey_behavior_selection_saving: {
                                            entry: ['resetLoadingService', 'displayJourneyBehaviorSelectionScreen'],
                                            invoke: {
                                                id: 'saveJourneyBehaviorSelectionModification',
                                                src: 'saveJourneyBehaviorSelectionModification',
                                                onDone: {
                                                    target: 'journey_behavior_selection_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        gamification_touchpoint_screen: {
                                            entry: ['displayGamificationTouchpointScreen', 'loadingCompleted'],
                                            on: {
                                                RMI_REQUIREMENTS_MET_FAKE: {
                                                    actions: ['sendFakeRMIRequirementsMet']
                                                },
                                                CONTINUE: 'pretraining_completed_saving'
                                            }
                                        },
                                        pretraining_completed_saving: {
                                            invoke: {
                                                id: 'savePretrainingCompleted',
                                                src: 'savePretrainingCompleted',

                                                onDone: [
                                                    {
                                                        target: 'pretraining_completed_after_start_date_saving',
                                                        cond: 'onsiteTrainingOverOrDemoUser'
                                                    },
                                                    {
                                                        target: 'pretraining_completed'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        pretraining_completed_after_start_date_saving: {
                                            invoke: {
                                                id: 'savePretrainingCompletedAfterStartDate',
                                                src: 'savePretrainingCompletedAfterStartDate',
                                                onDone: {
                                                    target: 'pretraining_completed'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        pretraining_completed: {
                                            type: 'final'
                                        }
                                    },
                                    onDone: {
                                        target: 'pretraining_status_reloading'
                                    }
                                },
                                pretraining_status_reloading: {
                                    invoke: {
                                        id: 'requestPretrainingStatus',
                                        src: 'requestPretrainingStatus',
                                        onDone: {
                                            target: 'waiting_for_package_intro_completed_decision',
                                            actions: ['assignPretrainingStatus']
                                        },
                                        onError: {
                                            target: '#request_error',
                                            actions: ['assignErrors']
                                        }
                                    }
                                },
                                waiting_for_package_intro_completed_decision: {
                                    always: [
                                        {
                                            target: 'home_entry',
                                            cond: 'packageIntroCompleted',
                                            actions: ['resetFullScreenLoadingSpinnerActive']
                                        },
                                        {
                                            target: 'waiting_for_onsite_training_over_decision'
                                        }
                                    ]
                                },
                                pretraining_completed_screen: {
                                    id: 'pretraining_completed_screen',
                                    entry: ['displayPretrainingCompletedScreen', 'displayPushNotificationPopover'],

                                    /*always: [
                                        {
                                            target: 'home_entry',
                                            cond: 'packageIntroCompleted'
                                        }
                                    ],*/

                                    /*always: [
                                        {
                                            target: 'onsite_training_over',
                                            cond: 'isDemoUser'
                                        }
                                    ],*/
                                    /*always: [
                                        {
                                            target: 'onsite_training_over',
                                            actions: ['setOnsiteTrainingCheckFlag'],
                                            cond: 'onsiteTrainingOver'
                                        },
                                        {
                                            target: 'pretraining_status_loading',
                                            actions: ['setOnsiteTrainingCheckFlag'],
                                            cond: 'onsiteTrainingUpcomingAndFirstCheck'
                                        }
                                    ],*/
                                    after: {
                                        60000: [
                                            {
                                                target: 'pretraining_status_reloading'
                                                /*actions: ['resetOnsiteTrainingCheckFlag'],*/
                                            }
                                        ]
                                    },
                                    on: {
                                        GO_TO_HOME_TAB: 'reloading_pretraining_status_before_home',
                                        SAVE_QUESTION_FEEDBACK: 'question_feedback_saving',
                                        SHOW_PACKAGE_START: 'package_intro_screen'
                                    }
                                },
                                question_feedback_saving: {
                                    invoke: {
                                        id: 'saveQuestionFeedback',
                                        src: 'saveQuestionFeedback',

                                        onDone: {
                                            target: 'pretraining_completed_screen',
                                            actions: []
                                        },
                                        onError: {
                                            target: '#request_error',
                                            actions: ['assignErrors']
                                        }
                                    }
                                },
                                push_notification_popover: {
                                    entry: ['displayPushNotificationPopover'],
                                    on: {
                                        SHOW_PRETRAINING_COMPLETED: 'pretraining_completed_screen'
                                    }
                                },
                                waiting_for_onsite_training_over_decision: {
                                    always: [
                                        {
                                            target: 'package_intro_screen',
                                            /*actions: ['resetOnsiteTrainingCheckFlag'],*/
                                            cond: 'onsiteTrainingOverOrDemoUser',
                                            actions: ['resetFullScreenLoadingSpinnerActive']
                                        },
                                        {
                                            target: 'pretraining_completed_screen',
                                            /*actions: ['resetOnsiteTrainingCheckFlag']*/
                                            actions: ['resetFullScreenLoadingSpinnerActive']
                                        }
                                    ]
                                },
                                package_intro_screen: {
                                    entry: ['displayPackageIntroScreen'],
                                    on: {
                                        COMPLETE_PACKAGE_INTRO: 'package_intro_completed_saving'
                                    }
                                },
                                package_intro_completed_saving: {
                                    invoke: {
                                        id: 'savePackageIntroCompleted',
                                        src: 'savePackageIntroCompleted',

                                        onDone: {
                                            target: 'reloading_pretraining_status_before_home',
                                            actions: []
                                        },
                                        onError: {
                                            target: '#request_error',
                                            actions: ['assignErrors']
                                        }
                                    }
                                },
                                reloading_pretraining_status_before_home: {
                                        invoke: {
                                        id: 'requestPretrainingStatus',
                                        src: 'requestPretrainingStatus',
                                        onDone: {
                                            target: 'home_entry',
                                            actions: ['assignPretrainingStatus']
                                        },
                                        onError: {
                                            target: '#request_error',
                                            actions: ['assignErrors']
                                        }
                                    }
                                },
                                single_training_entry: {
                                    id: 'single_training_entry',
                                    initial: 'training_completed_not_last_week_decision',
                                    /* 'displayTrainingOverviewScreen' is not added in entry here, because we are quickly checking, if we can go to the training completed screen. 
                                    'displayTrainingOverviewScreen' is added for the entry part of pretraining_status_loading */
                                    entry: ['resetLoadingService'],
                                    states: {
                                        training_completed_not_last_week_decision: {
                                            always: [
                                                {
                                                    target: '#single_training.single_training_completed_screen',
                                                    cond: 'isCurrentWeekSingleTrainingCompletedAndNotLastWeek'
                                                },
                                                {
                                                    target: 'pretraining_status_loading'
                                                }
                                            ]
                                        },
                                        pretraining_status_loading: {
                                            entry: ['displayTrainingOverviewScreen'],
                                            invoke: {
                                                id: 'requestPretrainingStatus',
                                                src: 'requestPretrainingStatus',
                                                onDone: {
                                                    target: 'training_metadata_loading',
                                                    actions: ['assignPretrainingStatus']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        training_metadata_loading: {
                                            invoke: {
                                                id: 'requestCurrentWeek',
                                                src: 'requestCurrentWeek',
                                                onDone: {
                                                    target: 'previous_week_status_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        previous_week_status_loading: {
                                            invoke: {
                                                id: 'requestPreviousWeekStatus',
                                                src: 'requestPreviousWeekStatus',
                                                onDone: {
                                                    target: 'current_week_status_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        current_week_status_loading: {
                                            invoke: {
                                                id: 'requestCurrentWeekStatus',
                                                src: 'requestCurrentWeekStatus',
                                                onDone: [
                                                    {
                                                        target: 'current_skill_loading'
                                                        //cond: 'isPackageCompleted'
                                                    } /*,
                                                    {
                                                        target: '#single_training.single_training_completed_screen',
                                                        cond: 'isCurrentWeekSingleTrainingCompleted'
                                                    },
                                                    {
                                                        target: '#single_training.single_training_substate_history',
                                                    }*/
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        current_skill_loading: {
                                            exit: ['loadingCompleted'],
                                            invoke: {
                                                id: 'requestSkillSelection',
                                                src: 'requestSkillSelection',
                                                onDone: [
                                                    {
                                                        //If package is completed we go to normal "training overview screen" which is initial state of "single training".
                                                        //This is because training overview screen automatically adapts to show an adapted end of journey design after package is completed.
                                                        target: '#single_training',
                                                        cond: 'isPackageCompleted'
                                                    },
                                                    {
                                                        target: '#single_training.single_training_completed_screen',
                                                        cond: 'isCurrentWeekSingleTrainingCompletedAndNotLastWeek'
                                                    },

                                                    {
                                                        target: '#single_training',
                                                        cond: 'weekJustIncreased',
                                                        actions: ['resetWeekJustIncreased']
                                                    },
                                                    {
                                                        // We reinitialize the single training if the
                                                        // training completed screen is loaded based on
                                                        // the history state cause the last training was
                                                        // a history training and therefore ended on this
                                                        // screen.
                                                        target: '#single_training',
                                                        actions: ['resetLastTrainingWasHistoryOrLibrary'],
                                                        cond: 'lastTrainingWasHistoryOrLibraryAndCurrentTrainingIsNotHistoryOrLibrary'
                                                    },
                                                    {
                                                        target: '#single_training.single_training_substate_history'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        }
                                    }
                                },
                                single_training: {
                                    entry: ['resetFullScreenLoadingSpinnerActive'],
                                    id: 'single_training',
                                    //Shortcut for development, should be 'single_training_start'
                                    initial: 'training_overview_screen',
                                    on: {
                                        GO_TO_SINGLE_TRAINING_TAB: 'single_training_entry',
                                        GO_TO_GROUP_TRAINING_TAB: 'group_training_entry',
                                        GO_TO_HOME_TAB: 'home_entry',
                                        GO_TO_PROFILE_TAB: 'profile_entry',
                                        GO_TO_JOURNEY_TAB: 'journey_entry'
                                    },
                                    states: {
                                        single_training_substate_history: {
                                            type: 'history',
                                            history: 'deep'
                                        },
                                        // single_training_start: {
                                        //     on: {
                                        //         LOAD_TRAINING_METADATA: 'training_metadata_loading'
                                        //     }
                                        // },
                                        training_overview_screen: {
                                            entry: ['displayTrainingOverviewScreen'],
                                            on: {
                                                START_SINGLE_TRAINING: [
                                                    {
                                                        target: 'single_training_content_loading',
                                                        cond: 'isMicrotrainingSetForCurrentWeek',
                                                        actions: ['setMicrotrainingWasAlreadySet', 'setNormalTrainingMode']
                                                    },
                                                    {
                                                        target: 'previous_week_goal_loading',
                                        
                                                    }
                                                ],
                                                GO_TO_LIBRARY_OVERVIEW: {
                                                    target: '#journey_entry',
                                                    actions: ['setGoToLibraryAfterLoadingFlag']
                                                },
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet']
                                                }
                                            }
                                        },
                                        goal_decision: {
                                            id: 'goal_decision',
                                            always: [
                                                {
                                                    target: 'goal_attainment_screen',
                                                    cond: 'wasPriorWeekGoalSet'
                                                },
                                                {
                                                    target: 'waiting_for_skill_or_microtraining_screen_decision',
                                                    cond: 'pretrainingCompletedAfterStartDateAndFirstWeek'
                                                },
                                                {
                                                    target: 'waiting_for_first_is_last_week_and_is_training_completed_decision'
                                                    //cond should not be needed as state machine should default to this in case above cond for target current_goal_loading does not evaluate to true.
                                                }
                                            ]
                                        },
                                        previous_week_goal_loading: {
                                            id: 'previous_week_goal_loading',
                                            invoke: {
                                                id: 'requestCurrentGoal',
                                                src: 'requestCurrentGoal',
                                                onDone: {
                                                    target: 'goal_decision'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        goal_attainment_screen: {
                                            id: 'goal_attainment_screen',
                                            entry: ['displayGoalAttainmentScreen'],
                                            on: {
                                                START_SINGLE_TRAINING: [   {
                                                    target: 'waiting_for_skill_or_microtraining_screen_decision',
                                                    cond: 'pretrainingCompletedAfterStartDateAndFirstWeek'
                                                },
                                                {
                                                    target: 'waiting_for_first_is_last_week_and_is_training_completed_decision'
                                                }],
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet']
                                                }
                                            }
                                        },
                                        waiting_for_first_is_last_week_and_is_training_completed_decision: {
                                            entry: ['displayMicrotrainingQuestionsIntroScreen', 'resetLoadingService'],
                                            id: 'waiting_for_first_is_last_week_and_is_training_completed_decision',
                                            always: [
                                                {
                                                    target: 'microtraining_questions_loading_for_last_week',
                                                    cond: 'isLastWeekAndIsTrainingCompleted'
                                                },
                                                {
                                                    target: 'microtraining_questions_loading'
                                                }
                                            ]
                                        },
                                        microtraining_questions_loading: {
                                            invoke: {
                                                id: 'requestMicrotrainingQuestions',
                                                src: 'requestMicrotrainingQuestions',
                                                onDone: {
                                                    target: 'microtraining_questions_intro_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        microtraining_questions_loading_for_last_week: {
                                            invoke: {
                                                id: 'requestMicrotrainingQuestionsForLastWeek',
                                                src: 'requestMicrotrainingQuestionsForLastWeek',
                                                onDone: {
                                                    target: 'microtraining_questions_intro_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        microtraining_questions_intro_screen: {
                                            entry: ['displayMicrotrainingQuestionsIntroScreen', 'loadingCompleted'],
                                            on: {
                                                SHOW_MICROTRAINING_QUESTION: {
                                                    target: 'microtraining_question_screen',
                                                    actions: ['resetCurrentMicrotrainingQuestion']
                                                },
                                                BACK: '#main_scope.single_training.previous_week_goal_loading'
                                            }
                                        },
                                        microtraining_question_screen: {
                                            entry: ['displayMicrotrainingQuestionScreen'],
                                            on: {
                                                SHOW_NEXT_QUESTION: [
                                                    {
                                                        target: 'microtraining_question_screen',
                                                        actions: ['incrementCurrentMicrotrainingQuestion'],
                                                        cond: 'isNotLastMicrotrainingQuestion'
                                                    },
                                                    {
                                                        target: 'microtraining_question_answers_saving',
                                                        actions: ['incrementCurrentMicrotrainingQuestion']
                                                    }
                                                ],
                                                BACK: [
                                                    {
                                                        target: 'microtraining_question_screen',
                                                        actions: ['decrementCurrentMicrotrainingQuestion'],
                                                        cond: 'isNotFirstMicrotrainingQuestion'
                                                    },
                                                    {
                                                        target: 'microtraining_questions_intro_screen'
                                                    }
                                                ]
                                            }
                                        },
                                        microtraining_question_answers_saving: {
                                            invoke: {
                                                id: 'saveMicrotrainingQuestionAnswers',
                                                src: 'saveMicrotrainingQuestionAnswers',
                                                onDone: {
                                                    target: 'waiting_for_second_is_last_week_and_is_training_completed_decision'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        // enter new decision here(isLastWeek)
                                        waiting_for_second_is_last_week_and_is_training_completed_decision: {
                                            id: 'waiting_for_second_is_last_week_and_is_training_completed_decision',
                                            always: [
                                                {
                                                    target: '#end_of_journey',
                                                    cond: 'isLastWeekAndIsTrainingCompleted'
                                                },
                                                {
                                                    target: 'waiting_for_skill_or_microtraining_screen_decision'
                                                }
                                            ]
                                        },
                                        waiting_for_skill_or_microtraining_screen_decision: {
                                            always: [
                                                {
                                                    // If a skill or microtraining has already been selected
                                                    // for the current week we proceed to load the user's
                                                    // latest stats and go to the `microtraining_suggestion_screen`
                                                    // so that the user can begin that week's training.
                                                    // MICROTRAINING NEEDS TO BE CHECKED FIRST, CAUSE IF THIS IS TRUE, SKILL IS ALSO SET
                                                    target: 'single_training_content_loading',
                                                    cond: 'isMicrotrainingSetForCurrentWeek',
                                                    actions: ['setMicrotrainingWasAlreadySet', 'setNormalTrainingMode']
                                                },
                                                //{
                                                // Not needed anymore, because skill cannot be set without selecting a microtraining
                                                // If a skill or microtraining has already been selected
                                                // for the current week we proceed to load the user's
                                                // latest stats and go to the `microtraining_suggestion_screen`
                                                // so that the user can begin that week's training.
                                                // TODO correct flow from here! if Microtraining is already set, don't jump to microtraining screen but even further to load content.
                                                // If only skill is set but not microtraining, then jump to skill choice
                                                // target: 'microtraining_suggestion_loading',
                                                // cond: 'isSkillSetForCurrentWeek'
                                                //},
                                                {
                                                    target: 'fixed_order_status_loading'
                                                }
                                            ]
                                        },
                                        fixed_order_status_loading: {
                                            entry: ['resetLoadingService'],
                                            invoke: {
                                                id: 'requestFixedOrderStatus',
                                                src: 'requestFixedOrderStatus',
                                                onDone: {
                                                    target: 'fixed_order_decision'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        fixed_order_decision: {
                                            always: [
                                                {
                                                    target: 'fixed_order_microtraining_loading',
                                                    cond: 'isFixedOrderTraining'
                                                },
                                                {
                                                    target: 'microtraining_suggestion_loading'
                                                }
                                            ]
                                        },
                                        fixed_order_microtraining_loading: {
                                            entry: ['displayMicrotrainingSuggestionScreen', 'resetLoadingService'],
                                            invoke: {
                                                id: 'requestFixedOrderMicrotraining',
                                                src: 'requestFixedOrderMicrotraining',
                                                onDone: {
                                                    target: 'microtraining_suggestion_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        microtraining_suggestion_loading: {
                                            entry: ['displayMicrotrainingSuggestionScreen', 'resetLoadingService', 'resetIsAppComingBackFromFullSkillSelection'],
                                            invoke: {
                                                id: 'requestMicrotrainingRecommendations',
                                                src: 'requestMicrotrainingRecommendations',
                                                onDone: {
                                                    target: 'microtraining_suggestion_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        microtraining_suggestion_screen: {
                                            entry: [
                                                'loadingCompleted',
                                                'displayMicrotrainingSuggestionScreen',
                                                'microtrainingStartedFromSuggestions'
                                            ],
                                            on: {
                                                SHOW_SKILL_CHOICE: 'skills_loading',
                                                SHOW_MICROTRAINING_INFO: 'microtraining_info_screen',
                                                SELECT_MICROTRAINING: {
                                                    target: 'microtraining_selection_saving'
                                                },
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet']
                                                }
                                            }
                                        },
                                        //TODO remove if not nedded anymore. Currently state is not used
                                        microtrainings_loading: {
                                            entry: ['displayMicrotrainingChoiceScreen'],
                                            invoke: {
                                                id: 'requestMicrotrainings',
                                                src: 'requestMicrotrainings',
                                                onDone: {
                                                    target: 'microtraining_choice_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        microtraining_choice_screen: {
                                            id: 'microtraining_choice_screen',
                                            entry: ['displayMicrotrainingChoiceScreen'],
                                            on: {
                                                BACK: 'skill_choice_screen',
                                                SHOW_MICROTRAINING_INFO: 'microtraining_info_screen'
                                            }
                                        },
                                        microtraining_info_screen: {
                                            entry: ['displayMicrotrainingInfoScreen'],
                                            on: {
                                                SELECT_MICROTRAINING: {
                                                    target: 'microtraining_selection_saving'
                                                },
                                                GO_TO_TRAINING_SUGGESTION_SCREEN: [
                                                    {
                                                        target: 'skills_loading'
                                                    }
                                                ],
                                                GO_TO_TRAINING_CHOICE_SCREEN: {
                                                    target: 'microtraining_choice_screen'
                                                },
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet']
                                                }
                                            }
                                        },
                                        microtraining_selection_saving: {
                                            entry: ['resetLoadingService', 'displayTrainingVideoScreen'],
                                            invoke: {
                                                id: 'saveMicrotrainingSelection',
                                                src: 'saveMicrotrainingSelection',
                                                onDone: {
                                                    target: 'single_training_content_loading',
                                                    actions: ['setNormalTrainingMode']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        skills_loading: {
                                            entry: ['resetLoadingService', 'displaySkillChoiceScreen'],
                                            invoke: {
                                                id: 'requestSkills',
                                                src: 'requestSkills',
                                                onDone: {
                                                    target: 'skill_choice_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        //TODO remove if not needed anymore. Skill loading now redirects to skill_choice_screen
                                        // if user did not select a suggested microtraining and wants to see all skills.
                                        skill_suggestion_screen: {
                                            entry: ['displaySkillSuggestionScreen'],
                                            always: {
                                                target: 'skill_choice_screen',
                                                cond: 'isFirstWeekOrNoLastWeekSkillChoice'
                                            },
                                            on: {
                                                SHOW_SKILL_CHOICE: 'skill_choice_screen',
                                                SELECT_SKILL: 'skill_selection_saving'
                                            }
                                        },
                                        skill_choice_screen: {
                                            entry: ['loadingCompleted', 'displaySkillChoiceScreen', 'microtrainingStartedFromFullList'],
                                            on: {
                                                SELECT_SKILL: {
                                                    target: 'microtraining_choice_screen',
                                                    actions: ['setSelectedSkill']
                                                },
                                                BACK: {
                                                    target: 'microtraining_suggestion_screen',
                                                    actions: ['setIsAppComingBackFromFullSkillSelection']
                                                }
                                            }
                                        },
                                        //TODO can be removed if skills cannot be selected anymore separately
                                        skill_selection_saving: {
                                            invoke: {
                                                id: 'saveSkillSelection',
                                                src: 'saveSkillSelection',
                                                onDone: [
                                                    {
                                                        target: 'microtraining_suggestion_loading'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        // TODO remove
                                        skill_details_screen: {
                                            on: {
                                                SHOW_SKILL_CHOICE: 'skill_choice_screen'
                                            }
                                        },
                                        single_training_content_loading: {
                                            entry: ['resetLoadingService'],
                                            invoke: {
                                                id: 'requestSingleTrainingContent',
                                                src: 'requestSingleTrainingContent',
                                                onDone: [
                                                    {
                                                        target: 'use_progression_state_decision'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        use_progression_state_decision: {
                                            always: [
                                                {
                                                    target: 'video_screen',
                                                    actions: ['dontUseProgressionStateTraining'],
                                                    cond: 'isNotTrainingStartupLocationNormal'
                                                },
                                                {
                                                    target: '#feedback_voting',
                                                    actions: ['useProgressionStateTraining'],
                                                    cond: 'isProgressStateGoalSelectionDone'
                                                },
                                                {
                                                    target: 'goal_selection_screen',
                                                    actions: ['useProgressionStateTraining'],
                                                    cond: 'isProgressStateExerciseCompletlyDone'
                                                },
                                                {
                                                    target: 'exercise_step_screen',
                                                    actions: ['useProgressionStateTraining'],
                                                    cond: 'isProgressStateExerciseStepDone'
                                                },
                                                {
                                                    target: 'exercise_step_screen',
                                                    actions: ['useProgressionStateTraining'],
                                                    cond: 'isProgressStateVideoDone'
                                                },
                                                {
                                                    target: 'video_screen',
                                                    actions: ['useProgressionStateTraining'],
                                                }
                                            ]
                                        },
                                        video_screen: {
                                            entry: ['loadingCompleted', 'displayTrainingVideoScreen', 'resetExerciseStartTimer'],
                                            on: {
                                                DELETE_PROGRESS_STATE: {
                                                    actions: ['deleteProgressState']
                                                },
                                                BACK: [
                                                    {
                                                        target: '#journey.history',
                                                        actions: [
                                                            'setNormalTrainingMode','setLastTrainingWasHistoryOrLibrary'
                                                        ],
                                                        cond: 'isTrainingStartupLocationHistory'
                                                    },
                                                    {
                                                        target: '#journey.notes_screen',
                                                        actions: [
                                                            'setNormalTrainingMode','setLastTrainingWasHistoryOrLibrary'
                                                        ],
                                                        cond: 'isTrainingStartupLocationNoteHistory'
                                                    },
                                                    {
                                                        target: '#journey.library_screen',
                                                        actions: [
                                                            'setNormalTrainingMode','setLastTrainingWasHistoryOrLibrary'
                                                        ],
                                                        cond: 'isTrainingStartupLocationLibrary'
                                                    },
                                                    {
                                                        target: '#profile_entry',
                                                        actions: [
                                                            'setNormalTrainingMode','setLastTrainingWasHistoryOrLibrary'
                                                        ],
                                                        cond: 'isTrainingStartupLocationProfile'
                                                    },
                                                    {
                                                        target: 'microtraining_choice_screen',
                                                        cond: 'wasMicrotrainingStartedFromFullList',
                                                        actions: ['resetMicrotraining', 'resetImpulsViewed']
                                                    },
                                                    // if microtraining was already set, then the suggestions have not been loaded before
                                                    // {
                                                    //     target: 'microtraining_suggestion_loading',
                                                    //     cond: 'isMicrotrainingSetForCurrentWeek',
                                                    //     actions: ['resetMicrotraining']
                                                    // },
                                                    {
                                                        target: 'microtraining_suggestion_screen',
                                                        actions: ['resetMicrotraining', 'resetImpulsViewed', 'setIsAppComingBackFromFullSkillSelection']
                                                    }
                                                ],
                                                SHOW_EXERCISE_EXPLANATION: [
                                                    {
                                                        target: 'exercise_explanation_screen',
                                                        cond: 'isExerciseExplanationNeeded'
                                                        //condition if user wants to see exercise explanation
                                                    },
                                                    {
                                                        target: 'exercise_step_screen',
                                                        actions: ['resetCurrentExerciseStep']
                                                    }
                                                ],
                                                IMPULS_VIEWED: {
                                                    actions: ['setImpulsViewed']
                                                }
                                            }
                                        },
                                        exercise_explanation_screen: {
                                            entry: ['displayTrainingExerciseExplanationScreen', 'resetExerciseStartTimer'],
                                            on: {
                                                SHOW_EXERCISE_STEP_FROM_EXPLANATION: [
                                                    {
                                                        target: 'exercise_explanation_saving'
                                                    }
                                                ],
                                                SHOW_VIDEO_SCREEN: [
                                                    {
                                                        target: 'video_screen'
                                                    }
                                                ]
                                            }
                                        },
                                        exercise_explanation_saving: {
                                            entry: ['resetLoadingService', 'displayCurrentExerciseStepScreen'],
                                            invoke: {
                                                id: 'saveExerciseExplanation',
                                                src: 'saveExerciseExplanation',

                                                onDone: {
                                                    target: 'exercise_step_screen',
                                                    actions: ['resetCurrentExerciseStep']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        exercise_step_screen: {
                                            entry: ['loadingCompleted', 'displayCurrentExerciseStepScreen', 'saveExerciseVideoViewed'],
                                            on: {
                                                SAVE_EXERCISE_STEP_COMPLETED:{
                                                    actions:['saveExerciseStepCompleted']
                                                },
                                                SHOW_NEXT_STEP: [
                                                    {
                                                        target: 'exercise_step_screen',
                                                        actions: ['incrementCurrentExerciseStep'],
                                                        cond: 'isNotLastExerciseStep'
                                                    },
                                                    {
                                                        target: 'exercise_completed_screen'
                                                    }
                                                    /*{
                                                        target: '#feedback_voting'
                                                    }*/
                                                ],
                                                SHOW_PREVIOUS_EXERCISE_STEP: [
                                                    {
                                                        target: 'exercise_step_screen',
                                                        actions: ['decrementCurrentExerciseStep'],
                                                        cond: 'isNotFirstExerciseStep'
                                                    },
                                                    {
                                                        target: 'exercise_explanation_screen',
                                                        cond: 'isExerciseExplanationNeeded'
                                                    },
                                                    {
                                                        target: 'video_screen'
                                                    }
                                                ],
                                                //SHOW_FEEDBACK_VOTING_SCREEN: '#feedback_voting'
                                                //.feedback_voting_screen'
                                                //COMPLETE_EXERCISE: 'exercise_completed_screen'
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet'],
                                                    cond: 'isUseProgressionStateExercise'
                                                },
                                                LOAD_NOTES: 'exercise_note_loading'
                                            }
                                        },
                                        exercise_note_loading: {
                                            entry: ['resetLoadingService', 'displayTextualNoteModal', 'setRMIsPossibleForNoteIfTrainingModeIsNormal'],
                                            invoke: {
                                                id: 'requestNotes',
                                                src: 'requestNotes',
                                                onDone: {
                                                    target: 'exercise_note_modal_decision'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        exercise_note_modal_decision: {
                                            always: [
                                                {
                                                    target: '#popup_scope.textual_note',
                                                    actions: ['setTextualNoteModalShown', 'loadingCompleted', 'resetShouldNoteBeSaved', 'setTextualNoteModalStartupLocationDefault'],
                                                    cond: 'textualNoteModalNotShown'
                                                },
                                                {
                                                    target: 'exercise_note_saving',
                                                    actions: ['resetTextualNoteModalShown', 'resetLoadingService'],
                                                    cond: 'shouldNoteBeSaved'
                                                }, 
                                                {
                                                    target:'exercise_step_screen',
                                                    actions: ['resetTextualNoteModalShown', 'closeTextualNoteModal', 'resetRMIsPossibleForNote']
                                                }
                                            ]
                                        },
                                        exercise_note_saving: {
                                            invoke: {
                                                id: 'saveTextualNote',
                                                src: 'saveTextualNote',
                                                onDone: {
                                                    target: 'exercise_step_screen',
                                                    actions: ['loadingCompleted', 'closeTextualNoteModal', 'resetRMIsPossibleForNote']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        exercise_completed_screen: {
                                            entry: [
                                                'displayExerciseCompletedScreen',
                                                'resetMicrotrainingWasAlreadySet',
                                                'resetImpulsViewed',
                                                'resetExerciseStartTimer'
                                            ],
                                            /*always: {
                                                
                                                    // We reinitialize the single training if the 
                                                    // training completed screen is loaded based on
                                                    // the history state cause the last training was
                                                    // a history training and therefore ended on this
                                                    // screen.
                                                    target: '#single_training',
                                                    actions: ['resetLastTrainingWasHistoryOrLibrary'],
                                                    cond: 'lastTrainingWasHistoryOrLibraryAndCurrentTrainingIsNotHistoryOrLibrary'
                                                
                                            },*/
                                            on: {
                                                GO_TO_HISTORY_OVERVIEW: {
                                                    target: '#journey.history',
                                                    actions: [
                                                        'setNormalTrainingMode', 'setLastTrainingWasHistoryOrLibrary'
                                                    ]
                                                },
                                                GO_TO_LIBRARY_OVERVIEW: {
                                                    target: '#journey.library_screen',
                                                    actions: [
                                                        'setNormalTrainingMode', 'setLastTrainingWasHistoryOrLibrary', 'setGoToLibraryAfterLoadingFlag'
                                                    ]
                                                },
                                                GO_TO_PROFILE_TAB: {
                                                    target: '#profile_entry',
                                                    actions: [
                                                        'setNormalTrainingMode', 'setLastTrainingWasHistoryOrLibrary'
                                                    ]
                                                },
                                                SHOW_GOAL_SELECTION: {
                                                    target: 'goal_selection_screen'
                                                },
                                                GO_TO_NOTES: {
                                                    target: '#journey.notes_screen',
                                                    actions: [
                                                        'setNormalTrainingMode', 'setLastTrainingWasHistoryOrLibrary' 
                                                    ]
                                                }
                                            }
                                        },
                                        goal_selection_screen: {
                                            entry: ['displayGoalSelectionScreen'],
                                            on: {
                                                SAVE_GOAL_SELECTION: 'goal_selection_saving'
                                            }
                                        },
                                        // TODO remove if not needed anymore
                                        individual_goal_screen: {
                                            on: {
                                                SAVE_GOAL_SELECTION: 'goal_selection_saving'
                                                //COMPLETE_SINGLE_TRAINING: 'single_training_finishing'
                                            }
                                        },
                                        goal_selection_saving: {
                                            entry: ['resetLoadingService', 'displayFeedbackVotingScreen', 'saveExerciseGoalsSelected'],
                                            invoke: {
                                                id: 'saveGoalSelection',
                                                src: 'saveGoalSelection',

                                                onDone: {
                                                    target: '#feedback_voting'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                            /*on: {
                                                GOAL_SELECTION_SAVED: 'single_training_completion_saving'
                                            }*/
                                        },
                                        single_training_completion_saving: {
                                            entry: ['resetLoadingService', 'displaySingleTrainingCongratulationScreen'],
                                            invoke: {
                                                id: 'saveSingleTrainingCompleted',
                                                src: 'saveSingleTrainingCompleted',
                                                onDone: {
                                                    target: 'current_week_status_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        current_week_status_loading: {
                                            invoke: {
                                                id: 'requestCurrentWeekStatus',
                                                src: 'requestCurrentWeekStatus',
                                                onDone: [
                                                    {
                                                        target: 'single_training_congratulation_screen',
                                                        actions: ['setWeeklyQuizTimeReference']
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        single_training_congratulation_screen: {
                                            entry: ['loadingCompleted', 'displaySingleTrainingCongratulationScreen', 'setUserCompletedTaskTypeMicrotraining', 'setUserCompletedTask'],
                                            on: {
                                                SHOW_SINGLE_TRAINING_COMPLETED_SCREEN: {
                                                    target: '#popup_scope.player_progression',
                                                    actions: ['setPlayerProgressionStartupMicrotraining', 'setPlayerProgressDisplayedPoints']
                                                }
                                            }
                                        },
                                        single_training_completed_screen: {
                                            entry: ['loadingCompleted', 'displaySingleTrainingCompletedScreen'],
                                            always: {
                                                // We reinitialize the single training if the user is
                                                // on the training completed screen but the current week
                                                // has been reloaded and is no longer completed.
                                                target: '#single_training',
                                                cond: 'isNotCurrentWeekSingleTrainingCompleted'
                                            },
                                            on: {
                                                GO_TO_HOME_TAB: '#home_entry'
                                            }
                                        },
                                        package_completed_screen: {
                                            entry: ['displayPackageCompletedScreen'],
                                            on: {
                                                GO_TO_HOME_TAB: '#home_entry'
                                            }
                                        }
                                    }
                                },
                                group_training_entry: {
                                    initial: 'group_training_metadata_loading',
                                    states: {
                                        group_training_metadata_loading: {
                                            invoke: {
                                                id: 'requestCurrentWeek',
                                                src: 'requestCurrentWeek',
                                                onDone: {
                                                    target: 'previous_week_status_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        previous_week_status_loading: {
                                            invoke: {
                                                id: 'requestPreviousWeekStatus',
                                                src: 'requestPreviousWeekStatus',
                                                onDone: {
                                                    target: 'current_week_status_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        current_week_status_loading: {
                                            invoke: {
                                                id: 'requestCurrentWeekStatus',
                                                src: 'requestCurrentWeekStatus',
                                                onDone: [
                                                    {
                                                        target: 'group_matching_loading'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        group_matching_loading: {
                                            invoke: {
                                                id: 'requestMatchedGroup',
                                                src: 'requestMatchedGroup',
                                                onDone: [
                                                    {
                                                        target: '#group_training.group_training_completed_screen',
                                                        cond: 'isCurrentWeekGroupTrainingCompleted'
                                                    },
                                                    {
                                                        target: '#group_training.group_training_substate_history',
                                                        cond: 'isMatchingAvailable'
                                                    },
                                                    {
                                                        target: '#group_training.group_wait_screen'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        }
                                    }
                                },
                                group_training: {
                                    id: 'group_training',
                                    initial: 'group_training_intro_screen',
                                    on: {
                                        GO_TO_SINGLE_TRAINING_TAB: 'single_training_entry',
                                        GO_TO_GROUP_TRAINING_TAB: 'group_training_entry',
                                        GO_TO_HOME_TAB: 'home_entry',
                                        GO_TO_PROFILE_TAB: 'profile_entry'
                                    },
                                    states: {
                                        group_training_substate_history: {
                                            type: 'history',
                                            history: 'deep'
                                        },

                                        group_wait_screen: {
                                            entry: ['displayGroupTrainingWaitScreen']
                                        },

                                        group_training_intro_screen: {
                                            entry: ['displayGroupTrainingIntroScreen'],
                                            on: {
                                                LOAD_GROUP_TRAINING_CONTENT: 'group_training_content_loading'
                                            }
                                        },

                                        group_training_content_loading: {
                                            invoke: {
                                                id: 'requestGroupTrainingContent',
                                                src: 'requestGroupTrainingContent',
                                                onDone: {
                                                    target: 'group_exercise_step_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        group_exercise_step_screen: {
                                            entry: ['displayGroupExerciseStepScreen'],
                                            on: {
                                                SHOW_NEXT_GROUP_EXERCISE_STEP: [
                                                    {
                                                        target: 'group_exercise_step_screen',
                                                        actions: ['incrementCurrentGroupExerciseStep'],
                                                        cond: 'isNotLastGroupExerciseStep'
                                                    },
                                                    {
                                                        target: 'group_training_completion_saving'
                                                    }
                                                ],
                                                COMPLETE_GROUP_EXERCISE: 'group_training_completion_saving',
                                                LOAD_FEEDBACK_NOTES: 'feedback_notes_loading'
                                            }
                                        },
                                        feedback_notes_loading: {
                                            on: {
                                                FEEDBACK_NOTES_LOADED: 'feedback_notes_screen'
                                            }
                                        },
                                        feedback_notes_screen: {
                                            entry: ['displayFeedbackNotesScreen'],
                                            on: {
                                                SHOW_GROUP_EXERCISE_STEP: 'group_exercise_step_screen'
                                            }
                                        },
                                        group_training_completion_saving: {
                                            invoke: {
                                                id: 'saveGroupTrainingCompleted',
                                                src: 'saveGroupTrainingCompleted',
                                                onDone: {
                                                    target: 'group_training_completed_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        group_training_completed_screen: {
                                            entry: ['displayGroupTrainingCompletedScreen'],
                                            always: {
                                                // We reinitialize the group training if the user is
                                                // on the training completed screen but the current week
                                                // has been reloaded and is no longer completed.
                                                target: '#group_training',
                                                cond: 'isNotCurrentWeekGroupTrainingCompleted'
                                            },
                                            on: {
                                                GO_TO_HOME_TAB: '#home_entry'
                                            }
                                        }
                                    }
                                },
                                journey_entry: {
                                    entry: ['resetLoadingService', 'displayJourneyScreen', 'startConsoleLogTimer' ],
                                    id: 'journey_entry',
                                    type: 'parallel',
                                    states: {
                                        history_pending: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestHistory',
                                                        src: 'requestHistory',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        notes_pending: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestNoteHistory',
                                                        src: 'requestNoteHistory',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        achievement_progress_overview: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestAchievementsOverview',
                                                        src: 'requestAchievementsOverview',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        achievement_progress_list_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestAchievementProgressList',
                                                        src: 'requestAchievementProgressList',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                        actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        skills_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestSkillsPackage',
                                                        src: 'requestSkillsPackage',
                                                        onDone: {
                                                            target: 'microtraining_library_loading'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                microtraining_library_loading: {
                                                    invoke: {
                                                        id: 'requestMicrotrainingLibrary',
                                                        src: 'requestMicrotrainingLibrary',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        space_map_detail_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestSpaceMapDetails',
                                                        src: 'requestSpaceMapDetails',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        leaderboard_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestLeaderboardStats',
                                                        src: 'requestLeaderboardStats',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        player_progress_info_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestPlayerProgressInfo',
                                                        src: 'requestPlayerProgressInfo',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                    },
                                    onDone: [
                                        {
                                            target:'#tour_guide.journey_spacemap_tooltip',
                                            actions:['loadingCompleted'],
                                            cond:'isSpaceMapTooltipActive'
                                        },
                                        {
                                            target:'#tour_guide.journey_achievement_tooltip',
                                            actions:['loadingCompleted'],
                                            cond:'isTourGuideActive'
                                        },
                                        {
                                            target: '#journey.library_screen',
                                            actions: ['resetGoToLibraryAfterLoadingFlag'],
                                            cond: 'isGoToLibraryAfterLoadingFlag'
                                        },
                                        {
                                            target: '#journey'
                                        }
                                    ],
                                    exit: ['stopConsoleLogTimer'],
                                },
                                journey: {
                                    id: 'journey',
                                    entry: ['resetFullScreenLoadingSpinnerActive'],
                                    initial: 'journey_screen',
                                    on: {
                                        GO_TO_HOME_TAB: '#home_entry',
                                        GO_TO_PROFILE_TAB: '#profile_entry'
                                    },
                                    states: {
                                        journey_substate_history: {
                                            type: 'history',
                                            history: 'deep',
                                        },
                                        journey_screen: {
                                            entry: ['displayJourneyScreen', 'loadingCompleted', 'resetFullScreenLoadingSpinnerActive'],
                                            on: {
                                                GO_TO_HOME_TAB: '#home_entry',
                                                GO_TO_PROFILE_TAB: '#profile_entry',
                                                GO_TO_ACHIEVEMENTS: {
                                                    target:'#achievements.reload_achievements',
                                                    actions: ['setAchievementStartupLocationJourney']
                                                },
                                                GO_TO_SPACE_MAP: '#popup_scope.space_map',
                                                GO_TO_NOTES: {
                                                    target: 'notes_screen'
                                                },
                                                GO_TO_COMPLETE_HISTORY: {
                                                    target: 'history',
                                                    actions: ['setHistoryStartupLocationJourney']
                                                },
                                                GO_TO_COMPLETE_LIBRARY: {
                                                    target:'#journey.library_screen',
                                                    actions: ['setLibraryStartupLocationJourney']
                                                },
                                                GO_TO_DUELS: {
                                                    target: 'duels',
                                                    actions: ['setDuelsStartupLocationJourney']
                                                },
                                                GO_TO_LEADERBOARD: 'leaderboard_screen'
                                            }
                                        },
                                        achievements: {
                                            id: 'achievements',
                                            initial: 'reload_achievements',
                                            entry: ['resetFullScreenLoadingSpinnerActive'],
                                            states: {
                                                reload_achievements: {
                                                    invoke: {
                                                        id: 'requestAchievementsOverview',
                                                        src: 'requestAchievementsOverview',
                                                        onDone: {
                                                            target: 'achievements_screen'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                achievements_screen: {
                                                    entry: ['displayAchievementsScreen', 'resetFullScreenLoadingSpinnerActive'],
                                                    on: {
                                                        BACK: [
                                                            {
                                                                target:'#journey.journey_screen',
                                                                cond: 'isAchievementStartupLocationJourney'
                                                            },
                                                            {
                                                                target: '#multi_package_hub.package_history_screen',
                                                                cond: 'isAchievementStartupLocationMultiPackageHub'
                                                            },
                                                            {
                                                                target: '#home',
                                                                cond: 'isAchievementStartupLocationHome'
                                                            },
                                                        ],
                                                        SHOW_ACHIEVEMENT_DETAIL_MODAL: 'achievement_detail_modal'
                                                    }
                                                },
                                                achievement_detail_modal: {
                                                    on: {
                                                        CLOSE_ACHIEVEMENT_DETAIL_MODAL: 'achievements_screen'
                                                    },
                                                }
                                            }
                                        },
                                        notes_screen: {
                                            id: 'notes_screen',
                                            initial: 'notes_pending',
                                            entry: ['resetFullScreenLoadingSpinnerActive'],
                                            states: {
                                                notes_pending: {
                                                    invoke: {
                                                        id: 'requestNoteHistory',
                                                        src: 'requestNoteHistory',
                                                        onDone: {
                                                            target: 'notes_screen'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },  
                                                notes_screen: {
                                                    entry: ['displayNotesScreen','setTextualNoteModalStartupLocationNoteHistory', 'resetFullScreenLoadingSpinnerActive'],
                                                    on: {
                                                        CLOSE_NOTES: {
                                                            target: '#journey',
                                                            actions: ['setTextualNoteModalStartupLocationDefault']
                                                        },
                                                        SHOW_TEXTUAL_NOTE_MODAL: {
                                                            target: 'textual_note_modal'
                                                        },
                                                    }
                                                },
                                                textual_note_modal: {
                                                    always: [
                                                        {
                                                            target: '#popup_scope.textual_note',
                                                            actions: ['setTextualNoteModalShown', 'displayTextualNoteModal', 'loadingCompleted'],
                                                            cond: 'textualNoteModalNotShown' 
                                                        },
                                                        {
                                                            target: 'note_history_saving',
                                                            actions: ['resetTextualNoteModalShown', 'resetLoadingService', 'closeTextualNoteModal'],
                                                            cond: 'shouldNoteBeSaved'
                                                        },
                                                        {
                                                            target: '#journey.notes_screen.notes_screen',
                                                            actions: ['resetTextualNoteModalShown', 'closeTextualNoteModal']
                                                        }
                                                    ],
                                                
                                                },
                                                note_history_saving: {
                                                    invoke: {
                                                        id: 'saveHistoricNote',
                                                        src: 'saveHistoricNote',
                                                        onDone: {
                                                            target: 'reload_notes_screen'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }

                                                },
                                                
                                                reload_notes_screen: {
                                                    invoke: {
                                                        id: 'requestNoteHistory',
                                                        src: 'requestNoteHistory',
                                                        onDone: {
                                                            target: 'notes_screen',
                                                            actions: ['loadingCompleted', 'closeTextualNoteModal']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        history: {
                                            id: 'history',
                                            initial: 'history_pending',
                                            entry: ['resetFullScreenLoadingSpinnerActive'],
                                            states: {
                                                history_substate_history: {
                                                    type: 'history',
                                                    history: 'deep'
                                                },
                                                history_pending: {
                                                    invoke: {
                                                        id: 'requestHistory',
                                                        src: 'requestHistory',
                                                        onDone: {
                                                            target: 'history_screen'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                history_screen: {
                                                    entry: ['displayHistoryScreen', 'resetFullScreenLoadingSpinnerActive'],
                                                    on: {
                                                        GO_TO_HISTORY_TRAINING: {
                                                            target: '#single_training.single_training_content_loading',
                                                            actions: ['setHistoryTrainingMode']
                                                        },
                                                        GO_TO_QUIZ_HISTORY: {
                                                            target: 'quiz_loading'
                                                        },
                                                        GO_TO_TOTW_HISTORY: {
                                                            target: 'totw_loading'
                                                        },
                                                        GO_TO_DAILY_CHALLENGE_HISTORY: {
                                                            target: '#journey.daily_challenge_loading',
                                                            actions: ['setDailyChallengeStartupLocationHistory']
                                                        },
                                                        SHOW_TEXTUAL_NOTE_MODAL: {
                                                            target: 'textual_note_modal'
                                                        },
                                                        CLOSE_HISTORY: [
                                                            {
                                                                target: '#home',
                                                                cond: 'isHistoryStartupLocationHome'
                                                            },
                                                            {
                                                                target: '#journey',
                                                                cond: 'isHistoryStartupLocationJourney'
                                                            },
                                                        ]
                                                    }
                                                },
                                                textual_note_modal: {
                                                    entry: ['resetRMIsPossibleForNote'],
                                                    always: [
                                                        {
                                                            target: '#popup_scope.textual_note',
                                                            actions: ['setTextualNoteModalShown', 'displayTextualNoteModal', 'loadingCompleted'],
                                                            cond: 'textualNoteModalNotShown'
                                                        },
                                                        {
                                                            target: 'textual_note_saving',
                                                            actions: ['resetTextualNoteModalShown', 'resetLoadingService'],
                                                            cond: 'shouldNoteBeSaved'
                                                        },
                                                        {
                                                            target: '#journey.history.history_screen',
                                                            actions: ['resetTextualNoteModalShown', 'closeTextualNoteModal']
                                                        }
                                                    ]
                                                },
                                                textual_note_saving: {
                                                    invoke: {
                                                        id: 'saveTextualNote',
                                                        src: 'saveTextualNote',
                                                        onDone: {
                                                            target: 'reload_history'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                reload_history: {
                                                    invoke: {
                                                        id: 'requestHistory',
                                                        src: 'requestHistory',
                                                        onDone: {
                                                            target: 'history_screen',
                                                            actions: ['loadingCompleted', 'closeTextualNoteModal']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                quiz_loading: {
                                                    entry: ['resetLoadingService', 'displayWeeklyQuizIntro'],
                                                    invoke: {
                                                        id: 'requestQuizData',
                                                        src: 'requestQuizData',
                                                        onDone: {
                                                            target: '#weekly_quiz',
                                                            actions: ['setQuizStartupLocationHistory']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                totw_loading: {
                                                    entry: ['resetLoadingService', 'displayTipOfTheWeekScreen'],
                                                    invoke: {
                                                        id: 'requestTipOfTheWeekByID',
                                                        src: 'requestTipOfTheWeekByID',
                                                        onDone: {
                                                            target: '#tip_of_the_week',
                                                            actions: ['setTOTWStartupLocationHistory']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        library_screen: {
                                            entry: ['displayLibraryScreen', 'resetFullScreenLoadingSpinnerActive'],
                                            on: {
                                                CLOSE_LIBRARY: [
                                                    {
                                                        target: '#journey.journey_screen',
                                                        cond: 'isLibraryStartupLocationJourney',
                                                        actions: ['resetGoToLibraryAfterLoadingFlag']
                                                    },
                                                    {
                                                        target: '#home',
                                                        cond: 'isLibraryStartupLocationHome'
                                                    },
                                                    {
                                                        target: '#end_of_journey.end_of_journey_congratulations_screen',
                                                        cond: 'isLibraryStartupLocationEndOfJourneyCongratulations',
                                                        actions: ['resetGoToLibraryAfterLoadingFlag']
                                                    }
                                                ],
                                                GO_TO_LIBRARY_TRAINING: {
                                                    target: '#single_training.single_training_content_loading',
                                                    actions: ['setLibraryTrainingMode']
                                                }
                                            }
                                        },
                                        duels: {
                                            id: 'duels',
                                            initial: 'duels_loading',
                                            entry: ['resetFullScreenLoadingSpinnerActive'],
                                            states: {
                                                duels_loading: {
                                                    entry: ['resetLoadingService','displayDuelOverviewScreen', 'resetWasDuelDetailOpenedForNewlyGeneratedDuel'],
                                                    invoke: {
                                                        id: 'requestDuelOverview',
                                                        src: 'requestDuelOverview',
                                                        onDone: [
                                                            {
                                                                target:'duel_overview',
                                                                cond: 'hasDuelBeenInitiated'
                                                            },
                                                            {
                                                                target: 'generated_duel_loading',
                                                                cond: 'isNotBeforeStartDate'
                                                            },{
                                                                target: 'duel_overview',
                                                            }
                                                        ],
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                generated_duel_loading: {
                                                    invoke: {
                                                        id: 'requestGeneratedDuel',
                                                        src: 'requestGeneratedDuel',
                                                        onDone: 
                                                            {
                                                                target: 'duel_overview'
                                                            },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                duel_overview: {
                                                    entry: ['loadingCompleted', 'displayDuelOverviewScreen'],
                                                    on: {
                                                        GO_TO_DUEL_DETAILS:{
                                                            target: 'duel_details',
                                                            actions:['setDuelForDetailsScreen']
                                                        },
                                                        START_GENERATED_DUEL: {
                                                            target: 'send_starting_generated_duel'
                                                        },
                                                        START_SLOT_MACHINE_ANIM: {
                                                            actions: ['startSlotMachineAnim']
                                                        },
                                                        BACK: [
                                                            {
                                                                target: '#journey.journey_screen',
                                                                cond: 'isDuelOverviewStartupLocationJourney'
                                                            },
                                                            {
                                                                target: '#home_entry',
                                                                cond: 'isDuelOverviewStartupLocationHome'
                                                            },
                                                            {
                                                                target: '#home_entry'
                                                            }
                                                        ],
                                                        SET_DUEL_DETAIL_CALLED_FOR_NEWLY_GENERATED_DUEL: {
                                                            actions: ['setWasDuelDetailOpenedForNewlyGeneratedDuel']
                                                        }
                                                    },
                                                    exit: ['resetResultFromGeneratingDuelHasArrived']
                                                },
                                                duel_details: {
                                                    entry: ['displayDuelDetailsScreen'],
                                                    on: {
                                                        BACK: [
                                                            {
                                                                target: 'duels_loading',
                                                                cond: 'wasDuelDetailOpenedForNewlyGeneratedDuel'
                                                            },
                                                            {
                                                                target: 'duel_overview'
                                                            }
                                                        ]
                                                    }
                                                },
                                                send_starting_generated_duel: {
                                                    invoke: {
                                                        id: 'startGeneratedDuel',
                                                        src: 'startGeneratedDuel',
                                                        onDone: {
                                                            target: 'duel_overview',
                                                            actions: ['setResultFromGeneratingDuelHasArrived']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                    exit: ['setResultFromGeneratingDuelHasArrived']
                                                },
                                                send_duel_invitation_viewed: {
                                                    entry: ['resetLoadingService', 'displayDuelOverviewScreen'],
                                                    invoke: {
                                                        id: 'sendDuelInvitationViewed',
                                                        src: 'sendDuelInvitationViewed',
                                                        onDone: {
                                                            target: 'duels_loading'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                send_duel_completion_viewed: {
                                                    entry: ['resetLoadingService', 'displayDuelSummaryScreen'],
                                                    invoke: {
                                                        id: 'sendDuelCompletionViewed',
                                                        src: 'sendDuelCompletionViewed',
                                                        onDone: {
                                                            target: 'duel_completion_screen'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                duel_completion_screen: {
                                                    entry: ['displayDuelSummaryScreen', 'loadingCompleted', 'setPlayerPointsTypeDuel'],
                                                    on: {
                                                        BACK: '#home_entry',
                                                        GO_TO_PLAYER_PROGRESSION: [
                                                            {
                                                                target: '#popup_scope.player_progression',
                                                                actions: ['setPlayerProgressionStartupDuel', 'setPlayerProgressDisplayedPoints'],
                                                                cond: 'hasLevelUpHappened'
                                                            },
                                                            {
                                                                target: '#home_entry',
                                                                actions: ['setShowSmallPointsAnimation', 'setPlayerSmallPointsDisplayedPoints', 'presentToastWithSmallPointsAnimation']
                                                            }
                                                        ]
                                                        
                                                    }
                                                }
                                            }
                                        },
                                        leaderboard_screen: {
                                            entry: ['displayLeaderboardScreen'],
                                            on: {
                                                BACK: 'journey_screen'
                                            }
                                        },
                                        daily_challenge_loading: {
                                            entry: ['resetLoadingService'],
                                            invoke: {
                                                id: 'requestDailyChallengeById',
                                                src: 'requestDailyChallengeById',
                                                onDone: {
                                                    target: '#daily_challenge.daily_challenge_completion_feedback',
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                    }
                                },
                                multi_package_hub: {
                                    id: 'multi_package_hub',
                                    initial: 'multi_package_hub_screen',
                                    states: {
                                        multi_package_hub_screen: {
                                            entry: ['displayMultiPackageHubScreen', 'loadingCompleted', 'subLoadingCompleted'],
                                            on: {
                                                BACK: '#profile',
                                                GO_TO_CERTIFICATE_PAGE: {
                                                    target: 'loading_certificate',
                                                },
                                                GO_TO_MICROTRAINING_HISTORY: {target: 'microtraining_history', actions: ['setMicrotrainingHistoryStartupLocationMultiPackageHub']},
                                                GO_TO_PACKAGE_HISTORY_PAGE: {
                                                    target: 'package_history_entry', actions: ['setPackageHistoryStartupLocationMultiPackageOverview']
                                                },
                                            },
                                        },
                                        loading_certificate: {
                                            invoke: {
                                                id: 'requestCertificatePackageHub',
                                                src: 'requestCertificatePackageHub',
                                                onDone: {
                                                    target: '#end_of_journey.end_of_journey_certificate_screen',
                                                    actions: ['setCertificateStartupLocationMultiPackageHub']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }},
                                        package_history_entry: {
                                            id: 'package_history_entry',
                                            type: 'parallel',
                                            entry: ['resetSubLoadingService', 'setFullScreenLoadingSpinnerActive'],
                                            onDone: {
                                                target: 'package_history_screen',
                                            },
                                            states: {
                                                loading_insights: {
                                                    initial:'loading',
                                                    states: {
                                                        loading: {
                                                            invoke: {
                                                                id: 'requestInsightsPackageHub',
                                                                src: 'requestInsightsPackageHub',
                                                                onDone: {
                                                                    target: 'success'
                                                                },
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                                                                                
                                                            },
                                                        },
                                                        success: {
                                                            type: 'final'
                                                        }
                                                    }
                                                },
                                                loading_stats: {
                                                    initial:'loading',
                                                    states: {
                                                        loading: {
                                                            invoke: {
                                                                id: 'requestStatsPackageHub',
                                                                src: 'requestStatsPackageHub',
                                                                onDone: {
                                                                    target: 'success'
                                                                },                                                        
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                            },
                                                        },
                                                        success: {
                                                            type: 'final'
                                                        }
                                                    }
                                                },
                                                loading_conquests: {
                                                    initial:'loading',
                                                    states: {
                                                        loading: {
                                                            invoke: {
                                                                id: 'requestConquestsPackageHub',
                                                                src: 'requestConquestsPackageHub',
                                                                onDone: {
                                                                    target: 'success'
                                                                },                                                        
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                            },
                                                        },
                                                        success: {
                                                            type: 'final'
                                                        }
                                                    }
                                                },
                                                loading_achievemnts: {
                                                    initial:'loading',
                                                    states: {
                                                        loading: {
                                                            invoke: {
                                                                id: 'requestAchievementsPackageHub',
                                                                src: 'requestAchievementsPackageHub',
                                                                onDone: {
                                                                    target: 'success'
                                                                },                                                        
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                            },
                                                        },
                                                        success: {
                                                            type: 'final'
                                                        }
                                                    }
                                                },
                                                loading_feedback: {
                                                    initial:'loading',
                                                    states: {
                                                        loading: {
                                                            invoke: {
                                                                id: 'requestFeedbackPackageHub',
                                                                src: 'requestFeedbackPackageHub',
                                                                onDone: {
                                                                    target: 'success'
                                                                },                                                        
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                            },
                                                        },
                                                        success: {
                                                            type: 'final'
                                                        }
                                                    }
                                                },
                                                loading_skilltype: {
                                                    initial:'loading',
                                                    states: {
                                                        loading: {
                                                            invoke: {
                                                                id: 'requestSkillTypePackageHub',
                                                                src: 'requestSkillTypePackageHub',
                                                                onDone: {
                                                                    target: 'success'
                                                                },                                                        
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                            },
                                                            },
                                                        success: {
                                                            type: 'final'
                                                        }
                                                    }
                                                }
                                            },
                                        },
                                        package_history_screen: {
                                            entry: ['displayPackageHistoryScreen', 'subLoadingCompleted', 'resetPageHistoryGraph', 'resetFullScreenLoadingSpinnerActive'],
                                            on: {
                                                BACK: [
                                                    {
                                                        target: '#multi_package_hub.multi_package_hub_screen',
                                                        cond: 'isPackageHistoryStartupLocationMultiPackageHub',
                                                    },
                                                    {
                                                        target: '#profile',
                                                        cond: 'isPackageHistoryStartupLocationProfile',
                                                    }],
                                                GO_TO_ACHIEVEMENTS: {
                                                    target:'#achievements.achievements_screen',
                                                    actions: ['setAchievementStartupLocationMultiPackageHub', 'setAchievementData']
                                                },
                                                GO_TO_FEEDBACK_PROFILE: {
                                                    target: '#profile.feedback_profile',
                                                    actions: ['setFeedbackProfileStartupLocationMultiPackageHub', 'setFeedbackProfileData', 'setProfileSectionToProfile']
                                                },
                                                GO_TO_MICROTRAINING_HISTORY: {
                                                    target: 'microtraining_history',
                                                    actions: ['setMicrotrainingHistoryStartupLocationPackageHistory']
                                                },
                                                GO_TO_PROFILE_TAB: {
                                                    target: '#profile',
                                                    
                                                },
                                                GO_TO_MULTI_PACKAGE_HUB: {
                                                    target: '#multi_package_hub',
                                                },
                                                GO_TO_PACKAGE_HISTORY_PAGE: {
                                                    target: '#package_history_entry'
                                                },
                                                
                                                SHOW_SKILL_TYPE: {
                                                    target: '#pretraining.skill_type_explanation_screen',
                                                    actions: ['setSkillTypeStartupLocationPackageHistory']
                                                }

                                            },
                                        },
                                        microtraining_history: {
                                            entry: ['displayMicrotrainingHistoryScreen'],
                                            on: {
                                                BACK: [
                                                    {
                                                        target: '#multi_package_hub.package_history_screen',
                                                        cond: 'isMicrotrainingHistoryStartupLocationPackageHistory',
                                                    },
                                                    {
                                                        target: '#multi_package_hub.multi_package_hub_screen',
                                                        cond: 'isMicrotrainingHistoryStartupLocationMultiPackageHub',
                                                    }
                                                ],
                                            },
                                        }
                                    }, 
                                },
                                home_entry: {
                                    entry: ['resetFullScreenLoadingSpinnerActive','resetLoadingService', 'displayHomeScreen', 'startConsoleLogTimer'],
                                    id: 'home_entry',
                                    type: 'parallel',
                                    // I don't know why we go to achievement_review after we are done with the home entry state
                                    onDone: {
                                        target: '#achievement_review'
                                    },
                                    exit: ['stopConsoleLogTimer'],
                                    states: {
                                        current_week_pending: {
                                            initial:'loading_current_week', 
                                            states:{
                                                loading_current_week: {
                                                    invoke: {
                                                        id: 'requestCurrentWeek',
                                                        src: 'requestCurrentWeek',
                                                        onDone: {
                                                            target: 'loading_current_week_status'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                // Current Week Status need Data from Current Week
                                                loading_current_week_status: {
                                                    invoke: {
                                                        id: 'requestCurrentWeekStatus',
                                                        src: 'requestCurrentWeekStatus',
                                                        onDone: {
                                                            target: 'loading_after_current_week_status'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                //The Following states need data from the current week status
                                                loading_after_current_week_status: {
                                                    id: 'loading_home_gamification',
                                                    type: 'parallel',
                                                    states: {
                                                        load_totw: {
                                                            initial: 'loading',
                                                            states:{
                                                                loading: {
                                                                    invoke: {
                                                                        id: 'requestTipOfTheWeek',
                                                                        src: 'requestTipOfTheWeek',
                                                                        onDone: {
                                                                            target: 'success'
                                                                        },
                                                                        onError: {
                                                                            target: '#request_error',
                                                                            actions: ['assignErrors']
                                                                        }
                                                                    },
                                                                },
                                                                success: {
                                                                    type: 'final'
                                                                }
                                                            }
                                                        },
                                                        load_quiz: {
                                                            initial: 'decision',
                                                            states:{
                                                                decision: {
                                                                    //Here we first check if we need to load a quiz other wise we jsut set it to success
                                                                    always: [
                                                                        {
                                                                            target: 'loading',
                                                                            cond: 'mustWeeklyQuizBeLoaded'
                                                                        },
                                                                        {  
                                                                            target: 'success'
                                                                        }
                                                                    ]
                                                                },
                                                                loading: {
                                                                    invoke: {
                                                                        id: 'requestWeeklyQuizData',
                                                                        src: 'requestWeeklyQuizData',
                                                                        onDone: {
                                                                            target: 'success'
                                                                        },
                                                                        onError: {
                                                                            target: '#request_error',
                                                                            actions: ['assignErrors']
                                                                        }
                                                                    },
                                                                },
                                                                success: {
                                                                    type: 'final'
                                                                }
                                                            }
                                                        },
                                                        lottery_resolvement_timer_loading: {
                                                            initial: 'decision',
                                                            states: {
                                                                decision: {
                                                                    always: [
                                                                        {
                                                                            target: 'loading_lottery_resolvement_timer',
                                                                            cond: 'isLastWeekAndAreLotteryRequirementsMet'
                                                                        },
                                                                        {  
                                                                            target: 'success'
                                                                        }
                                                                    ]
                                                                },
                                                                loading_lottery_resolvement_timer: {
                                                                    invoke: {
                                                                        id: 'requestLotteryResolvementTimer',
                                                                        src: 'requestLotteryResolvementTimer',
                                                                        onDone: {
                                                                            target: 'loading_lottery_result'
                                                                        },
                                                                        onError: {
                                                                            target: '#request_error',
                                                                            actions: ['assignErrors']
                                                                        }
                                                                    },
                                                                },
                                                                loading_lottery_result: {
                                                                    invoke: {
                                                                        id: 'requestLotteryResult',
                                                                        src: 'requestLotteryResult',
                                                                        onDone: {
                                                                            target: 'success'
                                                                        },
                                                                        onError: {
                                                                            target: '#request_error',
                                                                            actions: ['assignErrors']
                                                                        }
                                                                    },
                                                                },
                                                                success: {
                                                                    type: 'final'
                                                                }
                                                            }
                                                        },
                                                    },
                                                    onDone: {
                                                        target: 'success'
                                                    }
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        history_pending: { 
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestHistory',
                                                        src: 'requestHistory',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        package_status_loading: { 
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestPackageStatus',
                                                        src: 'requestPackageStatus',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        achievement_progress_list_loading: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestAchievementProgressList',
                                                        src: 'requestAchievementProgressList',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        daily_challenge_loading: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestDailyChallenge',
                                                        src: 'requestDailyChallenge',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        space_map_detail_loading: {
                                            initial:'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestSpaceMapDetails',
                                                        src: 'requestSpaceMapDetails',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        player_progress_info_loading: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestPlayerProgressInfo',
                                                        src: 'requestPlayerProgressInfo',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        streak_counter_loading: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestStreakCounterData',
                                                        src: 'requestStreakCounterData',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        duel_week_stats_loading: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestWeeklyDuelStats',
                                                        src: 'requestWeeklyDuelStats',
                                                        onDone: [
                                                            {
                                                                target: 'load_duel_details',
                                                                cond: 'duelSummaryAndNoInvitesAvailable',
                                                            },
                                                            {
                                                                target: 'success'
                                                            },
                                                        ],
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                load_duel_details: {
                                                    invoke: {
                                                        id: 'requestDuelOverview',
                                                        src: 'requestDuelOverview',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },

                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        feature_unlock_list_loading: {
                                            initial: 'loading',
                                            states:{
                                                loading: {
                                                    invoke: {
                                                        id: 'requestFeatureUnlockList',
                                                        src: 'requestFeatureUnlockList',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        lottery_update_loading: {
                                            initial: 'decision',
                                            states: {
                                                decision: {
                                                    always: [
                                                        {
                                                            target: 'loading',
                                                            cond: 'areLotteryRequirementsMet'
                                                        },
                                                        {  
                                                            target: 'success'
                                                        }
                                                    ]
                                                },
                                                loading: {
                                                    invoke: {
                                                        id: 'requestLotteryUpdate',
                                                        src: 'requestLotteryUpdate',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                    },
                                },
                                home: {
                                    id: 'home',
                                    initial: 'home_screen',
                                    entry: ['resetFullScreenLoadingSpinnerActive'],
                                    always:{
                                        target: '#streak_count',
                                        cond: 'isStreakIncreasedAndNoSmallPointAnimationIsRunning'
                                    },
                                    on: {
                                        OPEN_STREAK_COUNTER_DETAIL: '#streak_count.streak_detail',
                                        GO_TO_SINGLE_TRAINING_TAB: 'single_training_entry',
                                        GO_TO_GROUP_TRAINING_TAB: 'group_training_entry',
                                        GO_TO_HOME_TAB: 'home_entry',
                                        GO_TO_PROFILE_TAB: 'profile_entry',
                                        GO_TO_JOURNEY_TAB: 'journey_entry',
                                        GO_TO_WEEKLY_QUIZ: {
                                            target: 'weekly_quiz',
                                            actions: ['setQuizStartupLocationHome']
                                        },
                                        GO_TO_HISTORY: {
                                            target: '#journey.history',
                                            actions: ['setHistoryStartupLocationHome']
                                        },
                                        GO_TO_ACHIEVEMENT_REVIEW: 'achievement_review.achievement_review_screen',
                                        RESET_PACKAGE: 'reset_package',
                                        INCREASE_PACKAGE: 'increase_package',
                                        GO_TO_PILOT_INTRO: '#pilot_user_introduction_screen',
                                        START_END_OF_JOURNEY: {
                                            target: '#previous_week_goal_loading',
                                            actions: ['setEOJFlowStarted']
                                        },
                                        GO_TO_END_OF_JOURNEY_CERTIFICATE: {
                                            target: '#end_of_journey_certificate_loading',
                                            actions: ['setCertificateStartupLocationHome']
                                        },
                                        GO_TO_NEW_CHALLENGE: {
                                            target: '#daily_challenge.daily_challenge_new_challenge',
                                            actions: ['setDailyChallengeStartupLocationHome']
                                        },
                                        GO_TO_CHALLENGE_COMPLETION: {
                                            target: '#daily_challenge.daily_challenge_completion_feedback',
                                            actions: ['setDailyChallengeStartupLocationHome']
                                        },
                                        SHOW_ACHIEVEMENTS_IN_JOURNEY: {
                                            target: '#achievements.reload_achievements',
                                            actions: ['setAchievementStartupLocationHome']
                                        },
                                        OPEN_SPACE_MAP: {
                                            target: '#popup_scope.space_map'
                                        },
                                        START_TOUR_GUIDE: {
                                            target: '#tour_guide',
                                            actions: ['setShowTourGuide']
                                        },
                                        GO_TO_DUELS: {
                                            target: '#duels',
                                            actions: ['setDuelsStartupLocationHome']
                                        },
                                        SEND_DUEL_INVITATION_VIEWED: {
                                            target: '#duels.send_duel_invitation_viewed',
                                            actions: ['setDuelsStartupLocationHome']
                                        },
                                        SEND_DUEL_COMPLETION_VIEWED: {
                                            target: '#duels.send_duel_completion_viewed',
                                            actions: ['setDuelForSummaryScreen']
                                        },
                                        GO_TO_LOTTERY_UPDATE: {
                                            target: '#lottery.lottery_update_screen'
                                        },
                                        GO_TO_LOTTERY_RESULT: {
                                            target: '#lottery.lottery_result_screen'
                                        },
                                        CHECK_FOR_STREAK_COUNTER_INCREASE: {
                                            target: '#streak_count',
                                            cond: 'isStreakIncreasedAndNoSmallPointAnimationIsRunning'
                                        }
                                    },
                                    states: {
                                        home_substate_history: {
                                            type: 'history',
                                            history: 'deep'
                                        },
                                        home_screen: {
                                            entry: ['loadingCompleted', 'displayHomeScreen', 'resetFullScreenLoadingSpinnerActive'],
                                            on: {
                                                // GO_TO_HISTORY_OVERVIEW: {
                                                //     target: '#achievementshistory',
                                                //     actions: ['setHistoryStartupLocationHome']
                                                // },
                                                GO_TO_TIP_OF_THE_WEEK: [
                                                    {
                                                        target: '#tip_of_the_week',
                                                        actions: ['setTOTWStartupLocationHome', 'setUserCompletedTaskTypeTOTW', 'setUserCompletedTask'],
                                                        cond: 'isTOTWNotCompletedYet'
                                                    },
                                                    {
                                                        target: '#tip_of_the_week',
                                                        actions: ['setTOTWStartupLocationHome'],
                                                    }
                                                ]
                                                ,
                                                GO_TO_LIBRARY_OVERVIEW: {
                                                    target: '#journey_entry',
                                                    actions: ['setLibraryStartupLocationHome', 'setGoToLibraryAfterLoadingFlag']
                                                },
                                                START_TOUR_GUIDE: {
                                                    target: '#tour_guide',
                                                    actions: ['setShowTourGuide']
                                                }
                                            }
                                        } 
                                    }
                                },
                                lottery:{
                                    id: 'lottery',
                                    states:{
                                        lottery_update_screen:{
                                            entry: 'displayLotteryUpdateScreen',
                                            on: {
                                                BACK: {
                                                    target: 'send_lottery_update_viewed'
                                                },
                                            }
                                        },
                                        send_lottery_update_viewed:{
                                            invoke: {
                                                id: 'sendLotteryUpdateViewed',
                                                src: 'sendLotteryUpdateViewed',
                                                onDone: {
                                                    target: '#home_entry',
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors' ]
                                                }
                                            },
                                        },
                                        lottery_result_screen:{
                                            entry: 'displayLotteryResultScreen',
                                            on: {
                                                BACK: {
                                                    target: 'send_lottery_result_viewed'
                                                },
                                            }
                                        },
                                        send_lottery_result_viewed:{
                                            invoke: {
                                                id: 'sendLotteryResultViewed',
                                                src: 'sendLotteryResultViewed',
                                                onDone: {
                                                    target: '#home_entry',
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors' ]
                                                }
                                            },
                                        },
                                    }
                                },
                                streak_count:{
                                    id: 'streak_count',
                                    initial:'send_streak_increased_viewed',
                                    states:{
                                        send_streak_increased_viewed:{
                                            entry: ['displayStreakIncreasedModal'],
                                            invoke: {
                                                id: 'notifyStreakIncreasedViewed',
                                                src: 'notifyStreakIncreasedViewed',
                                                onDone: {
                                                    target: 'streak_counter_loading',
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors' ]
                                                }
                                            },
                                        },
                                        streak_counter_loading: {
                                            invoke: {
                                                id: 'requestStreakCounterData',
                                                src: 'requestStreakCounterData',
                                                onDone: {
                                                    target: 'streak_increased_modal',
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors' ]
                                                }
                                            }                
                                        },
                                        streak_increased_modal: {
                                            entry:'setHasUserSeenStreakCountPointAnimation',
                                            on: {
                                                CLOSE_STREAK_COUNTER_LEVEL_UP: [
                                                    {
                                                        target: 'streak_counter_tooltip_homepage',
                                                        cond: 'hasUserNotSeenStreakCounterTutorial',
                                                        actions:['displayHomeScreen']
                                                    },
                                                    {
                                                        target: 'streak_decision'
                                                    }
                                                ]
                                            },
                                            exit: ['closeStreakIncreasedModal', 'setCameFromStreakCounter']
                                        },
                                        streak_decision: {
                                            entry: ['setPlayerPointsTypeStreak'],
                                            always: [{
                                                    target: '#popup_scope.player_progression',
                                                    actions: ['setPlayerProgressionStartupStreak', 'setPlayerProgressDisplayedPoints'],
                                                    cond: 'hasUserSeenStreakCountPointAnimationAndHasLevelUpHappened'

                                                },
                                                {  
                                                    target: '#home_entry',
                                                    actions: ['setShowSmallPointsAnimation', 'setPlayerSmallPointsDisplayedPoints', 'presentToastWithSmallPointsAnimation'],
                                                    cond: 'hasLevelUpNotHappenedAndHasUserReceivedPoints'
                                                },
                                                {
                                                    target: '#home_entry'
                                                }
                                            ]
                                        },
                                        streak_counter_tooltip_homepage: {
                                            entry:'displayStreakCounterToolTip',
                                            on: {
                                                CLOSE_STREAK_COUNTER_TOOLTIP: 'streak_information_animation',
                                            }
                                        },
                                        streak_information_animation:{
                                            entry: ['openStreakCounterAnimation', 'notifyStreakCounterAnimationViewed'],
                                            on: {
                                                OPEN_STREAK_COUNTER_DETAIL: 'streak_detail' 
                                            }
                                        },
                                        streak_detail: {
                                            entry: 'openStreakCounterDetailModal',
                                            on: {
                                                RETURN_TO_HOME: [{
                                                    actions: ['closeStreakCounterDetailModal'],
                                                    target: 'streak_decision'
                                                }]
                                            }
                                        }
                                    },
                                },
                                reset_package: {
                                    initial: 'reset_package_info_screen',
                                    states: {
                                        reset_package_info_screen: {
                                            entry: ['displayResetPackageAlert'],
                                            on: {
                                                SUBMIT_PACKAGE_RESET: 'package_resetting',
                                                CANCEL_PACKAGE_RESET: '#home.home_substate_history'
                                            }
                                        },
                                        package_resetting: {
                                            invoke: {
                                                id: 'savePackageReset',
                                                src: 'savePackageReset',
                                                onDone: {
                                                    target: '#logged_in_entry'
                                                }
                                            },
                                            exit: ['setWeekJustIncreased']
                                        }
                                    }
                                },
                                increase_package: {
                                    initial: 'package_increasing',
                                    states: {
                                        package_increasing: {
                                            invoke: {
                                                id: 'savePackageIncrease',
                                                src: 'savePackageIncrease',
                                                onDone: {
                                                    target: '#home_entry'
                                                }
                                            }
                                        }
                                    },
                                    exit: ['setWeekJustIncreased', 'resetAllPopupChecks']
                                },
                                weekly_quiz: {
                                    id: 'weekly_quiz',
                                    initial: 'quiz_welcome_screen',
                                    onDone: 
                                        [
                                            {
                                                target: '#popup_scope.player_progression',
                                                actions: ['setPlayerProgressionStartupQuiz', 'setPlayerProgressDisplayedPoints'],
                                                cond: 'hasLevelUpHappened',
                                            },
                                            {
                                                target: '#home_entry',
                                                actions: ['setShowSmallPointsAnimation', 'setPlayerSmallPointsDisplayedPoints', 'presentToastWithSmallPointsAnimation']
                                            },
                                        ],
                                    states: {
                                        quiz_welcome_screen: {
                                            entry: ['loadingCompleted', 'displayWeeklyQuizIntro'],
                                            on: {
                                                START_WEEKLY_QUIZ: 'quiz_question_screen',
                                                CLOSE_QUIZ: [
                                                    {
                                                        target: '#home.home_substate_history',
                                                        cond: 'isQuizStartupLocationHome'
                                                    },
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isQuizStartupLocationHistory'
                                                    }
                                                ]
                                            }
                                        },
                                        quiz_question_screen: {
                                            entry: ['displayCurrentQuestion'],
                                            on: {
                                                QUESTION_ANSWER_GIVEN: {
                                                    target: 'quiz_answer_explanation_screen',
                                                    actions: ['setSelectedAnswer']
                                                },
                                                EXPLANATION_SELECTED: 'quiz_answer_explanation_screen',
                                                CONTINUE_TO_NEXT_QUESTION_OR_SUMMARY: [
                                                    {
                                                        target: 'quiz_result_saving',
                                                        cond: 'isLastQuestion'
                                                    },
                                                    {
                                                        target: 'quiz_question_screen',
                                                        actions: ['increaseCurrentSelectedQuestion']
                                                    }
                                                ],
                                                RETURN_TO_PREVIOUS_QUESTION: {
                                                    target: 'quiz_question_screen',
                                                    actions: ['decreaseCurrentlySelectedQuestion']
                                                },
                                                RMI_REQUIREMENTS_MET_FAKE: {
                                                    actions: ['sendFakeRMIRequirementsMet']
                                                }
                                            }
                                        },
                                        quiz_answer_explanation_screen: {
                                            entry: ['displayQuestionExplanation'],
                                            on: {
                                                CONTINUE_TO_NEXT_QUESTION_OR_SUMMARY: [
                                                    {
                                                        target: 'quiz_result_saving',
                                                        cond: 'isLastQuestion'
                                                    },
                                                    {
                                                        target: 'quiz_question_screen',
                                                        actions: ['increaseCurrentSelectedQuestion']
                                                    }
                                                ],
                                                RETURN_TO_PREVIOUS_QUESTION: {
                                                    target: 'quiz_question_screen',
                                                    actions: ['decreaseCurrentlySelectedQuestion']
                                                },
                                                CLOSE_QUESTION_EXPLANATION: 'quiz_question_screen'
                                            }
                                        },
                                        quiz_result_saving: {
                                            entry: ['resetLoadingService', 'displayWeeklyQuizSummary'],
                                            invoke: {
                                                id: 'saveQuizResult',
                                                src: 'saveQuizResult',
                                                onDone: {
                                                    target: 'quiz_summary_screen'
                                                }
                                            }
                                        },
                                        quiz_summary_screen: {
                                            entry: ['loadingCompleted','displayWeeklyQuizSummary', 'setUserCompletedTaskTypeQuiz', 'setUserCompletedTask'],
                                            on: {
                                                CLOSE_QUIZ: [
                                                    {
                                                        target: 'exit_weekly_quiz_screen',
                                                        cond: 'isQuizStartupLocationHome'
                                                    },
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isQuizStartupLocationHistory'
                                                    }
                                                ]
                                            }
                                        },
                                        exit_weekly_quiz_screen: {
                                            type: 'final',
                                            entry: ['setPlayerPointsTypeQuiz']
                                        }
                                    }
                                },
                                tip_of_the_week: {
                                    id: 'tip_of_the_week',
                                    initial: 'tip_of_the_week_screen',
                                    states: {
                                        tip_of_the_week_screen: {
                                            entry: ['loadingCompleted', 'displayTipOfTheWeekScreen'],
                                            on: {
                                                CLOSE_TIP_OF_THE_WEEK: [
                                                    {
                                                        target: 'tip_of_the_week_finished_saving',
                                                        cond: 'isTOTWStartupLocationHome'
                                                    },
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isTOTWStartupLocationHistory'
                                                    }
                                                ],
                                                RMI_REQUIREMENTS_MET: {
                                                    target: 'tip_of_the_week_rmi_sending',
                                                    actions: ['setRMIName']
                                                }
                                            }
                                        },
                                        tip_of_the_week_rmi_sending: {
                                            entry: ['resetLoadingService', 'resetProgressDetails', 'displayHomeScreen'],
                                            invoke: {
                                                id: 'sendRMIRequirementsMetWithLevelUpChance',
                                                src: 'sendRMIRequirementsMetWithLevelUpChance',
                                                onDone: {
                                                    target: 'tip_of_the_week_finished_saving'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        tip_of_the_week_finished_saving: {
                                            entry: ['resetLoadingService', 'displayHomeScreen'],
                                            invoke: {
                                                id: 'saveTOTWFinished',
                                                src: 'saveTOTWFinished',
                                                onDone: 
                                                [
                                                    {
                                                        target: 'tip_of_the_week_level_up_decision',
                                                        cond: 'hasUserReceivedPoints'
                                                    },
                                                    {
                                                        target: '#home_entry'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        tip_of_the_week_level_up_decision: {
                                            entry: ['setPlayerPointsTypeTOTW'],
                                            always: [
                                                {
                                                    target: '#popup_scope.player_progression',
                                                    actions: ['setPlayerProgressionStartupTOTW', 'setPlayerProgressDisplayedPoints'],
                                                    cond: 'hasLevelUpHappened'
                                                },
                                                {
                                                    target: '#home_entry',
                                                    actions: ['setShowSmallPointsAnimation', 'setPlayerSmallPointsDisplayedPoints', 'presentToastWithSmallPointsAnimation']
                                                }
                                            ]                                
                                        }
                                    }
                                },
                                daily_challenge: {
                                    id: 'daily_challenge',
                                    states: {
                                        daily_challenge_new_challenge: {
                                            entry: ['displayNewChallengeScreen'],
                                            on: {
                                                START_DAILY_CHALLENGE: 'daily_challenge_send_started',
                                                REJECT_DAILY_CHALLENGE: 'daily_challenge_set_time_modal',
                                                RETURN_TO_HOME: '#home_entry',
                                            }
                                        },
                                        daily_challenge_set_time_modal: {
                                            entry: ['displaySetTimeDailyChallengeModal'],
                                            on: {
                                                BACK: {
                                                    target: 'daily_challenge_new_challenge',
                                                    actions: ['resetRejectDailyChallengeModal']
                                                },
                                                SET_CHALLENGE_TIME_AND_PUSH:{
                                                    target: 'daily_challenge_set_time_and_push',
                                                    actions: ['resetRejectDailyChallengeModal']
                                                } 
                                            }
                                        },
                                        daily_challenge_set_time_and_push: {
                                            invoke: {
                                                id: 'setDailyChallengeTimeAndPush',
                                                src: 'setDailyChallengeTimeAndPush',
                                                onDone: '#home_entry',
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        daily_challenge_send_started: {
                                            invoke: {
                                                id: 'saveDailyChallengeStarted',
                                                src: 'saveDailyChallengeStarted',
                                                onDone: {
                                                    target: 'daily_challenge_completion_feedback',
                                                    actions: ['setRMIsPossibleForDailyChallenge']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        daily_challenge_completion_feedback: {
                                            entry: ['displayDailyChallengeCompletionFeedbackScreen', 'loadingCompleted', 'setRMIsPossibleForDailyChallenge'],
                                            on: {
                                                BACK: [
                                                    {
                                                        target: 'daily_challenge_completion_feedback',
                                                        actions: ['decrementCurrentDailyChallengeStep'],
                                                        cond: 'isNotFirstDailyChallengeStep'
                                                    },
                                                    {
                                                        target: '#home_entry',
                                                        cond: 'isDailyChallengeStartupLocationHome',
                                                        actions: ['resetRMIsPossibleForNote']
                                                    },
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isDailyChallengeStartupLocationHistory'
                                                    },
                                                    {
                                                        target: '#journey.notes_screen',
                                                        cond: 'isDailyChallengeStartupLocationNotes'
                                                    },
                                                ],
                                                SAVE_DAILY_CHALLENGE_STEP_COMPLETED:{
                                                    actions:['saveDailyChallengeStepCompleted']
                                                },
                                                SHOW_NEXT_STEP: [
                                                    {
                                                        target: 'daily_challenge_completion_feedback',
                                                        actions: ['incrementCurrentDailyChallengeStep'],
                                                        cond: 'isNotLastDailyChallengeStep'
                                                    },
                                                    {
                                                        target: 'daily_challenge_completed_successful',
                                                        actions: ['setUserCompletedTaskTypeDailyChallenge', 'setUserCompletedTask', 'resetRMIsPossibleForNote'],
                                                        cond: 'isDailyChallengeStartupLocationHome'
                                                    }, 
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isDailyChallengeStartupLocationHistory'
                                                    }
                                                ],
                                                CHALLENGE_COMPLETED_SUCCESSFULLY: [
                                                    {
                                                        target: 'daily_challenge_completed_successful',
                                                        actions: ['setUserCompletedTaskTypeDailyChallenge', 'setUserCompletedTask', 'resetRMIsPossibleForNote'],
                                                        cond: 'isDailyChallengeStartupLocationHome'
                                                    },
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isDailyChallengeStartupLocationHistory'
                                                    }
                                                ],
                                                CHALLENGE_COMPLETED_FAILED: [
                                                    {
                                                        target: 'daily_challenge_completed_failed',
                                                        actions: ['resetRMIsPossibleForNote'],
                                                        cond: 'isDailyChallengeStartupLocationHome'
                                                    },
                                                    {
                                                        target: '#journey.history',
                                                        cond: 'isDailyChallengeStartupLocationHistory'
                                                    }
                                                ],
                                                LOAD_NOTES: 'daily_challenge_note_loading',
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet'],
                                                    cond: 'isUseProgressionStateDailyChallenge'
                                                },
                                                BACK_TO_JOURNEY: [
                                                    {
                                                    target: '#journey.history',
                                                        cond: 'isDailyChallengeStartupLocationHistory'
                                                },  
                                                {
                                                    target: '#journey.notes_screen',
                                                    cond: 'isDailyChallengeStartupLocationNotes'
                                                }
                                            ],
                                            }
                                        },
                                        daily_challenge_note_loading: {
                                            entry: ['resetLoadingService', 'displayTextualNoteModal'],
                                            invoke: {
                                                id: 'requestNotes',
                                                src: 'requestNotes',
                                                onDone: {
                                                    target: 'daily_challenge_note_modal_decision'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        daily_challenge_note_modal_decision: {
                                            always: [
                                                {
                                                    target: '#popup_scope.textual_note',
                                                    actions: ['setTextualNoteModalShown', 'loadingCompleted', 'resetShouldNoteBeSaved', 'setTextualNoteModalStartupLocationDefault'],
                                                    cond: 'textualNoteModalNotShown'
                                                },
                                                {
                                                    target: 'daily_challenge_note_saving',
                                                    actions: ['resetTextualNoteModalShown', 'resetLoadingService'],
                                                    cond: 'shouldNoteBeSaved'
                                                }, 
                                                {
                                                    target:'daily_challenge_completion_feedback',
                                                    actions: ['resetTextualNoteModalShown', 'closeTextualNoteModal']
                                                }
                                            ]
                                        },
                                        daily_challenge_note_saving: {
                                            invoke: {
                                                id: 'saveTextualNote',
                                                src: 'saveTextualNote',
                                                onDone: {
                                                    target: 'daily_challenge_completion_feedback',
                                                    actions: ['loadingCompleted', 'closeTextualNoteModal']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        daily_challenge_completed_successful: {
                                            id: 'daily_challenge_completed_successful',
                                            type: 'parallel',
                                            onDone: {
                                                target: 'daily_challenge_level_up_decision'
                                            },
                                            states: {
                                                daily_challenge_success_animation: {
                                                    initial: 'daily_challenge_success_animation_playing',
                                                    states: {
                                                        daily_challenge_success_animation_playing: {
                                                            entry: ['displayDailyChallengeSuccessAnimationScreen'],
                                                            on: {
                                                                DAILY_CHALLENGE_SUCCESS_ANIMATION_FINISHED:
                                                                    'daily_challenge_success_animation_finished'
                                                            }
                                                        },
                                                        daily_challenge_success_animation_finished: {
                                                            type: 'final'
                                                        }
                                                    }
                                                },
                                                daily_challenge_success_data_sync: {
                                                    initial: 'daily_challenge_success_saving',
                                                    states: {
                                                        daily_challenge_success_saving: {
                                                            invoke: {
                                                                id: 'setDailyChallengeCompletedSuccessful',
                                                                src: 'setDailyChallengeCompletedSuccessful',
                                                                onDone: 'daily_challenge_success_data_sync_finished',
                                                                onError: {
                                                                    target: '#request_error',
                                                                    actions: ['assignErrors']
                                                                }
                                                            }
                                                        },
                                                        daily_challenge_success_data_sync_finished: {
                                                            type: 'final'
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        daily_challenge_level_up_decision: {
                                            entry: ['setPlayerPointsTypeDailyChallenge'],
                                            always: [
                                                {
                                                    target: '#popup_scope.player_progression',
                                                    actions: ['setPlayerProgressionStartupDailyChallenge', 'setPlayerProgressDisplayedPoints'],
                                                    cond: 'hasLevelUpHappened'
                                                },
                                                {
                                                    target: '#home_entry',
                                                    actions: ['setShowSmallPointsAnimation', 'setPlayerSmallPointsDisplayedPoints', 'presentToastWithSmallPointsAnimation'],
                                                    cond: 'hasLevelUpNotHappened'
                                                }
                                            ],
                                            exit: ['resetHasSeenNoteRMIForDailyChallenge']
                                        },
                                        daily_challenge_completed_failed: {
                                            entry: ['resetLoadingService', 'displayDailyChallengeSummaryScreen', 'setDailyChallengeFailed'],
                                            invoke: {
                                                id: 'setDailyChallengeCompletedFailed',
                                                src: 'setDailyChallengeCompletedFailed',
                                                onDone: {
                                                    target: 'daily_challenge_summary',
                                                    actions: 'resetHasSeenNoteRMIForDailyChallenge'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        daily_challenge_summary: {
                                            entry: ['loadingCompleted', 'displayDailyChallengeSummaryScreen', 'resetHasSeenNoteRMIForDailyChallenge', 'resetDailyChallengeFailed'],
                                            on: {
                                                GO_TO_HOME_TAB: '#home_entry'
                                            }
                                        }
                                    }
                                },
                                achievement_review: {
                                    id: 'achievement_review',
                                    initial: 'waiting_for_achievement_review_week_decision',
                                    onDone: {
                                        target: '#home.home_substate_history'
                                    },
                                    states: {
                                        waiting_for_achievement_review_week_decision: {
                                            always: [
                                                {
                                                    target: 'achievement_review_loading',
                                                    cond: 'isAchievementReviewWeekAndNotViewedOrIsLastWeekAndIsTrainingCompleted'
                                                    // cond: 'isAchievementReviewWeek'
                                                },
                                                {
                                                    target: 'achievement_review_completed',
                                                    actions: ['hideAchievementReviewAnimation']
                                                }
                                            ]
                                        },
                                        achievement_review_loading: {
                                            invoke: {
                                                id: 'requestAchievementReviewStats',
                                                src: 'requestAchievementReviewStats',
                                                onDone: [
                                                    {
                                                        target: 'achievement_review_screen',
                                                        cond: 'isLastWeekAndIsTrainingCompletedAndEOJFlowStarted'
                                                    },
                                                    {
                                                        target: 'achievement_review_completed',
                                                        cond: 'isLastWeekAndIsTrainingCompleted'
                                                    },
                                                    {
                                                        target: 'achievement_review_completed',
                                                        actions: ['showAchievementReviewAnimation']
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        achievement_review_screen: {
                                            entry: ['displayAchievementReviewScreen'],
                                            id: 'achievement_review_screen',
                                            on: {
                                                SAVE_ACHIEVEMENT_REVIEW_COMPLETED: {
                                                    target: 'achievement_review_completed_saving'
                                                },
                                                GO_TO_HOME_TAB: '#home.home_substate_history',
                                                GO_TO_END_OF_JOURNEY_CERTIFICATE: {
                                                    target: '#end_of_journey.end_of_journey_certificate_screen',
                                                    cond: 'isLastWeekAndIsTrainingCompleted',
                                                    actions: ['setCertificateStartupLocationEndOfJourney']
                                                }
                                            }
                                        },
                                        achievement_review_completed_saving: {
                                            invoke: {
                                                id: 'saveAchievementReviewCompleted',
                                                src: 'saveAchievementReviewCompleted',
                                                onDone: {
                                                    target: 'achievement_review_completed_screen'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        achievement_review_completed_screen: {
                                            entry: ['displayAchievementReviewCompletedScreen'],
                                            on: {
                                                GO_TO_SINGLE_TRAINING_TAB: {
                                                    target: '#single_training_entry'
                                                },
                                                GO_TO_HOME_TAB: '#home.home_substate_history'
                                            },
                                            exit: ['hideAchievementReviewAnimation']
                                        },
                                        achievement_review_animation: {
                                            entry: ['showAchievementReviewAnimation'],
                                            always: [
                                                {
                                                    target: 'achievement_review_completed'
                                                }
                                            ]
                                        },
                                        achievement_review_completed: {
                                            type: 'final'
                                        }
                                    }
                                },
                                end_of_journey: {
                                    initial: 'package_status_loading',
                                    id: 'end_of_journey',
                                    states: {
                                        package_status_loading: {
                                            invoke: {
                                                id: 'requestPackageStatus',
                                                src: 'requestPackageStatus',
                                                onDone: {
                                                    target: 'end_of_journey_certificate_loading'
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        end_of_journey_certificate_loading: {
                                            id: 'end_of_journey_certificate_loading',
                                            invoke: {
                                                id: 'requestCertificate',
                                                src: 'requestCertificate',
                                                onDone: [
                                                    {
                                                        target: 'end_of_journey_certificate_screen',
                                                        cond: 'isEOJCertificateStartupLocationHome'
                                                    },
                                                    {
                                                        target: 'end_of_journey_screen'
                                                    }
                                                ],
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        end_of_journey_screen: {
                                            entry: ['displayEndOfJourneyScreen'],
                                            id: 'end_of_journey_screen',
                                            on: {
                                                GO_TO_ACHIEVEMENT_REVIEW: {
                                                    target: '#achievement_review'
                                                    // target: 'end_of_journey_certificate_screen'
                                                }
                                            }
                                        },
                                        end_of_journey_certificate_screen: {
                                            entry: ['displayEndOfJourneyCertificateScreen'],
                                            on: {
                                                GO_TO_CONGRATULATIONS_SCREEN: 'end_of_journey_congratulations_screen',
                                                BACK: [
                                                    {
                                                    target: '#multi_package_hub.multi_package_hub_screen',
                                                    cond: 'isCertificateStartupLocationMultiPackageHub',
                                                    },
                                                    {
                                                        target: '#home_entry',
                                                        actions: 'isCertificateStartupLocationEndOfJourney',
                                                    }
                                                ],
                                                GO_TO_HOME_TAB: {
                                                    target: '#home_entry'
                                                }
                                                // SEND_CERTIFICATE_TO_EMAIL: 'send_certificate_to_email',
                                            },
                                            exit: ['resetEOJFlowStarted']
                                        },
                                        end_of_journey_congratulations_screen: {
                                            entry: ['displayEndOfJourneyCongratulationsScreen'],
                                            on: {
                                                GO_TO_LIBRARY_OVERVIEW: {
                                                    target: '#journey_entry',
                                                    actions: ['setLibraryStartupLocationEndOfJourneyCongratulations', 'setGoToLibraryAfterLoadingFlag']
                                                },
                                                GO_TO_HOME_TAB: {
                                                    target: '#home_entry'
                                                }
                                            }
                                        }
                                    }
                                },
                                feedback_voting: {
                                    id: 'feedback_voting',
                                    initial: 'feedback_voting_screen',
                                    states: {
                                        feedback_voting_screen: {
                                            entry: ['loadingCompleted', 'displayFeedbackVotingScreen'],
                                            on: {
                                                FEEDBACK_VOTING_GIVEN: [
                                                    {
                                                        target: 'user_praisal_screen',
                                                        actions: ['sendFeedbackOnMicrotraining']
                                                    }
                                                ],
                                                SKIP_FEEDBACK_VOTING: '#single_training.single_training_completion_saving',
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet']
                                                }
                                            }
                                        },
                                        user_praisal_screen: {
                                            entry: ['displayFeedbackUserPraisalScreen'],
                                            on: {
                                                COMPLETE_FEEDBACK_VOTING: '#single_training.single_training_completion_saving'
                                            }
                                        }
                                    }
                                },
                                profile_entry: {
                                    id: 'profile_entry',
                                    type: 'parallel',
                                    entry: ['resetLoadingService', 'displayProfileScreen', 'startConsoleLogTimer'],
                                    states: {
                                        profile_insights_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestProfileInsights',
                                                        src: 'requestProfileInsights',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        skill_type_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestSkillType',
                                                        src: 'requestSkillType',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        profile_stats_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestProfileStats',
                                                        src: 'requestProfileStats',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        profile_feedback_link_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestFeedbackLink',
                                                        src: 'requestFeedbackLink',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }                                                      
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        user_details_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestUserDetails',
                                                        src: 'requestUserDetails',
                                                        onDone: {
                                                            target: 'success',
                                                            actions: ['updateUserDetails']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }                                                     
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        available_avatars_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestAvailableAvatars',
                                                        src: 'requestAvailableAvatars',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }                                                      
                                                    },
                                                },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        skills_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestSkillsPackage',
                                                        src: 'requestSkillsPackage',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                    },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        feedback_profile_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestFeedbackProfile',
                                                        src: 'requestFeedbackProfile',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                    },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        loading_multi_package_hub: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestMultiPackageHub',
                                                        src: 'requestMultiPackageHub',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                    },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                        package_status_loading: {
                                            initial:'loading',
                                            states: {
                                                loading: {
                                                    invoke: {
                                                        id: 'requestPackageStatus',
                                                        src: 'requestPackageStatus',
                                                        onDone: {
                                                            target: 'success'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    },
                                                    },
                                                success: {
                                                    type: 'final'
                                                }
                                            }
                                        },
                                    },
                                    onDone: [
                                        {
                                            target: '#profile.profile_tutorial',
                                            cond: 'hasUserNotSeenProfileTurorial'
                                        },
                                        {
                                            target: '#profile.profile_screen',
                                            cond: 'isProfileSectionSetToProfile'
                                        },
                                        {
                                            target: '#profile.profile_substate_history',
                                            cond: 'isProfileSectionSetToRelyOnSubstateHistory'
                                        },
                                        {
                                            target: '#profile',
                                        }
                                    ],
                                    exit: ['stopConsoleLogTimer']
                                },
                                profile: {
                                    id: 'profile',
                                    initial: 'profile_screen',
                                    entry: ['loadingCompleted', 'resetProfileSectionToRelyOnSubstateHistory'],
                                    on: {
                                        GO_TO_SINGLE_TRAINING_TAB: 'single_training_entry',
                                        GO_TO_GROUP_TRAINING_TAB: 'group_training_entry',
                                        GO_TO_HOME_TAB: 'home_entry',
                                        GO_TO_PROFILE_TAB: 'profile_entry',
                                        GO_TO_JOURNEY_TAB: '#journey_entry',
                                        GO_TO_SETTINGS: {
                                            target: 'profile.settings',
                                            actions: ['setTextualNoteModalStartupLocationSettings']},
                                        GO_TO_MULTI_PACKAGE_HUB: {
                                            target: '#multi_package_hub',
                                        },
                                        GO_TO_PACKAGE_HISTORY_PAGE: {
                                            target: '#package_history_entry',
                                            actions: ['setPackageHistoryStartupLocationProfile']
                                        },
                                        GO_TO_FEEDBACK_PROFILE: {
                                            target:'profile.feedback_profile',
                                            actions: ['setFeedbackProfileStartupLocationProfile']
                                        },
                                    },
                                    states: {
                                        profile_substate_history: {
                                            type: 'history',
                                            history: 'deep'
                                        },

                                        profile_screen: {
                                            entry: ['displayProfileScreen'],
                                            on: {
                                                GO_TO_HISTORY_TRAINING: {
                                                    target: '#single_training.single_training_content_loading',
                                                    actions: ['setProfileTrainingMode']
                                                },
                                                SHOW_SKILL_TYPE: {
                                                    target: '#pretraining.skill_type_explanation_screen',
                                                    actions: ['setSkillTypeStartupLocationProfil']
                                                },
                                                RMI_REQUIREMENTS_MET: {
                                                    target: 'profile_rmi_sending',
                                                    actions: ['setRMIName']
                                                },
                                            }
                                        },
                                        profile_rmi_sending: {
                                            entry: ['resetProgressDetails'],
                                            invoke: {
                                                id: 'sendRMIRequirementsMetWithLevelUpChance',
                                                src: 'sendRMIRequirementsMetWithLevelUpChance',
                                                onDone: [
                                                    {
                                                        target: 'profile_level_up_decision',
                                                        cond: 'hasUserReceivedPoints'
                                                    },
                                                    {
                                                        target: 'profile_screen'
                                                    }
                                                ]
                                                ,
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        profile_level_up_decision: {
                                            entry: ['setPlayerPointsTypeRMI'],
                                            always: [
                                                {
                                                    target: '#popup_scope.player_progression',
                                                    actions: ['setPlayerProgressionStartupRMI', 'setPlayerProgressDisplayedPoints', 'setPlayerProgressionHasStarted'],
                                                    cond: 'hasPlayerProgressionNOTStartedAndHasLevelUpHappened'
                                                },
                                                {
                                                    target: 'profile_screen',
                                                    actions: ['setShowSmallPointsAnimation', 'setPlayerSmallPointsDisplayedPoints', 'presentToastWithSmallPointsAnimation'],
                                                    cond: 'hasLevelUpNotHappened'
                                                },
                                                {
                                                    target: 'profile_screen'
                                                }
                                            ]                                
                                        },
                                        profile_tutorial: {
                                            entry: ['displayProfileScreen', 'saveProfileTutorialViewed'],
                                            on: {
                                                CLOSE_PROFILE_TUTORIAL: 'profile_screen'
                                                }
                                        },
                                        settings: {
                                            id: 'settings',
                                            initial: 'load_all_settings',
                                            states: {
                                                load_all_settings: {
                                                    entry: ['resetLoadingService', 'displaySettingsScreen'],
                                                    invoke: {
                                                        id: 'requestUserSettings',
                                                        src: 'requestUserSettings',
                                                        onDone: {
                                                            target: 'load_avatar',
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }   
                                                },
                                                load_settings: {
                                                    entry: ['resetLoadingService', 'displaySettingsScreen'],
                                                    invoke: {
                                                        id: 'requestUserSettings',
                                                        src: 'requestUserSettings',
                                                        onDone: {
                                                            target: 'settings_screen',
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                load_avatar: {
                                                    entry: ['resetLoadingService', 'displaySettingsScreen'],
                                                    invoke: {
                                                        id: 'requestUserDetails',
                                                        src: 'requestUserDetails',
                                                        onDone: {
                                                            target: 'settings_screen',
                                                            actions: ['updateUserDetails']
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                                settings_screen: {
                                                    entry: ['displaySettingsScreen','loadingCompleted'],
                                                    on: {
                                                        BACK: {
                                                            target: '#profile',
                                                            actions: ['setTextualNoteModalStartupLocationDefault'],
                                                        },
                                                        GO_TO_CONTACT:'settings_contact',
                                                        SAVE_CHANGE_SCHEDULE: {
                                                            target: 'settings_change_schedule_saving',
                                                        },
                                                        SAVE_CHANGE_HAPTICS_ENABLED: {
                                                            actions: ['saveChangeHapticsEnabled'],
                                                        },
                                                        CHANGE_AVATAR: 'settings_change_avatar',
                                                        SHOW_TEXTUAL_NOTE_MODAL: {
                                                            target: 'textual_note_modal'
                                                        },
                                                        GO_TO_SELF_SERVICE_SUMMARY: {
                                                            target: '#main_scope.self_service',
                                                            actions: ['setSelfServiceStartupLocationSettings', 'loadSelfServiceTeamMembers']
                                                        },
                                                        LOGOUT: {
                                                            target: '#login_screen',
                                                            actions: ['setProfileSectionToProfile','triggerLogout']
                                                        }
                                                    }
                                                },
                                                settings_change_avatar: {
                                                    entry: ['displayChangeAvatarScreen'],
                                                    on: {
                                                        BACK: 'settings_screen',
                                                        SAVE_AVATAR_SELECTION: 'settings_change_avatar_saving'
                                                    }
                                                },
                                                settings_change_avatar_saving: {
                                                    entry: ['resetLoadingService'],
                                                    invoke: {
                                                        id: 'saveAvatarSelection',
                                                        src: 'saveAvatarSelection',
                                                        onDone: {
                                                            target: 'load_avatar',
                                                            actions: ['updateAvatar']
                                                        }
                                                    }
                                                },
                                                settings_change_schedule_saving: {
                                                    entry: ['resetLoadingService'],
                                                    invoke: {
                                                        id: 'saveChangeSchedule',
                                                        src: 'saveChangeSchedule',
                                                        onDone: {
                                                            target: 'load_settings',
                                                        }
                                                    }
                                                },
                                                settings_contact: {
                                                    entry: ['displayContactScreen'],
                                                    on: {
                                                        BACK: 'settings_screen'
                                                    }
                                                },
                                                textual_note_modal: {
                                                    entry: ['resetRMIsPossibleForNote'],
                                                    always: [
                                                        {
                                                            target: '#popup_scope.textual_note',
                                                            actions: ['setTextualNoteModalShown', 'displayTextualNoteModal', 'loadingCompleted'],
                                                            cond: 'textualNoteModalNotShown'
                                                        },
                                                        {
                                                            target: 'textual_note_saving',
                                                            actions: ['resetTextualNoteModalShown', 'resetLoadingService', 'closeTextualNoteModal'],
                                                            cond: 'shouldAppFeedbackBeSaved'
                                                        },
                                                        {
                                                            target: 'settings_screen',
                                                            actions: ['resetTextualNoteModalShown', 'closeTextualNoteModal']
                                                    
                                                        }
                                                    ]
                                                },
                                                textual_note_saving: {
                                                    invoke: {
                                                        id: 'sendFeedback',
                                                        src: 'sendFeedback',
                                                        onDone: {
                                                            target: 'settings_screen'
                                                        },
                                                        onError: {
                                                            target: '#request_error',
                                                            actions: ['assignErrors']
                                                        }
                                                    }
                                                },
                                            },
                                        },
                                        feedback_profile: {
                                            id: 'feedback_profile',
                                            initial: 'feedback_profile_screen',
                                            states: {
                                                feedback_profile_screen: {
                                                    entry: ['displayFeedbackProfileScreen'],
                                                    on: {
                                                        BACK: [
                                                                {
                                                                    target:'#multi_package_hub.package_history_screen',
                                                                    cond: 'isFeedbackProfileStartupLocationMultiPackageHub'
                                                                },
                                                                {
                                                                    target:'#profile.profile_screen',
                                                                    cond: 'isFeedbackProfileStartupLocationProfile'
                                                                },
                                                        ],
                                                        SAVE_TEXT_FEEDBACK_VISITED: {
                                                            actions: ['sendFeedbackProfileVisited']
                                                        },
                                                        GO_TO_BEHAVIOR_FEEDBACK: {
                                                            target: 'behavior_feedback_screen',
                                                        },
                                                        GO_TO_SHARE_FEEDBACK_PROFILE: {
                                                            target: 'sharing_feedback_modal'
                                                        }
                                                    }
                                                },
                                                behavior_feedback_screen: {
                                                    entry: ['displayBehaviorFeedbackScreen'],
                                                    on: {
                                                        BACK: 'feedback_profile_screen',
                                                        SAVE_BEHAVIOR_FEEDBACK_VISITED: {
                                                            actions: ['sendBehaviorFeedbackVisited']
                                                        }
                                                    }
                                                },
                                                sharing_feedback_modal: {
                                                    entry: ['loadProfileForSharing'],
                                                    on: {
                                                        BACK: 'feedback_profile_screen'
                                                    }
                                                },
                                            },
                                        }
                                    }
                                },
                                tour_guide: {
                                    id: 'tour_guide',
                                    initial: 'space_map_intro_animation',
                                    states: {
                                        space_map_intro_animation: {
                                            entry: ['displaySpaceMapScreen'],
                                            on: {
                                                CONTINUE:  {
                                                    target: 'space_map_user_exploration',
                                                },
                                            }
                                        },
                                        space_map_user_exploration: {
                                            entry: ['openPlanet2Tooltip'],
                                            on: {
                                                CLOSE_SPACE_MAP: {
                                                    target: 'home_screen_launch_journey',
                                                },
                                            }
                                        },
                                        home_screen_launch_journey: {
                                            entry: ['displayHomeScreen', 'openJourneyTabTooltip'],
                                            on: {
                                                GO_TO_JOURNEY_TAB: {
                                                    target: '#journey_entry', // This state jumps into the journey_entry which loads the journey screen, after the Journey is loaded it will jump into the journey_achievement_tooltip state 
                                                    actions:['openTabBar'] 
                                                },
                                            },
                                        },
                                        journey_achievement_tooltip: {
                                            entry: ['displayJourneyScreen','openJourneyAchievementsTooltip', 'closeTabBar'],
                                            on: {
                                                GO_TO_ACHIEVEMENTS: {
                                                    target: 'achievements_animation',
                                                },
                                            }
                                        },
                                        achievements_animation: {
                                            entry: ['displayAchievementsScreen','startAchievementsAnimation'],
                                            on: {
                                                CONTINUE:{
                                                    target:'achievements_tooltip',
                                                    actions: ['stopAchievementsAnimation']
                                                },
                                            },
                                        },
                                        achievements_tooltip: {
                                            entry: ['openAchievementsTooltip'],
                                            on: {
                                                NEXT: {
                                                    target: 'achievement_exploration',
                                                },
                                                SHOW_ACHIEVEMENT_DETAIL_MODAL: 'achievement_detail_modal'
                                            },
                                        },
                                        achievement_exploration: {
                                            on:{
                                                SHOW_ACHIEVEMENT_DETAIL_MODAL: 'achievement_detail_modal'
                                            }
                                        },
                                        achievement_detail_modal: {
                                            entry: ['closeAchievementsTooltip'],
                                            on: {
                                                CLOSE_ACHIEVEMENT_DETAIL_MODAL: 'achievement_back_tooltip'
                                            },
                                        },
                                        achievement_back_tooltip: {
                                            entry:['openAchievementsBackTooltip'],
                                            on: {
                                                BACK: {
                                                    target: '#journey_entry',  // Apperantly you need to reload the journey screen to assure the SpaceMapTooltip is displayed correctly
                                                    actions: ['openJourneySpacemapTooltip']
                                                }
                                            }, 
                                        },
                                        journey_spacemap_tooltip: {
                                            entry: ['displayJourneyScreen'],
                                            on: {
                                                CONTINUE: [
                                                    {
                                                        target:'journey_hometab_tooltip_prestart',
                                                        cond: 'isBeforeStartDate'
                                                    },
                                                    {
                                                        target:'journey_hometab_tooltip'
                                                    }
                                                ]
                                            }
                                        },
                                        journey_hometab_tooltip_prestart: {
                                            entry: ['openPreStartHomeTabTooltip'],
                                            on: {
                                                CONTINUE:  'save_tour_guide_viewed_pre_date'
                                            }
                                        },
                                        save_tour_guide_viewed_pre_date: {
                                            invoke: {
                                                id: 'saveTourGuideViewed',
                                                src: 'saveTourGuideViewed',
                                                onDone: {
                                                    target: '#journey_entry',
                                                    actions:['resetShowTourGuide']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        },
                                        journey_hometab_tooltip: {
                                            entry: ['openHomeTabTooltip'],
                                            on: {
                                                GO_TO_HOME_TAB: {
                                                    target: 'save_tour_guide_viewed',
                                                }
                                            }
                                        },
                                        save_tour_guide_viewed: {
                                            invoke: {
                                                id: 'saveTourGuideViewed',
                                                src: 'saveTourGuideViewed',
                                                onDone: {
                                                    target: '#home_entry',
                                                    actions:['resetShowTourGuide']
                                                },
                                                onError: {
                                                    target: '#request_error',
                                                    actions: ['assignErrors']
                                                }
                                            }
                                        }
                                    }
                                },
                                microtraining_free: {},
                                package_end: {}
        }
                        },
                        popup_scope: {
                            id: 'popup_scope',
                            states: {
                                popup_scope_substate_history: {
                                    type: 'history',
                                    history: 'deep'
                                },
                                player_progression: {
                                    id: 'player_progression',
                                    initial: 'player_progression_screen',
                                    states: {
                                        player_progression_screen: {
                                            entry: ['displayPlayerProgressionScreen','resetHasUserSeenStreakCountPointAnimation'],
                                            on: {
                                                START_PLAYER_PROGRESS_ANIMATION: {
                                                    actions: ['startPlayerProgressAnimation']
                                                },
                                                SKIP_PROGRESSION_COMPONENT: {
                                                    actions: ['setSkippAllAnimationsProgressionComponent']
                                                },
                                                GO_TO_HOME_TAB:[
                                                    {
                                                        target: '#logged_in.main_scope.streak_count.streak_decision',
                                                        actions:['resetCameFromStreakCounter', 'resetShowRestOfPlayerProgressionUpdate', 'resetPlayerProgressLevelUp'],
                                                        cond:'cameFromStreakCounter'
                                                    },
                                                    {
                                                        target: '#logged_in.main_scope.main_scope_substate_history',
                                                        actions: ['resetShowRestOfPlayerProgressionUpdate', 'resetPlayerProgressLevelUp'],
                                                        cond: 'isPlayerProgressionStartupFromRMI'
                                                    },
                                                    {
                                                        target: '#logged_in.main_scope.daily_challenge.daily_challenge_summary',
                                                        actions: ['resetShowRestOfPlayerProgressionUpdate', 'resetPlayerProgressLevelUp'],
                                                        cond: 'isDailyChallengeFailed'
                                                    },
                                                    {
                                                        target: '#logged_in.main_scope.home_entry',
                                                        actions:  ['resetShowRestOfPlayerProgressionUpdate', 'resetPlayerProgressLevelUp']
                                                    }
                                                ],
                                                OPEN_SPACE_MAP_LEVEL_UP: {
                                                    target: '#popup_scope.space_map',
                                                    actions: ['setSpaceMapShowLevelUp']
                                                }
                                            },
                                            exit: ['resetSkippAllAnimationsProgressionComponent']
                                        }
                                    }
                                },
                                space_map: {
                                    id: 'space_map',
                                    initial: 'space_map_screen',
                                    states: {
                                        space_map_screen: {
                                            entry: ['displaySpaceMapScreen'],
                                            on: {
                                                CLOSE_SPACE_MAP: {
                                                    target: '#logged_in.main_scope.main_scope_substate_history'
                                                },
                                                RETURN_FROM_LEVEL_UP: {
                                                    target: '#popup_scope.player_progression',
                                                    actions: ['resetSpaceMapShowLevelUp', 'setShowRestOfPlayerProgressionUpdate']
                                                }
                                            }
                                        }
                                    }
                                },
                                textual_note: {
                                    id: 'textual_note',
                                    initial: 'textual_note_modal',
                                    states: {
                                        textual_note_modal: {
                                            on: {
                                                CLOSE_TEXTUAL_NOTE_MODAL: {
                                                    target: '#logged_in.main_scope.main_scope_substate_history'
                                                },
                                                SAVE_TEXTUAL_NOTE: {
                                                    target: '#logged_in.main_scope.main_scope_substate_history',
                                                    actions: ['requestNoteHistory']
                                                },
                                                RMI_REQUIREMENTS_MET: {
                                                    actions: ['sendRMIRequirementsMet']
                                                },
                                                RMI_REQUIREMENTS_MET_FAKE: {
                                                    actions: ['sendFakeRMIRequirementsMet']
                                                },
                                                SEND_FEEDBACK: {
                                                    target: '#logged_in.main_scope.main_scope_substate_history'
                                                },
                                                SAVE_HISTORIC_NOTE: {
                                                    target: '#logged_in.main_scope.main_scope_substate_history',
                                                    actions: ['resetShouldNoteBeSaved', 'resetShouldNoteBeSaved']
                                                },
                                                GO_TO_HISTORY_TRAINING: { 
                                                    target: '#logged_in.main_scope.single_training.single_training_content_loading',
                                                    actions: ['setNoteHistoryTrainingMode', 'closeTextualNoteModal' ,'resetTextualNoteModalShown'] 
                                                }, 
                                                GO_TO_DAILY_CHALLENGE_HISTORY: {
                                                    target: '#journey.daily_challenge_loading',
                                                    actions: ['setDailyChallengeStartupLocationNotes', 'resetTextualNoteModalShown', 'closeTextualNoteModal']
                                                },
                                            }
                                        }
                                    } 
                                }
                            }
                        }
                    }
                }             
            }
        },
        request_error: {
            entry: ['resetFullScreenLoadingSpinnerActive'],
            id: 'request_error',
            /*entry: ['displayRequestErrorScreen'],*/
            initial: 'waiting_for_error_type_decision',
            states: {
                waiting_for_error_type_decision: {
                    always: [
                        {
                            target: 'save_error_data',
                            cond: 'isNotWebError'
                        },
                        {
                            target: 'internet_problem_modal',
                            cond: 'isInternetProblem'
                        },
                        {
                            target: 'error_screen',
                            actions: ['setKnownErrorCode'],
                            cond: 'isKnownError'
                        },
                        {
                            target: 'save_error_data'
                        }
                    ]
                },
                internet_problem_modal: {
                    entry: ['displayInternetProblemModal'],
                    on: {
                        RETRY: {
                            target: '#logged_in.logged_in_substate_history',
                            actions: ['setIsAppCurrentlyRetryingRequestsTrue']
                        }
                    }
                },
                save_error_data: {
                    invoke: {
                        id: 'saveErrorData',
                        src: 'saveErrorData',
                        onDone: 'error_screen'
                    }
                },
                error_screen: {
                    entry: ['displayErrorScreen'],
                    on: {
                        LOGOUT: {
                            target: '#login_screen',
                            actions: ['triggerLogout']
                        }
                    }
                }
            }
        }
    }
}