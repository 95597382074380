<ion-header [class]="headerBackgroundClass">
	<ion-toolbar mode="ios" class="savvi-header">
		<ion-buttons *ngIf="backButtonIcon == 'backarrow'" slot="start">
			<ion-button class="headerbutton ion-gloat-left" fill="clear" (click)="backButtonCallback()" id="backButton" aria-label="backButton">
				<ion-icon [class]="backButtonIconClass" name="chevron-back-outline"></ion-icon>
			</ion-button>
		</ion-buttons>
		<ng-container *ngIf="title">
			<ion-title class="sav-title-3">
				<div class="week-header">
					<span class="week-header-text" style="background-color: white" [innerHTML]="title"> </span>
				</div>
			</ion-title>
		</ng-container>
		<ion-buttons *ngIf="backButtonIcon == 'close'" slot="end">
			<ion-button class="headerbutton ion-float-right" fill="clear" (click)="backButtonCallback()" id="closeButton" aria-label="closeButton">
				<ion-icon [class]="backButtonIconClass" name="close"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
