import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { SavviMachineService } from '@app/_xstate/savvi-machine.service';
import { Logout } from '@app/_xstate/savvi-machine.events';
import { AuthenticationService } from '@app/_services/authentication.service';
import { VaultService } from '@app/_services/vault.service';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		private vaultService: VaultService,
		private savviMachineService: SavviMachineService,
		private platform: Platform
	) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return true;
		/*const user = this.authenticationService.userValue;
        const refreshToken = localStorage.getItem('refreshToken');
        if (user && refreshToken) {
            // logged in so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            this.savviMachineService.send(new Logout(/*state.url));
            
            //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }*/

		console.log('isUserAuthenticated called by authgard for route ' + this.getResolvedUrl(route) + ' and returned ' + (await this.authenticationService.isUserAuthenticated()));
		console.log('The vault isLocked state returned ' + (await this.vaultService.isLocked()));
		console.log('The keys in the vault are: ' + JSON.stringify(await this.vaultService.getKeys()));
		if (/*this.platform.is('hybrid') ? await this.authenticationService.isRefreshTokenAvailable() : */ await this.authenticationService.isUserAuthenticated()) {
			return true;
		} else {
			// not logged in so redirect to login page
			console.log('AuthGuard sends Logout Event because no refreshToken available.');
			this.savviMachineService.send(new Logout());
			return false;
		}
	}

	getResolvedUrl(route: ActivatedRouteSnapshot): string {
		return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
	}
}
