import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GroupMatching } from '../_models/group-matching';
import { Group } from '../_models/group';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { PackageService } from './package.service';
import { SingleTrainingService } from './single-training.service';
import { GroupTrainingContent } from '../_models/group-training-content';
import { MicrotrainingsSelection } from '@app/_models/microtrainings-selection';

@Injectable({
	providedIn: 'root'
})
export class GroupTrainingService {
	private groupMatching: GroupMatching;
	private groupTrainingContent: GroupTrainingContent;

	constructor(
		private http: HttpClient,
		private apiService: ApiService,
		private packageService: PackageService,
		private singleTrainingService: SingleTrainingService
	) {}

	loadGroupMatching(packageId: number, forceMatchingForDemoUser: boolean): Promise<GroupMatching> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		params = params.set('force', forceMatchingForDemoUser.toString());
		return this.apiService
			.get<GroupMatching>('/microtrainings/grouptraining/matching', params)
			.pipe(map((groupMatching) => (this.groupMatching = groupMatching)))
			.toPromise();
	}

	loadGroupTrainingContent(microtrainingId: number): Promise<GroupTrainingContent> {
		let params = new HttpParams();
		params = params.set('microtraining_id', microtrainingId.toString());
		return this.apiService
			.get<GroupTrainingContent>('/microtrainings/grouptraining/content', params)
			.pipe(map((groupTrainingContent) => (this.groupTrainingContent = groupTrainingContent)))
			.toPromise();
	}

	saveGroupTrainingCompleted(): Promise<any> {
		// Demo Users use Previous Week Microtraining
		const microtrainingId = this.singleTrainingService.getCurrentWeekStatus().microtraining
			? this.singleTrainingService.getCurrentWeekStatus().microtraining.pk
			: this.singleTrainingService.getPreviousWeekStatus().microtraining.pk;
		const postData = {
			package_id: this.packageService.getCurrentPackage().pk,
			week: this.singleTrainingService.getCurrentWeekStatus().week,
			microtraining_id: microtrainingId
		};
		return this.apiService
			.post<MicrotrainingsSelection>(`/microtrainings/grouptraining/completed`, postData)
			.pipe(
				map((microtrainingSelection) => {
					this.singleTrainingService.setCurrentWeekStatus(microtrainingSelection);
				})
			)
			.toPromise();
	}

	getGroupTrainingContent(): GroupTrainingContent {
		return this.groupTrainingContent;
	}

	getGroup(userId: number): Group {
		return this.groupMatching.groups.find((match) => match.group.find((membership) => membership.user.id === userId));
	}

	getCurrentWeekCompleted(): boolean {
		return this.singleTrainingService.getCurrentWeekStatus().group_training_completed;
	}
}
