import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import Config from '../../config.json';
import { from, mergeMap, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services/authentication.service';
import { AuthService } from '@app/_services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	apiUrl = Config && Config.API_URL && Config.API_URL != '$API_URL' ? Config.API_URL : environment.apiUrl;

	constructor(private authenticationService: AuthenticationService) {}

	/*intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authenticationService.userValue;
        const isLoggedIn = user && user.access;
        const isApiUrl = request.url.startsWith(this.apiUrl);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${user.access}` }
            });
        }

        return next.handle(request);
    }*/

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.containsNonAuth(req)) {
			return next.handle(req);
		} else {
			//const isLoggedIn = this.authenticationService.isUserAuthenticated(); //RefreshTokenAvailable();
			const isApiUrl = req.url.startsWith(environment.apiUrl);

			return from(
				isApiUrl /*&& isLoggedIn*/
					? this.authenticationService.getAccessToken().then((token) => {
							if (token) {
								req = req.clone({
									setHeaders: {
										// eslint-disable-next-line @typescript-eslint/naming-convention
										Authorization: 'Bearer ' + token
									}
								});
							}
					  })
					: Promise.resolve()
			).pipe(mergeMap(() => next.handle(req)));
		}
	}

	containsNonAuth(req: HttpRequest<any>): boolean {
		if (req.url.includes('non-auth')) {
			return true;
		} else {
			return false;
		}
	}
}
