import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models/user';
import { ApiService } from './api.service';
import { ChangePassword } from '@app/_models/change-password';

@Injectable({ providedIn: 'root' })
export class AuthService {
	@Output() public triggerTimer: EventEmitter<any> = new EventEmitter();

	private userSubject: BehaviorSubject<User>;
	public user: Observable<User>;

	constructor(
		private router: Router,
		private apiService: ApiService
	) {
		this.userSubject = new BehaviorSubject<User>(null);
		this.user = this.userSubject.asObservable();
	}

	public get userValue(): User {
		return this.userSubject.value;
	}

	login(username: string, password: string): Promise<User> {
		let postData = {
			email: username,
			password: password
		};

		return this.apiService
			.post<User>(`/token`, postData)
			.pipe(
				map((user) => {
					this.userSubject.next(user);
					this.startRefreshTokenTimer();
					return user;
				})
			)
			.toPromise();
	}

	changePassword(oldPassword: string, newPassword: string): Promise<ChangePassword> {
		let postData = {
			old_password: oldPassword,
			new_password: newPassword
		};

		return this.apiService.put<ChangePassword>('/change_password', postData).toPromise();
	}

	logout() {
		//this.apiService.post<any>(`/users/revoke-token`, {}).subscribe();
		this.stopRefreshTokenTimer();
		this.userSubject.next(null);
		localStorage.removeItem('refreshToken');
		// done in extra action. this.router.navigate(['/login']);
	}

	refreshToken(refreshToken: string): Promise<User> {
		let postData = {
			refresh: refreshToken
		};

		return this.apiService
			.post<User>(`/token/refresh`, postData)
			.pipe(
				map((user) => {
					user.refresh = /*this.userValue.refresh || */ localStorage.getItem('refreshToken');
					this.userSubject.next(user);
					this.startRefreshTokenTimer();
					return user;
				})
			)
			.toPromise();
	}

	// helper methods

	private refreshTokenTimeout;

	private startRefreshTokenTimer() {
		// parse json object from base64 encoded jwt token
		const jwtToken = JSON.parse(atob(this.userValue.access.split('.')[1]));

		// set a timeout to refresh the token a minute before it expires
		const expires = new Date(jwtToken.exp * 1000);
		const timeout = expires.getTime() - Date.now() - 270 * 1000;
		this.refreshTokenTimeout = setTimeout(() => this.triggerTimer.emit(null), timeout); //this.savviMachineService.send(new RefreshLogin()), timeout)//this.refreshToken(this.userValue.refresh).subscribe(), timeout);
	}

	/*public triggerRefreshToken() : void{
        this.refreshToken(this.userValue.refresh).subscribe()
    }*/

	private stopRefreshTokenTimer() {
		clearTimeout(this.refreshTokenTimeout);
	}
}
