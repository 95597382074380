import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgressDetails } from '@app/_models/progress-details';
import { Question } from '@app/_models/question';
import { QuizData } from '@app/_models/quiz-data';
import { QuizQuestion } from '@app/_models/quiz-question';
import { QuizQuestionChoice } from '@app/_models/quiz-question-choice';
import { QuizQuestionChoiceResponse } from '@app/_models/quiz-question-choice-response';
import { UserQuizQuestionChoice } from '@app/_models/user-quiz-question-choice';
import { RMIRequierementsMet } from '@app/_xstate/savvi-machine.events';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { ApiService } from './api.service';
import { GamificationService } from './gamification.service';

@Injectable({
	providedIn: 'root'
})
export class QuizService {
	private quizData: QuizData;

	private quizQuestionExplanationShownSubject: BehaviorSubject<boolean>;
	quizQuestionExplanationShown: Observable<boolean>;

	currentQuestion: number = 0;

	getQuizData(): QuizData {
		return this.quizData;
	}

	constructor(
		private apiService: ApiService,
		private gamificationService: GamificationService
	) {
		this.quizQuestionExplanationShownSubject = new BehaviorSubject<boolean>(null);
		this.quizQuestionExplanationShown = this.quizQuestionExplanationShownSubject.asObservable();
	}

	loadWeeklyQuizData(microtrainingID: number): Promise<QuizData> {
		let params = new HttpParams();
		params = params.set('microtraining_id', microtrainingID.toString());

		return this.apiService
			.get<QuizData>('/quiz/progress', params)
			.pipe(map((quizData) => (this.quizData = quizData)))
			.toPromise();
	}

	loadQuizDataForRedoByUUID(quiUUID: string): Promise<QuizData> {
		let params = new HttpParams();
		params = params.set('quiz_uuid', quiUUID);

		return this.apiService
			.get<QuizData>('/history/redo/quiz', params)
			.pipe(map((quizData) => (this.quizData = quizData)))
			.toPromise();
	}

	isQuizAvailable(): boolean {
		if (this.quizData) {
			return true;
		} else {
			return false;
		}
	}

	setSelectedAnswer(userChoice: QuizQuestionChoice) {
		const newUserChoice = {
			question_id: this.quizData.quiz.questions[this.currentQuestion].id,
			progress_id: this.quizData.id,
			choice: userChoice
		};

		this.quizData.user_choices.push(newUserChoice);
	}

	getAllQuizQuestions(): QuizQuestion[] {
		return this.quizData.quiz.questions;
	}

	getCurrentQuestionID(): number {
		return this.quizData.quiz.questions[this.currentQuestion].id;
	}

	displayQuizQuestionExplanationModal() {
		this.quizQuestionExplanationShownSubject.next(true);
	}

	resetQuizQuestionExplanationModalShown() {
		this.quizQuestionExplanationShownSubject.next(false);
	}

	resetCurrentQuestion() {
		this.currentQuestion = 0;
	}

	isCurrentQuestionLastQuestion(): boolean {
		return this.currentQuestion + 1 == this.quizData.quiz.question_amount;
	}

	saveQuizResult(microtrainingID: number): Promise<any> {
		let params = new HttpParams();
		params = params.set('microtraining_id', microtrainingID.toString());

		const postData: QuizQuestionChoiceResponse[] = [];

		this.quizData.user_choices.forEach(function (choiceEntry) {
			postData.push({
				question_id: choiceEntry.question_id,
				progress_id: choiceEntry.progress_id,
				choice_id: choiceEntry.choice.id
			});
		});

		return this.apiService
			.post<ProgressDetails>('/quiz/progress', postData, params)
			.pipe(
				map((progressDetails) => {
					this.gamificationService.progressDetails = progressDetails;
				})
			)
			.toPromise();
	}
}
