import { Component, OnInit } from '@angular/core';
import { GamificationService } from '@app/_services/gamification.service';
import { RewardedMicroInteractionService } from '@app/_services/rewarded-micro-interaction.service';
import { TranslationService } from '@app/_services/translation.service';
import { SavviContext } from '@app/_xstate/savvi-context';

@Component({
	selector: 'app-rewarded-micro-interaction',
	templateUrl: './rewarded-micro-interaction.component.html',
	styleUrls: ['./rewarded-micro-interaction.component.scss']
})
export class RewardedMicroInteractionComponent implements OnInit {
	constructor(
		private gamificationService: GamificationService,
		private translationService: TranslationService,
		private rmiService: RewardedMicroInteractionService
	) {}

	ngOnInit() {}

	getPoints(): string {
		return '+' + this.rmiService.pointsGained + ' ' + this.translationService.translate.instant('xp');
	}

	getTitle(): string {
		return this.rmiService.toastTitle;
	}

	getDescription(): string {
		return this.rmiService.toastDescription;
	}

	isSublineAvailable(): boolean {
		if (this.rmiService.toastDescription) {
			return true;
		}

		return false;
	}

	isAchievement(): boolean {
		return this.rmiService.isToastForAchievement;
	}

	getAchievementIconPath(): string {
		return this.rmiService.achievementIconPath;
	}

	// Small points animation label

	isShowSmallPointsAnimation(): boolean {
		return this.gamificationService.isShowSmallPointsAnimation();
	}
	getScoreForPlayer(): string {
		if (this.gamificationService.getPlayerSmallPointsDisplayedPoints() != 0) {
			return this.gamificationService.getPlayerSmallPointsDisplayedPoints().toString();
		} else {
			return this.gamificationService.getPlayerProgressInfo().points.toString();
		}
	}
}
