import { Injectable, NgZone } from '@angular/core';
import {
  interpret,
  Machine,
  MachineOptions,
  State,
  assign,
  EventObject, DoneEvent
} from 'xstate';
import { SavviContext } from './savvi-context';
import { SavviEvent, CurrentGoalLoaded, LoadingFail, Login, LoginSuccessful, RefreshSuccessful, ShowExerciseStepFromExplanation,RefreshFail, RefreshLogin, Logout, UserStatsLoaded, UserDetailsLoaded, SingleTrainingContentLoaded, GroupTrainingContentLoaded, GroupMatchingLoaded, ShowGroupExerciseStep, PackagesLoaded, PretrainingQuestionAnswersSaved, SkillSelectionSaved, MicrotrainingSelectionSaved, SaveGoalSelection, ChangeCurrentPassword, SelectSkill, SelectMicrotraining, GoToLibraryTraining, SaveGenericGoalSelection, SaveAvatarSelection, SaveQuestionFeedback, SaveAchievementReviewCompleted, QuestionAnswerGiven, ShowMicrotrainingInfo, SaveLongTermGoalSelection, ShowJourneyBehaviorSelectionModification, SaveJourneyBehaviorSelectionModification, SetChallengeTimeAndPush, LoginNew, RMIRequierementsMet, RMIRequierementsMetFake, GoToQuizHistory, GoToTOTWHistory, LoadNotes, GoToDailyChallengeHistory, GoToHistoryTraining, SaveChangeSchedule, GoToDuelDetails, SendDuelCompletionViewed, SaveExerciseStepCompleted, RequestPackageHistory, RequestCertificate, SaveBehaviorFeedbackVisited, SaveChangeHapticsPreferences, SaveTextFeedbackVisited, SaveDailyChallengeStepCompleted, HandleError } from './savvi-machine.events';
import { context, savviMachineConfig } from './savvi-machine.config';
import { SavviSchema } from './savvi-schema';
import { forkJoin, fromEventPattern, Observable, of, pipe } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { SingleTrainingService } from '@app/_services/single-training.service';
import { AuthService } from '@app/_services/auth.service';
import { PushNotificationService } from '@app/_services/push-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '@app/_services/profile.service';
import { PackageService } from '@app/_services/package.service';
import { PretrainingService } from '@app/_services/pretraining.service';
import { GroupTrainingService} from '@app/_services/group-training.service';
import { LoaderService } from '@app/_services/loader.service';
import { TrainingMode } from '@app/_models/training-mode';
import { AchievementReviewService } from '@app/_services/achievement-review.service';

import { GamificationService } from '@app/_services/gamification.service';
import { JourneyService } from '@app/_services/journey.service';

import { TranslationService } from '@app/_services/translation.service';

import { FeedbackVotingService } from '@app/_services/feedback-voting.service';
import { QuizService } from '@app/_services/quiz.service';
import { PilotUserService } from '@app/_services/pilot-user.service';
import { TipOfTheWeekService } from '@app/_services/tip-of-the-week.service';
import { EndOfJourneyService } from '@app/_services/end-of-journey.service';
import { ProfileSection, ProfileSegment } from '@app/_models/profile-section';
import { DailyChallengeService } from '@app/_services/daily-challenge.service';
import { ErrorService } from '@app/_services/error.service';
import { SkillTypeStartupLocation } from '@app/_models/skill-type-startup-location';
import { AuthenticationService } from '@app/_services/authentication.service';
import { User } from '@app/_models/user';
import { SplashScreen } from '@capacitor/splash-screen';
import { RewardedMicroInteractionService } from '@app/_services/rewarded-micro-interaction.service';
import { PlayerPointType } from '@app/_models/player-progression-startup';
import { QuizStartupLocation } from '@app/_models/quiz-startup-location';
import { TOTWStartupLocation } from '@app/_models/totwstartup-location';
import { NotesService } from '@app/_services/notes.service';
import { DailyChallengeStartupLocation } from '@app/_models/daily-challenge-startup-location';
import { NoteResourceType } from '@app/_models/note-resource-type';
import { NoteActivityType } from '@app/_models/note-activity-type';
import { WeekHistoryActivityType } from '@app/_models/week-history-activity-type';
import { HomeService } from '@app/_services/home.service';
import { HistoryStartupLocation } from '@app/_models/history-startup-location';
import { LibraryStartupLocation } from '@app/library-start-up-location';
import { AchievementStartupLocation } from '@app/achievement-startup-location';
import { TourGuideService } from '@app/_services/tour-guide.service';
import { SettingsService } from '@app/_services/settings.service';
import { DuelService } from '@app/_services/duel.service';
import { DuelStartupLocation } from '@app/_models/duel-startup-location';
import { FeedbackProfileService } from '@app/_services/feedback-profile.service';
import { ExerciseService } from '@app/_services/exercise.service';
import { ExerciseProgressionStateName } from '@app/_models/progression-state';
import { MultiPackageHubService } from '@app/_services/multi-package-hub.service';
import { FeedbackProfileStartupLocation } from '@app/_models/feedback-profile-start-up-location';
import { LotteryService } from '@app/_services/lottery.service';
import { LeaderboardService } from '@app/_services/leaderboard.service';
import { ThemingService } from '@app/_services/theming.service';
import { AnimationFactoryService } from '@app/_services/animation-factory.service';
import { StartupLocationMicrotrainingHistory } from '@app/_models/startup-location-microtraining-history';
import { PackageHistoryStartupLocation } from '@app/_models/multi-package';
import { TextualNoteModalStartupLocation } from '@app/_models/textual-note-modal-startup-location';
import { LicenseSelfService } from '@app/_services/license-self-service.service';
import { SelfServiceStartupLocation } from '@app/_models/self-service-startup-location';
import { DataStorageService } from '@app/_services/data-storage.service';

@Injectable({
  providedIn: 'root'
})
export class SavviMachineService {
  savviMachineOptions: Partial<MachineOptions<SavviContext, SavviEvent>> = {
    services: {
      evaluateIsAuthenticated: (context, event) => {
        //console.log("isUserAuthenticated called by savvi machine service guard evaluateIsAuthenticatad");
        return this.authenticationService.isUserAuthenticated();
      },
      requestLogin: (context, event : Login) => {
        this.pushNotificationService.setExternalUserId(event.email);
        return this.authService.login(event.email, event.password)
          /*.pipe(
            map(user => new LoginSuccessful(user)),
            catchError(result => of(new LoadingFail(result.error)))
          );*/
      },
      requestLoginNew: (context, event: LoginNew) => {
        return this.authenticationService.login();
      },
      requestRefreshLogin: (context, event) => {
        const refreshToken = localStorage.getItem('refreshToken');
        return this.authService.refreshToken(refreshToken);
          /*.pipe(
            map(user => new RefreshSuccessful(user)),
            catchError((result : HttpErrorResponse) => of(new RefreshFail(result, this.router.url)))
          );*/
      },
      requestConfig: (context, event) => {
        console.log("requestConfig called by savvi machine service")
        return this.themingService.loadConfig();
      },
      loadAssets: (context, event) => {
        console.log('loadAssets called by savvi machine service');
        return this.themingService.loadAssets() 
      },
      requestPackages: (context, event) => {
        return this.packageService.loadPackages()
      },
      requestSelfServiceStatus: (context,event) => {
        console.log('requestSelfServiceStatus called by savvi machine service')
        return this.licenseSelfService.loadSubscriptionStatus();
      },
      requestSelfServiceData: (context,event) => {
        return this.licenseSelfService.loadSelfServicePackageCreation();
      },
      saveSelfServicePackageChange: (context, event) => {
        return this.licenseSelfService.changeSelfServicePackage();
      },
      createSelfServicePackage: (context, event) => {
        return this.licenseSelfService.createSelfServicePackage();
      },
      requestPretrainingStatus: (context, event) => {
        console.log('requestPretrainingStatus called by savvi machine service');

        if(!context.packages[0]) {
          return Promise.reject(new Error('Dem Nutzer ist kein Trainings-Package zugewiesen oder das zugewiesene Trainings-Package ist abgelaufen.'));
        }
        return this.questionService.loadPretrainingStatus(context.packages[0].pk);
      },
      savePasswordChange: (context, event : ChangeCurrentPassword) => {
        return this.authService.changePassword(event.oldPassword, event.newPassword);
      },
      requestLongTermGoals: (context, event) => {
        return this.packageService.loadLongTermGoals();
      },
      requestSkillsPackage: (context, event) => {
        return this.packageService.loadSkills();
      },
      requestPretrainingQuestions: (context, event) => {
        return this.questionService.loadPretrainingQuestions(context.packages[0].pk);
      },
      savePretrainingQuestionAnswers: (context, event) => {
        return this.questionService.savePretrainingQuestionAnswers()
          .pipe(
            map(something => new PretrainingQuestionAnswersSaved()),
            catchError(result => of(new LoadingFail(result.error)))
          );
      },
      saveJourneyBehaviorToGoalSelection: (context, event) => {
        return this.packageService.saveJourneyBehaviorToGoalSelection();
      },
      saveJourneyBehaviorSelectionModification: (context, event) =>{
        return this.packageService.saveJourneyBehaviorSelectionModification();
      },
      saveAvatarSelection: (context, event: SaveAvatarSelection) => {
        return this.profileService.saveAvatarSelection(event.avatar);
      },
      saveCalendarBlocker: (context, event) => {
        return this.pushNotificationService.saveCalendarBlocker((<SaveChangeSchedule>event).offset, (<SaveChangeSchedule>event).pushEnable);
      },
      saveChangeSchedule: (context, event:SaveChangeSchedule) => {
        return this.settingsService.saveUserNotificationSettings(event.offset, event.pushEnable);
      },
      savePretrainingCompleted: (context, event) => {
        return this.questionService.savePretrainingCompleted(context.packages[0].pk);
      },
      savePretrainingCompletedAfterStartDate: (context, event) => {
        return this.questionService.savePretrainingCompletedAfterStartDate(context.packages[0].pk);
      },
      saveQuestionFeedback: (context, event : SaveQuestionFeedback) => {
        return this.questionService.saveQuestionFeedback(context.packages[0].pk, event.feedback);
      },
      saveErrorData: (context, event) => {
        console.log("Error in Context: " + JSON.stringify(context.errors.error));
        console.log("Context: ", context);
        let errorMessage = '';

        if(context.errors.error.message && context.errors.error.stack) {
          console.log('ERROR MESSAGE: ' + context.errors.error.message);
          console.log('ERROR STACK: ' + context.errors.error.stack);
          errorMessage = context.errors.error.message + '    STACK:   ' + context.errors.error.stack;
        } else {
          errorMessage = context.errors.error.toString()
        }

        return this.errorService.saveError(errorMessage);
      },
      savePackageIntroCompleted: (context, event) => {
        return this.questionService.savePackageStartCompleted(context.packages[0].pk);
      },
      requestCurrentWeek: (context, event) => {
        return this.singleTrainingService.loadCurrentWeek(context.packages[0].pk)
      },
      requestCurrentWeekStatus: (context, event) => {
        return this.singleTrainingService.loadCurrentWeekStatus(context.packages[0].pk);
      },
      requestSkillSelection: (context, event) => {
        return this.singleTrainingService.loadSkillSelection();
      },
      requestFixedOrderStatus: (context, event) => {
        return this.singleTrainingService.loadFixedOrderStatus();
      },
      requestFixedOrderMicrotraining: (context, event) => {
        return this.singleTrainingService.loadFixedOrderMicrotraining();
      },
      requestMicrotrainingRecommendations: (context, event) => {
        return this.singleTrainingService.loadMicrotrainingRecommendations();
      },
      requestPreviousWeekStatus: (context, event) => {
        return this.singleTrainingService.loadPreviousWeekStatus(context.packages[0].pk);
      },
      requestCurrentGoal: (context, event) => {
      return this.singleTrainingService.loadCurrentGoal(context.packages[0].pk); //context.packages[0].pk
      },
      requestUserSettings: (context, event) => {
        return this.settingsService.loadUserSettings();
      },
      requestUserStats: (context, event) => {
        /*return this.profileService.loadUserStats(context.package.id)
      }/*{*/
        console.log('REQUEST USER STATS SERVICE CALLED')
        return this.profileService.loadUserStats(context.packages[0].pk)
        .then((statsPerWeek) => console.log(JSON.stringify(statsPerWeek)))
          .catch(
            (_)=>console.log('CATCH CALLED')
          )
          .finally(
            ()=>console.log('FINALLY CALLED')
          );
          /*.then((statsPerWeek) => {
            console.log('Will fire UserStatsLoaded now');
            new UserStatsLoaded(statsPerWeek)
          })
          .catch((result) => new LoadingFail(result.error.errors));*/
          /*.pipe(
            map(statsPerWeek => new UserStatsLoaded(statsPerWeek)),
            catchError(result => of(new LoadingFail(result.error.errors)))
          );  */
      },
      requestSkillType: (context, event) => {
        return this.profileService.loadSkillTypeReveal();
      },

      requestFeedbackLink: (context, event) => {
        return this.feedbackProfileService.loadFeedbackLink();
      },
      loadTrainingPlan: (context, event) =>{
        return this.packageService.loadTrainingPlan();
      },
      requestUserDetails: (context, event) => {
        return this.profileService.loadMe();
          /*.pipe(
            map(user => new UserDetailsLoaded(user)),
            catchError(result => of(new LoadingFail(result.error.errors)))
          );*/     
      },
      requestAvailableAvatars: (context, event) => {
        return this.profileService.loadAvailableAvatars();
      },
      requestHistory: (context, event) => {
        return this.journeyService.loadHistory();
        //return this.journeyService.loadMicrotrainingHistory(context.packages[0].pk);
      },
      requestNoteHistory: (context, event) => {
        return this.journeyService.loadNoteHistory();
        //return this.journeyService.loadMicrotrainingHistory(context.packages[0].pk);
      },
      requestProfileInsights: (context, event) => {
        return this.profileService.loadProfileInsights();
      },
      requestProfileStats: (context, event) => {
        return this.profileService.loadProfileStats();
      },
      requestFeedbackProfile: (context, event) => {
        return this.feedbackProfileService.loadFeedbackProfile();
      },
      requestMicrotrainingLibrary : (context, event) => {
        return this.singleTrainingService.loadMicrotrainingLibrary();
      },
      requestGenericGoalsSelection : (context, event) => {
        return this.packageService.loadGenericGoalsSelection();
      },
      requestMatchedGroup: (context, event) => {   
        return this.groupTrainingService.loadGroupMatching(context.packages[0].pk, context.user.user_config.is_demo_user)
      },
      requestGroupTrainingContent: (context, event) => {
        if(this.authService.userValue.user_config.is_demo_user){
          return this.groupTrainingService.loadGroupTrainingContent(this.singleTrainingService.getPreviousWeekStatus().microtraining.pk)
        }
        else{
          return this.groupTrainingService.loadGroupTrainingContent(this.singleTrainingService.getCurrentWeekStatus().microtraining.pk)
        }
      },
      saveGroupTrainingCompleted: (context, event) => {
        return this.groupTrainingService.saveGroupTrainingCompleted();
      },
      requestSkills: (context, event) => {
        return this.singleTrainingService.loadSkills();
      },
      saveSkillSelection: (context, event : SelectSkill) => {
        return this.singleTrainingService.selectSkill(event.skillPk);
          /*.pipe(
            map(_result => new SkillSelectionSaved()),
            catchError(result => of(new LoadingFail(result.error.errors)))
          );*/
      },
      requestMicrotrainingSuggestion: (context, event) => {
        return this.singleTrainingService.loadMicrotrainingSuggestion();
      },
      requestMicrotrainings: (context, event) => {
        return this.singleTrainingService.loadMicrotrainings();
      },
      selectSuggestedMicrotraining: (context, event) => {
        return this.singleTrainingService.selectSuggestedMicrotraining()
          .pipe(
            map(_result => new MicrotrainingSelectionSaved()),
            catchError(result => of(new LoadingFail(result.error.errors)))
          );
      },
      saveMicrotrainingSelection: (context, event : SelectMicrotraining) => {
        return this.singleTrainingService.selectMicrotraining(event.microtraining);
      },
      requestMicrotrainingQuestions: (context, event) => {
        return this.singleTrainingService.loadMicrotrainingQuestions(
          context.packages[0].pk,
          this.singleTrainingService.getCurrentWeek()-1,
          this.singleTrainingService.getCurrentWeekStatus().microtraining);
      },
      requestMicrotrainingQuestionsForLastWeek: (context, event) => {
        return this.singleTrainingService.loadMicrotrainingQuestions(
          context.packages[0].pk,
          this.singleTrainingService.getCurrentWeek(),
          this.singleTrainingService.getCurrentWeekStatus().microtraining);
      },
      saveMicrotrainingQuestionAnswers: (context, event) => {
        return this.singleTrainingService.saveMicrotrainingQuestionAnswers();
      },
      saveGoalSelection: (context, event: SaveGoalSelection) => {
        return this.singleTrainingService.saveGoalSelection(event.goal_pk);
      },
      saveSingleTrainingCompleted: (context, event) => {
        return this.exerciseService.saveSingleTrainingCompleted();
      },
      
      requestSingleTrainingContent: (context, event) => {
        const useRealProgressionState = context.trainingMode === TrainingMode.normal;
        if(event instanceof GoToHistoryTraining) {
          console.log("Event is of type GoToHistoryTraining");
          return this.exerciseService.loadREDOSingleTrainingContent(
            (<GoToHistoryTraining>event).microtraining_uuid, false, useRealProgressionState,
          );
        } else if (event instanceof GoToLibraryTraining) {
          console.log("Event is of type GoToLibraryTraining");
          return this.exerciseService.loadREDOSingleTrainingContent(
            (<GoToLibraryTraining>event).microtraining_uuid, false,   useRealProgressionState,
          );
        } else {
          console.log("Event is not of type GoToHistoryTraining or GoToLibraryTraining");
          return this.exerciseService.loadSingleTrainingContent(
            this.singleTrainingService.getCurrentWeekStatus().microtraining.pk, true, useRealProgressionState
          );
        }
      },
      saveExerciseExplanation : (context, event : ShowExerciseStepFromExplanation) => {
        return this.singleTrainingService.saveExerciseExplanation(context.user, event.doNotShowAgain);
      },

      //GAMIFICATION
      requestAchievementsOverview : (context, event) => {
        return this.gamificationService.loadAchievementsOverview();
      },
      requestAchievementProgressList : (context, event) => {
        return this.gamificationService.loadAchievementProgressList();
      },
      requestWeeklyQuizData : (context, event) => {
        return this.quizService.loadWeeklyQuizData(this.singleTrainingService.getCurrentWeekStatus().microtraining.pk);
      },
      requestQuizData : (context, event : GoToQuizHistory) => {
        return this.quizService.loadQuizDataForRedoByUUID(event.quiz_uuid);
      },
      saveQuizResult: (context, event) => {
        return this.quizService.saveQuizResult(this.singleTrainingService.getCurrentWeekStatus().microtraining.pk);
      },
      saveTOTWFinished: (context, event) => {
        return this.tipOfTheWeekService.saveTOTWFinished();
      },
      saveAchievementReviewCompleted : (context, event) => {
        return this.achievementReviewService.saveAchievementReviewCompleted();
      },
      requestAchievementReviewStats : (context, event) => {
        return this.achievementReviewService.loadAchievementReviewStats(context.packages[0].pk)
      },
      requestRewardedMicroInteractionPointList : (context, event) => {
        return this.rewardedMicroInteractionService.loadRewardedMicroInteractionPointList()
      },
      requestStreakCounterData : (context, event) => {
        return this.gamificationService.loadStreakCounterData()
      },
      notifyStreakIncreasedViewed: (context, event) => {
        return this.gamificationService.saveStreakIncreaseViewed()
      },
      requestSpaceMapDetails : (context, event) => {
        return this.gamificationService.loadSpaceMapDetails();
      },
      requestPlayerProgressInfo : (context, event) => {
        return this.gamificationService.loadPlayerProgressInfo();
      },
      saveTourGuideViewed : (context, event) => {
        console.log("saveTourGuideViewed called");
        return this.gamificationService.saveTourGuideViewed();
      },
      requestFeatureUnlockList : (context, event) => {
        return this.gamificationService.loadFeatureUnlockList();
      },
      sendRMIRequirementsMetWithLevelUpChance: (context, event: RMIRequierementsMet) => {
        console.log("sendRewardedMicroInteractionWithLevelUpChance called with event.RMI_name = " + event.RMI_name);
        return this.gamificationService.sendRewardedMicroInteractionWithLevelUpChance(event.RMI_name);
      },
      
      //PILOT USER
      savePackageReset : (context,event)=>{
        return this.pilotUserService.savePackageReset();
      },
      savePackageIncrease : (context,event)=>{
        return this.pilotUserService.savePackageIncrease();
      },


      //TIP OF THE WEEK
      requestTipOfTheWeek : (context, event) => {
        if(this.singleTrainingService.getCurrentWeekStatus() && this.singleTrainingService.getCurrentWeekStatus().microtraining) {
          return this.tipOfTheWeekService.loadTipOfTheWeek(this.singleTrainingService.getCurrentWeekStatus().microtraining.pk);
        } else {
          return this.tipOfTheWeekService.loadTipOfTheWeek(-1);
        }
      },
      requestTipOfTheWeekByID : (context, event: GoToTOTWHistory) => {
        return this.tipOfTheWeekService.loadTipOfTheWeekForRedoByUUID(event.totw_uuid);
      },

      //END OF JOURNEY
      requestCertificate : (context, event) => {
        return this.endOfJourneyService.loadCertificate();
      },
      requestPackageStatus : (context, event) => {
        return this.endOfJourneyService.loadPackageStatus(context.packages[0].pk);
      },

      //DAILY CHALLENGE
      requestDailyChallenge : (context, event) => {
        return this.dailyChallengeService.loadDaillyChallenge();
      },
      requestDailyChallengeById : (context, event: GoToDailyChallengeHistory) => {
        return this.dailyChallengeService.loadDaillyChallengeForRedoByUUID(event.daily_challenge_uuid);
      },
      savedailyChallengeSetTimeion : (context, event) => {
        return this.dailyChallengeService.saveChallengeRejection();
      },
      setDailyChallengeTimeAndPush : (context, event : SetChallengeTimeAndPush) => {
        return this.dailyChallengeService.setTimeAndPush(event.challengeTime , event.enablePush);
      },
      saveDailyChallengeStarted : (context, event) => {
        return this.dailyChallengeService.setStarted();
      },
      setDailyChallengeCompletedSuccessful : (context, event) => {
        return this.dailyChallengeService.setCompletedSuccessfull();
      },
      setDailyChallengeCompletedFailed : (context, event) => {
        return this.dailyChallengeService.setCompletedFailed();
      },
      requestNotes : (context, event: LoadNotes) => {
        console.log("requesting Notes")
        return this.notesService.loadNotes(event.noteActivityType, event.object_uuid);
      },
      saveTextualNote : (context, event) => {
        console.log("note is being saved");
        return this.notesService.saveTextualNote();
      },
      saveHistoricNote : (context, event) => {
        console.log("note is being saved");
        return this.notesService.saveHistoryNote();
      },
      sendFeedback : (context,event) => {
        console.log("send feedback is called by settings service");
      return this.settingsService.sendFeedback();
          },
      //DUEL
      requestWeeklyDuelStats : (context, event) => {
        console.log("requestWeeklyDuelStats requested by duelservice");
        return this.duelService.loadDuelWeekStats();
      },
      requestDuelOverview : (context, event) => {
        console.log("requestDuelOverview requested by duelservice");
        return this.duelService.loadDuelOverview();
      },
      requestGeneratedDuel : (context, event) => {
        console.log("requestGeneratedDuel requested by duelservice");
        return this.duelService.loadGeneratedDuel();
      },
      sendDuelInvitationViewed : (context, event) => {
        console.log("sendDuelInvitationViewed sent by duelservice");
        return this.duelService.sendDuelInvitationViewed();
      },
      sendDuelCompletionViewed : (context, event: SendDuelCompletionViewed) => {
        console.log("sendDuelCompletionViewed sent by duelservice");
        return this.duelService.sendDuelCompletionViewed(event.duel.pk);
      },
	    startGeneratedDuel: (context, event) => {
        return this.duelService.startGeneratedDuel();
      },

      //LOTTERY
      requestLotteryUpdate : (context, event) => {
        console.log("requestLotteryUpdate requested by lotteryService");
        return this.lotteryService.loadLotteryUpdate();
      },
      sendLotteryUpdateViewed : (context, event) => {
        console.log("sendLotteryUpdateViewed send by lotteryService");
        return this.lotteryService.saveLotteryUpdateViewed();
      },
      requestLotteryResolvementTimer : (context, event) => {
        console.log("requestLotteryResolvementTimer requested by lotteryService");
        return this.lotteryService.loadLotteryResolvementTimer();
      },
      requestLotteryResult : (context, event) => {
        console.log("requestLotteryResult requested by lotteryService");
        return this.lotteryService.loadLotteryResult();
      },
      sendLotteryResultViewed : (context, event) => {
        console.log("sendLotteryResultViewed send by lotteryService");
        return this.lotteryService.saveLotteryResultViewed();
      },
      //Leaderboard
      requestLeaderboardStats : (context, event) => {
        return this.leaderboardService.loadLeaderboard();
      },

      //
      requestMultiPackageHub : (context, event) => {
        return this.multiPackageHubService.loadMultiPackageHub();
      },
      requestInsightsPackageHub : (context, event) => {
        console.log("requestInsightsPackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        return this.multiPackageHubService.loadPackageInsights(packageId);
      },
      requestStatsPackageHub : (context, event) => {
        console.log("requestStatsPackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        return this.multiPackageHubService.loadPackageStats(packageId);
      },
      requestConquestsPackageHub : (context, event) => {
        console.log("requestConquestsPackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        return this.multiPackageHubService.loadPackageConquests(packageId);
      },
      requestFeedbackPackageHub : (context, event) => {
        console.log("requestFeedbackPackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        return this.multiPackageHubService.loadPackageFeedbackProfile(packageId);
      },
      requestCertificatePackageHub: (context, event: RequestCertificate) => {
        console.log("requestCertificatePackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        console.log("packageId: " + packageId);
        return this.multiPackageHubService.loadCertificate(packageId);
      },
      requestAchievementsPackageHub: (context, event) => {
        console.log("requestAchievementsPackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        return this.multiPackageHubService.loadPackageAchievements(packageId);
      },
      requestSkillTypePackageHub: (context, event) => {
        console.log("requestSkillTypePackageHub called");
        const packageId = this.multiPackageHubService.getSelectedPackage().pk;
        return this.multiPackageHubService.loadSkillType(packageId);
      }
    },
    guards: {
      // LOGIN
      loggedInEntryRelatedDataAlreadyLoaded: (context, event) => {
        let result : boolean = context.packages !== null && context.user !== null && context.currentPretrainingProgress.pretrainingStatus !== null;
        console.log("Guard loggedInEntryRelatedDataAlreadyLoaded evaluated to: " + result)
        return result;
      },
      passwordResetRequired: (context, event) => {
        return context.user.user_config.password_reset_required;
      },

      //SELF SERVICE
      isUserSelfService: (context,event) => {
        console.log("!!this.licenseSelfService.getSubscriptionStatus(): ", !!this.licenseSelfService.getSubscriptionStatus());
        return !!this.licenseSelfService.getSubscriptionStatus() && !this.licenseSelfService.userHasPackage();
      },
      isSelfServiceStartupLocationLogin: (context, event) => {
        console.log("isSelfServiceStartupLocationLogin: ", (this.licenseSelfService.getSelfServiceStartupLocation() === SelfServiceStartupLocation.login));
        return this.licenseSelfService.getSelfServiceStartupLocation() === SelfServiceStartupLocation.login;
      },
      isSelfServiceStartupLocationSettings: (context, event) => {
        console.log("isSelfServiceStartupLocationSettings: ", (this.licenseSelfService.getSelfServiceStartupLocation() === SelfServiceStartupLocation.settings));
        return this.licenseSelfService.getSelfServiceStartupLocation() === SelfServiceStartupLocation.settings;
      },
      // PRETRAINING
      isFirstWeekOrNoLastWeekSkillChoice: (context, event) => {
        return this.singleTrainingService.getCurrentWeek() === 1 || !this.singleTrainingService.getSkillsPerWeek().find(skillPerWeek => skillPerWeek.lastWeekSkillChoice);
      }, 
      isNotFirstPretrainingQuestion: (context, event) => {
        return context.currentPretrainingProgress.currentPretrainingQuestion > 0;
      },
      isNotLastPretrainingQuestion: (context, event) => {
        return context.currentPretrainingProgress.currentPretrainingQuestion < this.questionService.getPretrainingQuestions().length - 1;
      },
      // ACHIEVEMENT REVIEW
      isAchievementReviewWeek: (context, event) => {
        console.log(JSON.stringify(context.packages[0].achievement_review_weeks));
        console.log(context.packages[0].achievement_review_weeks.map(achievementReviewWeek => achievementReviewWeek.achievement_review_week));
        console.log(context.packages[0].achievement_review_weeks.map(achievementReviewWeek => achievementReviewWeek.achievement_review_week).includes(this.singleTrainingService.getCurrentWeek()));
        return context.packages[0].achievement_review_weeks.map(achievementReviewWeek => achievementReviewWeek.achievement_review_week).includes(this.singleTrainingService.getCurrentWeek());
      },
      isAchievementReviewWeekAndNotViewedOrIsLastWeekAndIsTrainingCompleted: (context, event)=>{
        console.log('isAchievementReviewWeekOrIsLastWeekAndIsTrainingCompleted && !this.achievementReviewService.isAchievementReviewViewedThisWeek(): ' + (this.achievementReviewService.isAchievementReviewWeekOrIsLastWeekAndIsTrainingCompleted() && !this.achievementReviewService.isAchievementReviewViewedThisWeek()));
        return this.achievementReviewService.isAchievementReviewWeekOrIsLastWeekAndIsTrainingCompleted() && !this.achievementReviewService.isAchievementReviewViewedThisWeek();
      },
      isAchievementReviewCompleted: (context, event) => {
        return this.gamificationService.getPlayerProgressInfo().has_seen_review_this_week;
      },
      isAchievementReviewUnchecked: (context, event) => {

        return !context.popupChecks.achievementReviewChecked;
      },
      // SINGLE TRAINING
      isProgressStateVideoDone: (context, event) => {
        console.log("isProgressStateVideoDone returns: " + (this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.VideoDone));
        return this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.VideoDone; ;
      },

      isProgressStateExerciseCompletlyDone: (context, event) => {
        console.log("isProgressStateExerciseCompletlyDone returns: " + (this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.ExerciseCompletlyDone));
        return this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.ExerciseCompletlyDone;
      },
      isProgressStateExerciseStepDone: (context, event) => {
        console.log("isProgressStateExerciseStepDone returns: " + (this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.ExerciseStepDone));
        return this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.ExerciseStepDone;
      },
      isProgressStateGoalSelectionDone: (context, event) => {
        console.log("isProgressStateGoalSelectionDone returns: " + (this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.GoalSelectionDone));
        return this.exerciseService.getExerciseProgressionStateName() === ExerciseProgressionStateName.GoalSelectionDone;
      },
      isNotFirstExerciseStep: (context, event) => {
        return this.exerciseService.getCurrentExerciseStepIndex() > 0;
      },
      isNotLastExerciseStep: (context, event) => {
        return this.exerciseService.getCurrentExerciseStepIndex() < this.exerciseService.getExerciseLength() -1;
      },
      isNotFirstDailyChallengeStep: (context, event) => {
        return this.dailyChallengeService.getCurrentDailyChallengeStepIndex() > 0;
      },
      isNotLastDailyChallengeStep: (context, event) => {
        return this.dailyChallengeService.getCurrentDailyChallengeStepIndex() < this.dailyChallengeService.getDailyChallengeLength() -1;
      },
      isUseProgressionStateExercise: (context, event) => {
        console.log("isUseProgressionStateExercise returns: " + this.exerciseService.useRealProgressionState);
        return this.exerciseService.useRealProgressionState;
      },
      isUseProgressionStateDailyChallenge: (context, event) => {
        console.log("isUseProgressionStateDailyChallenge returns: " + this.dailyChallengeService.useRealProgressionState);
        return this.dailyChallengeService.useRealProgressionState;
      },
      isNotFirstGroupExerciseStep: (context, event) => {
        return context.currentTrainingProgress.currentGroupExerciseStep > 0;
      },
      isNotLastGroupExerciseStep: (context, event) => {
        return context.currentTrainingProgress.currentGroupExerciseStep < this.groupTrainingService.getGroupTrainingContent().group_exercise.group_exercise_steps.length - 1;
      },
      isNotFirstMicrotrainingQuestion: (context, event) => {
        return context.currentTrainingProgress.currentMicrotrainingQuestion > 0;
      },
      isNotLastMicrotrainingQuestion: (context, event) => {
        return context.currentTrainingProgress.currentMicrotrainingQuestion < this.singleTrainingService.getMicrotrainingQuestions().length - 1;
      },
      isCurrentWeekSingleTrainingCompleted: (context, event) => {
        console.log("isCurrentWeekSingleTrainingCompleted returns: " + this.singleTrainingService.getCurrentWeekCompleted());
        return this.singleTrainingService.getCurrentWeekCompleted() && context.trainingMode === TrainingMode.normal;
      },
      isCurrentWeekSingleTrainingCompletedAndNotLastWeek:(context, event)=>{
        console.log("isCurrentWeekSingleTrainingCompletedAndNotLastWeek returns: " + this.singleTrainingService.getCurrentWeekCompleted() && context.trainingMode === TrainingMode.normal && !this.singleTrainingService.isLastWeek());
        return this.singleTrainingService.getCurrentWeekCompleted() && context.trainingMode === TrainingMode.normal && !this.singleTrainingService.isLastWeek();
      },
      isNotCurrentWeekSingleTrainingCompleted: (context, event) => {
        return !this.singleTrainingService.getCurrentWeekCompleted();
      },
      isGoToLibraryAfterLoadingFlag: (context, event) => {
        return context.goToLibraryAfterLoadingFlag;
      },
      isSkillSetForCurrentWeek: (context, event) => {
        return !!this.singleTrainingService.getCurrentSkill();
      },
      isFixedOrderTraining: (context, event) => { 
        console.log("isFixedOrderTraining returns: ", this.singleTrainingService.isNextMicrotrainingFixedOrder());
        return this.singleTrainingService.isNextMicrotrainingFixedOrder();
      },
      isMicrotrainingSetForCurrentWeek: (context, event) => { 
        console.log("isMicrotrainingSetForCurrentWeek returns: " + !!this.singleTrainingService.getCurrentWeekStatus().microtraining);
        return !!this.singleTrainingService.getCurrentWeekStatus().microtraining;
      },
      wasPriorWeekGoalSet: (context, event) => {
        console.log("was prior week goal set", !!this.singleTrainingService.getCurrentGoals().amount_of_challenges_selected)
        return !!this.singleTrainingService.getCurrentGoals().amount_of_challenges_selected;
      },
      wasMicrotrainingStartedFromSuggestions: (context, event) => {
        return !context.currentTrainingProgress.microtrainingStartedFromFullList;
      },
      wasMicrotrainingStartedFromFullList: (context, event) => {
        return context.currentTrainingProgress.microtrainingStartedFromFullList;
      },
      isPackageCompleted: (context, event) => {
        return this.endOfJourneyService.packageCompleted;
      },
      isEOJCertificateStartupLocationHome: (context, event) => {
        return this.endOfJourneyService.getCertificateStartupLocation() === 'Home';
      },
      noRefreshToken: () => !localStorage.getItem('refreshToken'),
      isLoggedIn: (context, event) => <boolean>(<any>event).data,
      is401Unauthorized: (context, event) => {
        if(event instanceof RefreshFail) {
          return (<RefreshFail>event).httpErrorResponse.status == 401;
        } else {
          return (<any>event).data.status == 401;
        }
      },
      is400OldPasswordWrong: (context, event) => {
        if(event instanceof RefreshFail) {
          return (<RefreshFail>event).httpErrorResponse.status == 400;
        } else {
          return (<any>event).data.status == 400;
        }
      },
      is410Gone: (context, event) => {
        return (<any>event).data.status == 410;
      },
      isMatchingAvailable: (context, event) => {
        return this.groupTrainingService.getGroup(context.user.id) !== undefined;
      },
      isCurrentWeekGroupTrainingCompleted: (context, event) => {
        return this.groupTrainingService.getCurrentWeekCompleted();
      },
      isNotCurrentWeekGroupTrainingCompleted: (context, event) => {
        return !this.groupTrainingService.getCurrentWeekCompleted();
      },
      onsiteTrainingUpcomingOrDemoUser/*AndFirstCheck*/: (context, event) => {
        console.log("Check start_date... Start date is: " + new Date(context.packages[0].start_date) + " and now it is: " + new Date());
        let onsiteTrainingUpcoming = (new Date()) < new Date(context.packages[0].start_date);
        console.log('Guard onsiteTrainingUpcomingOrDemoUser evaluated to ' + (onsiteTrainingUpcoming || context.user.user_config.is_demo_user) + ' with (onsiteTrainingUpcoming=' + onsiteTrainingUpcoming + ' OR isDemoUser=' + context.user.user_config.is_demo_user + ')');
        return onsiteTrainingUpcoming || context.user.user_config.is_demo_user /*&& !context.currentPretrainingProgress.onsiteTrainingOverAlreadyChecked*/;
      },
      onsiteTrainingOver: (context, event) => {
        console.log("Check start_date... Start date is: " + new Date(context.packages[0].start_date) + " and now it is: " + new Date());
        let onsiteTrainingOver = new Date() >= new Date(context.packages[0].start_date);
        console.log('Guard onsiteTrainingOver evaluated to ' + onsiteTrainingOver);
        return onsiteTrainingOver;
      },
      onsiteTrainingOverAndNotDemoUser: (context, event) => {
        console.log("Check start_date... Start date is: " + new Date(context.packages[0].start_date) + " and now it is: " + new Date());
        let onsiteTrainingOver = new Date() >= new Date(context.packages[0].start_date);
        console.log('Guard onsiteTrainingOverAndNotDemoUser evaluated to ' + (onsiteTrainingOver && !context.user.user_config.is_demo_user) + ' with (onsiteTrainingOver=' + onsiteTrainingOver + ' AND isDemoUser=' + context.user.user_config.is_demo_user + ')');
        return onsiteTrainingOver && !context.user.user_config.is_demo_user;
      },
      onsiteTrainingOverOrDemoUser: (context, event) => {
        console.log("Check start_date... Start date is: " + new Date(context.packages[0].start_date) + " and now it is: " + new Date());
        let onsiteTrainingOver = new Date() >= new Date(context.packages[0].start_date);
        console.log('Guard onsiteTrainingOverOverOrDemoUser evaluated to ' + (onsiteTrainingOver || context.user.user_config.is_demo_user) + ' with (onsiteTrainingOver=' + onsiteTrainingOver + ' AND isDemoUser=' + context.user.user_config.is_demo_user + ')');
        return onsiteTrainingOver || context.user.user_config.is_demo_user;
      },
      isDemoUser: (context, event) => {
        return context.user.user_config.is_demo_user;
      },
      hasUserNotSeenProfileTurorial: (context, event) => {
        return !this.gamificationService.getPlayerProgressInfo().has_viewed_user_profile_tutorial;
      },
      hasSkillType: (context, event) => {
        console.log('Guard hasSkillType evaluated to ' + this.profileService.hasSkillType());
        console.log('SkillType ToString: ' + this.profileService.getSkillType());
        
        return this.profileService.hasSkillType();
      },
      pretrainingCompleted: (context, event) => {
        console.log('Guard pretrainingCompleted evaluated to ' + context.currentPretrainingProgress.pretrainingStatus.pretraining_completed);
        return context.currentPretrainingProgress.pretrainingStatus.pretraining_completed;
      },
      isNotBeforeStartDate: (context, event) => {
        console.log('Guard isNotBeforeStartDate evaluated to ' + !this.preTrainingService.isBeforeStartDate());
        return !this.preTrainingService.isBeforeStartDate();
      },
      isBeforeStartDate: (context, event) => {
        console.log('Guard isBeforeStartDate evaluated to ' + this.preTrainingService.isBeforeStartDate());
        return this.preTrainingService.isBeforeStartDate();
      },
      pretrainingCompletedAfterStartDateAndFirstWeek: (context, event) => {
        console.log('Guard pretrainingCompletedAfterStartDateAndFirstWeek evaluated to ' + context.currentPretrainingProgress.pretrainingStatus.pretraining_completed_after_start_date && (this.singleTrainingService.getCurrentWeek() === 1) + ' with (pretrainingCompletedAfterStartDate =' + context.currentPretrainingProgress.pretrainingStatus.pretraining_completed_after_start_date + ' AND isFirstWeek=' + (this.singleTrainingService.getCurrentWeek() === 1) + ')');
        return context.currentPretrainingProgress.pretrainingStatus.pretraining_completed_after_start_date && (this.singleTrainingService.getCurrentWeek() === 1);
      },
      packageIntroCompleted: (context, event) => {
        console.log('Guard packageIntroCompleted evaluated to ' + context.currentPretrainingProgress.pretrainingStatus.package_start_completed);
        return context.currentPretrainingProgress.pretrainingStatus.package_start_completed;
      },
      
      isExerciseExplanationNeeded: (context, event) => {
        console.log("USER INFO PRINTED");
        console.log(context.user);
        console.log(context.user.user_preferences);
        console.log('context.user.user_preferences.skip_exercise_intro_screen && context.trainingMode == TrainingMode.normal: ' + context.user.user_preferences.skip_exercise_intro_screen + ' && ' + (context.trainingMode == TrainingMode.normal));
        return !context.user.user_preferences.skip_exercise_intro_screen && context.trainingMode == TrainingMode.normal;
      },

      //NOTES
      textualNoteModalNotShown: (context, event) => {
        console.log("textualNoteModalNotShown returns: " + !context.textualNoteModalShown);
        return !context.textualNoteModalShown;
      },

      shouldNoteBeSaved: (context, event) => {
        console.log("Should note be saved: " + this.notesService.shouldNoteBeSaved);
        return this.notesService.shouldNoteBeSaved;
      },

      //SETTINGS

      shouldAppFeedbackBeSaved: (context, event) => {
        console.log("Should note be saved: " + this.settingsService.shouldAppFeedbackBeSaved);
        return this.settingsService.shouldAppFeedbackBeSaved;
      },

      //PROFILE
      isProfileSectionSetToRelyOnSubstateHistory: (context, event) => {
        console.log("isProfileSectionSetToRelyOnSubstateHistory was called and returned: " + (context.profileSection === ProfileSection.relyOnSubstateHistory));
        return context.profileSection === ProfileSection.relyOnSubstateHistory;
      },
      isProfileSectionSetToProfile: (context, event) => {
        console.log("isProfileSectionSetToProfile was called and returned: " + (context.profileSection === ProfileSection.profile));
        return context.profileSection === ProfileSection.profile;
      },

      isNotTrainingStartupLocationNormal: (context, event) => {
        console.log("is Training Startup location Normal: ", (context.trainingMode === TrainingMode.normal));
        return context.trainingMode !== TrainingMode.normal;
      },
      isTrainingStartupLocationHistory: (context, event) => {
        console.log("is Training Startup location History: ", (context.trainingMode === TrainingMode.history));
        return context.trainingMode === TrainingMode.history;
      },
      isTrainingStartupLocationLibrary: (context, event) => {
        console.log("is Training Startup location Library: ", (context.trainingMode === TrainingMode.library));
        return context.trainingMode === TrainingMode.library;
      },
      isTrainingStartupLocationProfile: (context, event) => {
        console.log("is Training Startup location Profile: ", (context.trainingMode === TrainingMode.profile));
        return context.trainingMode === TrainingMode.profile;
      },
      isTrainingStartupLocationNoteHistory: (context, event) => {
        console.log("is Training Startup location Notes: ", (context.trainingMode === TrainingMode.noteHistory));
        return context.trainingMode === TrainingMode.noteHistory;
      },

      isLibraryStartupLocationHome: (context, event) => {
        console.log("is Library Startup location Home: ", (this.journeyService.getLibraryStartupLocation() === LibraryStartupLocation.home) + " with " + this.journeyService.getLibraryStartupLocation() + " being the library startup location");
        return this.journeyService.getLibraryStartupLocation() === LibraryStartupLocation.home;
      },
      isLibraryStartupLocationEndOfJourneyCongratulations: (context, event) => {
        console.log("is Library Startup location EndOfJourneyCongratulations: ", (this.journeyService.getLibraryStartupLocation() === LibraryStartupLocation.endOfJourneyCongratulations) + " with " + this.journeyService.getLibraryStartupLocation() + " being the library startup location");
        return this.journeyService.getLibraryStartupLocation() === LibraryStartupLocation.endOfJourneyCongratulations;
      },
      isLibraryStartupLocationJourney: (context, event) => {
        console.log("is Library Startup location Journey: ", (this.journeyService.getLibraryStartupLocation() === LibraryStartupLocation.journey) + " with " + this.journeyService.getLibraryStartupLocation() + " being the library startup location");
        return this.journeyService.getLibraryStartupLocation() === LibraryStartupLocation.journey;
      },
      lastTrainingWasHistoryOrLibraryAndCurrentTrainingIsNotHistoryOrLibrary: (context, event) => {
        let isHistoryOrLibrary = context.trainingMode === TrainingMode.history || context.trainingMode === TrainingMode.library
        console.log("Guard lastTrainingWasHistoryOrLibraryAndCurrentTrainingIsNotHistoryOrLibrary called with lastTrainingWasHistoryOrLibrary currently being " + context.lastTrainingWasHistoryOrLibrary + " and current Training isHistoryOrLibrary being " + isHistoryOrLibrary);
        return context.lastTrainingWasHistoryOrLibrary && !isHistoryOrLibrary;
      },
      isSkillTypeStartupLocationProfile: (context, event) => {
        return this.profileService.isSkillTypeStartupLocationProfile(context.skillTypeStartupLocation);
      },
      isSkillTypeStartupLocationPackageHistory: (context, event) => {
        return this.profileService.isSkillTypeStartupLocationPackageHistory(context.skillTypeStartupLocation);
      },
      isHistoryStartupLocationHome: (context, event) => {
        console.log("is History Startup location Home: ", (this.journeyService.getHistoryStartupLocation() === HistoryStartupLocation.home));
        return this.journeyService.getHistoryStartupLocation() === HistoryStartupLocation.home;
      },
      isHistoryStartupLocationJourney: (context, event) => {
        console.log("is History Startup location Journey: ", (this.journeyService.getHistoryStartupLocation() === HistoryStartupLocation.journey));
        return this.journeyService.getHistoryStartupLocation() === HistoryStartupLocation.journey;
      },
      isTOTWNotCompletedYet: (context, event) => {
        return !this.tipOfTheWeekService.tipOfTheWeek.completedThisWeek;
      },
      isQuizStartupLocationHome: (context, event) => {
        console.log("is Quiz Startup location Home: ", (context.quizStartupLocation === QuizStartupLocation.home));
        return context.quizStartupLocation === QuizStartupLocation.home;
      },
      isQuizStartupLocationHistory: (context, event) => {
        console.log("is Quiz Startup location History: ", (context.quizStartupLocation === QuizStartupLocation.history));
        return context.quizStartupLocation === QuizStartupLocation.history;
      },
      isTOTWStartupLocationHome: (context, event) => {
        console.log("is TotW Startup location Home: ", (context.totwStartupLocation === TOTWStartupLocation.home));
        return context.totwStartupLocation === TOTWStartupLocation.home;
      },
      isTOTWStartupLocationHistory: (context, event) => {
        console.log("is TotW Startup location History: ", (context.totwStartupLocation === TOTWStartupLocation.history));
        return context.totwStartupLocation === TOTWStartupLocation.history;
      },
      isDailyChallengeStartupLocationHome: (context, event) => {
        console.log("is DailyChallenge Startup location Home: ", (context.dailyChallengeStartupLocation === DailyChallengeStartupLocation.home));
        return context.dailyChallengeStartupLocation === DailyChallengeStartupLocation.home;
      },
      isDailyChallengeStartupLocationHistory: (context, event) => {
        console.log("is DailyChallenge Startup location History: ", (context.dailyChallengeStartupLocation === DailyChallengeStartupLocation.history));
        return context.dailyChallengeStartupLocation === DailyChallengeStartupLocation.history;
      },

      isDailyChallengeStartupLocationNotes: (context, event) => {
        console.log("is DailyChallenge Startup location Notes: ", (context.dailyChallengeStartupLocation === DailyChallengeStartupLocation.notes));
        return context.dailyChallengeStartupLocation === DailyChallengeStartupLocation.notes;
      },

      isAchievementStartupLocationHome: (context, event) => {
        console.log("is Achievement Startup location Home: ", this.journeyService.getAchievementStartupLocation() === AchievementStartupLocation.home);
        return this.journeyService.getAchievementStartupLocation() === AchievementStartupLocation.home;
      },
      isAchievementStartupLocationJourney: (context, event) => {
        console.log(" is Achievement Startup location Joureny: ",(this.journeyService.getAchievementStartupLocation() === AchievementStartupLocation.journey));
        return this.journeyService.getAchievementStartupLocation() === AchievementStartupLocation.journey;
      },
      isAchievementStartupLocationMultiPackageHub: (context, event) => {
        console.log(" is Achievement Startup location MultPackageHub: ",(this.journeyService.getAchievementStartupLocation() === AchievementStartupLocation.multiPackageHub));
        return this.journeyService.getAchievementStartupLocation() === AchievementStartupLocation.multiPackageHub;
      },

      isCertificateStartupLocationMultiPackageHub: (context, event) => {
        return this.endOfJourneyService.getCertificateStartupLocation() === 'MultiPackageHub';
      },
      isCertificateStartupLocationEndOfJourney: (context, event) => {
        return this.endOfJourneyService.getCertificateStartupLocation() === 'EndOfJourney';
      },

      isMicrotrainingHistoryStartupLocationPackageHistory: (context, event) => {
        return this.multiPackageHubService.isStartupLocationMicrotrainingHistory(StartupLocationMicrotrainingHistory.PackageHistory);
      },

      isMicrotrainingHistoryStartupLocationMultiPackageHub: (context, event) => {
        return this.multiPackageHubService.isStartupLocationMicrotrainingHistory(StartupLocationMicrotrainingHistory.MultiPackageHub);
      },

      isPackageHistoryStartupLocationProfile: (context, event) => {
        return this.multiPackageHubService.getPackageHistoryStartupLocation() == PackageHistoryStartupLocation.Profile; 
      },

      isPackageHistoryStartupLocationMultiPackageHub: (context, event) => {
        return this.multiPackageHubService.getPackageHistoryStartupLocation() == PackageHistoryStartupLocation.MultiPackageHubOverview; 
      },

      //GAMIFICATION

      hasUserReceivedPoints: (context, event) => {
        return this.gamificationService.hasUserReceivedPoints();
      },
      mustWeeklyQuizBeLoaded: (context, event) => {
        console.log("mustWeeklyQuizBeLoaded is called with result: " +"" );
        // (this.singleTrainingService.getCurrentWeekStatus().single_training_completed?
        // (this.singleTrainingService.getCurrentWeekStatus().weekly_quiz_completed? false : true) : 
        // false));

        // && !this.singleTrainingService.getCurrentWeekStatus().weekly_quiz_completed
        return this.singleTrainingService.getCurrentWeekStatus().single_training_completed

      },
      isLastQuestion: (context, event) => {
        return this.quizService.isCurrentQuestionLastQuestion();
      },
      isPilotUser:(context, event) => {
        console.log("isPilotUser been called")
        return this.pilotUserService.isPilotUser(context.user.user_config.is_pilot_user, this.packageService.getCurrentPackage().package_type);
      },
      isLastWeekAndIsTrainingCompleted:(context, event)=>{
        return this.singleTrainingService.isLastWeekAndIsTrainingCompleted();
      },
      isLastWeekAndIsTrainingCompletedAndEOJFlowStarted:(context, event)=>{
        return this.singleTrainingService.isLastWeekAndIsTrainingCompleted() && context.EOJFlowStarted;
      },
      weekJustIncreased:(context, event) => {
        console.log("weekJustIncreased returned " + this.pilotUserService.weekJustIncreased)
        return this.pilotUserService.weekJustIncreased;
      },
      hasLevelUpHappened:(context, event) => {
        console.log("hasLevelUpHappened called with result: " + this.gamificationService.hasLevelUpHappenedAfterGainingPoints());
        return this.gamificationService.hasLevelUpHappenedAfterGainingPoints();
      },
      hasLevelUpNotHappened:(context, event) => {
        console.log("hasLevelUpNotHappened called with result: " + !this.gamificationService.hasLevelUpHappenedAfterGainingPoints());
        return !this.gamificationService.hasLevelUpHappenedAfterGainingPoints();
      },
      hasLevelUpNotHappenedAndHasUserReceivedPoints:(context, event) => {
        console.log("hasLevelUpNotHappenedAndHasUserReceivedPoints called with result: " + (!this.gamificationService.hasLevelUpHappenedAfterGainingPoints() && this.gamificationService.hasUserReceivedPoints()));
        return this.gamificationService.hasUserReceivedPoints() && !this.gamificationService.hasLevelUpHappenedAfterGainingPoints();
      },
      isPlayerProgressionStartupFromRMI:(context, event) => {
        console.log("isPlayerProgressionStartupFromRMI called with: " + (context.playerProgressionStartup === PlayerPointType.rmi));
        return context.playerProgressionStartup === PlayerPointType.rmi;
      },
      isDailyChallengeFailed:(context, event) => {
        console.log("isDailyChallengeFailed called with: " + (this.dailyChallengeService.getDailyChallengeFailed()));
        return this.dailyChallengeService.getDailyChallengeFailed();
      },
      hasPlayerProgressionNOTStartedAndHasLevelUpHappened:(context, event) => { 
        return ((context.playerProgressionHasStarted === false) && (this.gamificationService.hasLevelUpHappenedAfterGainingPoints()));
      },

      //Duel

      isDuelOverviewStartupLocationJourney:(context, event) => {
        console.log("is Duel overview Startup location Journey: ", this.duelService.getDuelstartupLocation() == DuelStartupLocation.journey)
        return this.duelService.getDuelstartupLocation() == DuelStartupLocation.journey;
      },
      isDuelOverviewStartupLocationHome:(context, event) => {
        console.log("is Duel overview Startup location Home: ", this.duelService.getDuelstartupLocation() == DuelStartupLocation.home)
        return this.duelService.getDuelstartupLocation() == DuelStartupLocation.home;
      },
      hasDuelBeenInitiated:(context, event) => {
        console.log("hasDuelBeenInitiated called with result: " + this.duelService.hasDuelBeenInitiated());
        return this.duelService.hasDuelBeenInitiated();
      },
      duelSummaryAndNoInvitesAvailable:(context, event) => {
        console.log('duelSummaryAndNoInvitesAvailable: ', this.duelService.duelSummaryAndNoInvitesAvailable());
        return this.duelService.duelSummaryAndNoInvitesAvailable();
      },
	  wasDuelDetailOpenedForNewlyGeneratedDuel:(context, event) => {
        console.log('wasDuelDetailOpenedForNewlyGeneratedDuel: ', this.duelService.getWasDuelDetailOpenedForNewlyGeneratedDuel());
        return this.duelService.getWasDuelDetailOpenedForNewlyGeneratedDuel();
      },

      //Tour Guide

      isTourGuideActive : (context, event) => {
        console.log("isTourGuideActive");
        return this.tourGuideService.getShowTourGuide();
      },

      isSpaceMapTooltipActive : (context, event) => {
        console.log("isSpaceMapTooltipActive");
        return this.tourGuideService.getShowSpaceMapTooltip();
      },

      //ERRORS:
      isNotWebError: (context, event) => {
        if(context.errors?.error) {
          console.log("Status Code is: " + context.errors.error["status"] + " which leads to isNotWebError being " + (context.errors.error["status"] != "0" && context.errors.error["status"] != "504"));
          return context.errors.error["status"] == null;
        }
        return false;
      },
      isInternetProblem: (context, event) => {
        if(context.errors?.error) {
          console.log("Status Code is: " + context.errors.error["status"] + " which leads to isInternetProblem being " + (context.errors.error["status"] == "0" || context.errors.error["status"] == "504"));
          return context.errors.error["status"] == "0" || context.errors.error["status"] == "504";
        }
        return false;
      },
      isKnownError: (context, event) => {
        if(context.errors?.error) {
          console.log("context.errors.error: " + JSON.stringify(context.errors.error));
          return this.errorService.isErrorAKnownError(context.errors.error);
        }
        return false;
      },

      isStreakIncreasedAndNoSmallPointAnimationIsRunning: (context, event) => {
        const data = this.gamificationService.getStreakData();
        console.log("isStreakIncreasedAndNoSmallPointAnimationIsRunning called with result: " + (!data.has_streak_increase_notified && data.has_streak_increased) && !this.rewardedMicroInteractionService.getIsSmallPointAnimationRunning());
        return (!data.has_streak_increase_notified && data.has_streak_increased && !this.rewardedMicroInteractionService.getIsSmallPointAnimationRunning()) ;
      },

      hasUserSeenStreakCountPointAnimationAndHasLevelUpHappened: (context, event) => {
        return this.gamificationService.getHasUserSeenStreakCountPointAnimation() && this.gamificationService.hasLevelUpHappenedAfterGainingPoints();
      },

      cameFromStreakCounter: (context, event) => {
        return this.gamificationService.getCameFromStreakCounter();
      },

      hasUserNotSeenStreakCounterTutorial: (context, event) => {
        const data = this.gamificationService.getStreakData();
        return  !data.has_viewed_streak_counter_explanation;
      },
      hasUserNotSeenTourGuide: (context, event) => {
        return !this.gamificationService.getPlayerProgressInfo().has_viewed_tour_guide;
      },
      //Lottery
      areLotteryRequirementsMet: (context, event) => {
        console.log("isLotteryEnabled called with result: " + this.packageService.getCurrentPackage().lottery_conditions_met );
        return this.packageService.getCurrentPackage().lottery_conditions_met;
      },
      isLastWeekAndAreLotteryRequirementsMet: (context, event) => { 
        console.log("isLastWeekAndAreLotteryRequirementsMet called with result: " + this.packageService.getCurrentPackage().lottery_conditions_met && this.singleTrainingService.isLastWeek());
        return this.packageService.getCurrentPackage().lottery_conditions_met && this.singleTrainingService.isLastWeek();
      },


      //Feedback Profile

      isFeedbackProfileStartupLocationProfile: (context, event) => {
        console.log("isFeedbackProfileStartupLocationProfile called with result: " + (this.feedbackProfileService.getFeedbackProfileStartupLocation() === FeedbackProfileStartupLocation.profile));
        return this.feedbackProfileService.getFeedbackProfileStartupLocation() === FeedbackProfileStartupLocation.profile;
      },

      isFeedbackProfileStartupLocationMultiPackageHub: (context, event) => {
        console.log("isFeedbackProfileStartupLocationMultiPackageHub called with result: " + (this.feedbackProfileService.getFeedbackProfileStartupLocation() === FeedbackProfileStartupLocation.multiPackageHub));
        return this.feedbackProfileService.getFeedbackProfileStartupLocation() === FeedbackProfileStartupLocation.multiPackageHub;
      },

    },
    actions: {
      hideSplashScreen: async (context, event) => {
        await SplashScreen.hide();
      },
      displayAppWelcomeScreen: (context, event ) => {
        this.router.navigateByUrl('/app-welcome-screen');
      },
      displayLoginScreen: (context, event) => {
        /*if(event instanceof RefreshFail) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: (<RefreshFail>event).returnUrl } });
        } else if (event instanceof Logout) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: (<Logout>event).returnUrl } });
        } else {
          this.router.navigateByUrl('/login');
        }*/
        this.router.navigateByUrl('/login');
      },
      /*displayLatestScreen: (context, event) => {
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigate([returnUrl]);
      },*/
      displaySelfServiceLoginScreen: (context, event) => {
        this.router.navigateByUrl('/self-service-login-screen');
      },
      displaySelfServiceTrainingSetup: (context, event) => {
        this.router.navigateByUrl('/self-service-training-setup');
      },
      displaySelfServiceTeamInvite: (context, event) => {
        this.router.navigateByUrl('/self-service-team-invite');
      },
      displaySelfServiceSummary: (context, event) => {
        this.router.navigateByUrl('/self-service-summary');
      },
      displaySelfServiceTeamOverview: (context, event) => {
        this.router.navigateByUrl('/self-service-team-overview');
      },
      displayPasswordChangeScreen: (context, event) => {
        this.router.navigateByUrl('/login/password-change');
      },
      displayPasswordChangeCompletedScreen: (context, event) => {
        this.router.navigateByUrl('/login/password-change-completed');
      },
      displayPretrainingIntroScreen: (context, event) => {
        this.router.navigateByUrl('/pretraining-intro');
      },
      displayGoalBehaviorIntroScreen: (context, event) => {
        this.router.navigateByUrl('/goal-behavior-intro');
      },
      displayGenericGoalsScreen: (context, event ) => {
        this.router.navigateByUrl('/generic-goals');
      },
      displayLongTermGoalsScreen: (context, event ) => {
        this.router.navigateByUrl('/long-term-goals');
      },
      displayCalendarBlockerScreen: (context, event ) => {
        this.router.navigateByUrl('/calendar-blocker');
      },
      displayJourneyBehaviorsScreen: (context, event ) => {
        this.router.navigateByUrl('/journey-behaviors');
      },
      displayAvatarSelectionScreen: (context, event ) => {
        this.router.navigateByUrl('/avatar-selection');
      },
      displayPretrainingQuestionsIntroScreen: (context, event ) => {
        this.router.navigateByUrl('/pretraining-questions-intro');
      },
      displayProfileSetupScreen: (context, event ) => {
        this.router.navigateByUrl('/profile-setup');
      },
      displayPretrainingQuestionScreen: (context, event ) => {
        this.router.navigateByUrl('/pretraining-question');
      },
      displayResultCalculationScreen: (context, event ) => {
        this.router.navigateByUrl('/result-calculation');
      },
      displaySkillTypeExplanationScreen: (context, event ) => {
        this.router.navigateByUrl('/skill-type-explanation');
      },
      displayProfileIntroductionScreen: (context, event ) => {
        this.router.navigateByUrl('/profile-introduction');
      },
      displayJourneyBehaviorSelectionScreen: (context, event ) => {
        this.router.navigateByUrl('/journey-behavior-selection');
      },
      displayJourneyBehaviorSelectionModificationScreen: (context, event ) => {
        this.router.navigateByUrl('/journey-behavior-selection-modification');
      },
      displayGamificationTouchpointScreen: (context, event ) => {
        this.router.navigateByUrl('/gamification-touchpoint');
      },
      displayPretrainingCompletedScreen: (context, event ) => {
        this.router.navigateByUrl('/pretraining-completed');
      },
      displayPushNotificationPopover: (context, event ) => {
        this.questionService.displayPushNotificationPopover();
      },
      displayPackageIntroScreen: (context, event ) => {
        this.router.navigateByUrl('/package-intro');
      },
      displayHomeScreen: (context, event ) => {
        this.router.navigateByUrl('/tabs/home');
      },
      displayAchievementReviewScreen: (context, event) => {
        this.router.navigateByUrl('/achievement-review');
      },
      displayEndOfJourneyCertificateScreen: (context, event) => {
        this.router.navigateByUrl('/training/end-of-journey-certificate');
      },
      displayAchievementReviewCompletedScreen: (context, event) => {
        this.router.navigateByUrl('/achievement-review/achievement-review-completed');
      },
      displayTrainingOverviewScreen: (context, event ) => {
        console.log("displayTrainingOverviewScreen got called");
        this.router.navigateByUrl('/tabs/training');
        console.log("URL after navigation: " + this.route.snapshot.toString()
        );
      },
      displayMicrotrainingSuggestionScreen: (context, event) => {
        this.router.navigateByUrl('/training/training-suggestion');
      },
      displayMicrotrainingChoiceScreen: (context, event) => {
        this.router.navigateByUrl('/training/training-suggestion/choice');
      },
      displayTrainingExerciseExplanationScreen: (context, event) => {
        this.router.navigateByUrl('/training/exercise-explanation');
      },
      displayTrainingVideoScreen: (context, event) => {
        this.router.navigateByUrl('/training/video');
      },
      displayMicrotrainingInfoScreen: (context, event : ShowMicrotrainingInfo) => {
        this.singleTrainingService.displayMicrotrainingInfoModal(event.microtraining);
      },
      displayCurrentExerciseStepScreen: (context, event) => {
        this.router.navigateByUrl('/training/exercise');
      },
      displayFeedbackVotingScreen: (context, event) => {
        this.router.navigateByUrl('/training/feedback-voting');
      },
      displayFeedbackUserPraisalScreen:(context, event) => {
        this.router.navigateByUrl('/training/feedback-voting/feedback-voting-completed');
      },
      displayExerciseCompletedScreen: (context, event) => {
        this.router.navigateByUrl('/training/exercise-completed');
      },
      displayGoalSelectionScreen: (context, event) => {
        this.router.navigateByUrl('/training/goal-selection');
      },
      displaySingleTrainingCongratulationScreen: (context, event) => {
        this.router.navigateByUrl('/training/training-congratulation')
      },
      displaySingleTrainingCompletedScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/training/training-completed');
      },
      displayPackageCompletedScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/training/package-completed');
      },
      displayGroupTrainingTab: (context, event ) => {
        this.router.navigateByUrl('/tabs/group-training');
      },
      displayGroupTrainingWaitScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/group-training');
      },
      displayGroupTrainingIntroScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/group-training/group-intro');
      },
      displayGroupTrainingCaptainIntroScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/group-training/group-captain-intro');
      },
      displayGroupExerciseStepScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/group-training/exercise');
      },
      displayGroupTrainingCompletedScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/group-training/completed');
      },
      /*displayProfileTab: (context, event ) => {
        this.router.navigateByUrl('/tabs/profile');
      },*/
      displayGoalAttainmentScreen: (context, event) => {
        this.router.navigateByUrl('/training/goal-achievement');
      },
      // displayGoalAttainmentScreen: (context, event) => {
      //   this.router.navigateByUrl('/training/goal-achievement');
      // },
      displayEndOfJourneyScreen: (context, event) => {
        this.router.navigateByUrl('/training/end-of-journey-screen');
      },
      displayEndOfJourneyCongratulationsScreen: (context, event) => {
        this.router.navigateByUrl('/training/end-of-journey-congratulations');
      },
      displayMicrotrainingQuestionsIntroScreen: (context, event) => {
        this.router.navigateByUrl('/training/questions');
      },
      displayMicrotrainingQuestionScreen: (context, event) => {
        this.router.navigateByUrl('/training/questions/question-scale');
      },
      displaySkillSuggestionScreen: (context, event) => {
        this.router.navigateByUrl('/training/skill-suggestion');
      },
      displaySkillChoiceScreen: (context, event) => {
        this.router.navigateByUrl('/training/skill-choice');
      },
      displayMicrotrainingIntroScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/training/training-intro');
      },
      displayProfileScreen: (context, event) => {
        //let _statsPerWeek = (<any>event).data.statsPerWeek;
        this.router.navigateByUrl('tabs/profile'/*, {state: { statsPerWeek: _statsPerWeek}}*/);
      },
      displaySettingsScreen: (context, event) => {
        this.router.navigateByUrl('/profile/settings');
      },
      displayChangeAvatarScreen: (context, event) => {
        this.router.navigateByUrl('/profile/settings/change-avatar');
      },
      displayContactScreen: (context, event) => {
        this.router.navigateByUrl('/profile/settings/contact-screen');
      },
      displayFeedbackProfileScreen: (context, event) => {
        this.router.navigateByUrl('/profile/feedback-profile');
      },
      displayBehaviorFeedbackScreen: (context, event) => {
        this.router.navigateByUrl('/profile/feedback-profile/behavior-text-feedback');
      },
      displayPilotUserIntroductionScreen: (context, event) => {
        this.ngZone.run(()=>this.router.navigateByUrl('pilot-user-introduction'));
      },
      displayResetPackageAlert: (context, event) => {
        this.pilotUserService.displayResetPackageAlert();
      },
      //Journey
      displayJourneyScreen: (context, event) => {
        this.router.navigateByUrl('/tabs/journey');
      },
      displayAchievementsScreen: (context, event) => {
        this.router.navigateByUrl('/journey/achievements-screen');
      },
      displayNotesScreen: (context, event) => {
        this.router.navigateByUrl('/journey/notes-screen');
      },
      displayHistoryScreen: (context, event) => {
        this.router.navigateByUrl('/journey/history-screen');
      },
      displayLibraryScreen: (context, event) => {
        this.router.navigateByUrl('/journey/library-screen');
      },
      displayLeaderboardScreen: (context, event) => {
        this.router.navigateByUrl('/journey/leaderboard-screen');
      },
      displayMultiPackageHubScreen: (context, event) => {
        this.router.navigateByUrl('/multi-package-hub-screen');
      },
      displayPackageHistoryScreen: (context, event) => {
        this.router.navigateByUrl('/multi-package-hub-screen/package-history-page');
      },
      displayMicrotrainingHistoryScreen: (context, event) => {
        this.router.navigateByUrl('/multi-package-hub-screen/microtraining-histroy-page');
      },
      assignUserAvatar: assign<SavviContext, SavviEvent>((context, event: any) => {
        return {
          user: {
            ...context.user,
            user_avatar: {
              identifier: this.profileService.getSelectedAvatarInPretraining()
            }
          }
        };
      }),
      resetProfileStats: (context, event) => {
        this.profileService.resetProfileStats();
      },
      resetCurrentPretrainingStep: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            currentStep: 1
          }
        };
      }),
      setFullScreenLoadingSpinnerActive: (context, event) => {
        console.log("LOADER was set and spinner is shown!")
        this.loaderService.setFullScreenLoadingSpinnerActive();
      },
      resetFullScreenLoadingSpinnerActive:(context, event) => {
        console.log("LOADER was reset and spinner is hidden!")
        this.loaderService.resetFullScreenLoadingSpinnerActive();
      },
      resetLoadingService: (context, event) => {
        console.log("LOADER was reset. Begin loading!")
        this.loaderService.resetLoading();
        this.loaderService.resetLoadingProgress();
      },
      resetSubLoadingService: (context, event) => {
        console.log("LOADER was reset. Begin loading!")
        this.loaderService.resetSubLoading();
      },
      sendFeedbackProfileVisited: (context, event: SaveTextFeedbackVisited) => {
        this.feedbackProfileService.saveTextfeedbackVisited(event.feedback_ids);
      },
      loadProfileForSharing: (context, event) => {
        console.log("Load profile for sharing");
        this.feedbackProfileService.loadFeedbackProfilePDF(context.user);
      },
      sendBehaviorFeedbackVisited: (context, event: SaveBehaviorFeedbackVisited) => {
        this.feedbackProfileService.saveBehaviorTextfeedbackVisited(event.feedback_ids);
      },
      loadingCompleted: (context, event) => {
        this.loaderService.loadingComplete();
      },
      subLoadingCompleted: (context, event) => {
        this.loaderService.subLoadingComplete();
      },
      updateLoadingProgressPretrainingResultCalculation: (context, event) => {
        this.loaderService.updateLoadingProgress(25);
        this.loaderService.updateLoaderProgressAlertSubject();
      },
      storeRefreshToken: (context, event) => {
        localStorage.setItem('refreshToken', context.user.refresh);
      },
      assignPackages: assign<SavviContext, SavviEvent>((context, event : any) => {
        return { packages: event.data };
      }),
      setLanguage: (context, event) => {
        this.translationService.language = this.packageService.getCurrentPackage()?.language;
      },
      assignUser: assign<SavviContext, SavviEvent>((context, event : any) => {
        //user: (<LoginSuccessful>event).user
        return { user: new User() };
      }),
      assignPretrainingStatus: assign<SavviContext, SavviEvent>((context, event : any) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            pretrainingStatus : event.data
          }
        };
      }),
      assignUserStats: assign<SavviContext, SavviEvent>((context, event) => ({
        statsPerWeek: (<UserStatsLoaded>event).statsPerWeek
      })),
      assignCurrentWeek: assign<SavviContext, SavviEvent>((context, event: any) => {
        return { week: event.data };
      }),
      microtrainingStartedFromSuggestions: (context, event) => {
        context.currentTrainingProgress.microtrainingStartedFromFullList = false;
      },
      microtrainingStartedFromFullList: (context, event) => {
        context.currentTrainingProgress.microtrainingStartedFromFullList = true;
      },
      setIsAppComingBackFromFullSkillSelection: (context, event) => {
        this.singleTrainingService.setIsAppComingBackFromFullSkillSelection();
      },
      resetIsAppComingBackFromFullSkillSelection: (context, event) => {
        this.singleTrainingService.resetIsAppComingBackFromFullSkillSelection();
      },
      assignSingleTrainingContent: assign<SavviContext, SavviEvent>((context, event: SingleTrainingContentLoaded) => {
        return { singleTrainingContent: event.singleTrainingContent };
      }),
      assignGroupTrainingContent: assign<SavviContext,  SavviEvent>((context, event: GroupTrainingContentLoaded) => {
        return { groupTrainingContent: event.groupTrainingContent };
      }),
      setImpulsViewed: (context, event) => {
        context.impulsViewed = true;
      },
      resetImpulsViewed: (context, event) => {
        context.impulsViewed = false;
      },
      passwordHasBeenChanged: (context, event) => {
        console.log("Password has been changed. Toast message is now set to true");
        context.toastMessages.showPasswordChangedToast = true;
      },
      saveChangeSchedule: (context, event) => {
        return this.settingsService.saveUserNotificationSettings((<SaveChangeSchedule>event).offset, (<SaveChangeSchedule>event).pushEnable);
      },
      toastHasBeenShown: (context, event) => {
        console.log("Toast message for password change has been shown. Toast message is now set to false");
        context.toastMessages.showPasswordChangedToast = false;
      },
      removeAllBehaviorFromSelectedGoal: (context, event) => {
        this.packageService.removeAllBehaviorFromSelectedGoal();
      },
      profileExplanationViewed: (context, event) => {
        context.currentPretrainingProgress.profileExplanationViewed = true;
      },
      resetProfileExplanationViewed: (context, event) => {
        context.currentPretrainingProgress.profileExplanationViewed = false;
      },
      resetBehaviorToGoalSelection: (context, event) => {
        this.packageService.resetBehaviorToGoalSelection();
      },
      copyOldTrainingPlan: (context, event) => {
        this.packageService.copyOldTrainingPlan();
      },
      restoreOldTrainingPlan: (context, event) => {
        this.packageService.restoreOldTrainingPlan();
      },
      saveExerciseVideoViewed: (context, event) => {
        this.exerciseService.saveExerciseVideoViewed();
      },
      saveExerciseGoalsSelected: (context, event) => {
        this.exerciseService.saveExerciseGoalSelectionDone();
      },
      saveExerciseStepCompleted: (context, event: SaveExerciseStepCompleted) => {
        return this.exerciseService.saveExerciseStepCompleted(
          event.exerciseStepOrderNumber,
          event.result
        );
      },
      deleteProgressState: (context, event) => {
        return this.exerciseService.deleteProgressState();
      },
      incrementCurrentExerciseStep: (context, event) => {
        this.exerciseService.incrementCurrentExerciseStep();
      },decrementCurrentExerciseStep: (context, event) => {
        this.exerciseService.decrementCurrentExerciseStep();
      },
      incrementCurrentDailyChallengeStep: (context, event) => {
        this.dailyChallengeService.incrementCurrentDailyChallengeStep();
      },decrementCurrentDailyChallengeStep: (context, event) => {
        this.dailyChallengeService.decrementCurrentDailyChallengeStep();
      },
      resetCurrentPretrainingQuestion: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            currentPretrainingQuestion: 0
          }
        };
      }),
      incrementCurrentPretrainingQuestion: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            currentPretrainingQuestion: context.currentPretrainingProgress.currentPretrainingQuestion + 1
          }
        };
      }),
      decrementCurrentPretrainingQuestion: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            currentPretrainingQuestion: context.currentPretrainingProgress.currentPretrainingQuestion - 1
          }
        };
      }),
      incrementCurrentPretrainingStep: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            currentStep: context.currentPretrainingProgress.currentStep + 1
          }
        };
      }),
      decrementCurrentPretrainingStep: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentPretrainingProgress: {
            ...context.currentPretrainingProgress,
            currentStep: context.currentPretrainingProgress.currentStep - 1
          }
        };
      }),
      resetCurrentExerciseStep: (context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentExerciseStep: 0
          }
        };
      },
      resetCurrentGroupExerciseStep: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentGroupExerciseStep: 0
          }
        };
      }),
      incrementCurrentGroupExerciseStep: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentGroupExerciseStep: context.currentTrainingProgress.currentGroupExerciseStep + 1
          }
        };
      }),
      decrementCurrentGroupExerciseStep: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentGroupExerciseStep: context.currentTrainingProgress.currentGroupExerciseStep - 1
          }
        };
      }),
      resetCurrentMicrotrainingQuestion: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentMicrotrainingQuestion: 0
          }
        };
      }),
      incrementCurrentMicrotrainingQuestion: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentMicrotrainingQuestion: context.currentTrainingProgress.currentMicrotrainingQuestion + 1
          }
        };
      }),
      decrementCurrentMicrotrainingQuestion: assign<SavviContext, SavviEvent>((context, event) => {
        return {
          currentTrainingProgress: {
            ...context.currentTrainingProgress,
            currentMicrotrainingQuestion: context.currentTrainingProgress.currentMicrotrainingQuestion - 1
          }
        };
      }),
      setNormalTrainingMode: (context, event) => {
        console.log("Training mode now set to normal");
        context.trainingMode = TrainingMode.normal;
      },
      setProfileTrainingMode: (context, event) => {
        console.log("Training mode now set to profile");
        context.trainingMode = TrainingMode.profile;
      },
      setLibraryTrainingMode: (context, event) => {
        console.log("Training mode now set to library");
        context.trainingMode = TrainingMode.library;
      },
      setHistoryTrainingMode: (context, event) => {
        console.log("Training mode now set to history");
        context.trainingMode = TrainingMode.history;
      },
      setNoteHistoryTrainingMode: (context, event) => {
        console.log("Training mode now set to history");
        context.trainingMode = TrainingMode.noteHistory;
      },
      //SELF SERvICE
      setSelfServiceStartupLocationLogin: (context, event) => {
        console.log('Self Service Startup Location set to Login');
        this.licenseSelfService.setSelfServiceStartupLocation(SelfServiceStartupLocation.login);
      },
      setSelfServiceStartupLocationSettings: (context, event) => {
        console.log('Self Service Startup Location set to Settings');
        this.licenseSelfService.setSelfServiceStartupLocation(SelfServiceStartupLocation.settings);
      },
      loadSelfServiceTeamMembers: (context,event) => {
        console.log('loading active team members in subscription from savvi machine service')
        return this.licenseSelfService.loadSubscriptionTeam();
      },
      //END SELF SERVICE
      setLibraryStartupLocationHome: (context, event) => {
        console.log('Library Startup location now set to Home');
        this.journeyService.setLibraryStartupLocationHome();
      },
      setLibraryStartupLocationEndOfJourneyCongratulations: (context, event) => {
        console.log('Library Startup location now set to EndOfJourneyCongratulations');
        this.journeyService.setLibraryStartupLocationEndOfJourneyCongratulations();
      },
      setLibraryStartupLocationJourney: (context, event) => {
        console.log('Library Startup location now set to Journey');
        this.journeyService.setLibraryStartupLocationJourney();
      },
      setHistoryDailyChallengeMode: (context, event) => {
        console.log("Daily challenge mode now set to history");
        context.isDailyChallengeHistory = true;
      },
      resetHistoryDailyChallengeMode: (context, event) => {
        console.log("Daily challenge mode now set to normal");
        context.isDailyChallengeHistory = false;
      },

      saveDailyChallengeStepCompleted: (context, event: SaveDailyChallengeStepCompleted) => {
        return this.dailyChallengeService.saveDailyChallengeStepCompleted(
          event.dailyChallengeStepOrderNumber,
          event.result
        );
      },

      setCertificateStartupLocationMultiPackageHub: (context, event) => {
        console.log('Certificate Startup location now set to MultiPackageHub');
        this.endOfJourneyService.setCertificateStartupLocationMultiPackageHub();
      },
      setCertificateStartupLocationEndOfJourney: (context, event) => {
        console.log('Certificate Startup location now set to EndOfJourney');
        this.endOfJourneyService.setCertificateStartupLocationEndOfJourney();
      },
      setCertificateStartupLocationHome: (context, event) => {
        console.log('Certificate Startup location now set to Home');
        this.endOfJourneyService.setCertificateStartupLocationHome();
      },
      //DUEL
      setDuelsStartupLocationJourney: (context, event) => {
        console.log('Duel Startup location now set to Journey');
        this.duelService.setDuelstartupLocation(DuelStartupLocation.journey);
      },
      setDuelsStartupLocationHome: (context, event) => {
        console.log('Duel Startup location now set to Home');
        this.duelService.setDuelstartupLocation(DuelStartupLocation.home);
      },
      displayDuelOverviewScreen: (context, event) => {
        this.router.navigateByUrl('/duels');
      },
      displayDuelDetailsScreen: (context, event) => {
        this.router.navigateByUrl('/duels/duel-details');
      },
      setDuelForDetailsScreen: (context, event: GoToDuelDetails) => {
        console.log("setDuelForDetailsscreen called with the following duel", event.duel);
        this.duelService.setDuelForDetailsScreen(event.duel);
      },
      setDuelForSummaryScreen: (context, event: SendDuelCompletionViewed) => {
        console.log("setDuelForSummaryScreen called with the following duel", event.duel);
        this.duelService.setDuelForSummaryScreen(event.duel);
      },
      displayDuelSummaryScreen: (context, event) => {
        this.router.navigateByUrl('/duels/duel-summary');
      },
      startSlotMachineAnim: (context, event) => {
        console.log("triggerStartSlotMachineAnim was clicked");
        this.duelService.triggerStartSlotMachineAnim();
      },
      setResultFromGeneratingDuelHasArrived: (context, event) => {
        console.log("setResultFromGeneratingDuelHasArrived called");
        this.duelService.setResultFromGeneratingDuelHasArrived();
      },
      resetResultFromGeneratingDuelHasArrived: (context, event) => {
        console.log("resetResultFromGeneratingDuelHasArrived called");
        this.duelService.resetResultFromGeneratingDuelHasArrived();
      },
      setWasDuelDetailOpenedForNewlyGeneratedDuel: (context, event) => {
        console.log("setWasDuelDetailOpenedForNewlyGeneratedDuel called");
        this.duelService.setWasDuelDetailOpenedForNewlyGeneratedDuel();
      },
	    resetWasDuelDetailOpenedForNewlyGeneratedDuel: (context, event) => {
        console.log("resetWasDuelDetailOpenedForNewlyGeneratedDuel called");
        this.duelService.resetWasDuelDetailOpenedForNewlyGeneratedDuel();
      },
      setQuizStartupLocationHistory: (context, event) => {
        console.log('Quiz Startup location now set to History');
        context.quizStartupLocation = QuizStartupLocation.history;
      },
      setQuizStartupLocationHome: (context, event) => {
        console.log('Quiz Startup location now set to History');
        context.quizStartupLocation = QuizStartupLocation.home;
      },
      setHistoryStartupLocationHome: (context, event) => {
        console.log('History Startup location now set to Home');
        this.journeyService.setHistoryStartupLocationHome();
      },
      setHistoryStartupLocationJourney: (context, event) => {
        console.log('History Startup location now set to Journey');
        this.journeyService.setHistoryStartupLocationJourney();
      },

      setAchievementStartupLocationHome: (context, event) => {
        console.log('Achievements Startup location now set to Home');
        this.journeyService.setAchievementStartupLocationHome();
      },
      setAchievementStartupLocationJourney: (context, event) => {
        console.log('Achievements Startup location now set to Journey');
        this.journeyService.setAchievementStartupLocationJourney();
      },
      setAchievementStartupLocationMultiPackageHub: (context, event) => {
        console.log('Achievements Startup location now set to MultiPackageHub');
        this.journeyService.setAchievementStartupLocationMultiPackageHub();
      },
      setMicrotrainingHistoryStartupLocationMultiPackageHub: (context, event) => {
        console.log('MultiPackageHub Startup location now set to MicrotrainingHistory');
        this.multiPackageHubService.setStartupLocationMicrotrainingHistory(StartupLocationMicrotrainingHistory.MultiPackageHub);
      },
      setMicrotrainingHistoryStartupLocationPackageHistory: (context, event) => {
        console.log('MultiPackageHub Startup location now set to PackageHistory');
        this.multiPackageHubService.setStartupLocationMicrotrainingHistory(StartupLocationMicrotrainingHistory.PackageHistory);
      },
      setPackageHistoryStartupLocationProfile: (context, event) => {
        console.log('Package History Startup location now set to Profile');
        this.multiPackageHubService.setPackageHistoryStartupLocation(PackageHistoryStartupLocation.Profile);
      },
      setPackageHistoryStartupLocationMultiPackageOverview: (context, event) => {
        console.log('Package History Startup location now set to MultiPackageHubOverview');
        this.multiPackageHubService.setPackageHistoryStartupLocation(PackageHistoryStartupLocation.MultiPackageHubOverview);
      },
      setTextualNoteModalStartupLocationDefault: (context,event) => {
        console.log('TextualNoteModal Startup Location set to History');
        this.notesService.setTextualNoteModalStartupLocation(TextualNoteModalStartupLocation.default)
      },
      setTextualNoteModalStartupLocationNoteHistory: (context,event) => {
        console.log('TextualNoteModal Startup Location set to NoteHistory');
        this.notesService.setTextualNoteModalStartupLocation(TextualNoteModalStartupLocation.noteHistory)
      },
      setTextualNoteModalStartupLocationSettings: (context,event) => {
        console.log('TextualNoteModal Startup Location set to Settings');
        this.notesService.setTextualNoteModalStartupLocation(TextualNoteModalStartupLocation.settings)
      },
      setGoToLibraryAfterLoadingFlag: (context, event) => {
        console.log('Set go to library after loading flag to active');
        context.goToLibraryAfterLoadingFlag = true;
      },
      resetGoToLibraryAfterLoadingFlag: (context, event) => {
        console.log('Reset go to library after loading flag to false');
        context.goToLibraryAfterLoadingFlag = false;
      },
      setTOTWStartupLocationHistory: (context, event) => {
        console.log("TOTW Startup location now set to History")
        context.totwStartupLocation = TOTWStartupLocation.history;
      },
      setTOTWStartupLocationHome: (context, event) => {
        console.log("TOTW Startup location now set to Home")
        context.totwStartupLocation = TOTWStartupLocation.home;
      },
      setDailyChallengeStartupLocationHistory: (context, event) => {
        console.log("DailyChallenge Startup location now set to History")
        context.dailyChallengeStartupLocation = DailyChallengeStartupLocation.history;
      },
      setDailyChallengeStartupLocationNotes: (context, event) => {
        console.log("DailyChallenge Startup Location now set to Notes");
        context.dailyChallengeStartupLocation = DailyChallengeStartupLocation.notes;
      },

      setDailyChallengeStartupLocationHome: (context, event) => {
        console.log("DailyChallenge Startup location now set to Home")
        context.dailyChallengeStartupLocation = DailyChallengeStartupLocation.home;
      },

      setFeedbackProfileStartupLocationMultiPackageHub: (context, event) => {
        console.log('Feedback Profile Startup location now set to MultiPackageHub');
        this.feedbackProfileService.setFeedbackProfileStartupLocation(FeedbackProfileStartupLocation.multiPackageHub);
      },

      setFeedbackProfileStartupLocationProfile: (context, event) => {
        console.log('Feedback Profile Startup location now set to Profile');
        this.feedbackProfileService.setFeedbackProfileStartupLocation(FeedbackProfileStartupLocation.profile);
      },

      setProfileSectionToProfile: (context, event) => {
        console.log("Profile Section was set to Profile")
        context.profileSection = ProfileSection.profile;
      },
      setProfileSegmentToInsights: (context, event) => {
        console.log("Profile Section was set to Insights")
        this.profileService.setProfileSegment(ProfileSegment.insights);
      },
      setProfileSegmentToStats: (context, event) => {
        console.log("Profile Section was set to History")
        this.profileService.setProfileSegment(ProfileSegment.stats);
      },
      setProfileInsigthSwiperIndexToDevelopment: (context, event) => {
        console.log("Profile Insights Swiper Index was set to Development")
        this.profileService.setProfileInsightSwiperIndex(-1);
      },
      resetProfileSectionToRelyOnSubstateHistory: (context, event) => {
        console.log("Profile Section was reset to rely on substate history");
        context.profileSection = ProfileSection.relyOnSubstateHistory;
      },
      setLastTrainingWasHistoryOrLibrary: (context, event) => {
        console.log("setLastTrainingWasHistoryOrLibrary called with lastTrainingWasHistoryOrLibrary now being " + context.lastTrainingWasHistoryOrLibrary);
        context.lastTrainingWasHistoryOrLibrary = true;
      },
      resetLastTrainingWasHistoryOrLibrary: (context, event) => {
        context.lastTrainingWasHistoryOrLibrary = false;
        console.log("resetLastTrainingWasHistoryOrLibrary called with lastTrainingWasHistoryOrLibrary now being " + context.lastTrainingWasHistoryOrLibrary);
      },
      setMicrotrainingWasAlreadySet: (context, event) => {
        context.microtrainingWasAlreadySet=true;
        console.log("setMicrotrainingWasAlreadySet called with microtrainingWasAlreadySet now being " + context.microtrainingWasAlreadySet);
      },
      resetMicrotrainingWasAlreadySet: (context, event) => {
        context.microtrainingWasAlreadySet=false;
        console.log("resetMicrotrainingWasAlreadySet called with microtrainingWasAlreadySet now being " + context.microtrainingWasAlreadySet);
      },
      resetMicrotraining: (context, event) => {
        this.singleTrainingService.resetMicrotraining();
      },
      setSelectedSkill: (context, event : SelectSkill) => {
        this.singleTrainingService.setSelectedSkill(event.skillPk);
      },
      setSkillTypeStartupLocationProfil: (context, event) => {
        this.profileService.setSkillTypeStartupLocation(SkillTypeStartupLocation.profile);
        context.skillTypeStartupLocation = SkillTypeStartupLocation.profile;
      },
      setSkillTypeStartupLocationPretraining: (context, event) => {
        this.profileService.setSkillTypeStartupLocation(SkillTypeStartupLocation.pretraining);
        context.skillTypeStartupLocation = SkillTypeStartupLocation.pretraining;
      },
      setSkillTypeStartupLocationPackageHistory: (context, event) => {
        this.profileService.setSkillTypeStartupLocation(SkillTypeStartupLocation.packageHistory);
        context.skillTypeStartupLocation = SkillTypeStartupLocation.packageHistory;
      },
      setSkillTypeStartupLocationReturningDuringOnboarding: (context, event) => {
        this.profileService.setSkillTypeStartupLocation(SkillTypeStartupLocation.returningDuringOnboarding);
        context.skillTypeStartupLocation = SkillTypeStartupLocation.returningDuringOnboarding;
      },
      assignFrontendError: assign<SavviContext, SavviEvent>((context, event: HandleError) => {
        console.log("AssignErrors assigned following error: ", event.error);
        return {errors: {error: event.error}};
      }),
      assignErrors: assign<SavviContext, SavviEvent>((context, event: any, meta) => {
        console.log("AssignErrors assigned following error: " + JSON.stringify(event.data));
        console.log("AssignErrors got following meta: " + JSON.stringify(meta));
        return {errors: {error: event.data}};
      }),
      removeOldErrors: assign<SavviContext, SavviEvent>((context, event: any, meta) => {
        return {errors: null};
      }),
      // assignPackageErrors: assign<SavviContext, SavviEvent>((context, event: any) => {
      //   console.log(JSON.stringify(event.data));
      //   if(event.data.error.expired) {
      //     return {errors : {error: {detail: this.translationService.translate.instant('login.mainPage.packageExpired')}}};// Your account has expired!
      //   } else {
      //     return {errors : {error: {detail: this.translationService.translate.instant('login.mainPage.noPackage')}}}; // You're not assigned to a training"
      //   }
      // }),
      updateUserDetails: (context, event: any) => {
        console.log('UPDATE USER DETAILS');
        //context.user =  {...(event.data)};
        console.log('context user: ', context.user);
        console.log('event.data', event.data);
        context.user.id = event.data.id;
        context.user.first_name = event.data.first_name;
        context.user.last_name = event.data.last_name;
        context.user.email = event.data.email;
        context.user.user_avatar = event.data.user_avatar;
        context.user.user_config = event.data.user_config;
        context.user.user_preferences = event.data.user_preferences;
        console.log('context user: ', context.user);

      },
      setOneSignalExternalUserId: (context, event : any) => {
        console.log("Called setOneSignalExternalUserId with email " + event.data.email);
        this.pushNotificationService.setExternalUserId(event.data.email);
      },
      /*updateHistory: (context, event: any) => {
      
      },*/
      triggerLogout: (context, event) => {
        this.authenticationService.logout();
        localStorage.setItem('loggedInBefore', 'true');
      },
      setOnsiteTrainingCheckFlag: (context, event) => {
        context.currentPretrainingProgress.onsiteTrainingOverAlreadyChecked = true;
      },
      resetOnsiteTrainingCheckFlag: (context, event) => {
        context.currentPretrainingProgress.onsiteTrainingOverAlreadyChecked = false;
      },
      //Feedback Voting
      sendFeedbackOnMicrotraining: (context, event) => {
        this.feedbackVotingService.sendFeedbackOnMicrotraining(context.packages[0].pk, this.singleTrainingService.getCurrentWeekStatus().microtraining.pk);
      }, 

      dontUseProgressionStateTraining: (context, event) => {
        console.log("dontUseProgressionStateTraining is called");
        this.exerciseService.setUseProgressionState(false);
      },
      useProgressionStateTraining: (context, event) => {
        console.log("useProgressionStateTraining is called");
        
        this.exerciseService.setUseProgressionState(true);
      },

      // NOTES
      setTextualNoteModalShown : (context, event) => {
        context.textualNoteModalShown = true;
      },
      resetTextualNoteModalShown : (context, event) => {
        context.textualNoteModalShown = false;
      },
      displayTextualNoteModal : (context, event) => {
        this.notesService.displayTextualNoteModal();
      },
      closeTextualNoteModal : (context, event) => {
        console.log("closeTextualNoteModal is called");
        this.notesService.closeTextualNoteModal();
      },
      resetShouldNoteBeSaved: (context, event) => {
        console.log("resetShouldNoteBeSaved is called");
        this.notesService.shouldNoteBeSaved = false;
      },
      setRMIsPossibleForNoteIfTrainingModeIsNormal: (context, event) => {
        console.log("setRMIsPossibleForNoteIfTrainingModeIsNormal is called with (context.trainingMode == TrainingMode.normal) = " + (context.trainingMode == TrainingMode.normal));
        if (context.trainingMode == TrainingMode.normal) {
          this.notesService.setAreRMIsPossible(true);
        }
      },
      setRMIsPossibleForDailyChallenge: (context, event) => {
        console.log("setRMIsPossibleForDailyChallenge is called and context.isDailyChallengeHistory = " + context.isDailyChallengeHistory);
        if (!context.isDailyChallengeHistory) {
          this.notesService.setAreRMIsPossible(true);
        }
      },
      resetRMIsPossibleForNote: (context, event) => {
        console.log("resetRMIsPossibleForNote is called");
        this.notesService.setAreRMIsPossible(false);
      },
      resetHasSeenNoteRMIForDailyChallenge: (context, event) => {
        console.log("setHasSeenNoteRMIForDailyChallenge(false) is called");
        this.dailyChallengeService.setHasSeenNoteRMIForDailyChallenge(false);
      },

      // GAMIFICATION
      displayPlayerProgressionScreen : (context, event) => {
        this.router.navigateByUrl('/player-progression')
      },
      displaySpaceMapScreen : (context, event) => {
        console.log("space map display method is called");
        this.router.navigateByUrl('/space-map')
      },

      sendRMIRequirementsMet: (context, event : RMIRequierementsMet) => {
        if(context.trainingMode == TrainingMode.normal){
          this.rewardedMicroInteractionService.sendRewardedMicroInteraction(event.RMI_name);
        }
      },
      sendFakeRMIRequirementsMet: (context, event : RMIRequierementsMetFake) => {
        this.rewardedMicroInteractionService.showFakeRewardedMicroInteraction(event.RMI_name);
      },
      setRMIName: (context, event : RMIRequierementsMet) => {
        this.rewardedMicroInteractionService.setRMIName(event.RMI_name);
      },
      resetProgressDetails: (context, event) => {
        this.gamificationService.resetProgressDetails();
      },
      startPlayerProgressAnimation: (context, event) => {
        this.gamificationService.triggerPlayerProgressAnimation(context.playerProgressionStartup, context.showRestOfPlayerProgressionUpdate);
      },
      resetExerciseStartTimer: (context, event) => {
        this.rewardedMicroInteractionService.resetExerciseStartTimer();
      },
      showAchievementReviewAnimation : (context, event) => {
        this.achievementReviewService.showAchievementReviewAnimation();
      },
      hideAchievementReviewAnimation : (context, event) => {
        this.achievementReviewService.hideAchievementReviewAnimation();
      },
      setAchievementReviewChecked: (context, event) => {
        context.popupChecks.achievementReviewChecked = true;
      },
      resetAllPopupChecks: (context, event) => {
        context.popupChecks.achievementReviewChecked = false;
      },
      resetPlayerProgressLevelUp: (context, event) => {
        this.gamificationService.resetPlayerProgressLevelUp();
      },
      setPlayerProgressDisplayedPoints: (context, event) => {
        this.gamificationService.setPlayerProgressDisplayedPoints();
      },
      setShowSmallPointsAnimation: (context, event) => {
        this.gamificationService.setShowSmallPointsAnimation();
      },
      setPlayerSmallPointsDisplayedPoints: (context, event) => {
        this.gamificationService.setPlayerSmallPointsDisplayedPoints();
      },
      startSmallPointAnimation: (context, event) => {
        this.gamificationService.updateSmallPointsAnimation();
      },
      presentToastWithSmallPointsAnimation: (context, event) => {
        this.rewardedMicroInteractionService.presentToastWithSmallPointsAnimation(this.gamificationService.getPointsGained(), );
      },
      setPlayerPointsTypeMicrotraining: (context, event) => { 
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.microtraining);
      },
      setPlayerPointsTypeDailyChallenge: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.daily_challenge);
      },
      setPlayerPointsTypeQuiz: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.quiz);
      },
      setPlayerPointsTypeTOTW: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.totw);
      },
      setPlayerPointsTypeRMI: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.rmi);
      },
      setPlayerPointsTypeRMIAchievement: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.rmi_achievement);
      },
      setPlayerPointsTypeStreak: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.streak);
      },
      setPlayerPointsTypeDuel: (context, event) => {
        this.rewardedMicroInteractionService.setPlayerPointType(PlayerPointType.duel);
      },
      setPlayerProgressionStartupMicrotraining: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.microtraining;
      },
      setPlayerProgressionStartupDailyChallenge: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.daily_challenge;
      },
      setPlayerProgressionStartupQuiz: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.quiz;
      },
      setPlayerProgressionStartupTOTW: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.totw;
      },
      setPlayerProgressionStartupRMI: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.rmi;
      },
      setPlayerProgressionStartupRMIAchievement: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.rmi_achievement;
      },
      setPlayerProgressionStartupStreak: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.streak;
      },
      setPlayerProgressionStartupDuel: (context, event) => {
        context.playerProgressionStartup = PlayerPointType.duel;
      },
      setSpaceMapShowLevelUp: (context, event) => {
        this.gamificationService.setShowLevelUpOnSpaceMap();
      },
      resetSpaceMapShowLevelUp: (context, event) => {
        this.gamificationService.resetShowLevelUpOnSpaceMap();
      },
      setShowRestOfPlayerProgressionUpdate: (context, event) => {
        context.showRestOfPlayerProgressionUpdate = true;
      },
      resetShowRestOfPlayerProgressionUpdate: (context, event) => {
        context.showRestOfPlayerProgressionUpdate = false;
      },
      setPlayerProgressionHasStarted : (context, event) => {
        context.playerProgressionHasStarted = true;
      },
      resetPlayerProgressionHasStarted : (context, event) => {
        context.playerProgressionHasStarted = false;
      },
      setSkippAllAnimationsProgressionComponent: (context, event) => {
        console.log("setSkippAllAnimationsProgressionComponent called in machine service")
        this.gamificationService.skipAllAnimationsTriggered()
      },
      resetSkippAllAnimationsProgressionComponent: (context, event) => {
        this.gamificationService.resetSkipAllAnimationsTrigger();
      },
      setDailyChallengeFailed: (context, event) => {
        console.log("setDailyChallengeFailed called in machine service")
        this.dailyChallengeService.setDailyChallengeFailed();
      },
      resetDailyChallengeFailed: (context, event) => {
        console.log("resetDailyChallengeFailed called in machine service")
        this.dailyChallengeService.resetDailyChallengeFailed();
      },
      displayWeeklyQuizIntro : (context, event) => {
        this.router.navigateByUrl('/weekly-quiz')
      },
      displayCurrentQuestion  : (context, event) => {
        //reset showExplanation
        this.quizService.resetQuizQuestionExplanationModalShown();

        this.router.navigateByUrl('/weekly-quiz/weekly-quiz-question')
      },
      setSelectedAnswer: (context, event : QuestionAnswerGiven) => {
        return this.quizService.setSelectedAnswer(event.choice);
      },
      displayQuestionExplanation : (context, event) => {
        this.quizService.displayQuizQuestionExplanationModal();
      },
      increaseCurrentSelectedQuestion : (context, event) => {
        this.quizService.currentQuestion += 1;
      },
      decreaseCurrentlySelectedQuestion : (context, event) => {
        this.quizService.currentQuestion -= 1;
      },
      displayWeeklyQuizSummary: (context, event) => {
        //reset quizService
        this.quizService.resetQuizQuestionExplanationModalShown();
        this.quizService.resetCurrentQuestion();

        this.router.navigateByUrl('/weekly-quiz/weekly-quiz-summary');
      },
      setWeeklyQuizTimeReference: (context, event) => {
        this.pushNotificationService.setWeeklyQuizTimeReference();
      },

      //Tour Guide
      setShowTourGuide : (context, event) => {
        return this.tourGuideService.setShowTourGuide();
      },


      openJourneyAchievementsTooltip: (context, event) => {
        this.tourGuideService.openJourneyAchievementsTooltip();
      },
      closeJourneyAchievementsTooltip: (context, event) => {
        this.tourGuideService.closeJourneyAchievementsTooltip();
      },
      resetShowTourGuide: (context, event) => {
        return this.tourGuideService.resetShowTourGuide();
      },

      openAchievementsTooltip : (context, event) => {
        this.tourGuideService.startAchievementsTooltip();
      },

      closeAchievementsTooltip : (context, event) => {
        this.tourGuideService.stopAchievementsTooltip();
      },

      openAchievementsBackTooltip: (context, event) => {
        this.tourGuideService.startAchievementsBackTooltip();
      },

      closeAchievementsBackTooltip: (context, event) => {
        this.tourGuideService.stopAchievementsBackTooltip();
      },

      openJourneyTabTooltip : (context, event) => {
        this.tourGuideService.openJourneyTabTooltip();
      },
      closeJourneyTabTooltip : (context, event) => {
        this.tourGuideService.closeJourneyTabTooltip();
      },
      
      openPlanet2Tooltip : (context, event) => {
        this.tourGuideService.openShowPlanet2Tooltip();
      },
      closePlanet2Tooltip : (context, event) => {
        this.tourGuideService.closeShowPlanet2Tooltip();
      },

      startAchievementsAnimation : (context, event) => {
        this.tourGuideService.startAchievementsAnimation();
      },

      stopAchievementsAnimation : (context, event) => {
        this.tourGuideService.stopAchievementsAnimation();
      },

      openJourneySpacemapTooltip:( context, event) => {
      this.tourGuideService.openJourneySpacemapTooltip();
      },

      closeJourneySpacemapTooltip: (context, event) => {
      this.tourGuideService.closeJourneySpacemapTooltip();
      },

      openHomeTabTooltip:( context, event) => {
      this.tourGuideService.openHomeTabTooltip();
      },

      closeHomeTabTooltip: (context, event) => {
      this.tourGuideService.closeHomeTabTooltip();
      },
      openPreStartHomeTabTooltip:( context, event) => {
      this.tourGuideService.openPreStartHomeTabTooltip();
      },

      closePreStartHomeTabTooltip: (context, event) => {
      this.tourGuideService.closePreStartHomeTabTooltip();
      },

      openTabBar: (context, event) => {
        this.tourGuideService.openTabBar();
      },

      closeTabBar: (context, event) => {
        this.tourGuideService.closeTabBar();
      },

      saveChangeHapticsEnabled: (context, event: SaveChangeHapticsPreferences) => {
        return this.settingsService.saveUserHapticsSettings((<SaveChangeHapticsPreferences>event).hapticsEnabled);
      },

      saveProfileTutorialViewed: (context, event) => {
        this.profileService.saveProfileTutorialViewed();
      },
      
      //TIP OF THE WEEK
      displayTipOfTheWeekScreen: (context, event) => {
        this.router.navigateByUrl('/tip-of-the-week')
      },

      //DAILY CHALLENGE
      displayNewChallengeScreen: (context, event) => {
        this.router.navigateByUrl('/new-challenge')
      },
      displayDailyChallengeAcceptedScreen: (context, event) => {
        this.router.navigateByUrl('/daily-challenge-accepted')
      },
      displayDailyChallengeSuccessAnimationScreen: (context, event) => {
        this.router.navigateByUrl('/daily-challenge-success-animation')
      },
      displayDailyChallengeSummaryScreen: (context, event) => {
        this.router.navigateByUrl('/daily-challenge-summary')
      },
      displaySetTimeDailyChallengeModal : (context, event) => {
        this.dailyChallengeService.displaySetTimeDailyChallengeModal();
      },
      resetRejectDailyChallengeModal : (context, event) => {
        this.dailyChallengeService.resetRejectDailyChallengenModalShown();
      },
      displayDailyChallengeCompletionFeedbackScreen: (context, event) => {
        console.log("displayDailyChallengeCompletionFeedbackScreen called");
        this.router.navigateByUrl('/daily-challenge-completion-feedback')
      },

      //PILOT USER
      setWeekJustIncreased : (context,event) => {
        this.pilotUserService.setWeekJustIncreased();
      },
      resetWeekJustIncreased : (context,event) => {
        //this._savviMachine.getStateNodeById("single_training").getStateNodeByPath("single_training_substate_history").
        this.pilotUserService.resetWeekJustIncreased();
      },

      setSubscriptionStatus: (context, event) => {
        console.log(this.licenseSelfService.getSubscriptionStatus(), "Subscription Status Q11")
        context.subscription = true;
      },

      //END OF JOURNEY
      //CERTIFICATE
      setEOJFlowStarted: (context, event) => {
        context.EOJFlowStarted = true;
      },
      resetEOJFlowStarted: (context, event) => {
        context.EOJFlowStarted = false;
      },


      //ERRORS
      displayInternetProblemModal : (context, event) => {
        this.errorService.displayInternetProblemModal();
      },
      displayErrorScreen: (context, event) => {
        //let _statsPerWeek = (<any>event).data.statsPerWeek;
        this.router.navigateByUrl('request-error'/*, {state: { statsPerWeek: _statsPerWeek}}*/);
      },
      setIsAppCurrentlyRetryingRequestsTrue: (context, event) => {
        this.errorService.setIsAppCurrentylRetryingRequests(true);
      },
      setKnownErrorCode: (context, event) => {
        this.errorService.setErrorCode(context.errors.error["status"]);
      },

      //HOME SPEECH BUBBLE TEXT
      setUserCompletedTask: (context, event) => {
        console.log("Setting profileService.userCompletedTask to true");
        this.profileService.setUserCompletedTask();
      },
      setUserCompletedTaskTypeMicrotraining: (context, event) => {
        console.log("Setting profileService.userCompletedTaskType to microtraining");
        this.profileService.setUserCompletedTaskType(WeekHistoryActivityType.microtraining);
      },
      setUserCompletedTaskTypeQuiz: (context, event) => {
        console.log("Setting profileService.userCompletedTaskType to quiz");
        this.profileService.setUserCompletedTaskType(WeekHistoryActivityType.quiz);
      },
      setUserCompletedTaskTypeTOTW: (context, event) => {
        console.log("Setting profileService.userCompletedTaskType to tip_of_the_week");
        this.profileService.setUserCompletedTaskType(WeekHistoryActivityType.tip_of_the_week);
      },
      setUserCompletedTaskTypeDailyChallenge: (context, event) => {
        console.log("Setting profileService.userCompletedTaskType to daily_challenge");
        this.profileService.setUserCompletedTaskType(WeekHistoryActivityType.daily_challenge);
      },
      
      //PAGE HISTORY 

      resetPageHistoryGraph: (context, event) => {
        console.log("Set Page History Graph Observable to true")
        this.multiPackageHubService.resetPackageHistoryRedrawingItemSubject(true)
      },

      //STREAK COUNTER
      displayStreakIncreasedModal: (context, event) => {
        console.log("Open Streak Unlocked Modal");  
        this.gamificationService.openShowStreakCounterUnlockedModal()
      },

      closeStreakIncreasedModal: async (context, event) => {
        console.log("close Streak Unlocked Modal");
        this.gamificationService.closeShowStreakCounterUnlockedModal();
      },
      displayStreakCounterToolTip: (context, event) => {
        console.log("Open Streak Counter Tooltip");  
        this.gamificationService.openShowStreakCounterTooltip();
      },
      closeStreakCounterToolTip: (context, event) => {
        console.log("Close Streak Counter Tooltip");  
        this.gamificationService.closeShowStreakCounterTooltip();
      },
      
      openStreakCounterAnimation: (context, event) => {
        this.gamificationService.openShowStreakCounterTutorialAnimation();
      },

      notifyStreakCounterAnimationViewed: (context, event) => {
        this.gamificationService.saveStreakExplainationViewed();
        this.gamificationService.loadStreakCounterData();
      },

      openStreakCounterDetailModal: (context, event) => {
        this.gamificationService.openStreakDetailModal();
      },
      closeStreakCounterDetailModal: (context, event) => {
        this.gamificationService.closeStreakDetailModal();
      },

      setHasUserSeenStreakCountPointAnimation: (context, event) => {
        this.gamificationService.setHasUserSeenStreakCountPointAnimation();
      },
      resetHasUserSeenStreakCountPointAnimation: (context, event) => {
        this.gamificationService.resetHasUserSeenStreakCountPointAnimation();
      },
      setCameFromStreakCounter: (context, event) => {
        this.gamificationService.setCameFromStreakCounter();
      },
      resetCameFromStreakCounter: (context, event) => {
        this.gamificationService.resetCameFromStreakCounter();
      },

      //Lottery
      displayLotteryUpdateScreen: (context, event) => {
        this.router.navigateByUrl('/lottery-update')
      },
      displayLotteryResultScreen: (context, event) => {
        this.router.navigateByUrl('/lottery-result')
      },

      //MultiPackageHub
      

      setAchievementData: (context, event) => {
        this.gamificationService.setAllAchievementsWithProgress(this.multiPackageHubService.getAllAchievementsData());
      },
      setFeedbackProfileData: (context, event) => {
        this.feedbackProfileService.setFeedbackProfileData(this.multiPackageHubService.getFeedbackProfileData());
      },

      startConsoleLogTimer: (context, event) => {
        console.time("ConsoleLogTimer");
      },
      stopConsoleLogTimer: (context, event) => {
        console.timeEnd("ConsoleLogTimer");
      },
    }
  };

  private _savviMachine = Machine<SavviContext, SavviSchema, SavviEvent>(savviMachineConfig).withConfig(this.savviMachineOptions);

  private savviMachineService = interpret(this._savviMachine, {devTools: true}).onTransition((state) => console.log(state.event.type + "-->" + JSON.stringify(state.value))).start();


  savviState$ = fromEventPattern<[State<SavviContext, SavviEvent>, EventObject]>(
    handler => {
      return this.savviMachineService.onTransition(handler)
    },
    (_, service) => {
      // TODO: figure out why this removeHandler is being called at strange times
      
      console.groupCollapsed("Machine Service");
      console.warn("Service being stopped");
      console.log(service);
      console.groupEnd();
      // service.stop();
    }
  ).pipe(map(([state, _]) => state));


  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private authService: AuthService,
      private authenticationService : AuthenticationService,
      private pushNotificationService: PushNotificationService,
      private singleTrainingService: SingleTrainingService,
      private profileService: ProfileService,
      private packageService: PackageService,
      private questionService: PretrainingService,
      private licenseSelfService: LicenseSelfService,
      private groupTrainingService: GroupTrainingService,
      private achievementReviewService : AchievementReviewService,
      private loaderService : LoaderService,
      private gamificationService: GamificationService,
      private leaderboardService: LeaderboardService,
      private tourGuideService: TourGuideService,
      private quizService: QuizService,
      private translationService : TranslationService,
      private feedbackVotingService: FeedbackVotingService,
      private pilotUserService: PilotUserService,
      private tipOfTheWeekService : TipOfTheWeekService,
      private endOfJourneyService: EndOfJourneyService,
      private ngZone : NgZone,
      private dailyChallengeService : DailyChallengeService,
      private errorService : ErrorService,
      private rewardedMicroInteractionService : RewardedMicroInteractionService,
      private notesService : NotesService,
      private homeService : HomeService,
      private journeyService : JourneyService,
      private settingsService : SettingsService,
      private duelService : DuelService,
      private feedbackProfileService: FeedbackProfileService,
      private exerciseService : ExerciseService,
      private multiPackageHubService : MultiPackageHubService,
      private lotteryService : LotteryService,
      private themingService: ThemingService,
      private animationFactoryService: AnimationFactoryService,
      private preTrainingService: PretrainingService,
      private dataStorageService: DataStorageService
    ) {
      this.authService.triggerTimer.subscribe(
        () =>
        this.savviMachineService.send(new RefreshLogin()))
    }

  send(event: SavviEvent) {
    this.savviMachineService.send(event);
  }
}
