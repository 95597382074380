import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResolveAnimationSrcPipe } from './resolve-animation-src.pipe';
import { ResolveImgSrcPipe } from './resolve-img-src.pipe';
import { ResolveVariablesPipe } from './resolve-variables.pipe';

@NgModule({
	declarations: [ResolveAnimationSrcPipe, ResolveImgSrcPipe, ResolveVariablesPipe],
	imports: [CommonModule],
	exports: [ResolveAnimationSrcPipe, ResolveImgSrcPipe, ResolveVariablesPipe]
})
export class ConfigFileAssetResolverPipesModule {}
