import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorFeedbackPageProps, FeedbackBehavior, FeedbackProfile, GivenFrom, NonVisitedStructureSkills, SkillStats, TextBoxFeedback, TextFeedbackQuestion } from '@app/_models/feedback-profile';
import { map, of } from 'rxjs';
import { ProfileService } from './profile.service';
import { differenceInMilliseconds } from 'date-fns';
import { PackageService } from './package.service';
import { FeedbackProfileStartupLocation } from '@app/_models/feedback-profile-start-up-location';
import { RouterLinkWithHrefDelegate } from '@ionic/angular';
import { GamificationService } from './gamification.service';
import { PlayerProgressInfo } from '@app/_models/player-progress-info';
import { Filesystem, Directory, GetUriResult } from '@capacitor/filesystem';
import { User } from '@app/_models/user';
import { TranslationService } from './translation.service';

@Injectable({
	providedIn: 'root'
})
export class FeedbackProfileService {
	private userFeedbacklink: string;
	private feedbackProfile: FeedbackProfile;
	private feedbackAvailable: boolean = false;
	private activeSkill: { name: string; id: number };
	private activeScoresSkill: Map<string, number> = new Map();
	private activeScoresBehaviors = new Object();
	private activeBlindspots: Set<string> = new Set();
	private availibleGiveFrom: Array<GivenFrom> = new Array();
	private availableFeedbacks: Set<GivenFrom> = new Set();
	private showTextFeedbacks: boolean = false;
	private isProfileGenerated: boolean = false;
	private pdfBlob: Blob;
	private fileUri: GetUriResult;

	private unvisitedFeedbacks: Set<string> = new Set();
	private behavoirTextFeedbacks: Map<
		string,
		{
			[GivenFrom.Colleague]: TextFeedbackQuestion[];
			[GivenFrom.Manager]: TextFeedbackQuestion[];
			[GivenFrom.Subordinate]: TextFeedbackQuestion[];
			[GivenFrom.Total]: TextFeedbackQuestion[];
		}
	> = new Map();

	private feedbackProfileStartupLocation: FeedbackProfileStartupLocation;

	// BehaviorFeedbackPage

	private currentBehavior: BehaviorFeedbackPageProps | null;

	constructor(
		private apiService: ApiService,
		private packageService: PackageService,
		private translationService: TranslationService,
		private gamificationService: GamificationService
	) {}

	get isBehaviorTextAvailable(): boolean {
		return this.packageService.isThreeSixtyDegreeBehaviorBasedTextFeedbackEnabled();
	}

	get isTextFeedbackAvailable(): boolean {
		return this.packageService.isThreeSixtyDegreeFeedbackEnabled();
	}

	async loadFeedbackProfile() {
		if (!this.packageService.getCurrentPackage().threesixty_degree_feedback_enabled) return Promise.resolve();
		const response = await this.apiService
			.get<FeedbackProfile>('/threesixty_degree_feedback/stats')
			.pipe(map((feedbackProfile) => (this.feedbackProfile = feedbackProfile)))
			.toPromise();
		try {
			if (this.feedbackProfile == undefined) throw new Error(`feedbackProfile is undefined`);
		} catch (error) {
			return Promise.reject(error);
		}

		this.setAvailableFeedbacks();
		this.setUnvisitedFeedbacks(this.feedbackProfile.textbox_feedbacks.structure.non_visited_structure);
		this.setBehaviorTextFeedbacks();

		console.dir('QuestionFeedback', this.behavoirTextFeedbacks);

		if (this.availableFeedbacks.size > 0) {
			this.feedbackAvailable = true;
		}
		return Promise.resolve(response);
	}

	loadFeedbackLink(): Promise<string> {
		if (!this.packageService.getCurrentPackage().threesixty_degree_feedback_enabled) return Promise.resolve('');
		return this.apiService
			.get<string>('/threesixty_degree_feedback/link')
			.pipe(map((feedbacklink) => (this.userFeedbacklink = feedbacklink)))
			.toPromise();
	}

	async loadFeedbackProfilePDF(user: User) {
		try {
			this.isProfileGenerated = false;
			const response: Blob = (await this.apiService.downloadPDFFormat('/profile/export').toPromise()) as Blob;
			this.pdfBlob = response; // Save the PDF blob to the private variable
			console.log('loadFeedbackProfilePDF', response);
			this.isProfileGenerated = true;

			const base64Data = await this.blobToBase64(this.pdfBlob);

			// First, write the PDF to the local file system
			const fileName = this.getPDFName(user);
			const fileWrite = await Filesystem.writeFile({
				path: fileName,
				data: base64Data,
				directory: Directory.Documents,
				recursive: true
			});

			// Get the URI of the saved file
			this.fileUri = await Filesystem.getUri({
				directory: Directory.Documents,
				path: fileName
			});
		} catch (error) {
			console.error('Error downloading the PDF', error);
			this.isProfileGenerated = false;
		}
		console.log('isProfileGenerated', this.isProfileGenerated);
	}

	getPDFName(user: User): string {
		let nameAndTime: string = user.first_name + '_' + user.last_name + '_' + this.getFormattedDate();
		return this.translationService.translate.instant('feedbackProfile.feedbackSharing.fileName', { userTime: nameAndTime });
	}

	getFormattedDate() {
		const date = new Date(); // Creates a new date object with the current date and time
		const year = date.getFullYear(); // Gets the full year (e.g., 2024)
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Gets the month, adds 1 because getMonth() returns 0-11
		const day = date.getDate().toString().padStart(2, '0'); // Gets the day of the month and ensures it is two digits

		return `${year}_${month}_${day}`; // Formats the date
	}

	getFeedbackLink(): string {
		return this.userFeedbacklink;
	}

	async saveTextfeedbackVisited(feedback_ids: number[]) {
		const response = await this.apiService
			.post<{ none_visited_feedback: boolean }>('/threesixty_degree_feedback/general_feedback_viewed', { feedback_ids })
			.pipe(
				map((response) => {
					this.feedbackProfile.none_visited_feedback = response.none_visited_feedback;
					return response;
				})
			)
			.toPromise();
		try {
			if (this.feedbackProfile == undefined) throw new Error(`feedbackProfile is undefined`);
		} catch (error) {
			return Promise.reject(error);
		}
		return Promise.resolve(response);
	}

	async saveBehaviorTextfeedbackVisited(open_text_feedback_ids: number[]) {
		const response = await this.apiService
			.post<NonVisitedStructureSkills[]>('/threesixty_degree_feedback/feedback_viewed', { open_text_feedback_ids })
			.pipe(map((response) => response))
			.toPromise();
		try {
			if (this.feedbackProfile == undefined) throw new Error(`feedbackProfile is undefined`);
		} catch (error) {
			return Promise.reject(error);
		}

		this.feedbackProfile.textbox_feedbacks.structure.non_visited_structure = response;
		this.setUnvisitedFeedbacks(response);
	}

	async saveFirstTimeVisitedFeedbackProfile() {
		const response = await this.apiService
			.post<PlayerProgressInfo>('/threesixty_degree_feedback/explanation')
			.pipe(
				map((response) => {
					this.gamificationService.setPlayerProgressInfo(response);
					return response;
				})
			)
			.toPromise();
		return Promise.resolve(response);
	}

	getIsFeedbacklinkAvailable(): boolean {
		return this.userFeedbacklink !== 'no feedback questions';
	}

	getFeedbackProfileStartupLocation(): FeedbackProfileStartupLocation {
		return this.feedbackProfileStartupLocation;
	}
	setFeedbackProfileStartupLocation(location: FeedbackProfileStartupLocation) {
		this.feedbackProfileStartupLocation = location;
	}

	getBehaviorTextFeedbacks(
		skill_id: number,
		behavior_id: number
	):
		| { [GivenFrom.Colleague]: TextFeedbackQuestion[]; [GivenFrom.Manager]: TextFeedbackQuestion[]; [GivenFrom.Subordinate]: TextFeedbackQuestion[]; [GivenFrom.Total]: TextFeedbackQuestion[] }
		| undefined {
		return this.behavoirTextFeedbacks.get(`${skill_id}-${behavior_id}`);
	}

	getUnvisitedFeedbacks(skill_id: number, behavior_id: number): boolean {
		return this.unvisitedFeedbacks.has(`${skill_id}-${behavior_id}`);
	}

	setUnvisitedFeedbacks(non_visited_structure: NonVisitedStructureSkills[]) {
		this.unvisitedFeedbacks.clear();
		non_visited_structure.forEach((skill) => {
			skill.behaviors.forEach((behavior) => {
				this.unvisitedFeedbacks.add(`${skill.skill_id}-${behavior.behavior_id}`);
			});
		});
		console.log('setUnvisitedFeedbacks', this.unvisitedFeedbacks);
	}

	setBehaviorTextFeedbacks() {
		try {
			this.feedbackProfile.textbox_feedbacks.structure.all_textbox_feedbacks.forEach((skill) => {
				skill.behaviors.forEach((behavior) => {
					// Store the key object in a variable
					let key = `${skill.skill_id}-${behavior.behavior_id}`;

					this.behavoirTextFeedbacks.set(key, {
						[GivenFrom.Colleague]: [],
						[GivenFrom.Manager]: [],
						[GivenFrom.Subordinate]: [],
						[GivenFrom.Total]: []
					});

					// Use the same key object when you call get
					let feedbacks = this.behavoirTextFeedbacks.get(key);

					[GivenFrom.Colleague, GivenFrom.Manager, GivenFrom.Subordinate].forEach((givenFrom) => {
						behavior.according_feedback[givenFrom].forEach((question) => {
							if (question.textbox_input !== '') {
								feedbacks[givenFrom].push(question);
							}
						});
					});

					feedbacks[GivenFrom.Total] = [GivenFrom.Manager, GivenFrom.Colleague, GivenFrom.Subordinate].reduce((acc, givenFrom) => {
						return acc.concat(behavior.according_feedback[givenFrom]);
					}, []);

					console.log('setBehaviorTextFeedbacks', this.behavoirTextFeedbacks);
				});
			});
		} catch (error) {
			console.error(error);
		}
	}

	setFeedbackProfileData(feedbackProfile: FeedbackProfile) {
		this.feedbackProfile = feedbackProfile;
		this.setAvailableFeedbacks();
		if (this.availableFeedbacks.size > 0) {
			this.feedbackAvailable = true;
		}
	}

	getFeedbackProfile() {
		return this.feedbackProfile;
	}

	getFeedbackAvailable() {
		return this.feedbackAvailable;
	}

	getIsNewFeedback() {
		return this.feedbackProfile.none_visited_feedback;
	}

	getAllSkills(): Array<{ name: string; id: number }> {
		let skills = new Array<{ name: string; id: number }>();
		this.feedbackProfile.skill_stats[GivenFrom.Total].forEach((skill) => {
			console.log('Get All Skills set', skill);
			skills.push({ name: skill.skill_stat.skill.name, id: skill.skill_stat.skill.id });
		});
		return skills;
	}

	getActiveSkill() {
		return this.activeSkill;
	}

	getShowBlindSpot(behavior: string): boolean {
		return this.activeBlindspots.has(behavior);
	}

	setActiveSkill({ name: skillName, id: skillId }: { name: string; id: number }) {
		this.activeSkill = { name: skillName, id: skillId };
		this.availibleGiveFrom = this.getAvailabeGiveFrom();
		this.setScoresForActiveSkill();
		this.setScoresForActiveBehavior();
	}

	getActiveSkillStat() {
		return this.feedbackProfile.skill_stats[GivenFrom.Total].find((skillstat) => {
			return skillstat.skill_stat.skill.name === this.activeSkill.name;
		});
	}
	getAvailabeGiveFrom() {
		let givenFrom = new Array<GivenFrom>(GivenFrom.Own);
		if (this.feedbackProfile.skill_stats.total.length > 0) {
			givenFrom.push(GivenFrom.Total);
		}
		if (this.feedbackProfile.feedback.manager.length > 1) {
			givenFrom.push(GivenFrom.Manager);
		}
		if (this.feedbackProfile.feedback.colleague.length > 1) {
			givenFrom.push(GivenFrom.Colleague);
		}
		if (this.feedbackProfile.feedback.subordinate.length > 1) {
			givenFrom.push(GivenFrom.Subordinate);
		}
		this.availibleGiveFrom = givenFrom;

		return givenFrom;
	}

	getAllBehaviors(): Array<{ name: string; id: number }> {
		let behaviors = new Array<{ name: string; id: number }>();
		this.feedbackProfile.skill_stats.own
			.find((skillStat) => skillStat.skill_stat.skill.id == this.activeSkill.id)
			.behavior_stats.forEach((behaviorStat) => {
				behaviors.push({ name: behaviorStat.behavior.name, id: behaviorStat.behavior.id });
			});
		return behaviors;
	}

	getScoreForSkill(givenFrom: GivenFrom): number {
		return this.activeScoresSkill.get(givenFrom);
	}

	setScoresForActiveSkill() {
		this.activeScoresSkill.clear();
		this.availibleGiveFrom.forEach((givenFrom) => {
			this.activeScoresSkill.set(givenFrom, this.feedbackProfile.skill_stats[givenFrom].find((skillStat) => skillStat.skill_stat.skill.id == this.activeSkill.id).skill_stat.score);
		});
	}

	/**
	 *
	 * This function sets the scores for the active behavior.
	 * For faster access the scores are stored in an object with the following structure:
	 * {
	 * 	behavior1: {
	 * 		givenFrom1: {
	 * 			score: number,
	 * 			show: boolean,
	 * 		},
	 * 		givenFrom2: {
	 * 			score: number,
	 * 			show: boolean
	 * 		},
	 * }
	 *
	 * It also sets the activeBlindspots for the active behavior.
	 *
	 */
	setScoresForActiveBehavior() {
		this.activeScoresBehaviors = {};
		this.getAllBehaviors().forEach((behavior) => {
			this.activeScoresBehaviors[behavior.name] = {};
			for (let givenFrom of this.availibleGiveFrom) {
				this.activeScoresBehaviors[behavior.name][givenFrom] = {};
				const behaviorStat = this.feedbackProfile.skill_stats[givenFrom]
					.find((skillStat) => skillStat.skill_stat.skill.id == this.activeSkill.id)
					.behavior_stats.find((behaviorStat) => behaviorStat.behavior.name == behavior.name);
				this.activeScoresBehaviors[behavior.name][givenFrom].score = behaviorStat.score;
				this.activeScoresBehaviors[behavior.name][givenFrom].show = this.activeScoresBehaviors[behavior.name][givenFrom].score > -1;
				if (givenFrom == GivenFrom.Own) continue;
				if (givenFrom == GivenFrom.Total && behaviorStat.is_blindspot && this.activeScoresBehaviors[behavior.name][givenFrom].show) {
					this.activeBlindspots.add(behavior.name);
				}
			}
		});
	}

	getBehaviorDetails(behavior: string, givenFrom: GivenFrom): { score: number; show: boolean } {
		return this.activeScoresBehaviors[behavior][givenFrom];
	}

	getBehaviorDescription(behavior: string): FeedbackBehavior {
		return this.feedbackProfile.skill_stats.own
			.find((skillStat) => skillStat.skill_stat.skill.id == this.activeSkill.id)
			.behavior_stats.find((behaviorStat) => behaviorStat.behavior.name == behavior).behavior;
	}

	/**
	 * This function returns the feedback texts for a given givenFrom.
	 * The texts are sorted by the time they were created.
	 * @param givenFrom
	 * @returns Array<{ time: number; text: string }>
	 * @memberof FeedbackProfileService
	 *
	 */
	getFeedbackTexts(givenFrom: GivenFrom): Array<{ time: number; text: string; visited: boolean; id: number }> {
		try {
			if (this.feedbackProfile == undefined) throw new Error(`feedbackProfile is unde fined`);
			if (this.feedbackProfile.feedback[givenFrom] == undefined) throw new Error(`GivenFrom not found in feedbackProfile: ${givenFrom}`);
			if (this.availableFeedbacks.has(givenFrom) == false) throw new Error(`GivenFrom not found in availableFeedbacks: ${givenFrom}`);
		} catch (error) {
			console.error(error);
			return [];
		}
		let texts = new Array<{ time: number; text: string; visited: boolean; id: number }>();
		this.feedbackProfile.feedback[givenFrom].forEach((feedback) => {
			const today = new Date();
			let time = new Date(feedback.created_at);
			if (feedback.textbox_input != '') {
				texts.push({
					text: feedback.textbox_input,
					time: differenceInMilliseconds(today, time),
					visited: feedback.visited,
					id: feedback.id
				});
			}
		});
		return texts;
	}

	setAvailableFeedbacks() {
		this.availableFeedbacks.clear();
		for (let givenFrom of Object.values(GivenFrom)) {
			if (GivenFrom.Own == givenFrom) continue;
			this.feedbackProfile.feedback[GivenFrom.Total].push(...this.feedbackProfile.feedback[givenFrom]);
			if (this.feedbackProfile.feedback[givenFrom].length > 1) {
				this.availableFeedbacks.add(givenFrom);
			}
		}
		if (this.feedbackProfile.feedback[GivenFrom.Total].length > 0) {
			this.availableFeedbacks.add(GivenFrom.Total);
		}
	}

	showFeedbackTexts(givenFrom: GivenFrom): boolean {
		return this.availableFeedbacks.has(givenFrom);
	}

	setCurrentBehavior(skill_id: number, behavior_id: number) {
		this.currentBehavior = {
			name: this.feedbackProfile.skill_stats[GivenFrom.Total]
				.find((skillStat) => skillStat.skill_stat.skill.id == skill_id)
				.behavior_stats.find((behaviorStat) => behaviorStat.behavior.id == behavior_id).behavior.name,
			id: behavior_id,
			feedbacks: this.getBehaviorTextFeedbacks(skill_id, behavior_id)
		};
	}

	getCurrentBehavior(): BehaviorFeedbackPageProps | null {
		return this.currentBehavior;
	}

	getIsProfileGenerated(): boolean {
		return this.isProfileGenerated;
	}

	getPDFBlob(): Blob {
		return this.pdfBlob;
	}

	getFeedbackProfileFileUri(): GetUriResult {
		return this.fileUri;
	}

	async blobToBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result as string;
				resolve(base64data.split(',')[1]); // Removes the 'data:application/pdf;base64,' part
			};
			reader.onerror = (error) => {
				reject(error);
			};
		});
	}
}
