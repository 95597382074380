import { Injectable } from '@angular/core';
import { DuelWeekStats } from '@app/_models/duel-week-stats';
import { ApiService } from './api.service';
import { map } from 'rxjs';
import { LeaderboardStatEntry, LeaderboardStatOverview } from '@app/_models/leaderboard-stat-overview';

@Injectable({
	providedIn: 'root'
})
export class LeaderboardService {
	private leaderboardStats: LeaderboardStatOverview;

	private sortedWeeklyStats: LeaderboardStatEntry[];
	private sortedAllTimeStats: LeaderboardStatEntry[];

	constructor(private apiService: ApiService) {}

	async loadLeaderboard(): Promise<LeaderboardStatOverview> {
		const response = await this.apiService
			.get<LeaderboardStatOverview>('/gamification/leaderboard')
			.pipe(map((leaderboardStats) => (this.leaderboardStats = leaderboardStats)))
			.toPromise();

		this.sortLeaderboardStats();
		return Promise.resolve(response);
	}

	sortLeaderboardStats() {
		this.sortedWeeklyStats = this.leaderboardStats.leaderboard_entries.slice().sort((a, b) => a.rank_this_week - b.rank_this_week);
		this.sortedAllTimeStats = this.leaderboardStats.leaderboard_entries.slice().sort((a, b) => a.rank_total - b.rank_total);
	}

	getSortedWeeklyStats(): LeaderboardStatEntry[] {
		return this.sortedWeeklyStats;
	}

	getSortedAllTimeStats(): LeaderboardStatEntry[] {
		return this.sortedAllTimeStats;
	}
}
