import { Injectable } from '@angular/core';
import { TextualNote } from '@app/_models/note';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { ApiService } from './api.service';
import { NoteActivityType } from '@app/_models/note-activity-type';
import { HttpParams } from '@angular/common/http';
import { NoteHistoryObject } from '@app/_models/note-history-object';
import { TextualNoteModalStartupLocation } from '@app/_models/textual-note-modal-startup-location';
@Injectable({
	providedIn: 'root'
})
export class NotesService {
	private _textualNote: TextualNote;
	private _noteObject: NoteHistoryObject;

	private showTextualNoteModalSubject: BehaviorSubject<boolean>;
	private areRMIsPossible: boolean = false;

	public showTextualNoteModal: Observable<boolean>;

	public shouldNoteBeSaved: boolean = false;
	public lastScrollPosition: number = 0;

	private textualNoteModalStartupLocation: TextualNoteModalStartupLocation = TextualNoteModalStartupLocation.default;

	constructor(private apiService: ApiService) {
		this.showTextualNoteModalSubject = new BehaviorSubject<boolean>(null);
		this.showTextualNoteModal = this.showTextualNoteModalSubject.asObservable();
	}

	loadNotes(type: NoteActivityType, object_uuid: string): Promise<any> {
		let params = new HttpParams();
		params = params.set('object_uuid', object_uuid);
		params = params.set('type', type);
		return firstValueFrom(this.apiService.get<any>('/notes', params).pipe(map((textualNote) => (this._textualNote = textualNote.notes[0]))));
	}

	saveTextualNote(): Promise<any> {
		console.log('Note details', this._textualNote);
		const postData = {
			object_uuid: (this._textualNote as TextualNote).object_uuid,
			pk: (this._textualNote as TextualNote).pk,
			title: (this._textualNote as TextualNote).title,
			text: (this._textualNote as TextualNote).text,
			type: (this._textualNote as TextualNote).type,
		};
		return firstValueFrom(this.apiService.put<any>('/notes', postData));
	}

	saveHistoryNote(): Promise<any> {
		console.log('Note Object Details', this._noteObject);
		console.log('TextualNote: ', this._textualNote);
		const { text, object_uuid, pk, title, type } = this._noteObject;

		const postData = {
			object_uuid: object_uuid,
			pk: pk,
			title: title,
			text: (this._noteObject as NoteHistoryObject).text,
			type: type
		};
		return firstValueFrom(this.apiService.put<any>('/notes', postData));
	}

	displayTextualNoteModal() {
		this.showTextualNoteModalSubject.next(true);
	}

	closeTextualNoteModal() {
		this.showTextualNoteModalSubject.next(false);
	}

	get textualNote(): TextualNote {
		return this._textualNote;
	}

	set textualNote(textualNote: TextualNote) {
		this._textualNote = textualNote;
	}

	get noteObject(): NoteHistoryObject {
		return this._noteObject;
	}

	set noteObject(noteObject: NoteHistoryObject) {
		this._noteObject = noteObject;
	}

	getTextualNoteModalStartupLocation(): TextualNoteModalStartupLocation {
		return this.textualNoteModalStartupLocation;
	}

	setTextualNoteModalStartupLocation(locationEnum: TextualNoteModalStartupLocation): void {
		this.textualNoteModalStartupLocation = locationEnum;
	}

	getAreRMIsPossible(): boolean {
		return this.areRMIsPossible;
	}

	setAreRMIsPossible(value: boolean) {
		this.areRMIsPossible = value;
	}
}
