import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/_services/api.service';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export class SavviTranslateHttpLoader implements TranslateLoader {
	constructor(
		private apiService: ApiService,
		public prefix: string = '/assets/i18n/',
		public suffix: string = '.json'
	) {}

	/**
	 * Gets the translations from the server
	 */
	public getTranslation(lang: string): Observable<Object> {
		return this.apiService.getS3<Object>(`${this.prefix}${lang}${this.suffix}`);
	}
}
