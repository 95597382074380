import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DuelOverview } from '@app/_models/duel-overview';
import { map } from 'rxjs';
import { LotteryResult, LotteryUpdate, UserLotteryStatus } from '@app/_models/lottery';

@Injectable({
	providedIn: 'root'
})
export class LotteryService {
	private lotteryUpdate: LotteryUpdate;
	private lotteryResult: LotteryResult;
	private resolvementTimer: number = 0;

	constructor(private apiService: ApiService) {}

	loadLotteryUpdate(): Promise<LotteryUpdate> {
		return this.apiService
			.get<LotteryUpdate>('/lottery/update')
			.pipe(map((duelOverview) => (this.lotteryUpdate = duelOverview)))
			.toPromise();
	}

	saveLotteryUpdateViewed(): Promise<any> {
		return this.apiService.post('/lottery/update').toPromise();
	}

	loadLotteryResolvementTimer(): Promise<number> {
		return this.apiService
			.get<number>('/lottery/resolvement')
			.pipe(map((resolvementTimer) => (this.resolvementTimer = resolvementTimer)))
			.toPromise();
	}

	loadLotteryResult(): Promise<LotteryResult> {
		return this.apiService
			.get<LotteryResult>('/lottery/result')
			.pipe(map((duelOverview) => (this.lotteryResult = duelOverview)))
			.toPromise();
	}

	saveLotteryResultViewed(): Promise<any> {
		return this.apiService.post('/lottery/result').toPromise();
	}

	getLotteryUpdate(): LotteryUpdate {
		return this.lotteryUpdate;
	}

	isLotteryUpdateAvailable(): boolean {
		if (this.lotteryUpdate != null) {
			if (this.lotteryUpdate.configuration != null) {
				return true;
			}
		}
		return false;
	}

	isResolvementTimerOver(): boolean {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;
		console.log('timestamp: ' + this.resolvementTimer * 1000);
		console.log('currentDate: ' + currentDate);
		console.log('difference: ' + difference);
		if (difference < 0) {
			return true;
		} else {
			return false;
		}
	}

	getDaysUntilResolvementAsNumber(): number {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;

		if (difference < 0) {
			return 0;
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));

		return days;
	}

	getDaysUntilResolvement(): string {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;

		if (difference < 0) {
			return '0';
		}

		const days = Math.floor(difference / (1000 * 60 * 60 * 24));

		return days.toString();
	}

	getHoursUntilResolvement(): string {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;

		if (difference < 0) {
			return '0';
		}

		const hours = Math.floor((difference / 1000 / 60 / 60) % 24);

		return hours.toString();
	}

	getMinutesUntilResolvement(): string {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;

		if (difference < 0) {
			return '0';
		}

		const minutes = Math.floor((difference / 1000 / 60) % 60);

		return minutes.toString();
	}

	getSecondsUntilResolvement(): string {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;

		if (difference < 0) {
			return '0';
		}

		const seconds = Math.floor((difference / 1000) % 60);

		return seconds.toString();
	}

	getLotteryResult(): LotteryResult {
		return this.lotteryResult;
	}

	isLotteryTimerStillRunning(): boolean {
		let currentDate: number = Date.now();
		let difference = this.resolvementTimer * 1000 - currentDate;

		if (difference < 0) {
			return false;
		} else {
			return true;
		}
	}
}
