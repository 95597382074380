import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { GamificationEnvironment } from '@app/_models/multi-package';
import { ThemingService } from '@app/_services/theming.service';

@Pipe({
	name: 'resolveImgSrc'
})
@Injectable({
	providedIn: 'root'
})
export class ResolveImgSrcPipe implements PipeTransform {
	constructor(private themingService: ThemingService) {}

	async transform(value: string, ...args): Promise<string> {
		console.log('ResolveGraphicPipe', value, args);
		if (value) {
			return await this.themingService.loadImg(value, ...args);
		}
	}
}
