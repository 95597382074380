<ion-grid class="rmi-toast-grid" part="rmi-toast-grid">
	<ion-row class="rmi-toast-main-row" part="rmi-toast-main-row">
		<ion-col size="2.5">
			<ion-row>
				<img src="../../assets/gamification/comet.svg" class="rmi-toast-comet-img" part="rmi-toast-comet-img" />
			</ion-row>
			<ion-row>
				<span class="rmi-toast-points-label" part="rmi-toast-points-label" [innerHTML]="getPoints()"></span>
			</ion-row>
		</ion-col>
		<ion-col class="rmi-toast-headline-subline-col ion-align-items-center" part="rmi-toast-headline-subline-col">
			<ion-row>
				<span class="rmi-toast-headline-label" part="rmi-toast-headline-label" [innerHTML]="getTitle()"></span>
			</ion-row>
			<ion-row *ngIf="isSublineAvailable()" class="rmi-toast-subline-row" part="rmi-toast-subline-row">
				<span class="rmi-toast-subline-label" part="rmi-toast-subline-label" [innerHTML]="getDescription()"></span>
			</ion-row>
		</ion-col>
		<ion-col size="auto" *ngIf="isAchievement()">
			<img [src]="getAchievementIconPath()" class="achievement-in-toast" part="achievement-icon" id="iconAchievementInToast" aria-label="iconAchievementInToast" />
		</ion-col>
	</ion-row>
	<ion-row *ngIf="isShowSmallPointsAnimation()" class="rmi-toast-total-points-row" part="rmi-toast-total-points-row">
		<ion-col size="auto" class="rmi-toast-total-points-col" part="rmi-toast-total-points-col" style="padding: 0 2rem 0 1.3rem">
			<span class="rmi-toast-headline-label" part="rmi-toast-total-points-label">Total</span>
		</ion-col>
		<ion-col size="6" class="rmi-toast-total-points-value-col" part="rmi-toast-total-points-value-col">
			<ion-row class="rmi-toast-total-points-xp-row" part="rmi-toast-total-points-xp-row">
				<ion-col size="auto" style="padding: 0.3rem 0.4rem 0.2rem 0.4rem">
					<img src="./assets/gamification/comet.svg" style="width: 2.4rem; padding-top: 0.3rem" />
				</ion-col>
				<ion-col size="auto" style="padding: 0 0.4rem 0 0">
					<span class="rmi-toast-total-points-value" part="rmi-toast-total-points-value" [innerHTML]="getScoreForPlayer()"></span>&nbsp;<span
						class="rmi-toast-total-points-xp"
						part="rmi-toast-total-points-xp"
						[innerHTML]="'xp' | translate"></span>
				</ion-col>
			</ion-row>
		</ion-col>
	</ion-row>
</ion-grid>
