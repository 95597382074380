import { Injectable } from '@angular/core';
import { HandleError } from '@app/_xstate/savvi-machine.events';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root' // This automatically registers the service in the root injector
})
export class ErrorNotificationService {
	private showErrorScreenSubject: BehaviorSubject<any>;
	public showErrorScreenSubjectItem: Observable<any>;

	constructor() {
		this.showErrorScreenSubject = new BehaviorSubject<any>(null);
		this.showErrorScreenSubjectItem = this.showErrorScreenSubject.asObservable();
	}

	// Method to emit errors
	notifyError(error: any) {
		this.showErrorScreenSubject.next(error);
	}
}
