import { Injectable } from '@angular/core';
// import { userInfo } from 'os';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
	providedIn: 'root'
})
export class PilotUserService {
	private resetPackageAlertShownSubject: BehaviorSubject<boolean>;
	resetPackageAlertShown: Observable<boolean>;
	private _openedFromHomeScreen: boolean = false;
	private _weekJustIncreased: boolean = false;

	constructor(private _apiService: ApiService) {
		this.resetPackageAlertShownSubject = new BehaviorSubject<boolean>(false);
		this.resetPackageAlertShown = this.resetPackageAlertShownSubject.asObservable();
	}

	isPilotUser(is_pilot_user: boolean, package_type: string): boolean {
		return is_pilot_user && package_type == 'pilot';
	}

	savePackageIncrease(): Promise<any> {
		return this._apiService.post('/pilot/increase').toPromise();
	}

	savePackageReset(): Promise<any> {
		return this._apiService.post('/pilot/reset').toPromise();
	}

	get openedFromHomeScreen(): boolean {
		return this._openedFromHomeScreen;
	}

	set openedFromHomeScreen(openedFromHomeScreen: boolean) {
		this._openedFromHomeScreen = openedFromHomeScreen;
	}

	get weekJustIncreased(): boolean {
		return this._weekJustIncreased;
	}

	setWeekJustIncreased() {
		this._weekJustIncreased = true;
	}

	resetWeekJustIncreased() {
		this._weekJustIncreased = false;
	}

	displayResetPackageAlert() {
		this.resetPackageAlertShownSubject.next(true);
		this.resetPackageAlertShownSubject.next(false);
	}

	/*closeResetPackageAlert(){
    this.resetPackageAlertShownSubject.next(false);
  }*/
}
