import { Certificate } from './certificate';
import { Conquests } from './conquests';

export type MultiPackage = {
	pk: number;
	login_date: string;
	start_date: string;
	end_date: string;
	most_improved_skills?: MostImprovedSkill[];
	certificate?: Certificate;
	external_name: string;
	trainer_written_text: string;
	video_url?: string;
	gamification_environment?: GamificationEnvironment;
	level: number;
	status: MultiPackageStatus;
	conquests: Conquests;
	is_active: boolean;
};

export enum MultiPackageStatus {
	NotStarted = 'not_started',
	InComplete = 'not_completed',
	Completed = 'completed'
}
export interface MostImprovedSkill {
	name: string;
	description: string;
}

export enum GamificationEnvironment {
	Space = 'space',
	Continental = 'continental',
	Islands = 'islands'
}

export enum SwiperSegment {
	Stats = 'stats',
	Insights = 'insights',
	Conquests = 'conquests'
}

export enum PackageHistoryStartupLocation {
	Profile = 'profile',
	MultiPackageHubOverview = 'multiPackageHubOverview'
}

export enum MultiPackageSelectionAction {
	close = 'close',
	select = 'select',
	hub = 'hub'
}
