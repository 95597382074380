import { HttpParams } from '@angular/common/http';
import { Injectable, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { MicrotrainingsSelection } from '@app/_models/microtrainings-selection';
import { StatsPerWeek } from '@app/_models/stats-per-week';
import { User } from '@app/_models/user';
import { BehaviorSubject, from, Observable, of, scheduled } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Avatar } from '@app/_models/avatar';
import { SkillType } from '@app/_models/skill-type';
import { ProfileInsights } from '@app/_models/profile-insights';
import { ProfileStats } from '@app/_models/profile-stats';
import { SkillTypeStartupLocation } from '@app/_models/skill-type-startup-location';
import { WeekHistoryItem } from '@app/_models/week-history-item';
import { WeekHistoryActivityType } from '@app/_models/week-history-activity-type';
import { PackageService } from './package.service';
import { SingleTrainingService } from './single-training.service';
import { OverallStatsPerWeek } from '@app/_models/overall-stats-per-week';
import { SettingsService } from './settings.service';
import { GamificationService } from './gamification.service';
import { PlayerProgressInfo } from '@app/_models/player-progress-info';
import { ProfileSegment } from '@app/_models/profile-section';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {
	private selectedAvatarInPretraining: string;

	private statsPerWeek: StatsPerWeek[];
	private statsPerWeekPromise: Promise<StatsPerWeek[]>;
	private skillType: SkillType;
	private profileInsights: ProfileInsights;
	private profileStats: ProfileStats;
	private selectedWeekInProfileStats: number;
	private selectedWeekInProfile: number;
	private selectedSkillInProfile: string;
	private user: User;
	private avatarList: string[];

	private microtrainingHistory: MicrotrainingsSelection[];
	private userCompletedTask: boolean = false;
	private userCompletedTaskType: WeekHistoryActivityType;

	private profileSegment: ProfileSegment = ProfileSegment.stats;
	private profileInsightSwiperIndex: number = 0;

	private skillTypeStartupLocation: SkillTypeStartupLocation = SkillTypeStartupLocation.profile;

	constructor(
		private apiService: ApiService,
		private settingsService: SettingsService,
		private gamificationService: GamificationService
	) {}

	loadUserStats(packageId: number): Promise<StatsPerWeek[]> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		this.statsPerWeekPromise = this.apiService
			.get<StatsPerWeek[]>('/userStats', params)
			.pipe(map((statsPerWeek) => (this.statsPerWeek = statsPerWeek)))
			.toPromise();

		return this.statsPerWeekPromise;
		/*.catch((error) => {

        }); */
	}

	loadMe(): Promise<User> {
		return this.apiService
			.get<User>('/me')
			.pipe(map((user) => (this.user = user)))
			.pipe(
				map((user) => {
					this.settingsService.setUserSettings({ haptics_enabled: user.user_preferences.haptics_enabled });
					return user;
				})
			)
			.toPromise();
	}

	loadAvailableAvatars(): Promise<string[]> {
		return this.apiService
			.get<string[]>('/me/avatars')
			.pipe(map((avatarList) => (this.avatarList = avatarList)))
			.toPromise();
	}

	loadSkillTypeReveal(): Promise<SkillType> {
		return this.apiService
			.get<SkillType>('/me/skill_type_reveal')
			.pipe(map((skillType) => (this.skillType = skillType)))
			.toPromise();
	}

	loadProfileInsights(): Promise<ProfileInsights> {
		return this.apiService
			.get<ProfileInsights>('/profile/insights')
			.pipe(map((profileInsights) => (this.profileInsights = profileInsights)))
			.toPromise();
	}

	loadProfileStats(): Promise<ProfileStats> {
		return this.apiService
			.get<ProfileStats>('/profile/stats')
			.pipe(map((profileStats) => (this.profileStats = profileStats)))
			.toPromise();
	}

	saveAvatarSelection(avatar: string): Promise<Avatar> {
		const postData = {
			identifier: avatar
		};

		return this.apiService.post<Avatar>('/user/avatar', postData).toPromise();
	}

	saveProfileTutorialViewed(): Promise<void> {
		return this.apiService
			.post<PlayerProgressInfo>('/profile/tutorial', {})
			.pipe(map((playerProgression) => this.gamificationService.setPlayerProgressInfo(playerProgression)))
			.toPromise();
	}

	getSelectedAvatarInPretraining(): string {
		return this.selectedAvatarInPretraining;
	}

	setSelectedAvatarInPretraining(avatar: string) {
		this.selectedAvatarInPretraining = avatar;
	}

	getSkillType(): SkillType {
		return this.skillType;
	}

	hasSkillType(): boolean {
		if (this.skillType) return true;
		else return false;
	}

	getCurrentUserStats(): StatsPerWeek[] {
		return this.statsPerWeek;
	}

	getCurrentUserStatsPromise(): Promise<StatsPerWeek[]> {
		return this.statsPerWeekPromise;
	}

	getUser(): User {
		return this.user;
	}

	resetProfileStats() {
		this.selectedSkillInProfile = null;
		this.selectedWeekInProfileStats = null;
	}

	getProfileInsights(): ProfileInsights {
		return this.profileInsights;
	}

	getProfileStats(): ProfileStats {
		return this.profileStats;
	}
	getSelectedWeekInProfileStats(): number {
		if (!this.selectedWeekInProfileStats) {
			// if not initialized than set value to latest week available
			this.selectedWeekInProfileStats = this.profileStats.general.weeks.sort((a, b) => a.week - b.week)[this.profileStats.general.weeks.length - 1].week;
		}

		return this.selectedWeekInProfileStats;
	}

	setSelectedWeekInProfile(week: number) {
		this.selectedWeekInProfileStats = week;
	}

	getSelectedSkillInProfile(): string {
		if (!this.selectedSkillInProfile) {
			// if not initialized than set value to latest week available
			this.selectedSkillInProfile = this.profileStats.general.name;
		}
		return this.selectedSkillInProfile;
	}

	setSelectedSkillInProfile(selectedSkill: string) {
		this.selectedSkillInProfile = selectedSkill;
	}

	getStatsChartWeekData(weeks: OverallStatsPerWeek[] = this.profileStats.general.weeks): string[] {
		var weekCounter = 0;
		var statsChartWeeksData: string[] = [];
		weeks.forEach(() => {
			statsChartWeeksData.push('W' + weekCounter);
			weekCounter++;
		});

		return statsChartWeeksData;
	}

	getStatsChartScoreData(): number[] {
		var statsChartScoresData: number[] = [];

		if (this.getSelectedSkillInProfile() == this.profileStats.general.name) {
			this.profileStats.general.weeks.forEach((week) => statsChartScoresData.push(week.average_score));
		} else {
			this.profileStats.skills.find((skill) => skill.name == this.getSelectedSkillInProfile()).weeks.forEach((week) => statsChartScoresData.push(week.average_score));
		}

		return statsChartScoresData;
	}

	isSkillTypeStartupLocationProfile(contextValue: SkillTypeStartupLocation): boolean {
		return contextValue == SkillTypeStartupLocation.profile;
	}

	isSkillTypeStartupLocationPretraining(contextValue: SkillTypeStartupLocation): boolean {
		return contextValue == SkillTypeStartupLocation.pretraining;
	}

	isSkillTypeStartupLocationPackageHistory(contextValue: SkillTypeStartupLocation): boolean {
		return contextValue == SkillTypeStartupLocation.packageHistory;
	}

	isSkillTypeStartupLocationReturningDuringOnboarding(contextValue: SkillTypeStartupLocation): boolean {
		return contextValue == SkillTypeStartupLocation.returningDuringOnboarding;
	}

	// needed for speech bubble text on home screen

	hasUserCompletedTask(): boolean {
		return this.userCompletedTask;
	}

	setUserCompletedTask() {
		this.userCompletedTask = true;
	}

	resetUserCompletedTask() {
		console.log('Setting profileService.userCompletedTask to false');
		this.userCompletedTask = false;
	}

	getUserCompletedTaskType(): WeekHistoryActivityType {
		return this.userCompletedTaskType;
	}

	setUserCompletedTaskType(type: WeekHistoryActivityType) {
		this.userCompletedTaskType = type;
	}

	getAvatarList(): string[] {
		return this.avatarList;
	}

	isAvatarIdentifierWithSVG(identifier: string): boolean {
		if (identifier.includes('.svg')) {
			return true;
		} else {
			return false;
		}
	}

	getProfileSegment(): ProfileSegment {
		return this.profileSegment;
	}

	setProfileSegment(segment: ProfileSegment) {
		this.profileSegment = segment;
	}

	getProfileInsightSwiperIndex(): number {
		return this.profileInsightSwiperIndex;
	}

	setProfileInsightSwiperIndex(index: number) {
		this.profileInsightSwiperIndex = index;
	}

	getSkillTypeStartupLocation(): SkillTypeStartupLocation {
		return this.skillTypeStartupLocation;
	}

	setSkillTypeStartupLocation(location: SkillTypeStartupLocation) {
		this.skillTypeStartupLocation = location;
	}
}
