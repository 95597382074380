import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TextualNote } from '@app/_models/note';
import { NoteActivityType } from '@app/_models/note-activity-type';
import { NoteHistoryObject } from '@app/_models/note-history-object';
import { TextualNoteModalStartupLocation } from '@app/_models/textual-note-modal-startup-location';
import { DailyChallengeService } from '@app/_services/daily-challenge.service';
import { JourneyService } from '@app/_services/journey.service';
import { LoaderService } from '@app/_services/loader.service';
import { NotesService } from '@app/_services/notes.service';
import { RewardedMicroInteractionService } from '@app/_services/rewarded-micro-interaction.service';
import { SettingsService } from '@app/_services/settings.service';
import { TranslationService } from '@app/_services/translation.service';
import { context } from '@app/_xstate/savvi-machine.config';
import { GoToDailyChallengeHistory, GoToHistoryTraining, RMIRequierementsMet, RMIRequierementsMetFake, SaveTextualNote, SendFeedback, saveHistoricNote } from '@app/_xstate/savvi-machine.events';
import { SavviMachineService } from '@app/_xstate/savvi-machine.service';
import { IonicModule, ToastController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentLibraryModule as SavviComponentsAngularModule } from '@savvi-learning/savvi-components-angular';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-textual-note-modal',
	templateUrl: './textual-note-modal.component.html',
	styleUrls: ['./textual-note-modal.component.scss'],
	standalone: true,
	imports: [CommonModule, FormsModule, IonicModule, TranslateModule, SavviComponentsAngularModule, QuillModule]
})
export class TextualNoteModalComponent implements OnInit {
	NoteActivityType = NoteActivityType;
	@Input() isOpen: boolean;
	@Input() didDismiss: () => void;

	activity_title: string;
	private loaderCompletedAlertSubscription: Subscription;
	editingMode: boolean = false;

	feedbackEditor = {
		toolbar: false
	};

	@ViewChild(QuillEditorComponent) editor: QuillEditorComponent;
	//@Input()
	textualNote: TextualNote;
	@Input() noteObject: NoteHistoryObject;
	feedbackString: string = '';
	feedbackInsufficient: boolean = false;
	modalMaxHeight = '70%';
	//@Output() saveTextualNoteEvent = new EventEmitter<ExerciseTextualNote | DailyChallengeTextualNote>();

	constructor(
		private journeyService: JourneyService,
		private savviMachineService: SavviMachineService,
		private notesService: NotesService,
		private rmiService: RewardedMicroInteractionService,
		private translationService: TranslationService,
		private loaderService: LoaderService,
		private dailyChallengeService: DailyChallengeService,
		private settingsService: SettingsService,
		private toastCtrl: ToastController
	) {}

	ngOnInit() {
		this.loaderCompletedAlertSubscription = this.loaderService.loaderCompletedAlert.subscribe((loaderCompletedAlert) => (loaderCompletedAlert ? this.initializeComponent() : null));
		this.editingMode = false;
		this.feedbackInsufficient = true;
	}

	ngOnDestroy() {
		this.loaderCompletedAlertSubscription.unsubscribe();
	}

	initializeComponent() {
		this.updateModalMaxHeight();
		console.log('TextualNoteModalComponent.initializeComponent()');
		console.log(this.getMode());
		this.editingMode = false;

		console.log('this.notesService.noteObject', this.notesService.noteObject);
		this.noteObject = this.notesService.noteObject;

		console.log('this.notesService.textualNote', this.notesService.textualNote);
		this.textualNote = this.notesService.textualNote;
		if (this.textualNote == null || !this.textualNote.text || this.textualNote.text == '') {
			this.editingMode = true;
		}
	}

	get loaded(): boolean {
		return this.loaderService.isLoaded();
	}

	triggerSaveTextualNote() {
		this.editingMode = false;
		//this.editor.quillEditor.enable(false);
		//this.saveTextualNoteEvent.emit(this.textualNote);
		this.notesService.textualNote = this.textualNote;
		this.notesService.shouldNoteBeSaved = true;

		console.log('this.notesService.textualNote triggerSave', this.notesService.textualNote);

		console.log(
			`triggerSave, are RMIs possible ${this.notesService.getAreRMIsPossible()}; isTextualNoteContent: ${!!this.notesService
				.textualNote}; getHasNOTSeenNoteRMIForDailyChallenge: ${!this.dailyChallengeService.getHasSeenNoteRMIForDailyChallenge()}`
		);

		console.log('this.notesService.textualNote.type == NoteActivityType.daily_challenge', this.notesService.textualNote.type == NoteActivityType.daily_challenge);
		if (this.notesService.textualNote.type == NoteActivityType.daily_challenge) {
			console.log(
				'this.notesService.getAreRMIsPossible() ' +
					this.notesService.getAreRMIsPossible() +
					' && this.notesService.textualNote ' +
					this.notesService.textualNote +
					' && !this.dailyChallengeService.getHasSeenNoteRMIForDailyChallenge() ' +
					!this.dailyChallengeService.getHasSeenNoteRMIForDailyChallenge() +
					'&& this.notesService.textualNote.rmi_transaction_created === false ' +
					(this.notesService.textualNote.rmi_transaction_created === false)
			);
			if (
				this.notesService.getAreRMIsPossible() &&
				this.notesService.textualNote &&
				!this.dailyChallengeService.getHasSeenNoteRMIForDailyChallenge() &&
				this.notesService.textualNote.rmi_transaction_created === false
			) {
				if (this.notesService.textualNote.text.length >= this.rmiService.getPointList().find((rmi) => rmi.name == 'daily_challenge_note').min_char) {
					this.savviMachineService.send(new RMIRequierementsMet('daily_challenge_note'));
					this.dailyChallengeService.setHasSeenNoteRMIForDailyChallenge(true);
				}
			}
		} else if (this.notesService.textualNote.type == NoteActivityType.exercise) {
			if (this.notesService.getAreRMIsPossible() && this.notesService.textualNote.text) {
				if (this.notesService.textualNote.text.length >= this.rmiService.getPointList().find((rmi) => rmi.name == 'minimun_char_in_note').min_char) {
					this.savviMachineService.send(new RMIRequierementsMet('minimun_char_in_note'));
				}
			}
		}

		this.savviMachineService.send(new SaveTextualNote());
		//I do not completely get why this is neccessary to prevent reloading after viewing when having edited a note beforehand, as the state machine should reset this
		this.notesService.shouldNoteBeSaved = false;
	}

	editTextualNote() {
		this.editingMode = true;
		//this.editor.quillEditor.enable(true);
	}

	getPlaceholderTextTextualNote(): string {
		return this.translationService.translate.instant('noteTaking.textualNote.placeholder');
	}

	getPlaceholderTextFeedback(): string {
		return this.translationService.translate.instant('settings.feedback.feedbackPlaceholder');
	}

	getMode(): string {
		let mode = this.notesService.getTextualNoteModalStartupLocation();
		switch (mode) {
			case TextualNoteModalStartupLocation.default:
				return 'default';
			case TextualNoteModalStartupLocation.settings:
				return 'settings';
			case TextualNoteModalStartupLocation.noteHistory:
				return 'noteHistory';
			default:
				return 'default';
		}
	}

	updateModalMaxHeight() {
		if (this.getMode() === 'settings') {
			this.modalMaxHeight = '60%';
		} else {
			this.modalMaxHeight = '70%';
		}
	}

	resetFeedbackInput() {
		this.feedbackString = '';
		this.feedbackInsufficient = true;
	}

	triggerSendFeedback() {
		console.log('Feedback is being sent');
		this.settingsService.feedbackString = this.feedbackString;
		this.settingsService.shouldAppFeedbackBeSaved = true;
		this.savviMachineService.send(new SendFeedback());
		this.resetFeedbackInput();
		this.presentFeedbackToast();
	}
	isFeedbackDisabled() {
		if (!this.feedbackString || this.feedbackString.trim().length <= 8) {
			this.feedbackInsufficient = true;
		} else {
			this.feedbackInsufficient = false;
		}
	}

	onFeedbackStringChange(newFeedback: string) {
		this.feedbackString = newFeedback;
	}

	async presentFeedbackToast() {
		const toast = await this.toastCtrl.create({
			message: this.translationService.translate.instant('settings.feedback.feedbackSent'),
			duration: 3000,
			position: 'top',
			cssClass: 'successfull-toast-light-green settings-toast-positioning'
		});

		await toast.present();
	}

	isExercise(exercise: NoteHistoryObject['type']): boolean {
		return exercise != 'exercise';
	}

	getButtonText(type: NoteHistoryObject['type']): string {
		if (type == 'exercise') {
			return this.translationService.translate.instant('journey.notesScreen.exercise'); //View / Redo fehlt
		} else {
			return this.translationService.translate.instant('journey.notesScreen.dailyChallenge');
		}
	}

	getWeekDayLabel(date: string): string {
		const weekday = new Date(date).getDay();

		switch (weekday) {
			case 1:
				return this.translationService.translate.instant('journey.notesScreen.monday');
			case 2:
				return this.translationService.translate.instant('journey.notesScreen.tuesday');
			case 3:
				return this.translationService.translate.instant('journey.notesScreen.wednesday');
			case 4:
				return this.translationService.translate.instant('journey.notesScreen.thursday');
			case 5:
				return this.translationService.translate.instant('journey.notesScreen.friday');
			case 6:
				return this.translationService.translate.instant('journey.notesScreen.saturday');
			case 0:
				return this.translationService.translate.instant('journey.notesScreen.sunday');
			default:
				return '';
		}
	}

	triggerRedoForActivity(noteObject: NoteHistoryObject) {
		switch (noteObject.type) {
			case NoteActivityType.exercise:
				console.log('history training called for Exercise no. ' + noteObject.microtraining_uuid);
				this.savviMachineService.send(new GoToHistoryTraining(noteObject.microtraining_uuid));
				break;
			case NoteActivityType.daily_challenge:
				console.log('note history daily challenge called for daily challenge uuid. ' + noteObject.object_uuid);
				this.savviMachineService.send(new GoToDailyChallengeHistory(noteObject.object_uuid));
				break;
		}
	}

	triggerSaveHistoricNote() {
		this.editingMode = false;
		this.notesService.noteObject.text = this.noteObject.text;
		this.notesService.shouldNoteBeSaved = true;

		console.log('this.notesService.noteObject.text triggerSave', this.notesService.noteObject.text);

		console.log('noteObject', this.noteObject);
		this.savviMachineService.send(new saveHistoricNote());
	}
}
