import { Component, OnInit } from '@angular/core';
import { ErrorService } from '@app/_services/error.service';
import { Retry } from '@app/_xstate/savvi-machine.events';
import { SavviMachineService } from '@app/_xstate/savvi-machine.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-internet-problem-modal',
	templateUrl: './internet-problem-modal.component.html',
	styleUrls: ['./internet-problem-modal.component.scss']
})
export class InternetProblemModalComponent implements OnInit {
	private internetProblemModalShownSubscription: Subscription;

	constructor(
		public modalController: ModalController,
		private translateService: TranslateService,
		private errorService: ErrorService,
		private savviMachineService: SavviMachineService
	) {}

	ngOnInit() {
		this.internetProblemModalShownSubscription = this.errorService.internetProblemModalShown.subscribe((internetProblemModalShown) =>
			internetProblemModalShown ? null : this.dismissInternetProblemModal()
		);
	}

	ngOnDestroy() {
		this.internetProblemModalShownSubscription.unsubscribe();
	}

	get isAppCurrentlyRetryingRequests(): boolean {
		return this.errorService.getIsAppCurrentlyRetryingRequests();
	}

	dismissInternetProblemModal() {
		this.modalController.dismiss();
	}

	triggerRetry() {
		this.savviMachineService.send(new Retry());
	}
}
