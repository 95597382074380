import { TranslationService } from './_services/translation.service';
import { ApplicationRef, Component, ComponentFactoryResolver, ElementRef, EmbeddedViewRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AnimationController, ModalController, Platform, ToastController, Animation, LoadingController } from '@ionic/angular';
import { User } from './_models/user';
import { AuthService } from './_services/auth.service';
import { SavviMachineService } from './_xstate/savvi-machine.service';
import { Data, NavigationStart, Router } from '@angular/router';
import { filter, timeout } from 'rxjs/operators';
import { Event as NavigationEvent } from '@angular/router';
import { CheckForStreakCounterIncrease, CloseTextualNoteModal, GoToHomeTab, HandleError, OpenStreakCounterDetail } from './_xstate/savvi-machine.events';
import { PushNotificationService } from '@app/_services/push-notification.service';
import { GlobalRouterDecorator } from './_helpers/global-router-decorator';
import { Subscription } from 'xstate';
import { ErrorService } from './_services/error.service';
import { InternetProblemModalComponent } from './internet-problem-modal/internet-problem-modal.component';
import Config from '../config.json';
import { environment } from '@environments/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { RewardedMicroInteractionService } from './_services/rewarded-micro-interaction.service';
import { RewardedMicroInteractionComponent } from './rewarded-micro-interaction/rewarded-micro-interaction.component';
import { GamificationService } from './_services/gamification.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { NotesService } from './_services/notes.service';
import { StatusBar } from '@capacitor/status-bar';
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';
import { PackageService } from './_services/package.service';
import { TextualNote } from './_models/note';
import { HapticService } from './_services/haptic.service';
import { App } from '@capacitor/app';
import { LoaderService } from './_services/loader.service';
import { init } from 'xstate/lib/actionTypes';
import { NoteHistoryObject } from './_models/note-history-object';
import { LicenseSelfService } from './_services/license-self-service.service';
import { ErrorNotification } from 'rxjs';
import { ErrorNotificationService } from './_services/error-notification.service';
import { DataStorageService } from './_services/data-storage.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	apiUrl = Config && Config.API_URL && Config.API_URL != '$API_URL' ? Config.API_URL : environment.apiUrl;
	//user: User;
	private internetProblemModalShownSubscription: Subscription;
	private internetProblemModal: HTMLIonModalElement;

	private showRewardedMicroInteractionToastSubscription: Subscription;
	private showPlayerProgressToastSubscription: Subscription;
	private showSmallPointAnimationToastSubscription: Subscription;
	private showStreakCounterTutorialAnimationSubscription: Subscription;
	private globalErrorHandleSubscription: Subscription;

	public showStreakCounterAnimation: boolean;
	public showAchievementsAnimation: boolean;
	bubbleText: string;
	private textualNoteModalShownSubscription: Subscription;

	private initalAppDataIsLoadingSubscription: Subscription;

	private innerHeight: number;
	private innerWidth: number;
	private iPhoneXRatio: number = 812 / 375;
	private deviceRatio: number;

	private fadeInWithSlide: Animation;
	private fadeOutWithSlide: Animation;
	private fadeInWithSlideBtnBar: Animation;
	private fadeOutWithSlideBtnBar: Animation;

	private neededHeightForModalSheet: number = 650;

	private initialAppLoading;

	private historicNoteModalShownSubscription: Subscription;
	noteObject: NoteHistoryObject;

	showNoteModal: boolean = false;
	textualNote: TextualNote;

	private appHasEnteredBackground: boolean = false;
	private ready: Promise<void>;

	onDismissNoteModal = () => {
		this.savviMachineService.send(new CloseTextualNoteModal());
	};

	constructor(
		private platform: Platform,
		private authService: AuthService,
		private savviMachineService: SavviMachineService,
		private router: Router,
		private pushNotificationService: PushNotificationService,
		private errorService: ErrorService,
		private modalController: ModalController,
		private toastController: ToastController,
		private rewardedMicroInteractionService: RewardedMicroInteractionService,
		private gamificationService: GamificationService,
		private notesService: NotesService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private injector: Injector,
		private appRef: ApplicationRef,
		public animationCtrl: AnimationController,
		private translationService: TranslationService,
		private packageService: PackageService,
		private hapticService: HapticService,
		private loadingCtrl: LoadingController,
		private loadingService: LoaderService,
		private licenseSelfService: LicenseSelfService,
		private errorNotificationService: ErrorNotificationService,
		private dataStorageService: DataStorageService
	) {
		this.pushNotificationService.initializeOneSignal();
		this.initializeApp();
		this.showStreakCounterAnimation = false;
		//this.authService.user.subscribe(x => this.user = x);

		console.log('IMPORTANT: Config Object looks like this: ' + JSON.stringify(Config) + ' thus apiUrl has been set to ' + this.apiUrl);
	}

	async ngOnInit() {
		this.handleFirstLaunch();

		this.platform.ready().then(() => {
			App.addListener('pause', this.handleAppPause.bind(this));
			App.addListener('resume', this.handleAppResume.bind(this));
			App.addListener('appStateChange', this.handleAppStateChange);

			App.addListener('backButton', () => {
				console.log('Back button event has been fired and is ignored to avoid app closing!');
			});
		});

		console.log('Platform is: ' + this.platform.platforms().toString());
		//if(this.platform.is("desktop")) {
		window.resizeTo(390, 844);
		//}

		this.internetProblemModalShownSubscription = this.errorService.internetProblemModalShown.subscribe((internetProblemModalShown) =>
			internetProblemModalShown ? this.showInternetProblemModal() : null
		);
		this.showRewardedMicroInteractionToastSubscription = this.rewardedMicroInteractionService.showRewardedMicroInteractionToast.subscribe((showRewardedMicroInteractionToast) =>
			showRewardedMicroInteractionToast
				? setTimeout(() => {
						this.presentRMIToast();
					}, 500)
				: null
		);

		this.initalAppDataIsLoadingSubscription = this.loadingService.initalAppDataIsLoadingItem.subscribe((initalAppDataIsLoading) => {
			initalAppDataIsLoading ? this.showInitialAppLoading() : this.hideInitialAppLoading();
		});

		this.showPlayerProgressToastSubscription = this.gamificationService.showPlayerProgressToast.subscribe((showPlayerProgressToast) =>
			showPlayerProgressToast ? this.presentPlayerProgressToast() : null
		);

		this.showSmallPointAnimationToastSubscription = this.rewardedMicroInteractionService.showSmallPointAnimationToast.subscribe((showSmallPointAnimationToast) =>
			showSmallPointAnimationToast ? this.presentToastWithSmallPointsAnimation() : null
		);

		this.textualNoteModalShownSubscription = this.notesService.showTextualNoteModal.subscribe((textualNoteModalShown) =>
			textualNoteModalShown ? this.showTextualNoteModal() : this.closeTextualNoteModal()
		);

		this.globalErrorHandleSubscription = this.errorNotificationService.showErrorScreenSubjectItem.subscribe((error) => {
			this.triggerErrorHandling(error);
		});

		this.innerHeight = window.innerHeight;
		this.innerWidth = window.innerWidth;

		this.deviceRatio = this.innerHeight / this.innerWidth;

		this.showStreakCounterTutorialAnimationSubscription = this.gamificationService.showStreakCounterTutorialAnimationItem.subscribe((showStreakCounterTutorialAnimation) =>
			showStreakCounterTutorialAnimation ? this.openStreakCounterTutorialAnimation() : this.closeStreakCounterTutorialAnimation()
		);
	}

	async handleFirstLaunch() {
		console.log('Checking if this is the first launch of the app...');
		const firstLaunch = await this.dataStorageService.readValue('firstLaunch');
		if (!firstLaunch) {
			console.log('First launch detected. Initializing...');
			await this.dataStorageService.writeValue('firstLaunch', true);
		} else {
			const wasInBackground = await this.dataStorageService.readValue('appWasInBackground');
			if (wasInBackground && !this.appHasEnteredBackground) {
				console.log('App was forcefully killed. Restarting...');
				this.resetAppData();
				this.forceRestartApp();
			} else if (wasInBackground && this.appHasEnteredBackground) {
				console.log('App resumed normally.');
				await this.dataStorageService.removeValue('appWasInBackground');
				this.appHasEnteredBackground = false;
			} else {
				console.log('App resumed normally with dataStorage havin no appWasInBackground key.');
			}
		}
	}

	handleAppPause() {
		console.log('App has been paused');
		this.appHasEnteredBackground = true;
		this.dataStorageService.writeValue('appWasInBackground', true);
	}

	handleAppResume() {
		console.log('App has resumed');
		this.handleFirstLaunch();
	}

	forceRestartApp() {
		window.location.reload();
	}

	resetAppData() {
		console.log('Resetting app data...');
		this.dataStorageService.clear();
	}

	ionViewDidEnter() {}

	ngOnDestroy() {
		console.log('ngOnDestroy is executed in app component');
		this.internetProblemModalShownSubscription.unsubscribe();
		this.showRewardedMicroInteractionToastSubscription.unsubscribe();
		this.showPlayerProgressToastSubscription.unsubscribe();
		this.textualNoteModalShownSubscription.unsubscribe();
		this.historicNoteModalShownSubscription.unsubscribe();
		this.showStreakCounterTutorialAnimationSubscription.unsubscribe();
		this.globalErrorHandleSubscription.unsubscribe();

		App.removeAllListeners();
		this.resetAppData();
	}

	async initializeApp() {
		console.log('Capacitor Plugin Splash Screen available? - ' + Capacitor.isPluginAvailable('SplashScreen'));

		this.platform.ready().then(async () => {
			console.log('PLATFORM READY');
			console.log('PLATFORM IS: ' + this.platform.platforms().toString());
			if (this.platform.is('capacitor') || this.platform.is('cordova')) {
				console.log('PLATFORM IS CAPACITOR or CORDOVA');
				StatusBar.show();
				ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
			}

			//this.platform.backButton.subscribeWithPriority(9999, () => {
			document.addEventListener(
				'backbutton',
				function (event) {
					event.preventDefault();
					event.stopPropagation();
					console.log('Backbutton Event was fired and intentionally swallowed to avoid harming the application!');
				},
				false
			);
			//});
		});

		this.router.events
			.pipe(
				// The "events" stream contains all the navigation events. For this demo,
				// though, we only care about the NavigationStart event as it contains
				// information about what initiated the navigation sequence.
				filter((event: NavigationEvent) => {
					return event instanceof NavigationStart;
				})
			)
			.subscribe((event: NavigationStart) => {
				console.group('NavigationStart Event');
				// Every navigation sequence is given a unique ID. Even "popstate"
				// navigations are really just "roll forward" navigations that get
				// a new, unique ID.
				console.log('navigation id:', event.id);
				console.log('route:', event.url);
				// The "navigationTrigger" will be one of:
				// --
				// - imperative (ie, user clicked a link).
				// - popstate (ie, browser controlled change such as Back button).
				// - hashchange
				// --
				// NOTE: I am not sure what triggers the "hashchange" type.
				console.log('trigger:', event.navigationTrigger);
				if (event.navigationTrigger == 'popstate') {
					this.savviMachineService.send(new GoToHomeTab());
					console.log('Sending GoToHomeTab transition to Savvi Machine... Hope it works...');
				}

				// This "restoredState" property is defined when the navigation
				// event is triggered by a "popstate" event (ex, back / forward
				// buttons). It will contain the ID of the earlier navigation event
				// to which the browser is returning.
				// --
				// CAUTION: This ID may not be part of the current page rendering.
				// This value is pulled out of the browser; and, may exist across
				// page refreshes.
				if (event.restoredState) {
					console.warn('restoring navigation id:', event.restoredState.navigationId);
				}

				console.groupEnd();
			});

		GlobalRouterDecorator.enableHistory(false);
	}

	private handleAppStateChange(state: { isActive: boolean }) {
		console.log(`App state changed. Is now active: ${state.isActive}`);
		console.log(`Event occurred at: ${new Date().toISOString()}`);

		// Example of logging additional information
		if (state.isActive) {
			console.log('App has become active');
			// Add more checks and logs here
		} else {
			console.log('App has gone to background');
			// Log additional background state information
		}
	}

	async showInitialAppLoading() {
		if (!this.initialAppLoading) {
			this.initialAppLoading = await this.loadingCtrl.create({
				message: this.translationService.translate.instant('loading'),
				mode: 'ios',
				cssClass: 'app-startup-loading'
			});

			this.initialAppLoading.present();
		}
	}

	async hideInitialAppLoading() {
		console.log('hideInitialAppLoading is executed in app component and initialAppLoading is: ', this.initialAppLoading);
		if (this.initialAppLoading) {
			await this.initialAppLoading.dismiss();
			this.initialAppLoading = null;
		}
	}

	private sidePeekAnimationItem: AnimationItem;
	sidePeekAnimationOptions: AnimationOptions = {
		path: '../assets/animations/Streak_Hero_Peek_Side_Mouth.json',
		loop: false,
		autoplay: false
	};

	async openStreakCounterTutorialAnimation() {
		console.log('open Streak Counter Tutorial Animation');
		setTimeout(() => {
			this.initStreakCounterAnimation();
		}, 300);
		this.showStreakCounterAnimation = true;
		setTimeout(() => {
			this.startAnimation();
		}, 300);
	}

	animationCreated(animationItem: AnimationItem): void {
		console.log(animationItem);

		this.sidePeekAnimationItem = animationItem;
	}

	initStreakCounterAnimation(): void {
		this.fadeInWithSlide = this.animationCtrl
			.create()
			.addElement(document.querySelector('#speechBubble'))
			.beforeStyles({
				opacity: 0,
				'pointer-events': 'none'
			})
			.duration(300)
			.fromTo('opacity', '0', '1')
			.fromTo('transform', 'translateY(1rem)', 'translateY(0rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': 'none'
			});

		this.fadeOutWithSlide = this.animationCtrl
			.create()
			.addElement(document.querySelector('#speechBubble'))
			.duration(300)
			.fromTo('opacity', '1', '0')
			.fromTo('transform', 'translateY(0rem)', 'translateY(1rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': 'none'
			});

		this.fadeInWithSlideBtnBar = this.animationCtrl
			.create()
			.addElement(document.querySelector('#btnRow'))
			.beforeStyles({
				opacity: 0
			})
			.duration(300)
			.fromTo('opacity', '0', '1')
			.fromTo('transform', 'translateY(2rem)', 'translateY(0rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 1,
				'pointer-events': 'auto'
			});

		this.fadeOutWithSlideBtnBar = this.animationCtrl
			.create()
			.addElement(document.querySelector('#btnRow'))
			.beforeStyles({
				opacity: 1,
				'pointer-events': 'auto'
			})
			.duration(300)
			.fromTo('opacity', '0', '1')
			.fromTo('transform', 'translateY(2rem)', 'translateY(0rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 0,
				'pointer-events': 'none'
			});
	}

	startAnimation() {
		let ts1, ts2, ts3, ts4, ts5, textreset, offset;
		textreset = 300;
		offset = 2100;
		ts1 = offset + 6000;
		ts2 = ts1 + 2 * textreset + 5100;
		ts3 = ts2 + 2 * textreset + 8300;
		ts4 = ts3 + 2 * textreset + 7000;
		ts5 = ts4 + 2 * textreset + 5000;

		this.bubbleText = this.getBubbleText(1);
		this.sidePeekAnimationItem.stop();
		this.sidePeekAnimationItem.play();
		setTimeout(() => {
			this.fadeInWithSlide.stop();
			this.fadeInWithSlide.play();
		}, 200);
		setTimeout(() => {
			this.fadeOutWithSlide.stop();
			this.fadeOutWithSlide.play();
		}, ts1);
		setTimeout(() => {
			this.bubbleText = this.getBubbleText(2);
		}, ts1 + textreset);
		setTimeout(
			() => {
				this.fadeInWithSlide.stop();
				this.fadeInWithSlide.play();
			},
			ts1 + 2 * textreset
		);
		setTimeout(() => {
			this.fadeOutWithSlide.stop();
			this.fadeOutWithSlide.play();
		}, ts2);
		setTimeout(() => {
			this.bubbleText = this.getBubbleText(3);
		}, ts2 + textreset);
		setTimeout(
			() => {
				this.fadeInWithSlide.stop();
				this.fadeInWithSlide.play();
			},
			ts2 + 2 * textreset
		);
		setTimeout(() => {
			this.fadeOutWithSlide.stop();
			this.fadeOutWithSlide.play();
		}, ts3);
		setTimeout(() => {
			this.bubbleText = this.getBubbleText(4);
		}, ts3 + textreset);
		setTimeout(
			() => {
				this.fadeInWithSlide.stop();
				this.fadeInWithSlide.play();
			},
			ts3 + 2 * textreset
		);
		setTimeout(() => {
			this.fadeOutWithSlide.stop(), this.fadeOutWithSlide.play();
		}, ts4);
		setTimeout(() => {
			this.bubbleText = this.getBubbleText(5);
		}, ts4 + textreset);
		setTimeout(
			() => {
				this.fadeInWithSlide.stop();
				this.fadeInWithSlide.play();
			},
			ts4 + 2 * textreset
		);
		setTimeout(() => {
			this.fadeInWithSlideBtnBar.stop();
			this.fadeInWithSlideBtnBar.play();
		}, ts5);
	}

	replayAnimation() {
		// Reset the animation to its initial state
		const fadeOutAll: Animation = this.animationCtrl
			.create()
			.addElement(document.querySelector('#speechBubble'))
			.addElement(document.querySelector('#btnRow'))
			.duration(300)
			.fromTo('opacity', '1', '0')
			.fromTo('transform', 'translateY(0rem)', 'translateY(1rem)')
			.easing('ease-out')
			.afterStyles({
				opacity: 0,
				'pointer-events': 'none'
			});

		this.fadeInWithSlideBtnBar.stop();
		this.fadeInWithSlide.stop();
		this.fadeOutWithSlide.stop();

		fadeOutAll.play();
		setTimeout(() => {
			this.bubbleText = this.getBubbleText(1);
		}, 350);

		// Start the animation again after a slight delay
		setTimeout(() => {
			this.startAnimation();
		}, 700);
	}

	getBubbleText(index: number): string {
		if (index == 2 || index == 4) {
			if (this.packageService.isDuellingUnlocked()) {
				return this.translationService.translate.instant(`streakCounter.tutorialanimationText${index}Duel`);
			}
		}
		return this.translationService.translate.instant(`streakCounter.tutorialanimationText${index}`);
	}

	async closeStreakCounterTutorialAnimation() {
		console.log('close StreakCounter Tutorial Animation');
		this.showStreakCounterAnimation = false;
		this.savviMachineService.send(new OpenStreakCounterDetail());
	}

	async showInternetProblemModal() {
		// if the app is already showing the internet problem modal and is currently retrying,
		// then do not open the modal again but instead stop the loading animation and return
		// the modal to its normal appearance
		if (this.errorService.getIsAppCurrentlyRetryingRequests()) {
			this.errorService.setIsAppCurrentylRetryingRequests(false);
		} else {
			const modal = await this.modalController.create({
				component: InternetProblemModalComponent,
				cssClass: 'transparent-modal-background'
			});

			return await modal.present();
		}
	}

	async presentRMIToast() {
		console.log('presentRMIToast is executed in app component');
		await this.hapticService.rmiRecievedHaptic();
		const toast = await this.toastController.create({
			message: '',
			duration: 2000,
			position: 'top',
			cssClass: 'rmi-toast-styling',
			id: 'rmi-toast',
			htmlAttributes: {
				'aria-label': 'rmi-toast'
			}
		});

		toast.addEventListener('ionToastWillPresent', () => {
			this.changeToastAppearance();
		});

		setTimeout(async () => {
			await toast.present();
		}, 200);
	}

	async presentPlayerProgressToast() {
		console.log('presentPlayerProgressToast is executed in app component');
		const toast = await this.toastController.create({
			message: '',
			duration: 1300,
			position: 'top',
			cssClass: 'rmi-toast-styling',
			id: 'rmi-toast',
			htmlAttributes: {
				'aria-label': 'rmi-toast'
			}
		});

		toast.addEventListener('ionToastWillPresent', () => {
			this.changeToastAppearance();
		});

		await toast.present();

		this.gamificationService.resetShowPlayerProgressToastSubject();
	}

	async presentToastWithSmallPointsAnimation() {
		console.log('presentToastWithSmallPointsAnimation is executed in app component');
		await this.hapticService.rmiRecievedHaptic();
		const toast = await this.toastController.create({
			message: '',
			duration: 4000,
			position: 'top',
			cssClass: 'rmi-toast-styling',
			id: 'rmi-toast',
			htmlAttributes: {
				'aria-label': 'rmi-toast'
			}
		});

		toast.addEventListener('ionToastWillPresent', () => {
			this.changeToastAppearance();
		});

		toast.addEventListener('ionToastDidDismiss', () => {
			console.log('resetShowSmallPointAnimationToastSubject is executed in app component');
			this.gamificationService.resetShowSmallPointsAnimation();
			this.rewardedMicroInteractionService.resetShowSmallPointAnimationToastSubject();
			this.rewardedMicroInteractionService.setIsSmallPointAnimationRunning(false);
			this.savviMachineService.send(new CheckForStreakCounterIncrease());
			this.gamificationService.resetProgressDetails();
		});

		this.rewardedMicroInteractionService.setIsSmallPointAnimationRunning(true);

		setTimeout(() => {
			this.gamificationService.updateSmallPointsAnimation();
		}, 2800);

		setTimeout(async () => {
			await toast.present();
		}, 1000);
	}

	async showTextualNoteModal() {
		this.showNoteModal = true;
	}

	closeTextualNoteModal() {
		console.log('closeTextualNoteModal is executed in app component');
		this.showNoteModal = false;
	}

	changeToastAppearance() {
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RewardedMicroInteractionComponent);
		const componentRef = componentFactory.create(this.injector);
		this.appRef.attachView(componentRef.hostView);
		const rmiComponent = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

		rmiComponent.style.cssText = 'width: 100%; border-radius: 1rem; background-color: var(--toast-background-color);';

		//let rmiToastDirectShadow = document.querySelector("ion-toast::shadow");
		let rmiToast = document.querySelector('#rmi-toast');
		let container = rmiToast.shadowRoot.querySelector('.toast-container');

		let wrapper = rmiToast.shadowRoot.querySelector('.toast-wrapper');

		container.removeChild(container.children[0]);
		container.appendChild(rmiComponent);
	}

	async triggerErrorHandling(error: any) {
		console.log('this.dataStorageService.hasValue(appWasInBackground)', await this.dataStorageService.hasValue('appWasInBackground'));

		if (error == null || error === undefined) {
			console.log('Error is null or undefined');
		} else if (await this.dataStorageService.hasValue('appWasInBackground')) {
			// when errors are fired while app is returning from background, restart the app
			console.log('Error while App might have lost data. App is restarted', error);
			this.resetAppData();
			this.forceRestartApp();
		} else {
			// only forward errors when becoming active from background worked normally
			console.log('Error is being redirected to the state Machine', error);
			this.savviMachineService.send(new HandleError(error));
		}
	}
}
