import { NgModule, APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA, ApplicationRef, Compiler, Injector, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChildrenOutletContexts, Route, Router, RouteReuseStrategy, ROUTES, UrlSerializer } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './_services/auth.service';
import { AnimationController } from '@ionic/angular';
//import { appInitializer } from './_helpers/app.initializer';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// used to create fake backend
//import { fakeBackendProvider } from './_helpers/fake-backend';

import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { RouteErrorHandler } from './_helpers/route-error-handler';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PWAService } from './_services/pwa.service';
import { InstallPromptModule } from './app-welcome-screen/install-prompt/install-prompt.module';
import { Capacitor } from '@capacitor/core';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './_services/translation.service';
import { ApiService } from './_services/api.service';
import { ComponentLibraryModule as SavviComponentsAngularModule } from '@savvi-learning/savvi-components-angular';
import { provideLottieOptions, LottieComponent } from 'ngx-lottie';
import { NgxEchartsModule } from 'ngx-echarts';
import { QuillModule, QuillModules } from 'ngx-quill';
import { GlobalRouterDecorator } from './_helpers/global-router-decorator';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { InternetProblemModalModule } from './internet-problem-modal/internet-problem-modal.module';
import { SuccessfulResponseInterceptor } from './_interceptors/successful-response.interceptor';
import { NgxMatomoTrackerModule, NgxMatomoRouterModule } from 'ngx-matomo-client';
import { RewardedMicroInteractionComponent } from './rewarded-micro-interaction/rewarded-micro-interaction.component';
import { RewardedMicroInteractionModule } from './rewarded-micro-interaction/rewarded-micro-interaction.module';
import { AudioTimeConversionPipe } from './pipes/audio-time-conversion.pipe';
import { Filesystem } from '@capacitor/filesystem';
import { ResolveAnimationSrcPipe } from './pipes/resolve-animation-src.pipe';
import { ResolveImgSrcPipe } from './pipes/resolve-img-src.pipe';
import { ConfigFileAssetResolverPipesModule } from './pipes/config-file-asset-resolver-pipes.module';
import { SavviDefaultHeaderComponent } from './components/headers/savvi-default-header/savvi-default-header.component';
import { TextualNoteModalComponent } from './components/modals/textual-note-modal/textual-note-modal.component';
import { GlobalErrorHandler } from './_error/global-error-handler';
import { LongPressDirective } from './directives/long-press.directive';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { KeyValueStorage } from '@ionic-enterprise/secure-storage/ngx';


const initializer = (pwaService: PWAService) => () => pwaService.initPwaPrompt();

const quillModules: QuillModules = {
	toolbar: [
		['bold', 'italic', 'underline'],
		[{ list: 'ordered' }, { list: 'bullet' }]
	]
};

@NgModule({
	declarations: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent],
	imports: [
		SavviComponentsAngularModule,
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		LottieComponent,
		RewardedMicroInteractionModule,
		//Find configuration options here: https://ionicframework.com/docs/angular/config#config-options
		IonicModule.forRoot({
			mode: 'ios',
			hardwareBackButton: false,
			swipeBackEnabled: false
		}),
		TranslateModule.forChild({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ApiService]
			}
		}),
		AppRoutingModule,
		ReactiveFormsModule,
		// IF PWA FUNCTIONALITY SHOULD BE ENABLED FOR WEB BUILDS; PUT THIS TO TRUE
		/*Capacitor.isNativePlatform() ? ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }) : */ ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production && !environment.isNative
		}),
		InstallPromptModule,
		InternetProblemModalModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [ApiService]
			}
		}),
		NgxEchartsModule.forRoot({
			/**
			 * This will import all modules from echarts.
			 * If you only need custom modules,
			 * please refer to [Custom Build] section.
			 */
			echarts: () => import('echarts') // or import('./path-to-my-custom-echarts')
		}),
		QuillModule.forRoot({
			modules: quillModules,
			placeholder: 'Note Taking...'
		}),
		NgxMatomoTrackerModule.forRoot({
			siteId: '2',
			trackerUrl: 'https://savvi.matomo.cloud'
		}),
		NgxMatomoRouterModule,
		ConfigFileAssetResolverPipesModule,
		SavviDefaultHeaderComponent,
		TextualNoteModalComponent,
		NgCircleProgressModule.forRoot({
			backgroundOpacity: 1,
			backgroundStrokeWidth: 0,
			radius: 32,
			space: -16,
			maxPercent: 100,
			outerStrokeWidth: 16,
			outerStrokeColor: '#fec010',
			outerStrokeGradientStopColor: '#000000',
			outerStrokeLinecap: 'butt',
			innerStrokeColor: 'rgba(255,255,255,0.17)',
			innerStrokeWidth: 16,
			animateTitle: false,
			animationDuration: 500,
			animation: true,
			showTitle: false,
			showSubtitle: false,
			showUnits: false,
			showBackground: false
		}),
		IonicModule.forRoot({})
	],
	providers: [
		KeyValueStorage,
		AnimationController,
		Clipboard,
		Location,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: Router,
			useFactory: routerFactory,
			deps: [ApplicationRef, UrlSerializer, ChildrenOutletContexts, Location, Injector, Compiler, ROUTES]
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: APP_INITIALIZER, useFactory: initializer, multi: true, deps: [PWAService] },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: SuccessfulResponseInterceptor, multi: true, deps: [ApiService] },
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		//{ provide: ErrorHandler, useClass: RouteErrorHandler }
		/*fakeBackendProvider*/
		provideHttpClient(withInterceptorsFromDi()),
		provideLottieOptions({
			player: () => import('lottie-web')
		})
	]
})
export class AppModule {}

function flatten<T>(arr: T[][]): T[] {
	return Array.prototype.concat.apply([], arr);
}

export function routerFactory(
	rootComponentType: Type<any>,
	urlSerializer: UrlSerializer,
	rootContexts: ChildrenOutletContexts,
	location: Location,
	injector: Injector,
	compiler: Compiler,
	config: Route[][]
): Router {
	return new GlobalRouterDecorator();
	/*rootComponentType,
    urlSerializer,
    rootContexts,
    location,
    injector,
    compiler,
    flatten(config)*/
}
