import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ThemingService } from '@app/_services/theming.service';

@Pipe({
	name: 'resolveVariables'
})
@Injectable({
	providedIn: 'root'
})
export class ResolveVariablesPipe implements PipeTransform {
	constructor(private themingService: ThemingService) {}
	transform(value: string, ...args: unknown[]): string | number | boolean | object | null {
		if (value) {
			try {
				return this.themingService.getConfigVariable(value);
			} catch (error) {
				console.error(error);
				return undefined;
			}
		}
	}
}
