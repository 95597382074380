import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse, HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { BehaviorSubject, Observable, lastValueFrom, of, throwError } from 'rxjs';
import Config from '../../config.json';
import { environment } from '@environments/environment';
import { Location } from '@angular/common';
import { delay, mergeMap, retryWhen, shareReplay, map, tap, take } from 'rxjs/operators';

const DEFAULT_MAX_RETRIES = 3;
const DEFAULT_BACKOFF = 1250;

export function retryWithBackoff<T>(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES, backoffMs = DEFAULT_BACKOFF, errorListNoRetry: string[]) {
	let retries = maxRetry;

	return (src: Observable<HttpResponse<T>>) =>
		src.pipe(
			retryWhen((errors: Observable<HttpErrorResponse>) =>
				errors.pipe(
					mergeMap((error) => {
						if (error.status && !errorListNoRetry.includes(error.status.toString())) {
							console.log('retryWhen called with status code ' + error.status.toString() + ' thus included in errorListNoRetry is ' + errorListNoRetry.includes(error.status.toString()));
							if (retries-- > 0) {
								const backoffTime = delayMs + (maxRetry - retries) * backoffMs;
								return of(error).pipe(delay(backoffTime));
							}
						}
						return throwError(error);
					})
				)
			)
		);
}

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	apiUrl = Config && Config.API_URL && Config.API_URL != '$API_URL' ? Config.API_URL : environment.apiUrl;

	private _language: string;

	private errorListNoRetry: string[] = [];

	private requestCompletedSuccessfullySubject: BehaviorSubject<boolean>;
	requestCompletedSuccessfully: Observable<boolean>;

	constructor(
		private http: HttpClient,
		private location: Location
	) {
		this.requestCompletedSuccessfullySubject = new BehaviorSubject<boolean>(null);
		this.requestCompletedSuccessfully = this.requestCompletedSuccessfullySubject.asObservable();
	}

	setErrorListNoRetry(list: string[]) {
		this.errorListNoRetry = list;
	}

	get<T>(url: string, params?: HttpParams, query_parameter?: { value: string; key: string }[]): Observable<T> {
		//console.log(`${environment.apiUrl}${url}${params.toString()}`)
		let query = '?';
		if (query_parameter) {
			query_parameter.forEach((element) => {
				query += `${element.key}=${element.value}&`;
			});
			query.trim();
			query = query.slice(0, -1);
		}

		console.log(`${this.apiUrl}${url}${query}`);
		return this.http
			.get<T>(`${this.apiUrl}${url}${query}`, { headers: this.headers, observe: 'response', params: params, responseType: 'json' })
			.pipe(retryWithBackoff(1250, 3, 1250, this.errorListNoRetry), shareReplay())
			.pipe(map((bla) => bla.body));
	}

	async getAsset<T>(url: string, file_extension: string) {
		const headersConfig = {
			'Cache-Control': 'no-store'
		};

		let httpHeaders = new HttpHeaders(headersConfig);
		console.debug('getAsset', `${environment.OTCAssetsUrl}${url}`, httpHeaders);

		let responseType;
		if (file_extension === 'json') {
			responseType = 'json';
		} else if (file_extension === 'text') {
			responseType = 'text';
		} else {
			responseType = 'blob';
		}

		const response = this.http
			.get<T>(`${environment.OTCAssetsUrl}${url}`, { headers: httpHeaders, observe: 'response', responseType: responseType })
			.pipe(retryWithBackoff(1250, 3, 1250, this.errorListNoRetry), shareReplay())
			.pipe(take(1));

		return await lastValueFrom(response);
	}
	getS3<T>(url: string, params?: HttpParams): Observable<T> {
		//console.log(`${environment.apiUrl}${url}${params.toString()}`)

		const headersConfig = {
			'Cache-Control': 'no-store'
			//Accept: 'application/json'
		};

		let httpHeaders = new HttpHeaders(headersConfig);

		return this.http
			.get<T>(`${url}`, { headers: httpHeaders, observe: 'response', params: params })
			.pipe(retryWithBackoff(1250, 3, 1250, this.errorListNoRetry), shareReplay())
			.pipe(map((bla) => bla.body));
	}

	post<T>(url: string, data: Object = {}, params?: HttpParams): Observable<T> {
		return this.http
			.post<T>(`${this.apiUrl}${url}`, JSON.stringify(data), { headers: this.headers, observe: 'response', params: params, responseType: 'json' })
			.pipe(retryWithBackoff(1250, 3, 1250, this.errorListNoRetry), shareReplay())
			.pipe(map((bla) => bla.body));
	}

	downloadPDFFormat(url: string): Observable<Blob> {
		return this.http.get<Blob>(`${this.apiUrl}${url}`, { headers: this.PDFHeaders, observe: 'response', responseType: 'blob' as 'json' }).pipe(map((bla) => bla.body)); // This casting tells TypeScript to treat 'blob' as assignable to the expected 'json' type.
	}

	get PDFHeaders(): HttpHeaders {
		const headersConfig = {
			'Content-Language': this._language,
			//'Cache-Control': "no-cache",
			Accept: '*/*'
		};

		return new HttpHeaders(headersConfig);
	}

	put<T>(url: string, data: Object = {}): Observable<T> {
		return this.http
			.put<T>(`${this.apiUrl}${url}`, JSON.stringify(data), { headers: this.headers, observe: 'response', responseType: 'json' })
			.pipe(retryWithBackoff(1250, 3, 1250, this.errorListNoRetry), shareReplay())
			.pipe(map((bla) => bla.body));
	}

	delete<T>(url: string, body: any = {}): Observable<T> {
		return this.http
			.delete<T>(`${this.apiUrl}${url}`, { headers: this.headers, observe: 'response', body: body })
			.pipe(retryWithBackoff(1250, 3, 1250, this.errorListNoRetry), shareReplay())
			.pipe(map((bla) => bla.body));
	}

	set language(language: string) {
		this._language = language;
	}

	get headers(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
			'Content-Language': this._language,
			//'Cache-Control': "no-cache",
			Accept: 'application/json'
		};

		return new HttpHeaders(headersConfig);
	}

	public triggerRequestCompletedSuccessfullyEvent() {
		this.requestCompletedSuccessfullySubject.next(true);
	}
}
