import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipOfTheWeek, TipOfTheWeekInfographic, TipOfTheWeekTextual } from '@app/_models/tip-of-the-week';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class TipOfTheWeekService {
	private tipOfTheWeekTextual: TipOfTheWeekTextual;
	private tipOfTheWeekInfographic: TipOfTheWeekInfographic;

	private _tipOfTheWeek: TipOfTheWeek;

	constructor(private apiService: ApiService) {
		/*this.tipOfTheWeekTextual = {
      id: 1,
      previewText: "Finden Sie heraus, welche Zeit für Sie geeignet ist, und planen Sie Ihr Lernen dann.",
      title: "Finden Sie Ihre beste Zeit",
      image: "", //https://obs.eu-de.otc.t-systems.com/savvi/quizImages/training0.svg
      text: "Manche Menschen arbeiten besser am Morgen. Andere arbeiten besser nachts. Finden Sie heraus, welche Zeit für Sie geeignet ist, und planen Sie Ihr Lernen dann. Lernen Sie nicht viel später als zu Ihrer üblichen Schlafenszeit.",
      link: "https://www.savvi.hr"
    }

    this.tipOfTheWeekInfographic = {
      id: 1,
      previewText: "Finden Sie heraus, welche Zeit für Sie geeignet ist, und planen Sie Ihr Lernen dann.",
      image: "https://st.depositphotos.com/1986419/55098/v/600/depositphotos_550986192-stock-illustration-business-workflow-roadmap-infographic-flat.jpg",
      alternativeText: "Völlig sinnloser Alternativtext für eine Infographic, der über ein Bottom Sheet geöffnet wird. Zur besseren Unterscheidung muss dieser Text sich von dem unterscheiden, den wir versuchsweise für den textuellen Tipp der Woche benutzen.",
    }

    if(Math.random()<0.5) {
      this._tipOfTheWeek = this.tipOfTheWeekTextual;
    } else {
      this._tipOfTheWeek = this.tipOfTheWeekInfographic;
    }
    */
	}

	loadTipOfTheWeek(microtrainingId: number): Promise<TipOfTheWeek> {
		let params = new HttpParams();
		params = params.set('microtraining_id', microtrainingId.toString());

		return this.apiService
			.get<TipOfTheWeek>('/weeklyTip', params)
			.pipe(map((tipOfTheWeek) => (this._tipOfTheWeek = tipOfTheWeek)))
			.toPromise();
	}

	saveTOTWFinished() {
		let params = new HttpParams();
		params = params.set('totw_id', this._tipOfTheWeek.id);

		return this.apiService.post('/weeklyTip', undefined, params).toPromise();
	}

	loadTipOfTheWeekForRedoByUUID(totw_uuid: string): Promise<TipOfTheWeek> {
		let params = new HttpParams();
		params = params.set('totw_uuid', totw_uuid);

		return this.apiService
			.get<TipOfTheWeek>('/history/redo/tip_of_the_week', params)
			.pipe(map((tipOfTheWeek) => (this._tipOfTheWeek = tipOfTheWeek)))
			.toPromise();
	}

	get tipOfTheWeek(): TipOfTheWeek {
		return this._tipOfTheWeek;
	}
}
