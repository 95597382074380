import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorNotificationService } from '@app/_services/error-notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private errorNotificationService: ErrorNotificationService) {}
	handleError(error: any) {
		console.log('Here is the error before being checked for type:', error);

		if (error == null || error === undefined) {
			console.log('Error is null or undefined');
		} else {
			console.log('Error is not null or undefined');

			// Errors thrown inside of promises are wrapped in a PromiseRejectionEvent. To reveal the true error, we need to unwrap it.
			if (error.rejection instanceof Error) {
				// Unwrap the error
				error = error.rejection;
				console.log('Here is the error after being unwrapped:', error);
			}
			// Specific error handling
			if (error instanceof TypeError) {
				console.error('Type Error occurred:', error.message);
				this.errorNotificationService.notifyError(error);
			} else if (error instanceof RangeError) {
				console.error('Range Error occurred:', error.message);
				this.errorNotificationService.notifyError(error);
			}

			// General error handling
			else {
				// For Example
				//NG0100: ExpressionChangedAfterItHasBeenCheckedError
				console.error('An error occurred but itis not critical:', error.message);
			}
		}
	}
}
