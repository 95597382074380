import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '@app/_services/translation.service';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-install-prompt',
	templateUrl: './install-prompt.component.html',
	styleUrls: ['./install-prompt.component.scss']
})
export class InstallPromptComponent implements OnInit {
	@Input() mobileType: 'ios' | 'android';
	@Input() promptEvent?: any;
	constructor(
		private modalController: ModalController,
		private translationService: TranslationService
	) {}

	ngOnInit() {}

	public installPwa(): void {
		this.promptEvent.prompt();
		this.close();
	}

	public close() {
		this.modalController.dismiss();
	}
}
