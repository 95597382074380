import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Package } from '@app/_models/package';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { GenericGoal } from '@app/_models/generic-goal';
import { LongTermGoalList } from '@app/_models/long-term-goal-list';
import { Skill } from '@app/_models/skill';
import { BehaviorToGoalSelection } from '@app/_models/behavior-to-goal-selection';
import { LongTermGoal } from '@app/_models/long-term-goal';
import { Behavior } from '@app/_models/behavior';
import { LongTermGoalType } from '@app/_models/long-term-goal-type';
import { TrainingPlan } from '@app/_models/training-plan';
import { GamificationEnvironment } from '@app/_models/multi-package';

@Injectable({
	providedIn: 'root'
})
export class PackageService {
	private packages: Package[];
	private longTermGoalList: LongTermGoalList;
	private skillBehaviorList: Skill[];
	private behaviorToGoalSelection: BehaviorToGoalSelection;
	private selectedGenericGoals: GenericGoal[];
	private trainingPlan: TrainingPlan;
	private oldTrainingPlan: TrainingPlan;
	private behaviorList: Behavior[] = [];

	constructor(private apiService: ApiService) {}

	loadPackages(): Promise<Package[]> {
		let params = new HttpParams();
		params = params.set('active', 'true');
		return this.apiService
			.get<Package[]>('/packages', params)
			.pipe(map((packages) => (this.packages = packages)))
			.toPromise();

		/*.catch((error) => {

      }); */
	}

	// TODO remove if not needed anymore
	loadGenericGoalsSelection(): Promise<GenericGoal[]> {
		return this.apiService
			.get<GenericGoal[]>('/genericGoals/selection')
			.pipe(map((selectedGenericGoals) => (this.selectedGenericGoals = selectedGenericGoals)))
			.toPromise();
	}

	loadLongTermGoals(): Promise<LongTermGoalList> {
		return this.apiService
			.get<LongTermGoalList>('/long_term_goals')
			.pipe(map((longTermGoalList) => (this.longTermGoalList = longTermGoalList)))
			.toPromise();
	}

	saveJourneyBehaviorToGoalSelection(): Promise<any> {
		let trainingGoal: number = null;
		let genericGoal: number = null;

		if (this.longTermGoalList.type == LongTermGoalType.training_goal) {
			trainingGoal = this.behaviorToGoalSelection.goalID;
		} else {
			genericGoal = this.behaviorToGoalSelection.goalID;
		}

		const putData = {
			training_goal: trainingGoal,
			generic_goal: genericGoal,
			first_behavior: this.behaviorToGoalSelection.behaviorIDs[0],
			second_behavior: this.behaviorToGoalSelection.behaviorIDs[1],
			third_behavior: null
		};

		return this.apiService.put('/training/plan', putData).toPromise();
	}

	loadSkills(): Promise<Skill[]> {
		return this.apiService
			.get<Skill[]>('/training/skills')
			.pipe(map((skillBehaviors) => (this.skillBehaviorList = skillBehaviors)))
			.toPromise();
	}

	getFreeBehaviorID(): number {
		return this.getBehaviorList().filter((b_ID) => !this.behaviorToGoalSelection.behaviorIDs.includes(b_ID.id))[0].id;
	}

	loadTrainingPlan(): Promise<TrainingPlan> {
		return this.apiService
			.get<TrainingPlan>('/training/plan')
			.pipe(map((trainingPlan) => (this.trainingPlan = trainingPlan)))
			.toPromise();
	}

	saveJourneyBehaviorSelectionModification(): Promise<any> {
		let trainingGoal: number = null;
		let genericGoal: number = null;

		if (this.longTermGoalList.type == LongTermGoalType.training_goal) {
			trainingGoal = this.behaviorToGoalSelection.goalID;
		} else {
			genericGoal = this.behaviorToGoalSelection.goalID;
		}

		const putData = {
			training_goal: trainingGoal,
			generic_goal: genericGoal,
			first_behavior: this.trainingPlan.first_behavior.id,
			second_behavior: this.trainingPlan.second_behavior.id,
			third_behavior: this.trainingPlan.third_behavior.id
		};

		return this.apiService.put('/training/plan', putData).toPromise();
	}

	savePackageGoalSelection(package_goal_ids: number[]): Promise<number[]> {
		const postData = {
			package_goal_ids: package_goal_ids,
			package_id: this.getCurrentPackage().pk
		};

		return this.apiService.post<number[]>('/package/packageGoals/selection', postData).toPromise();
	}

	getCurrentPackage(): Package {
		return this.packages[0];
	}

	getLongTermGoals(): LongTermGoalList {
		return this.longTermGoalList;
	}

	onLongTermGoalSelected(longTermGoal_pk: number) {
		// if the same goal is selected, it will be unselected. If a new goal is selected it is set as the goal
		if (this.behaviorToGoalSelection != null && this.behaviorToGoalSelection.goalID == longTermGoal_pk) {
			this.behaviorToGoalSelection = null;
		} else {
			this.behaviorToGoalSelection = {
				goalID: longTermGoal_pk,
				behaviorIDs: []
			};
		}
	}

	isLongTermGoalSelected(longTermGoal_pk: number): boolean {
		if (this.behaviorToGoalSelection == null) return false;
		return this.behaviorToGoalSelection.goalID == longTermGoal_pk;
	}

	getCurrentlySelectedGoalForBehaviorSelection(): LongTermGoal {
		return this.longTermGoalList.long_term_goals.find((longTermGoal) => longTermGoal.pk == this.behaviorToGoalSelection.goalID);
	}

	getBehaviorToGoalSelection(): BehaviorToGoalSelection {
		return this.behaviorToGoalSelection;
	}

	getCurrentlySelectedGoalForBehaviorSelectionAsID(): number {
		return this.behaviorToGoalSelection.goalID;
	}

	getBehaviorList(): Behavior[] {
		if (this.behaviorList.length == 0)
			this.skillBehaviorList.forEach((skill) => {
				skill.behaviors.forEach((behavior) => {
					this.behaviorList.push(behavior);
				});
			});

		return this.behaviorList;
	}

	copyOldTrainingPlan() {
		this.oldTrainingPlan = JSON.parse(JSON.stringify(this.trainingPlan));
	}

	restoreOldTrainingPlan() {
		this.trainingPlan = this.oldTrainingPlan;
	}

	getBehaviorListForJourneyBehaviorSelectionModification(): Behavior[] {
		let behaviorList: Behavior[] = [];
		this.skillBehaviorList.forEach((skill) => {
			skill.behaviors.forEach((behavior) => {
				if (behavior.id != this.trainingPlan.first_behavior.id && behavior.id != this.trainingPlan.second_behavior.id) behaviorList.push(behavior);
			});
		});

		return behaviorList;
	}

	onBehaviorForGoalSelected(behavior_pk: number) {
		//remove entry if already selected
		if (this.selectedBehaviorIsAlreadySelectedForGoal(behavior_pk)) {
			// modify the behaviorID list and assign the modified list to the selection object
			this.behaviorToGoalSelection.behaviorIDs = this.behaviorToGoalSelection.behaviorIDs.filter((element) => element != behavior_pk);
		} else {
			// Add behvior if maximum of 2 selectable behaviors has not been reached
			if (this.behaviorToGoalSelection.behaviorIDs.length < 2) {
				// add behaviorID to the behaviorID list of the currently selected goal
				this.behaviorToGoalSelection.behaviorIDs.push(behavior_pk);
			}
		}
	}

	selectedBehaviorIsAlreadySelectedForGoal(behavior_pk: number): boolean {
		if (this.behaviorToGoalSelection.behaviorIDs.filter((element) => element == behavior_pk).length > 0) {
			return true;
		}
		return false;
	}

	areTwoBehaviorsSelected(): boolean {
		return this.behaviorToGoalSelection.behaviorIDs.length == 2;
	}

	getTrainingPlan(): TrainingPlan {
		return this.trainingPlan;
	}

	updateTrainingplan(behavior: Behavior) {
		this.trainingPlan.third_behavior = behavior;
	}

	getLongTermGoalQuestion(): string {
		return this.longTermGoalList.long_term_goals.find((goal) => goal.pk == this.behaviorToGoalSelection.goalID).question;
	}

	isOneGoalSelected(): boolean {
		if (this.behaviorToGoalSelection != null) {
			return true;
		}
		return false;
	}

	removeAllBehaviorFromSelectedGoal() {
		this.behaviorToGoalSelection.behaviorIDs = [];
	}

	resetBehaviorToGoalSelection() {
		this.behaviorToGoalSelection = null;
	}

	getSelectedGenericGoals(): GenericGoal[] {
		return this.selectedGenericGoals;
	}

	isPackageVideoAvailable(): boolean {
		if (this.packages[0].video_url) {
			return true;
		}

		return false;
	}

	getSkills(): Skill[] {
		return this.skillBehaviorList;
	}

	getPackageVideo(): string {
		return this.packages[0].video_url;
	}

	getPackageWeeks(): number {
		return this.packages[0].number_of_weeks;
	}

	getPackage(): Package {
		return this.packages[0];
	}

	getGamificationEnvironment(): GamificationEnvironment {
		return this.packages[0].gamification_environment;
	}

	isDuellingUnlocked(): boolean {
		return this.packages[0].duel_enabled;
	}

	isThreeSixtyDegreeFeedbackEnabled(): boolean {
		return this.packages[0].threesixty_degree_feedback_enabled;
	}

	isThreeSixtyDegreeBehaviorBasedTextFeedbackEnabled(): boolean {
		return this.packages[0].threesixty_degree_behavior_based_text_feedback_enabled;
	}

	getPackages(): Package[] {
		return this.packages;
	}

	getDaysUntilStartDate(): string {
		let currentDate: number = Date.now();
		let difference = new Date(this.getCurrentPackage().start_date).getTime() - currentDate;

		if (difference < 0) {
			return '0';
		}

		const days = Math.floor(difference / 1000 / 60 / 60 / 24);

		return days.toString();
	}

	getHoursUntilStartDate(): string {
		let currentDate: number = Date.now();
		let difference = new Date(this.getCurrentPackage().start_date).getTime() - currentDate;

		if (difference < 0) {
			return '0';
		}

		const hours = Math.floor((difference / 1000 / 60 / 60) % 24);

		return hours.toString();
	}

	getMinutesUntilStartDate(): string {
		let currentDate: number = Date.now();
		let difference = new Date(this.getCurrentPackage().start_date).getTime() - currentDate;

		if (difference < 0) {
			return '0';
		}

		const minutes = Math.floor((difference / 1000 / 60) % 60);

		return minutes.toString();
	}

	getSecondsUntilStartDate(): string {
		let currentDate: number = Date.now();
		let difference = new Date(this.getCurrentPackage().start_date).getTime() - currentDate;

		if (difference < 0) {
			return '0';
		}

		const seconds = Math.floor((difference / 1000) % 60);

		return seconds.toString();
	}

	isStartDateInPast(): boolean {
		return new Date(this.getCurrentPackage().start_date).getTime() < Date.now();
	}
}
